import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';

import Checkbox from "@mui/material/Checkbox";

import ButtonGroup from "../../../../components/ButtonGroup";
import PasswordField from "../../../../components/registration/PasswordField";

import * as styles from "../../../../styles/styles";
import "./styles.css";

const propTypes = {
  agbAccepted: PropTypes.bool,
  onChangePassword: PropTypes.func,
  onChangeAgb: PropTypes.func,
  onNextPage: PropTypes.func,
  onChangeObligationOfSecrecy: PropTypes.func,
  obligationOfSecrecyAccepted: PropTypes.bool,
  onChangeSubscribeToNewsletter: PropTypes.func,
  subscribeToNewsletter: PropTypes.bool,
  password: PropTypes.string,
  registerUser: PropTypes.func,
};

const PageTwo = ({
  agbAccepted,
  onNextPage,
  onChangePassword,
  onChangeAgb,
  onChangeObligationOfSecrecy,
  obligationOfSecrecyAccepted,
  onChangeSubscribeToNewsletter,
  subscribeToNewsletter,
  password,
  registerUser,
}) => {
  const { t } = useTranslation();

  const [passwordValid, setPasswordValid] = useState(false);
  return (
    <>
      <PasswordField
        className="personal-information-element-container"
        placeholder={t("registration.RegistrationDialog.PageTwo.PasswordFieldPlaceholder", {ns: 'components'})}
        password={password}
        isValid={(valid) => setPasswordValid(valid)}
        onChange={onChangePassword}
        margin={{ margin: `0 0 ${styles.spacing24} 0` }}
      />
      {/* AGB Checkbox */}
      <div className="page-two-legal-wrapper">
        <div style={{ display: "flex", alignItems: "center" }}>
          <Checkbox
            color="primary"
            checked={agbAccepted}
            inputProps={{
              "aria-label": t("registration.RegistrationDialog.PageTwo.chkAcceptAgb", {ns: 'components'}),
            }}
            onChange={() => onChangeAgb(!agbAccepted)}
            value="agb"
          />
          {/* Have to use <span> instead of <Typography> Componentent
            here because if using <Typography> it breaks stupid on small screens */}
          <div>
            <span className="page-two-text">
              {t("registration.RegistrationDialog.PageTwo.chkAcceptAgbTxt1", {ns: 'components'})}{" "}
              <a href="/agb" target="_BLANK" className="page-two-link">
                {t("registration.RegistrationDialog.PageTwo.chkAcceptAgbTxt2", {ns: 'components'})}
              </a>
            </span>
          </div>
        </div>

        {/* Legal Checkbox */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <Checkbox
            color="primary"
            checked={obligationOfSecrecyAccepted}
            inputProps={{
              "aria-label": t("registration.RegistrationDialog.PageTwo.chkPrivacyPolicy", {ns: 'components'}),
            }}
            onChange={() =>
              onChangeObligationOfSecrecy(!obligationOfSecrecyAccepted)
            }
            value="datenschutz"
          />
          <div>
            <span className="page-two-text">
              {t("registration.RegistrationDialog.PageTwo.chkPrivacyPolicyTxt1", {ns: 'components'})}{" "}
              <a href="/privacy" target="_BLANK" className="page-two-link">
                {t("registration.RegistrationDialog.PageTwo.chkPrivacyPolicyTxt2", {ns: 'components'})}
              </a>
            </span>
          </div>
        </div>

        {/* Newsletter Checkbox */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <Checkbox
            color="primary"
            checked={subscribeToNewsletter}
            inputProps={{
              "aria-label": t("registration.RegistrationDialog.PageTwo.chkNewsletter", {ns: 'components'}),
            }}
            onChange={() =>
              onChangeSubscribeToNewsletter(!subscribeToNewsletter)
            }
            value="newsletter"
          />
          <div>
            <span className="page-two-text">
              {t("registration.RegistrationDialog.PageTwo.chkNewsletterTxt1", {ns: 'components'})}{" "}
              <span style={{ fontWeight: "bold" }}>{t("registration.RegistrationDialog.PageTwo.chkNewsletterTxt2", {ns: 'components'})}</span>
              {t("registration.RegistrationDialog.PageTwo.chkNewsletterTxt3", {ns: 'components'})}
            </span>
          </div>
        </div>
      </div>
      <ButtonGroup
        spacingTop={true}
        spacingBottom={true}
        align="center"
        leftButtonText={t("button.btnBack", {ns: 'common'})}
        leftButtonClick={() => onNextPage(1)}
        rightButtonText={t("button.btnRegister", {ns: 'common'})}
        rightButtonClick={registerUser}
        rightButtonDisabled={
          !(passwordValid && agbAccepted && obligationOfSecrecyAccepted)
        }
      />
    </>
  );
};

PageTwo.propTypes = propTypes;
export default PageTwo;
