/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import { ListSubheader } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import * as styles from "../../../../../../styles/styles";
import ColoredCircle from "../../../../../../components/ColoredCircle";
import { updateMeasureLayerConfig } from "../../../../../../actions/measure_layer_configs";
import EditTopographicPointLayerDialog from "../../../../../../containers/dialogs/EditTopographicPointLayerDialog";
import "./styles.css";
import {
  COLOR_MAP,
  COLOR_MAP_NAMES,
} from "../../../../../../constants/colormapping";

const overrideStyles = (theme) => ({
  root: {
    backgroundColor: styles.darkBlueSuperLight,
    lineHeight: "60px",
    fontSize: "2rem",
  },
});

const propTypes = {
  classes: PropTypes.object,
  updateMeasureLayerConfig: PropTypes.func,
  topographicPointLayer: PropTypes.object,
};

const TopographicPointListSubHeader = ({
  topographicPointLayer,
  updateMeasureLayerConfig,
  classes,
}) => {
  const { t } = useTranslation();

  const [modalOpen, setModalOpen] = React.useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const onClickLayerEdit = (newTopographicPointLayer) => {
    updateMeasureLayerConfig({
      ...topographicPointLayer,
      ...newTopographicPointLayer,
    });
    closeModal();
  };

  return (
    <>
      {modalOpen &&
        renderEditTopographicPointLayerDialog(
          modalOpen,
          closeModal,
          topographicPointLayer,
          onClickLayerEdit
        )}
      <ListSubheader component="div" classes={{ root: classes.root }}>
        <div className="container">
          {COLOR_MAP[topographicPointLayer.color] ? (
            <ColoredCircle
              color={COLOR_MAP[topographicPointLayer.color]}
              name={COLOR_MAP_NAMES[topographicPointLayer.color]}
              size={"xm"}
            />
          ) : (
            <ColoredCircle />
          )}
          <span className="sub-header-text">
            {topographicPointLayer.layer_name
              ? topographicPointLayer.layer_name
              : t('Project.Map.MeasurementList.TopographicPointListSubHeader.txtSubheaderLine', {ns: 'screens_private'})}
          </span>
          <IconButton onClick={() => openModal()} size="large">
            <EditIcon fontSize={"small"} />
          </IconButton>
        </div>
      </ListSubheader>
    </>
  );
};

function renderEditTopographicPointLayerDialog(
  modalOpen,
  closeModal,
  topographicPointLayer,
  onClickLayerEdit
) {
  return (
    <EditTopographicPointLayerDialog
      topographicPointLayer={topographicPointLayer}
      modalOpen={modalOpen}
      onNegativeButtonClicked={() => closeModal()}
      onPositiveButtonClicked={onClickLayerEdit}
    />
  );
}

function dispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateMeasureLayerConfig,
    },
    dispatch
  );
}

TopographicPointListSubHeader.propTypes = propTypes;
export default connect(
  null,
  dispatchToProps
)(
  withStyles(overrideStyles, { withTheme: true })(TopographicPointListSubHeader)
);
