/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Grid from "@mui/material/Grid2";

import ConfiguratorHeader from "./ConfiguratorHeader";
import ConfiguratorComponentCard from "./ConfiguratorCards/ConfiguratorComponentCard";
import ConfiguratorTemplateCard from "./ConfiguratorCards/ConfiguratorTemplateCard";
import ConfiguratorMapLayerCard from "./ConfiguratorCards/ConfiguratorMapLayerCard";

import { idle } from "../../../constants/status";

import { syncMeasureLayerConfigTemplates } from "../../../actions/measureLayerConfigTemplates";
import { syncComponentCatalogs } from "../../../actions/componentCatalogs";
import { 
  syncMapLayers, 
  syncMapLayersToProjectAssignments 
} from "../../../actions/map_layer";

const propTypes = {
  history: PropTypes.object,
  syncStatusComponentCatalogs: PropTypes.string,
  syncStatusMeasureLayerTemplates: PropTypes.string,
  syncStatusMapLayers: PropTypes.string,
  syncStatusMapLayersToProjectAssignment: PropTypes.string,
  syncMeasureLayerConfigTemplates: PropTypes.func,
  syncComponentCatalogs: PropTypes.func,
  syncMapLayers: PropTypes.func,
  syncMapLayersToProjectAssignments: PropTypes.func
};

const Configurator = ({
  history,
  syncStatusComponentCatalogs,
  syncStatusMeasureLayerTemplates,
  syncStatusMapLayers,
  syncStatusMapLayersToProjectAssignment,
  syncMeasureLayerConfigTemplates,
  syncComponentCatalogs,
  syncMapLayers,
  syncMapLayersToProjectAssignments
}) => {
  React.useEffect(() => {
    if (syncStatusMeasureLayerTemplates === idle) {
      syncMeasureLayerConfigTemplates();
    }
    if (syncStatusComponentCatalogs === idle) {
      syncComponentCatalogs();
    }
    if (syncStatusMapLayers === idle) {
      syncMapLayers();
    }
    if (syncStatusMapLayersToProjectAssignment === idle) {
      syncMapLayersToProjectAssignments();
    }
  }, [
    syncStatusMeasureLayerTemplates, 
    syncStatusComponentCatalogs, 
    syncStatusMapLayers,
    syncStatusMapLayersToProjectAssignment]);

  return (
    <div>
      <ConfiguratorHeader />
      <div style={{ margin: "2.5rem" }}>
        <Grid container direction="row" spacing={4} columns={{xs:6, md:12}}>
          <Grid size={4}>
            <ConfiguratorTemplateCard key="template-card" history={history} />
          </Grid>
          <Grid size={4}>
            <ConfiguratorComponentCard key="component-card" history={history} />
          </Grid>
          <Grid size={4}>
            <ConfiguratorMapLayerCard key="map-layer-card" history={history} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

function dispatchToProps(dispatch) {
  return bindActionCreators(
    {
      syncMeasureLayerConfigTemplates,
      syncComponentCatalogs,
      syncMapLayers,
      syncMapLayersToProjectAssignments
    },
    dispatch
  );
}

function stateToProps(state) {
  const syncStatusComponentCatalogs = state.getIn([
    "componentCatalogs",
    "componentCatalogSyncStatus",
  ]);

  const syncStatusMeasureLayerTemplates = state.getIn([
    "measureLayerConfigTemplates",
    "measureLayerConfigTemplateSyncStatus",
  ]);

  const syncStatusMapLayers = state.getIn([
    "mapLayers",
    "mapLayersSyncStatus",
  ]);

  const syncStatusMapLayersToProjectAssignment = state.getIn([
    "mapLayers",
    "mapLayersToProjectAssignmentSyncStatus"
  ])

  return {
    syncStatusComponentCatalogs,
    syncStatusMeasureLayerTemplates,
    syncStatusMapLayers,
    syncStatusMapLayersToProjectAssignment
  };
}

Configurator.propTypes = propTypes;
export default connect(stateToProps, dispatchToProps)(Configurator);
