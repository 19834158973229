/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Link } from 'react-router-dom';

const propTypes = {
  classNameContainer: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  projects: PropTypes.object.isRequired,
}

const SearchResultsSmall = ({
  classNameContainer = '',
  onClick,
  projects
}) => {
  const { t } = useTranslation();
  
  const renderList = () => {
    return Object.keys(projects).map(key => {
      const project = projects[key];
      const id = project.id
      return <Link
        key={id}
        style={{ textDecoration: 'none', color: 'inherit' }}
        to={`/projects/${id}`}>
        <ListItemButton
          onClick={onClick}>
          <ListItemText
            primary={
              t('SearchButton.SearchButtonResult.SearchResultsSmall.listItemTextProjectTitle', { project: project, ns: 'components' })
            }
            secondary={
              t('SearchButton.SearchButtonResult.SearchResultsSmall.listItemTextJobOrder', { project: project, ns: 'components' })
            } />
        </ListItemButton>
      </Link >
    })
  }

  return <div
    className={`search-result-small-container ${classNameContainer}`}>
    {renderList()}
  </div >
}

SearchResultsSmall.propTypes = propTypes;
export default SearchResultsSmall;
