import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import Button from "@mui/material/Button";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Header from "../../../../components/Header";
import * as styles from "../../../../styles/styles";
import Tooltip from "@mui/material/Tooltip";
import { renderSnackbar } from "../../../../actions/snackbars";
import { createProjectFile } from "../../../../actions/projectFiles";
import { SEARCH_KEY_OBJECT } from "../../../../constants/project";
import SearchButtonResults from "../../../../components/SearchButton/SearchButtonResults";

import ProjectFileTable from "./ProjectFileTable";
// eslint-disable-next-line no-unused-vars
const maxSize = 10.0; // in MB

import FileUploadDialog from "../../../../containers/dialogs/FileUploadDialog";

const propTypes = {
  users: PropTypes.array,
  projects: PropTypes.object,
  project: PropTypes.object,
  projectFiles: PropTypes.object,
  renderSnackbar: PropTypes.func,
  createProjectFile: PropTypes.func,
};

const ProjectFiles = ({
  users,
  projects,
  project,
  projectFiles,
  createProjectFile
}) => {
  const { t } = useTranslation();

  const [modalOpen, setModalOpen] = React.useState(false);
  // eslint-disable-next-line no-unused-vars
  const [files, setFiles] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [results, setResults] = React.useState({});


  const uploadProjectFile = (fileFormData) => {
    createProjectFile(fileFormData, project.id)
  }

  const onClickFileUploadButton = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  }

  return (
    <>
    {modalOpen &&
        renderModalContent(
          closeModal,
          uploadProjectFile,
          modalOpen
        )}
    
    <div className="project-bottom-container">
      <Header>
        <SearchButtonResults
          onClickOutside={() => {
            setSearchTerm("");
            setResults({});
          }}
          projects={projects.toJS()}
          users={users}
          searchTerm={searchTerm}
          results={results}
          searchKeyList={SEARCH_KEY_OBJECT}
          search={(searchTerm) => setSearchTerm(searchTerm)}
          getResults={(results) => setResults(results)}
          onClick={() => setResults({})}
          placeholderText={t('Project.ProjectFiles.txtSearchButtonPlaceholder', {ns: 'screens_private'})}
        />
        <div className="header-right-side">
          <Tooltip title={t('Project.ProjectFiles.txtTooltipFileUpload', {ns: 'screens_private'})} arrow>
            <Button
              style={{ margin: `0 ${styles.spacing16} 0 0` }}
              color="primary"
              variant="contained"
              component="span"
              size="medium"
              onClick={() => {
                onClickFileUploadButton()
              }}
            >
              <AddCircleIcon style={{ margin: "0 1.2rem 0 0" }} />
                {t('button.btnUpload', {ns: 'common'})}
            </Button>
          </Tooltip>
        </div>
      </Header>
      <ProjectFileTable project={project} projectFiles={projectFiles} />
    </div>
    </>
  );
};

function renderModalContent(
  closeModal,
  uploadProjectFile,
  modalOpen
) {

  return <FileUploadDialog
    modalOpen={modalOpen}
    onCloseModal={() => closeModal()}
    uploadProjectFile={(files) => uploadProjectFile(files)}
    maxSize={maxSize}
    acceptFileTypes={"image/jpeg, image/png, application/pdf"}
    acceptedFileExtensions={["jpg", "jpeg", "png", "pdf"]}
  />
}

function dispatchToProps(dispatch) {
  return bindActionCreators(
    {
      renderSnackbar,
      createProjectFile,
    },
    dispatch
  );
}

ProjectFiles.propTypes = propTypes;
export default connect(null, dispatchToProps)(ProjectFiles);
