/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Divider from "@mui/material/Divider";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";

import {
  dropdownFinishedProjectElements,
  dropdownOpenProjectElements,
  dropdownArchivedProjectElements,
  DOWNLOAD_KEY,
  EDIT_KEY,
  ASSIGN_KEY,
  DELETE_KEY,
  RESET_KEY,
} from "../../../../../constants/contextMenuEntries";
import {
  PROJECTS_ROUTE,
  PROJECTS_ARCHIVED_ROUTE,
} from "../../../../../constants/projectList";
import {
  STATE_OPEN,
  STATE_DONE,
  STATE_ARCHIVED,
} from "../../../../../constants/project";

import { deleteProjects, resetProject } from "../../../../../actions/projects";

import ProjectInfo from "./ProjectInfo";
import ProjectModules from "./ProjectModules";

import Confirmation from "../../../../../components/dialogs/Confirmation";
import AssignUser from "../../../../../containers/dialogs/AssignUser";
import DownloadData from "../../../../../containers/dialogs/DownloadData";
import ProjectForm from "../../../../../containers/dialogs/ProjectForm";
import ContextMenu from "../../../../../containers/ContextMenu";

import * as styles from "../../../../../styles/styles";

import "./styles.css";

const propTypes = {
  routeToPrevious: PropTypes.func,
  deleteProjects: PropTypes.func,
  resetProject: PropTypes.func,
  history: PropTypes.object,
  project: PropTypes.object,
  changeProjectView: PropTypes.func,
  showProjectSidePanelActions: PropTypes.object,
};

const ProjectSideBar = ({
  project,
  deleteProjects,
  resetProject,
  changeProjectView,
  history,
  routeToPrevious,
  showProjectSidePanelActions,
}) => {
  const { t } = useTranslation();

  const dividerStyle = { backgroundColor: styles.white, opacity: 0.5 };
  const goBackRoute = useLocation().pathname.includes(PROJECTS_ARCHIVED_ROUTE)
    ? PROJECTS_ARCHIVED_ROUTE
    : PROJECTS_ROUTE;

  // Prepare State-Handling.
  const [modalOpen, setModalOpen] = React.useState(false);
  const [menuEntryKey, setMenuEntryKey] = React.useState(null);

  const closeModal = () => {
    setModalOpen(false);
    setMenuEntryKey(null);
  };

  const openModal = (menuEntryKey) => {
    const bool = typeof menuEntryKey === "string";
    setModalOpen(bool);
    setMenuEntryKey(bool ? menuEntryKey : false);
  };

  // Prepare Actions
  const deleteSelectedProject = () => {
    deleteProjects(project.id);
    closeModal();
    history.goBack();
  };

  const resetSelectedProject = () => {
    resetProject(project.id);
    closeModal();
  };

  // Prepare Constants
  var contextMenuEntries;
  switch (project.state) {
    case STATE_OPEN:
      contextMenuEntries = dropdownOpenProjectElements;
      break;
    case STATE_DONE:
      contextMenuEntries = dropdownFinishedProjectElements;
      break;
    case STATE_ARCHIVED:
      contextMenuEntries = dropdownArchivedProjectElements;
      break;
  }

  return (
    (<div className="project-sidebar-container">
      {modalOpen &&
        renderModalContent(
          t,
          project,
          modalOpen,
          menuEntryKey,
          closeModal,
          deleteSelectedProject,
          resetSelectedProject
        )}
      <Link
        to={goBackRoute}
        style={{
          position: "absolute",
          left: "1rem",
          top: "2rem",
        }}
      >
        <IconButton
          style={{
            color: styles.white,
            textDecoration: "none",
          }}
          size="large">
          <ArrowBackIcon />
        </IconButton>
      </Link>
      {contextMenuEntries && (
        <ContextMenu
          color={styles.white}
          contextMenuEntries={contextMenuEntries}
          onMenuEntryClicked={(menuEntryKey) =>
            handleOnMenuEntryClicked(menuEntryKey, openModal)
          }
          style={{
            position: "absolute",
            right: "1rem",
            top: "2rem",
          }}
        />
      )}
      <ProjectInfo project={project} />
      <Divider variant="middle" style={{ ...dividerStyle }} sx={{
        opacity: "0.6"
      }} />
      <ProjectModules
        project={project}
        history={history}
        changeProjectView={changeProjectView}
        showProjectSidePanelActions={showProjectSidePanelActions}
      />
    </div>)
  );
};

function handleOnMenuEntryClicked(menuEntryKey, openModal) {
  openModal(menuEntryKey);
}

function renderModalContent(
  t,
  project,
  modalOpen,
  menuEntryKey,
  closeModal,
  deleteSelectedProject,
  resetSelectedProject
) {
  const { title, inserted_at } = project;

  switch (menuEntryKey) {
    case EDIT_KEY:
      return (
        <ProjectForm
          edit={true}
          modalOpen={modalOpen}
          onClick={() => closeModal()}
          onClose={() => closeModal()}
          project={project}
        />
      );
    case ASSIGN_KEY:
      return (
        <AssignUser
          project={project}
          modalOpen={modalOpen}
          onClick={closeModal}
          onClose={closeModal}
        />
      );
    case DELETE_KEY:
      return (
        <Confirmation
          highlightedText={title}
          headline={t('Confirmation.deleteProject.txtHeadline', {ns: 'common'})}
          leftSideText={t('Confirmation.deleteProject.txtLeftSideText', {ns: 'common'})}
          modalOpen={modalOpen}
          onClose={() => closeModal()}
          onClick={deleteSelectedProject}
          rightSideText={t('Confirmation.deleteProject.txtRightSideText', {insertedAt: moment(inserted_at).format("DD.MM.YYYY"), ns: 'common'})}
          textButton={t('Confirmation.deleteProject.txtTextButton', {ns: 'common'})}
        />
      );
    case DOWNLOAD_KEY:
      return (
        <DownloadData
          bulkAction={false}
          modalOpen={modalOpen}
          projects={[project.id]}
          project={project}
          onClose={() => closeModal()}
          onClick={() => closeModal()}
        />
      );
    case RESET_KEY:
      return (
        <Confirmation
          highlightedText={title}
          headline={t('Confirmation.resetProject.txtTextButton', {ns: 'common'})}
          leftSideText={t('Confirmation.resetProject.txtTextButton', {ns: 'common'})}
          modalOpen={modalOpen}
          onClose={() => closeModal()}
          onClick={resetSelectedProject}
          rightSideText={t('Confirmation.resetProject.txtTextButton', {ns: 'common'})}
          textButton={t('Confirmation.resetProject.txtTextButton', {ns: 'common'})}
        />
      );
    default:
      break;
  }
}

function dispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteProjects,
      resetProject,
    },
    dispatch
  );
}

ProjectSideBar.propTypes = propTypes;
export default connect(null, dispatchToProps)(ProjectSideBar);
