/* eslint-disable no-unused-vars */
import { fromJS, Map } from "immutable";
import {
  SET_MEASURE_LAYER_CONFIG,
  SET_MEASURE_LAYER_CONFIGS,
  EDIT_MEASURE_LAYER_CONFIG,
  REMOVE_MEASURE_LAYER_CONFIG,
} from "../constants/actions/actions";

const initialState = fromJS({
  loading: false,
  new: 0,
  items: fromJS({}),
});

export const prepareItems = (array) => {
  return Map(array.map((item) => [item.id.toString(), fromJS(item)]));
};

export function measureLayerConfigs(state = initialState, action) {
  switch (action.type) {
    case SET_MEASURE_LAYER_CONFIGS:
      return state.mergeIn(["items"], prepareItems(action.payload));

    case SET_MEASURE_LAYER_CONFIG:
      return state.setIn(
        ["items", action.payload.id.toString()],
        fromJS(action.payload.item)
      );

    case EDIT_MEASURE_LAYER_CONFIG: {
      let { id, item } = action.payload;
      return state.getIn([`items`, id.toString()])
        ? state.mergeIn([`items`, id.toString()], fromJS(item))
        : state;
    }
    case REMOVE_MEASURE_LAYER_CONFIG:
      return state.deleteIn([`items`, action.payload.id.toString()]);

    default:
      return state;
  }
}
