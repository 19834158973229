import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import * as styles from '../../styles/styles';

const propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
  render: PropTypes.func,
  renderTitle: PropTypes.func,
  renderContent: PropTypes.func,
  renderHint: PropTypes.func,
  renderButtonGroup: PropTypes.func,
  showHint: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  maxWidth: PropTypes.string
};

const CustomDialog = ({
  showCloseButton = false,
  open = false,
  onClose,
  onClick,
  render,
  renderTitle,
  renderHint,
  renderContent,
  renderButtonGroup,
  showHint = false,
  maxWidth = "sm"
}) => {
  return (
    <Dialog
      maxWidth={maxWidth}
      fullWidth={true}
      open={open}
      onClose={onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'>
      <div>
        {
          showCloseButton &&
          <IconButton
            style={{
              position: 'absolute',
              color: styles.darkBlueMediumDark,
              top: '0.2rem',
              right: '0.2rem',
              cursor: 'pointer',
            }}
            onClick={onClose}
            size="large">
            <CloseIcon />
          </IconButton>
        }

        {renderTitle && renderTitle()}
        {showHint && renderHint()}
        {renderContent && renderContent()}
        {renderButtonGroup && renderButtonGroup(onClick, onClose)}

        {render && render(onClick, onClose)}
      </div>
    </Dialog>
  );
}

CustomDialog.propTypes = propTypes;
export default CustomDialog;