/* eslint-disable no-unused-vars */
import DialogContent from "@mui/material/DialogContent";
import List from "@mui/material/List";
import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import React from "react";

import SingleActionDownloadItems from "./SingleActionDownloadItems";
import { subheadline } from "../styles";

const propTypes = {
  classes: PropTypes.object,
  firstCategoryFiles: PropTypes.array,
  secondCategoryFiles: PropTypes.array,
  thirdCategoryFiles: PropTypes.array,
  project: PropTypes.object,
  downloadAllFiles: PropTypes.bool,
  fileDownloadStatus: PropTypes.bool
};

const overrideStyles = (theme) => ({
  subheadline,
});

const SingleAction = ({
  classes,
  firstCategoryFiles,
  secondCategoryFiles,
  thirdCategoryFiles,
  project,
  downloadAllFiles,
  fileDownloadStatus
}) => {
  const { t } = useTranslation();

  return (
    <>
      <DialogContent>        
        <Typography color="primary" variant="body2">
          {t('dialogs.DownloadData.SingleAction.txtDocumentation', {ns: 'containers'})}
        </Typography>
        <List>
          <SingleActionDownloadItems
            project={project}
            files={firstCategoryFiles}
            downloadAllFiles={downloadAllFiles}
            fileDownloadStatus={fileDownloadStatus}
          />
        </List>
        <Typography
          classes={{ root: classes.subheadline }}
          color="primary"
          variant="body2"
        >
          {t('dialogs.DownloadData.SingleAction.txtGeodata', {ns: 'containers'})}
        </Typography>
        <List>
          <SingleActionDownloadItems
            project={project}
            files={secondCategoryFiles}
            downloadAllFiles={downloadAllFiles}
            fileDownloadStatus={fileDownloadStatus}
          />
        </List>
        <Typography
          classes={{ root: classes.subheadline }}
          color="primary"
          variant="body2"
        >
          {t('dialogs.DownloadData.SingleAction.txtLocalCoordinates', {ns: 'containers'})}
        </Typography>
        <List>
          <SingleActionDownloadItems
            project={project}
            files={thirdCategoryFiles}
            downloadAllFiles={downloadAllFiles}
            fileDownloadStatus={fileDownloadStatus}
          />
        </List>
      </DialogContent>
    </>
  );
};

SingleAction.propTypes = propTypes;
export default withStyles(overrideStyles, { withTheme: true })(SingleAction);
