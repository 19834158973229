import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import Column from "../../layout/Column";

const propTypes = {
  modalOpen: PropTypes.func,
};

const UserProfileAppSettings = ({ modalOpen }) => {
  const { t } = useTranslation();

  return (
    <>
      <Column side="left">
        <Typography color="primary" variant="h2">
          {t('userProfile.UserProfileAppSettings.title', {ns: 'components'})}
        </Typography>
      </Column>

      <Column side="right">
        <div style={{ margin: `6rem 0 0 0` }}>
          <Button
            size="medium"
            variant="contained"
            color="primary"
            onClick={() => modalOpen()}
          >
            {t('button.btnOpenAppSettings', {ns: 'common'})}
          </Button>
        </div>
      </Column>
    </>
  );
};

UserProfileAppSettings.propTypes = propTypes;
export default UserProfileAppSettings;
