/* eslint-disable no-unused-vars */
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import React, { useState } from "react";
import CustomDialog from "../../../components/CustomDialog";
import Button from "@mui/material/Button";

import {
  DXF,
  DXF_LOCAL,
  DXF_8_DIGIT,
  IMAGES,
  LOCAL,
  PDF,
  SHAPE,
  TRANSFORMATION_RESULT,
  TXT,
  WKT,
  DATA_MONITOR_RESULT,
} from "../../../constants/download";
import BulkAction from "./BulkAction";
import SingleAction from "./SingleAction";

const propTypes = {
  bulkAction: PropTypes.bool,
  classes: PropTypes.object,
  downloadDataFail: PropTypes.bool,
  modalOpen: PropTypes.bool,
  projects: PropTypes.array,
  project: PropTypes.object,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
};

const DownloadData = ({
  bulkAction = false,
  modalOpen,
  projects,
  project,
  onClick,
  onClose,
}) => {
  const { t } = useTranslation();

  const [state, setState] = useState({
    closeButton: false,
    downloadAllButton: false,
    headlineBulk: t('dialogs.DownloadData.txtSelectFileToDownload', {ns: 'containers'}),
    headlineSingle: t('dialogs.DownloadData.txtFileDownload', {ns: 'containers'}),
  });

  const [downloadAllFiles, setDownloadAllFiles] = useState(false);
  const [fileDownloadStatus, setFileDownloadStatus] = useState(false);

  const firstCategoryFiles = [PDF, IMAGES];
  const secondCategoryFiles = [
    WKT,
    DXF,
    DXF_8_DIGIT,
    SHAPE,
    TRANSFORMATION_RESULT,
    DATA_MONITOR_RESULT,
  ];
  const thirdCategoryFiles = [TXT, LOCAL, DXF_LOCAL];

  /**
   * Toggles downloadAllFiles to true and then sets a timeout to toggle it
   * back to false. This is used to trigger the download of all files.
   */
  const handleOnDownloadAllClicked = () => {
    setDownloadAllFiles(true)
    setFileDownloadStatus(true)

    setTimeout(() => {
      setDownloadAllFiles(false)
    }, 0);
  }

  return (
    <CustomDialog
      open={modalOpen}
      onClick={onClick}
      onClose={onClose}
      showCloseButton={state.closeButton ? true : !bulkAction}
      renderTitle={() => {
        return (
          <DialogTitle id="alert-dialog-title" style={{marginRight: '48px'}}>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <div>
                {bulkAction ? state.headlineBulk : state.headlineSingle}
              </div>
              
              {(!bulkAction || state.downloadAllButton) &&
              <Button
                disabled={false}
                style={{ marginLeft: `auto` }}
                color="primary"
                variant="contained"
                size="medium"
                onClick={handleOnDownloadAllClicked}
              >
                {t('dialogs.DownloadData.btnDownloadAll', {ns: 'containers'})}
              </Button>}
            </div>
          </DialogTitle>
        );
      }}
      renderContent={() => {
        return (
          <>
            {bulkAction ? (
              <BulkAction
                firstCategoryFiles={firstCategoryFiles}
                secondCategoryFiles={secondCategoryFiles}
                thirdCategoryFiles={thirdCategoryFiles}
                setNextPage={() => {
                  setState({
                    ...state,
                    closeButton: true,
                    downloadAllButton: true,
                    headlineBulk: t('dialogs.DownloadData.txtFileDownload', {ns: 'containers'}),
                  });
                }}
                onClick={onClick}
                onClose={onClose}
                projects={projects}
                downloadAllFiles={downloadAllFiles}
                fileDownloadStatus={fileDownloadStatus}
              />
            ) : (
              <SingleAction
                firstCategoryFiles={firstCategoryFiles}
                secondCategoryFiles={secondCategoryFiles}
                thirdCategoryFiles={thirdCategoryFiles}
                project={project}
                downloadAllFiles={downloadAllFiles}
                fileDownloadStatus={fileDownloadStatus}
              />
            )}
          </>
        );
      }}
      renderButtonGroup={(onClick, onClose) => {}}
    />
  );
};

DownloadData.propTypes = propTypes;
export default DownloadData;
