/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import withStyles from "@mui/styles/withStyles";

import { selectProjects, unselectProjects } from "../../actions/data";
import { deleteProjects, updateProjects } from "../../actions/projects";
import Confirmation from "../../components/dialogs/Confirmation";
import { STATE_DONE, STATE_ARCHIVED } from "../../constants/project";
import { hasOrganizationFeatureFlag } from "../../sharedFunctions/organization";
import { PROJECT_RESTRICTED_SINGLE } from "../../constants/featureFlags";

import * as styles from "../../styles/styles";
import DownloadData from "../dialogs/DownloadData";
import "./styles.css";

const overrideStyles = (theme) => ({
  checkbox: {
    marginRight: "1rem",
    marginLeft: "1.3rem",
  },
});

const propTypes = {
  classes: PropTypes.object,
  allProjectsSelected: PropTypes.bool,
  deleteProjects: PropTypes.func,
  updateProjects: PropTypes.func,
  projects: PropTypes.object.isRequired,
  showBulkActions: PropTypes.bool,
  selectProjects: PropTypes.func.isRequired,
  selectedProjects: PropTypes.object.isRequired,
  unselectProjects: PropTypes.func.isRequired,
  isProjectRestrictedSingle: PropTypes.bool,
};

const BulkActionsMenu = ({
  allProjectsSelected = false,
  classes,
  deleteProjects,
  updateProjects,
  projects,
  selectProjects,
  selectedProjects,
  showBulkActions = false,
  unselectProjects,
  isProjectRestrictedSingle,
}) => {
  const { t } = useTranslation();
  const [state, setState] = useState({
    modalOpen: false,
    key: null,
  });
  const titles = selectedProjects.map((id) => projects.get(id).get("title"));
  const selectedProjectsInt = selectedProjects.map(Number).toJS();
  const oneTitle = titles.size === 1;

  // Check if all selected projects have the project state done.
  // If that is the case a archive all selected projects button
  // can be shown.
  const filteredProjectsDone = selectedProjects.filter((projectId) => {
    return projects.get(projectId).get("state") === STATE_DONE;
  });

  // Get the list of selected project id and call the
  // deleteProjects() function. This function performs for
  // every Project a Delete API-Call to remove it from the Backend.
  const deleteSelectedProjects = () => {
    deleteProjects(selectedProjectsInt);
    closeAction();
  };

  // Take the list of selected Projects and set their state to
  // archived. After that unselect all previously selected projects
  // and call the function updateProjects to update the counterparts
  // on the backend.
  const archiveSelectedProjects = () => {
    const selectedProjectObjects = selectedProjects.map((id) => {
      return projects.get(id).set("state", STATE_ARCHIVED);
    });
    unselectProjects(selectedProjects);
    updateProjects(selectedProjectObjects.toJS());
    closeAction();
  };

  // Handle changes to the select/unselect all Projects
  // Checkbox.
  const onChange = () => {
    const [...projectKeys] = projects.keys();
    allProjectsSelected
      ? unselectProjects(projectKeys)
      : selectProjects(projectKeys);
  };

  // Handler to close any opened Modal.
  const closeAction = () => {
    setState({
      modalOpen: false,
      key: null,
    });
  };

  // Every Action in the BulkActionsMenu has its own
  // Modal. This function makes sure to show for every
  // Action the correct Modal.
  const renderClick = () => {
    switch (state.key) {
      case "delete":
        return (
          <Confirmation
            highlightedText={titles.join(", ")}
            headline={t('Confirmation.delete.txtHeadline', {ns: 'common'})}
            leftSideText={t('Confirmation.delete.txtLeftText.key', {count: titles.size, ns: 'common'})}
            modalOpen={state.modalOpen}
            onClose={() => closeAction()}
            onClick={deleteSelectedProjects}
            rightSideText={t('Confirmation.delete.txtRightText', {ns: 'common'})}
            textButton={t('Confirmation.delete.txtTextButton', {ns: 'common'})}
          />
        );
      case "download":
        return (
          <DownloadData
            bulkAction={true}
            modalOpen={state.modalOpen}
            projects={selectedProjectsInt}
            onClick={() => closeAction()}
            onClose={() => closeAction()}
          />
        );
      case "archive":
        return (
          <Confirmation
            highlightedText={titles.join(", ")}
            headline={t('Confirmation.archive.txtHeadline', {ns: 'common'})}
            leftSideText={t('Confirmation.archive.txtLeftText.key', {count: titles.size, ns: 'common'})}
            modalOpen={state.modalOpen}
            onClose={() => closeAction()}
            onClick={archiveSelectedProjects}
            rightSideText={t('Confirmation.archive.txtRightText.key', {count: titles.size, ns: 'common'})}
            textButton={t('Confirmation.archive.txtTextButton', {ns: 'common'})}
          />
        );
      default:
        break;
    }
  };

  const renderDeleteButton = () => (
    <Button
      color="primary"
      onClick={() => setState({ ...state, modalOpen: true, key: "delete" })}
      size="medium"
      variant="contained"
    >
      {t('button.btnDelete', {ns: 'common'})}
    </Button>
  );

  const renderDownloadButton = () => (
    <Button
      color="primary"
      disabled={
        !selectedProjects
          .map((id) => projects.getIn([id, "state"]))
          .includes("done")
      }
      onClick={() => setState({ ...state, modalOpen: true, key: "download" })}
      style={{ margin: `0 0 0 ${styles.spacing08}` }}
      size="medium"
      variant="contained"
    >
      {t('button.btnDownload', {ns: 'common'})}
    </Button>
  );

  const renderArchiveButton = () => (
    <Button
      color="primary"
      onClick={() => setState({ ...state, modalOpen: true, key: "archive" })}
      style={{ margin: `0 0 0 ${styles.spacing08}` }}
      size="medium"
      variant="contained"
    >
      {t('button.btnArchive', {ns: 'common'})}
    </Button>
  );

  return (
    <div
      className={`bulk-actions-menu-container ${
        showBulkActions ? "bulk-actions-menu-display" : "bulk-actions-menu-hide"
      }`}
    >
      {renderClick()}
      <Checkbox
        classes={{ root: classes.checkbox }}
        checked={true}
        onChange={onChange}
      />
      {renderDeleteButton()}
      {renderDownloadButton()}
      {!isProjectRestrictedSingle &&
        selectedProjects.size === filteredProjectsDone.size &&
        renderArchiveButton()}
    </div>
  );
};

function dispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteProjects,
      updateProjects,
      selectProjects,
      unselectProjects,
    },
    dispatch
  );
}

function stateToProps(state) {
  const data = state.get("data");
  const selectedProjects = data.get("selection");
  const projects = state.getIn(["projects", "items"]);

  const isProjectRestrictedSingle = hasOrganizationFeatureFlag(
    state,
    PROJECT_RESTRICTED_SINGLE
  );

  return {
    allProjectsSelected: selectedProjects.size === projects.size,
    projects,
    selectedProjects,
    showBulkActions: selectedProjects.size > 0,
    isProjectRestrictedSingle,
  };
}

BulkActionsMenu.propTypes = propTypes;
const styledBulkActionsMenu = withStyles(overrideStyles, { withTheme: true })(
  BulkActionsMenu
);
export default connect(stateToProps, dispatchToProps)(styledBulkActionsMenu);
