import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';

import { Typography } from "@mui/material";

import { success, pending, fail } from "../../../../constants/status";
import { REGISTRATION_FROM_BROWSER_PATHNAME } from "../../../../constants/registrationConstants";
import Spinner from "../../../../atoms/Spinner";

import qrCodeGooglePlayStore from "../../../../assets/images/qr_code_google_play_store.png";

import "./styles.css";

const propTypes = {
  registerUserStatus: PropTypes.string,
  pathNameState: PropTypes.string,
};

const PageThree = ({ registerUserStatus, pathNameState }) => {
  const { t } = useTranslation();

  const renderPageThreeBrowser = () => {
    if (registerUserStatus === pending) return <Spinner />;
    if (registerUserStatus === success)
      return (
        <div>
          <Typography variant="body1" color="textPrimary">
            {t('registration.RegistrationDialog.PageThree.txtSuccessBrowser1', {ns: 'components'})} <a href="mailto:hello@netpipe.io">hello@netpipe.io</a>.
          </Typography>
          <Typography
            variant="body1"
            color="textPrimary"
            sx={{ marginTop: "20px" }}
          >
            {t('registration.RegistrationDialog.PageThree.txtSuccessBrowser2', {ns: 'components'})}
          </Typography>
          <div className="app-download-div">
            <a href="https://play.google.com/store/apps/details?id=com.netpipe.measure&gl=DE&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
              <img
                className="google-play-store-badge"
                alt="Jetzt bei Google Play"
                src="https://play.google.com/intl/en_us/badges/static/images/badges/de_badge_web_generic.png"
              />
            </a>
            <img className="qr-code" src={qrCodeGooglePlayStore} alt={`Bild`} />
          </div>
        </div>
      );
    if (registerUserStatus === fail)
      return (
        <Typography color="error" variant="h6">
          {t('registration.RegistrationDialog.PageThree.txtFailureBrowser1', {ns: 'components'})} <a href="mailto:hello@netpipe.io">hello@netpipe.io</a>.
        </Typography>
      );
  };

  const renderPageThreeMobil = () => {
    if (registerUserStatus === pending) return <Spinner />;
    if (registerUserStatus === success)
      return (
        <Typography variant="body1" color="textPrimary">
          {t('registration.RegistrationDialog.PageThree.txtSuccessMobile1', {ns: 'components'})} <a href="mailto:hello@netpipe.io">hello@netpipe.io</a>.
        </Typography>
      );
    if (registerUserStatus === fail)
      return (
        <Typography color="error" variant="h6">
          {t('registration.RegistrationDialog.PageThree.txtFailureMobile1', {ns: 'components'})} <a href="mailto:hello@netpipe.io">hello@netpipe.io</a>.
        </Typography>
      );
  };

  return pathNameState === REGISTRATION_FROM_BROWSER_PATHNAME
    ? renderPageThreeBrowser()
    : renderPageThreeMobil();
};

PageThree.propTypes = propTypes;
export default PageThree;
