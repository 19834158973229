/* eslint-disable import/no-unresolved */
import { combineReducers } from "../sharedFunctions/redux-immutable/combineReducers";
import { app } from "./app";
import { dashboard } from "./dashboard";
import { data } from "./data";
import { measureLayerConfigs } from "./measure_layer_configs";
import { measurements } from "./measurements";
import { measurementLines } from "./measurementlines";
import { measurementSegments } from "./measurementsegments";
import { mediaFiles } from "./mediaFiles";
import { volumes } from "./volumes";
import { organizations } from "./organizations";
import { projects } from "./projects";
import { register } from "./register";
import { snackbars } from "./snackbars";
import { user } from "./user";
import { userAdministration } from "./userAdministration";
import { projectFiles } from "./projectFiles";
import { topographicPoints } from "./topographic_points";
import { componentCatalogs } from "./componentCatalogs";
import { measureLayerConfigTemplates } from "./measureLayerConfigTemplates";
import { mapLayers } from "./map_layer";
import { gnssData } from "./gnss_data";
import { language } from "./language";

export default combineReducers({
  data,
  dashboard,
  mediaFiles,
  volumes,
  user,
  app,
  projects,
  organizations,
  measureLayerConfigs,
  measurements,
  measurementLines,
  measurementSegments,
  userAdministration,
  register,
  snackbars,
  projectFiles,
  topographicPoints,
  componentCatalogs,
  measureLayerConfigTemplates,
  mapLayers,
  gnssData,
  language
});
