import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';

import * as styles from '../../../styles/styles';

const propTypes = {
  resendInvitation: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
}

const InvitedUserActions = ({
  resendInvitation,
  user,
}) => {
  const { t } = useTranslation();

  return <>
    <Typography
      color='textSecondary'
      style={{ margin: `0 ${styles.spacing24} 0 0` }}
      variant='body1'>
      {t('users.InvitedUsersActions.txtTitle', {ns: 'components'})}
    </Typography>
    <Button
      onClick={() => resendInvitation(user.id)}>
      {t('button.btnResendInvitation', {ns: 'components'})}
    </Button>
  </>
}

InvitedUserActions.propTypes = propTypes;
export default InvitedUserActions;