import i18next from '../i18n';

export const TYPE_BOOLEAN = "Boolean";
export const TYPE_INTEGER = "Integer";
export const TYPE_DOUBLE = "Double";
export const TYPE_STRING = "String";
export const TYPE_STRING_SET = "StringSet";

export const ORDERING_TYPE_INTRO_TOUR = "IntroTour";
export const ORDERING_TYPE_HINT_DIALOGS = "HintDialogs";
export const ORDERING_TYPE_EXPORT = "Export";
export const ORDERING_TYPE_ADVANCED_SETTINGS = "AdvancedSettings";
export const ORDERING_TYPE_GNSS = "GNSS";
export const ORDERING_TYPE_TRANSFORMATION = "Transformation";
export const ORDERUNG_SYNC = "Sync";

export const INTRO_TOUR_PROJECT_LIST = "ProjectList";
export const INTRO_TOUR_PROJECT = "Project";
export const INTRO_TOUR_PROJECT_DETAILS = "ProjectDetails";
export const INTRO_TOUR_CAMERA_AR_MEASUREMENT = "CameraArMeasurement";
export const INTRO_TOUR_VOLUME = "Volume";
export const INTRO_TOUR_MAP = "Map";
export const INTRO_TOUR_DRAWING = "Drawing";
export const INTRO_TOUR_CONFLICT = "Conflict";
export const INTRO_TOUR_GNSS = "GNSS";

export const HINT_DIALOGS_MAP_AND_DRAWING = "HintMapAndDrawing";
export const HINT_DIALOGS_CAMERA_AR_MEASUREMENT = "HintCameraArMeasurement";
export const HINT_DIALOGS_PROJECT_LIST = "HintProjectList";
export const HINT_DIALOGS_PROJECT_DETAILS = "HintProjectDetails";

export const ADVANCED_SETTINGS_CONFLICT = "AdvancedSettingsConflict";
export const ADVANCED_SETTINGS_CAMERA_AR_MEASUREMENT_LOGGING = "AdvancedSettingsCameraArMeasuementLogging";
export const ADVANCED_SETTINGS_CAMERA_AR_MEASUREMENT = "AdvancedSettingsCameraArMeasurement";

export const SYNC_SYNC = "SyncSync";

export const appSettingsOrdering = {
  intro_tour_project_list_activity: ORDERING_TYPE_INTRO_TOUR,
  intro_tour_ar_activity: ORDERING_TYPE_INTRO_TOUR,
  intro_tour_ar_point_activity_one: ORDERING_TYPE_INTRO_TOUR,
  intro_tour_drawing_fragment: ORDERING_TYPE_INTRO_TOUR,
  intro_tour_maps_fragment: ORDERING_TYPE_INTRO_TOUR,
  intro_tour_project_comparison_fragment: ORDERING_TYPE_INTRO_TOUR,
  intro_tour_result_fragment_finished_project: ORDERING_TYPE_INTRO_TOUR,
  intro_tour_result_fragment_open_project: ORDERING_TYPE_INTRO_TOUR,
  intro_tour_create_update_project_two: ORDERING_TYPE_INTRO_TOUR,
  intro_tour_gnss_map_view: ORDERING_TYPE_INTRO_TOUR,

  vis_explain_icon_dialog: ORDERING_TYPE_HINT_DIALOGS,
  map_hint: ORDERING_TYPE_HINT_DIALOGS,
  result_hint: ORDERING_TYPE_HINT_DIALOGS,
  depth_api_not_supported_hint: ORDERING_TYPE_HINT_DIALOGS,
  connectivity_hint: ORDERING_TYPE_HINT_DIALOGS,
  start_line_measurement_without_custom_maplayer_hint:
    ORDERING_TYPE_HINT_DIALOGS,
  missing_custom_map_folder_hint: ORDERING_TYPE_HINT_DIALOGS,

  project_conflict_handling: ORDERING_TYPE_ADVANCED_SETTINGS,
  measure_position_monitoring: ORDERING_TYPE_ADVANCED_SETTINGS,
  measure_position_data_logging: ORDERING_TYPE_ADVANCED_SETTINGS,
  measure_position_monitoring_and_data_logging_frequency:
    ORDERING_TYPE_ADVANCED_SETTINGS,
  measure_position_monitoring_and_data_logging_segment_distance_threshold:
    ORDERING_TYPE_ADVANCED_SETTINGS,
  measure_position_monitoring_and_data_logging_point_position_distance_threshold:
    ORDERING_TYPE_ADVANCED_SETTINGS,
  ar_camera_update_rate: ORDERING_TYPE_ADVANCED_SETTINGS,

  //gnss_interval_timer: "GNSS",
  //pref_gnss_device_name: "GNSS",
  //gnss_min_quality: "GNSS",
  //ntrip_caster: "GNSS",
  //ntrip_port: "GNSS",
  //ntrip_username: "GNSS",
  //ntrip_mount_point: "GNSS",
  //gnss_antenna_height: "GNSS",

  pref_gnss_use_height_component: ORDERING_TYPE_TRANSFORMATION,
  geo_export_crs: ORDERING_TYPE_TRANSFORMATION,

  pref_export_files: ORDERING_TYPE_EXPORT,
  pref_export_pdf_pages: ORDERING_TYPE_EXPORT,

  
};


export const introTourGrouping = {
  intro_tour_project_list_activity: INTRO_TOUR_PROJECT_LIST,
  intro_tour_create_update_project_two: INTRO_TOUR_PROJECT,
  intro_tour_project_comparison_fragment: INTRO_TOUR_CONFLICT,
  intro_tour_result_fragment_open_project: INTRO_TOUR_PROJECT_DETAILS,
  intro_tour_result_fragment_finished_project: INTRO_TOUR_PROJECT_DETAILS,
  intro_tour_ar_activity: INTRO_TOUR_CAMERA_AR_MEASUREMENT,
  intro_tour_ar_point_activity_one: INTRO_TOUR_VOLUME,
  intro_tour_gnss_map_view: INTRO_TOUR_GNSS,
  intro_tour_drawing_fragment: INTRO_TOUR_DRAWING,
  intro_tour_maps_fragment: INTRO_TOUR_MAP,
}

export const hintDialogsGrouping = {
  vis_explain_icon_dialog: HINT_DIALOGS_MAP_AND_DRAWING,
  missing_custom_map_folder_hint: HINT_DIALOGS_MAP_AND_DRAWING,
  map_hint: HINT_DIALOGS_CAMERA_AR_MEASUREMENT,
  result_hint: HINT_DIALOGS_CAMERA_AR_MEASUREMENT,
  depth_api_not_supported_hint: HINT_DIALOGS_CAMERA_AR_MEASUREMENT,
  connectivity_hint: HINT_DIALOGS_PROJECT_LIST,
  start_line_measurement_without_custom_maplayer_hint:
  HINT_DIALOGS_PROJECT_DETAILS,
}

export const advancedSettingsGrouping = {
  project_conflict_handling: ADVANCED_SETTINGS_CONFLICT,
  measure_position_monitoring: ADVANCED_SETTINGS_CAMERA_AR_MEASUREMENT_LOGGING,
  measure_position_data_logging: ADVANCED_SETTINGS_CAMERA_AR_MEASUREMENT_LOGGING,
  measure_position_monitoring_and_data_logging_frequency:
    ADVANCED_SETTINGS_CAMERA_AR_MEASUREMENT_LOGGING,
  measure_position_monitoring_and_data_logging_segment_distance_threshold:
    ADVANCED_SETTINGS_CAMERA_AR_MEASUREMENT_LOGGING,
  measure_position_monitoring_and_data_logging_point_position_distance_threshold:
    ADVANCED_SETTINGS_CAMERA_AR_MEASUREMENT_LOGGING,
  ar_camera_update_rate: ADVANCED_SETTINGS_CAMERA_AR_MEASUREMENT,
}


i18next.on('languageChanged init', () => {
  init()
})

const t = (textCode, options) => {
  return i18next.t(textCode, options);
}

export const appSettingsGroupingTitle = {}
export const appSettingsOrderingTitle = {}
export const appSettingsOrderingDescription = {}
export const appSettingsOrderingCategoryDescription = {}
export const appSettingsOrderingCategoryTitle = {}
export const prefExportFiles = {}
export const prefExportPdfPages = {}
export var editAppSettingsDialogTitle = ""
export var editAppSettingsDialogDescription = ""

const init = () => {
  ///////////////////////////
  // appSettingsGroupingTitle
  appSettingsGroupingTitle[INTRO_TOUR_PROJECT_LIST] = t('userSettings.appSettingsGroupingTitle.txtIntroTourProjectList', {ns: 'constants'})
  appSettingsGroupingTitle[INTRO_TOUR_PROJECT] = t('userSettings.appSettingsGroupingTitle.txtIntroTourProject', {ns: 'constants'})
  appSettingsGroupingTitle[INTRO_TOUR_CONFLICT] = t('userSettings.appSettingsGroupingTitle.txtIntroTourConflict', {ns: 'constants'})
  appSettingsGroupingTitle[INTRO_TOUR_PROJECT_DETAILS] = t('userSettings.appSettingsGroupingTitle.txtIntroTourProjectDetails', {ns: 'constants'})
  appSettingsGroupingTitle[INTRO_TOUR_CAMERA_AR_MEASUREMENT] = t('userSettings.appSettingsGroupingTitle.txtIntroTourCameraArMeasurement', {ns: 'constants'})
  appSettingsGroupingTitle[INTRO_TOUR_VOLUME] = t('userSettings.appSettingsGroupingTitle.txtIntroTourVolume', {ns: 'constants'})
  appSettingsGroupingTitle[INTRO_TOUR_GNSS] = t('userSettings.appSettingsGroupingTitle.txtIntroTourGnss', {ns: 'constants'})
  appSettingsGroupingTitle[INTRO_TOUR_DRAWING] = t('userSettings.appSettingsGroupingTitle.txtIntroTourDrawing', {ns: 'constants'})
  appSettingsGroupingTitle[INTRO_TOUR_MAP] = t('userSettings.appSettingsGroupingTitle.txtIntroTourMap', {ns: 'constants'})
  appSettingsGroupingTitle[HINT_DIALOGS_MAP_AND_DRAWING] = t('userSettings.appSettingsGroupingTitle.txtHintDialogsMapAndDrawing', {ns: 'constants'})
  appSettingsGroupingTitle[HINT_DIALOGS_CAMERA_AR_MEASUREMENT] = t('userSettings.appSettingsGroupingTitle.txtHintDialogsCameraArMeasurement', {ns: 'constants'})
  appSettingsGroupingTitle[HINT_DIALOGS_PROJECT_LIST] = t('userSettings.appSettingsGroupingTitle.txtHintDialogsProjectList', {ns: 'constants'})
  appSettingsGroupingTitle[HINT_DIALOGS_PROJECT_DETAILS] = t('userSettings.appSettingsGroupingTitle.txtHintDialogsProjectDetails', {ns: 'constants'})
  appSettingsGroupingTitle[ADVANCED_SETTINGS_CONFLICT] = t('userSettings.appSettingsGroupingTitle.txtAdvancedSettingsConflict', {ns: 'constants'})
  appSettingsGroupingTitle[ADVANCED_SETTINGS_CAMERA_AR_MEASUREMENT_LOGGING] = t('userSettings.appSettingsGroupingTitle.txtAdvancedSettingsCameraArMeasurementLogging', {ns: 'constants'})
  appSettingsGroupingTitle[ADVANCED_SETTINGS_CAMERA_AR_MEASUREMENT] = t('userSettings.appSettingsGroupingTitle.txtAdvancedSettingsCameraArMeasurement', {ns: 'constants'})
  // appSettingsGroupingTitle
  ///////////////////////////
  
  
  ///////////////////////////
  // appSettingsOrderingTitle
  appSettingsOrderingTitle.intro_tour_project_list_activity = t('userSettings.appSettingsOrderingTitle.txtIntroTourProjectListActivity', {ns: 'constants'})
  appSettingsOrderingTitle.intro_tour_ar_activity = t('userSettings.appSettingsOrderingTitle.txtIntroTourArActivity', {ns: 'constants'})
  appSettingsOrderingTitle.intro_tour_ar_point_activity_one = t('userSettings.appSettingsOrderingTitle.txtIntroTourArPointActivityOne', {ns: 'constants'})
  appSettingsOrderingTitle.intro_tour_drawing_fragment = t('userSettings.appSettingsOrderingTitle.txtIntroTourDrawingFragment', {ns: 'constants'})
  appSettingsOrderingTitle.intro_tour_maps_fragment = t('userSettings.appSettingsOrderingTitle.txtIntroTourMapsFragment', {ns: 'constants'})
  appSettingsOrderingTitle.intro_tour_project_comparison_fragment = t('userSettings.appSettingsOrderingTitle.txtIntroTourProjectComparisonFragment', {ns: 'constants'})
  appSettingsOrderingTitle.intro_tour_result_fragment_finished_project = t('userSettings.appSettingsOrderingTitle.txtIntroTourResultFragmentFinishedProject', {ns: 'constants'})
  appSettingsOrderingTitle.intro_tour_result_fragment_open_project = t('userSettings.appSettingsOrderingTitle.txtIntroTourResultFragmentOpenProject', {ns: 'constants'})
  appSettingsOrderingTitle.intro_tour_create_update_project_two = t('userSettings.appSettingsOrderingTitle.txtIntroTourCreateUpdateProjectTwo', {ns: 'constants'})
  appSettingsOrderingTitle.intro_tour_gnss_map_view = t('userSettings.appSettingsOrderingTitle.txtIntroTourGnssMapView', {ns: 'constants'})
  appSettingsOrderingTitle.vis_explain_icon_dialog = t('userSettings.appSettingsOrderingTitle.txtVisExplainIconDialog', {ns: 'constants'})
  appSettingsOrderingTitle.map_hint = t('userSettings.appSettingsOrderingTitle.txtMapHint', {ns: 'constants'})
  appSettingsOrderingTitle.result_hint = t('userSettings.appSettingsOrderingTitle.txtResultHint', {ns: 'constants'})
  appSettingsOrderingTitle.depth_api_not_supported_hint = t('userSettings.appSettingsOrderingTitle.txtDepthApiNotSupportedHint', {ns: 'constants'})
  appSettingsOrderingTitle.connectivity_hint = t('userSettings.appSettingsOrderingTitle.txtConnectivityHint', {ns: 'constants'})
  appSettingsOrderingTitle.start_line_measurement_without_custom_maplayer_hint =
  t('userSettings.appSettingsOrderingTitle.txtStartLineMeasurementWithoutCustomMaplayerHint', {ns: 'constants'})
  appSettingsOrderingTitle.missing_custom_map_folder_hint = t('userSettings.appSettingsOrderingTitle.txtMissingCustomMapFolderHint', {ns: 'constants'})
  appSettingsOrderingTitle.project_conflict_handling = t('userSettings.appSettingsOrderingTitle.txtProjectConflictHandling', {ns: 'constants'})
  appSettingsOrderingTitle.measure_position_monitoring = t('userSettings.appSettingsOrderingTitle.txtMeasurePositionMonitoring', {ns: 'constants'})
  appSettingsOrderingTitle.measure_position_data_logging = t('userSettings.appSettingsOrderingTitle.txtMeasurePositionDataLogging', {ns: 'constants'})
  appSettingsOrderingTitle.measure_position_monitoring_and_data_logging_frequency =
  t('userSettings.appSettingsOrderingTitle.txtMeasurePositionMonitoringAndDataLoggingFrequency', {ns: 'constants'})
  appSettingsOrderingTitle.measure_position_monitoring_and_data_logging_segment_distance_threshold =
  t('userSettings.appSettingsOrderingTitle.txtMeasurePositionMonitoringAndDataLoggingSegmentDistanceThreshold', {ns: 'constants'})
  appSettingsOrderingTitle.measure_position_monitoring_and_data_logging_point_position_distance_threshold =
  t('userSettings.appSettingsOrderingTitle.txtMeasurePositionMonitoringAndDataLoggingPointPositionDistanceThreshold', {ns: 'constants'})
  appSettingsOrderingTitle.ar_camera_update_rate = t('userSettings.appSettingsOrderingTitle.txtArCameraUpdateRate', {ns: 'constants'})
  appSettingsOrderingTitle.pref_gnss_use_height_component = t('userSettings.appSettingsOrderingTitle.txtPrefGnssUseHeightComponent', {ns: 'constants'})
  appSettingsOrderingTitle.geo_export_crs = t('userSettings.appSettingsOrderingTitle.txtGeoExportCrs', {ns: 'constants'})
  appSettingsOrderingTitle.pref_export_files = t('userSettings.appSettingsOrderingTitle.txtPrefExportFiles', {ns: 'constants'})
  appSettingsOrderingTitle.pref_export_pdf_pages = t('userSettings.appSettingsOrderingTitle.txtPrefExportPdfPages', {ns: 'constants'})
  // appSettingsOrderingTitle
  ///////////////////////////


  /////////////////////////////////
  // appSettingsOrderingDescription
  appSettingsOrderingDescription.intro_tour_project_list_activity = "",
  appSettingsOrderingDescription.intro_tour_ar_activity = "",
  appSettingsOrderingDescription.intro_tour_ar_point_activity_one = "",
  appSettingsOrderingDescription.intro_tour_drawing_fragment = "",
  appSettingsOrderingDescription.intro_tour_maps_fragment = "",
  appSettingsOrderingDescription.intro_tour_project_comparison_fragment = "",
  appSettingsOrderingDescription.intro_tour_result_fragment_finished_project = "",
  appSettingsOrderingDescription.intro_tour_result_fragment_open_project = "",
  appSettingsOrderingDescription.intro_tour_create_update_project_two = "",
  appSettingsOrderingDescription.intro_tour_gnss_map_view = "",
  appSettingsOrderingDescription.vis_explain_icon_dialog = "",
  appSettingsOrderingDescription.map_hint = t('userSettings.appSettingsOrderingDescription.txtMapHint', {ns: 'constants'}),
  appSettingsOrderingDescription.result_hint = t('userSettings.appSettingsOrderingDescription.txtResultHint', {ns: 'constants'}),
  appSettingsOrderingDescription.depth_api_not_supported_hint = t('userSettings.appSettingsOrderingDescription.txtDepthApiNotSupportedHint', {ns: 'constants'}),
  appSettingsOrderingDescription.connectivity_hint = t('userSettings.appSettingsOrderingDescription.txtConnectivityHint', {ns: 'constants'}),
  appSettingsOrderingDescription.start_line_measurement_without_custom_maplayer_hint = t('userSettings.appSettingsOrderingDescription.txtStartLineMeasurementWithoutCustomMaplayerHint', {ns: 'constants'}),
  appSettingsOrderingDescription.missing_custom_map_folder_hint = t('userSettings.appSettingsOrderingDescription.txtMissingCustomMapFolderHint', {ns: 'constants'}),
  appSettingsOrderingDescription.project_conflict_handling = t('userSettings.appSettingsOrderingDescription.txtProjectConflictHandling', {ns: 'constants'}),
  appSettingsOrderingDescription.measure_position_monitoring = t('userSettings.appSettingsOrderingDescription.txtMeasurePositionMonitoring', {ns: 'constants'}),
  appSettingsOrderingDescription.measure_position_data_logging = t('userSettings.appSettingsOrderingDescription.txtMeasurePositionDataLogging', {ns: 'constants'}),
  appSettingsOrderingDescription.measure_position_monitoring_and_data_logging_frequency =
  t('userSettings.appSettingsOrderingDescription.txtMeasurePositionMonitoringAndDataLoggingFrequency', {ns: 'constants'}),
  appSettingsOrderingDescription.measure_position_monitoring_and_data_logging_segment_distance_threshold = t('userSettings.appSettingsOrderingDescription.txtMeasurePositionMonitoringAndDataLoggingSegmentDistanceThreshold', {ns: 'constants'}),
  appSettingsOrderingDescription.measure_position_monitoring_and_data_logging_point_position_distance_threshold = t('userSettings.appSettingsOrderingDescription.txtMeasurePositionMonitoringAndDataLoggingPointPositionDistanceThreshold', {ns: 'constants'}),
  appSettingsOrderingDescription.ar_camera_update_rate =
  t('userSettings.appSettingsOrderingDescription.txtArCameraUpdateRate', {ns: 'constants'}),
  appSettingsOrderingDescription.pref_gnss_use_height_component = t('userSettings.appSettingsOrderingDescription.txtPrefGnssUseHeightComponent', {ns: 'constants'}),
  appSettingsOrderingDescription.geo_export_crs = t('userSettings.appSettingsOrderingDescription.txtGeoExportCrs', {ns: 'constants'}),
  appSettingsOrderingDescription.pref_export_files = t('userSettings.appSettingsOrderingDescription.txtPrefExportFiles', {ns: 'constants'}),
  appSettingsOrderingDescription.pref_export_pdf_pages = t('userSettings.appSettingsOrderingDescription.txtPrefExportPdfPages', {ns: 'constants'})
  // appSettingsOrderingDescription
  /////////////////////////////////
  

  ///////////////////////////////////
  // appSettingsOrderingCategoryTitle
  appSettingsOrderingCategoryTitle[ORDERING_TYPE_INTRO_TOUR] =  t('userSettings.appSettingsOrderingCategoryTitle.txtOrderingTypeIntroTour', {ns: 'constants'})
  appSettingsOrderingCategoryTitle[ORDERING_TYPE_HINT_DIALOGS] = t('userSettings.appSettingsOrderingCategoryTitle.txtOrderingTypeHintDialogs', {ns: 'constants'})
  appSettingsOrderingCategoryTitle[ORDERING_TYPE_EXPORT] = t('userSettings.appSettingsOrderingCategoryTitle.txtOrderingTypeExport', {ns: 'constants'})
  appSettingsOrderingCategoryTitle[ORDERING_TYPE_ADVANCED_SETTINGS] = t('userSettings.appSettingsOrderingCategoryTitle.txtOrderingTypeAdvancedSettings', {ns: 'constants'})
  appSettingsOrderingCategoryTitle[ORDERING_TYPE_GNSS] = t('userSettings.appSettingsOrderingCategoryTitle.txtOrderingTypeGnss', {ns: 'constants'})
  appSettingsOrderingCategoryTitle[ORDERING_TYPE_TRANSFORMATION] = t('userSettings.appSettingsOrderingCategoryTitle.txtOrderingTypeTransformation', {ns: 'constants'})
  // appSettingsOrderingCategoryTitle
  ///////////////////////////////////
  

  /////////////////////////////////////////
  // appSettingsOrderingCategoryDescription
  appSettingsOrderingCategoryDescription[ORDERING_TYPE_INTRO_TOUR] = t('userSettings.appSettingsOrderingCategoryDescription.txtOrderingTypeIntroTour', {ns: 'constants'})
  appSettingsOrderingCategoryDescription[ORDERING_TYPE_HINT_DIALOGS] = t('userSettings.appSettingsOrderingCategoryDescription.txtOrderingTypeHintDialogs', {ns: 'constants'})
  appSettingsOrderingCategoryDescription[ORDERING_TYPE_EXPORT] = t('userSettings.appSettingsOrderingCategoryDescription.txtOrderingTypeExport', {ns: 'constants'})
  appSettingsOrderingCategoryDescription[ORDERING_TYPE_ADVANCED_SETTINGS] = t('userSettings.appSettingsOrderingCategoryDescription.txtOrderingTypeAdvancedSettings', {ns: 'constants'})
  appSettingsOrderingCategoryDescription[ORDERING_TYPE_GNSS] = t('userSettings.appSettingsOrderingCategoryDescription.txtOrderingTypeGnss', {ns: 'constants'})
  appSettingsOrderingCategoryDescription[ORDERING_TYPE_TRANSFORMATION] = t('userSettings.appSettingsOrderingCategoryDescription.txtOrderingTypeTransformation', {ns: 'constants'})
  // appSettingsOrderingCategoryDescription
  /////////////////////////////////////////
  

  //////////////////
  // prefExportFiles
  prefExportFiles[1] = t('userSettings.prefExportFiles.txtType1', {ns: 'constants'})
  prefExportFiles[2] = t('userSettings.prefExportFiles.txtType2', {ns: 'constants'})
  prefExportFiles[3] = t('userSettings.prefExportFiles.txtType3', {ns: 'constants'})
  prefExportFiles[4] = t('userSettings.prefExportFiles.txtType4', {ns: 'constants'})
  prefExportFiles[5] = t('userSettings.prefExportFiles.txtType5', {ns: 'constants'})
  prefExportFiles[6] = t('userSettings.prefExportFiles.txtType6', {ns: 'constants'})
  prefExportFiles[7] = t('userSettings.prefExportFiles.txtType7', {ns: 'constants'})
  prefExportFiles[8] = t('userSettings.prefExportFiles.txtType8', {ns: 'constants'})
  prefExportFiles[12] = t('userSettings.prefExportFiles.txtType12', {ns: 'constants'})
  prefExportFiles[9] = t('userSettings.prefExportFiles.txtType9', {ns: 'constants'})
  prefExportFiles[10] = t('userSettings.prefExportFiles.txtType10', {ns: 'constants'})
  prefExportFiles[11] = t('userSettings.prefExportFiles.txtType11', {ns: 'constants'})
  prefExportFiles[13] = t('userSettings.prefExportFiles.txtType13', {ns: 'constants'})
  // prefExportFiles
  //////////////////
  

  /////////////////////
  // prefExportPdfPages
  prefExportPdfPages[1] = t('userSettings.prefExportPdfPages.txtType1', {ns: 'constants'})
  prefExportPdfPages[14] = t('userSettings.prefExportPdfPages.txtType14', {ns: 'constants'})
  prefExportPdfPages[2] = t('userSettings.prefExportPdfPages.txtType2', {ns: 'constants'})
  prefExportPdfPages[3] = t('userSettings.prefExportPdfPages.txtType3', {ns: 'constants'})
  prefExportPdfPages[4] = t('userSettings.prefExportPdfPages.txtType4', {ns: 'constants'})
  prefExportPdfPages[5] = t('userSettings.prefExportPdfPages.txtType5', {ns: 'constants'})
  prefExportPdfPages[6] = t('userSettings.prefExportPdfPages.txtType6', {ns: 'constants'})
  prefExportPdfPages[7] = t('userSettings.prefExportPdfPages.txtType7', {ns: 'constants'})
  prefExportPdfPages[8] = t('userSettings.prefExportPdfPages.txtType8', {ns: 'constants'})
  prefExportPdfPages[9] = t('userSettings.prefExportPdfPages.txtType9', {ns: 'constants'})
  prefExportPdfPages[10] = t('userSettings.prefExportPdfPages.txtType10', {ns: 'constants'})
  prefExportPdfPages[11] = t('userSettings.prefExportPdfPages.txtType11', {ns: 'constants'})
  prefExportPdfPages[12] = t('userSettings.prefExportPdfPages.txtType12', {ns: 'constants'})
  prefExportPdfPages[15] = t('userSettings.prefExportPdfPages.txtType15', {ns: 'constants'})
  prefExportPdfPages[16] = t('userSettings.prefExportPdfPages.txtType16', {ns: 'constants'})
  prefExportPdfPages[17] = t('userSettings.prefExportPdfPages.txtType17', {ns: 'constants'})
  prefExportPdfPages[18] = t('userSettings.prefExportPdfPages.txtType18', {ns: 'constants'})
  // prefExportPdfPages
  /////////////////////
  
  editAppSettingsDialogTitle = t('userSettings.editAppSettingsDialogTitle', {ns: 'constants'});
  editAppSettingsDialogDescription = t('userSettings.editAppSettingsDialogDescription', {ns: 'constants'});
}



export const appSettingsGrouping = {
  [ORDERING_TYPE_INTRO_TOUR]: introTourGrouping,
  [ORDERING_TYPE_HINT_DIALOGS]: hintDialogsGrouping,
  [ORDERING_TYPE_ADVANCED_SETTINGS]: advancedSettingsGrouping
}


const arCameraUpdateRateWordList = [
  { value: "30_fps", text: "30 FPS" },
  { value: "60_fps", text: "60 FPS" },
];
const geoExportCrsWordList = [
  { value: "utm", text: "UTM" },
  { value: "gauss_krueger", text: "Gauß Krüger" },
  { value: "ch1903plus", text: "CH1903+" },
  { value: "custom", text: "Custom" },
];

export const dropdownSettings = {
  ar_camera_update_rate: arCameraUpdateRateWordList,
  geo_export_crs: geoExportCrsWordList,
};

export const stringSetMap = {
  pref_export_files: prefExportFiles,
  pref_export_pdf_pages: prefExportPdfPages,
};

