/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";

import ComponentCatalogCard from "./ComponentCatalogCard";
import ComponentCatalogsHeader from "./ComponentCatalogsHeader";
import Spinner from "../../../atoms/Spinner";
import Confirmation from "../../../components/dialogs/Confirmation";
import ComponentCatalogCategoryDialog from "../../../containers/dialogs/ComponentCatalogCategoryDialog";

import { isAdmin } from "../../../sharedFunctions/user";

import {
  setComponentCatalogCategorySearchTerm,
  setActiveComponentCatalogUuid,
  syncComponentCatalogs,
  createComponentCatalog,
  updateComponentCatalog,
  deleteComponentCatalog,
  copyComponentCatalog
} from "../../../actions/componentCatalogs";

import { idle } from "../../../constants/status";
import {
  EDIT_KEY,
  DELETE_KEY,
  CREATE_KEY,
  COPY_KEY
} from "../../../constants/contextMenuEntries";

import "./styles.css";

const propTypes = {
  searchTerm: PropTypes.string,
  defaultCatalogCategories: PropTypes.any,
  organizationCatalogCategories: PropTypes.any,
  data: PropTypes.object,
  filteredBySearchTerm: PropTypes.array,
  syncStatus: PropTypes.string,
  isAdmin: PropTypes.bool,
  history: PropTypes.object,
  setComponentCatalogCategorySearchTerm: PropTypes.func,
  setActiveComponentCatalogUuid: PropTypes.func,
  syncComponentCatalogs: PropTypes.func,
  deleteComponentCatalog: PropTypes.func,
  createComponentCatalog: PropTypes.func,
  updateComponentCatalog: PropTypes.func,
  copyComponentCatalog: PropTypes.func
};

const ComponentCatalogs = ({
  searchTerm,
  defaultCatalogCategories,
  organizationCatalogCategories,
  data,
  filteredBySearchTerm,
  syncStatus,
  isAdmin,
  history,
  setComponentCatalogCategorySearchTerm,
  setActiveComponentCatalogUuid,
  syncComponentCatalogs,
  deleteComponentCatalog,
  createComponentCatalog,
  updateComponentCatalog,
  copyComponentCatalog
}) => {
  const { t } = useTranslation();

  const defaultStateShowModal = React.useMemo(() => {
    return {
      mode: "",
      modalOpen: false,
      data: {},
    };
  });

  const [stateShowModal, setStateShowModal] = React.useState(
    defaultStateShowModal
  );

  React.useEffect(() => {
    if (syncStatus === idle) {
      syncComponentCatalogs();
    }
  }, [syncStatus]);

  /**
   * The content of the payload depends on the mode.
   * See onCreateCategoryClicked, onEditCategoryClicked and
   * onDeleteCategoryClicked.
   *
   * @param {object} payload
   */
  const openModal = (payload) => {
    setStateShowModal(payload);
  };

  const closeModal = () => {
    setStateShowModal({ mode: "", modalOpen: false, data: {} });
  };

  const onCreateCategoryClicked = () => {
    openModal({ mode: CREATE_KEY, modalOpen: true, data: {} });
  };

  const onEditCategoryClicked = (
    catalogOrganizationCategory,
    catalogCategory
  ) => {
    openModal({
      mode: EDIT_KEY,
      modalOpen: true,
      data: {
        catalogOrganizationCategory: catalogOrganizationCategory,
        catalogCategory: catalogCategory,
      },
    });
  };

  const onDeleteCategoryClicked = (
    catalogOrganizationCategory,
    catalogCategory
  ) => {
    openModal({
      mode: DELETE_KEY,
      modalOpen: true,
      data: {
        catalogOrganizationCategory: catalogOrganizationCategory,
        catalogCategory: catalogCategory,
      },
    });
  };

  const onCopyCategoryClicked = (
    catalogOrganizationCategory,
    catalogCategory
  ) => {
    openModal({
      mode: COPY_KEY,
      modalOpen: true,
      data: {
        catalogOrganizationCategory: catalogOrganizationCategory,
        catalogCategory: catalogCategory,
      },
    });
  }

  /**
   * For information about the payload see the
   * ComponentCatalogCategoryDialog Component
   *
   * @param {object} payload
   */
  const onModalCreateCategoryOkClicked = (payload) => {
    openModal(defaultStateShowModal);
    createComponentCatalog(payload.data);
  };

  /**
   * For information about the payload see the
   * ComponentCatalogCategoryDialog Component
   *
   * @param {object} payload
   */
  const onModalUpdateCategoryOkClicked = (payload) => {
    openModal(defaultStateShowModal);
    updateComponentCatalog(payload.uuid, payload.data);
  };

  /**
   * For information about the payload see the
   * ComponentCatalogCategoryDialog Component
   *
   * @param {object} payload
   */
  const onModalDeleteCategoryOkClicked = (payload) => {
    openModal(defaultStateShowModal);
    deleteComponentCatalog(payload.data.catalogCategory.uuid);
  };

  /**
   * Copy a ComponentCategory and its containing ComponentData to the
   * organizations own ComponentCategory.
   * This function is called when the user clicks on the ok button in the
   * confirmation dialog.
   *
   * @param {object} payload
   * @param {object} payload.data
   * @param {string} payload.data.catalogCategory.uuid
   */
  const onModalCopyCategoryOkClicked = (payload) => {
    openModal(defaultStateShowModal);
    copyComponentCatalog(payload.data.catalogCategory.uuid);
  }

  /**
   * Render for every ComponentCategory a ComponentCategoryCard.
   * This function is called twice. One time for the default ComponentCategories
   * and a second time for the Organization owned ComponentCategories.
   * The dataItems array has the following structure
   *
   * ```
   * [
   *    {
   *       "rootUuid": "",
   *        "item": {
   *           "description": "",
   *           "name": "",
   *           "updated_at": "",
   *           "uuid": "",
   *           "root_uuid": ""
   *        }
   *    }
   * ]
   * ```
   * @param {array} dataItems
   * @returns
   */
  const renderComponentCategoryCards = (dataItems) => {
    const catalogCategoryGridElements = [];

    dataItems.forEach((catalogCategory) => {
      const item = catalogCategory.item;

      const catalogComponents = data.catalogComponentsCategories.filter(
        (catalogComponentsCategory) => {
          return catalogComponentsCategory.rootUuid === item.uuid;
        }
      );

      const catalogMaterials = data.catalogMaterialsCategories.filter(
        (catalogMaterialsCategory) => {
          return catalogMaterialsCategory.rootUuid === item.uuid;
        }
      );

      const catalogDimensions = data.catalogDimensionsCategories.filter(
        (catalogDimensionsCategory) => {
          return catalogDimensionsCategory.rootUuid === item.uuid;
        }
      );

      // Don't forget the PipeDefinitions!!

      const catalogOrganizationCategory =
        data.catalogOrganizationCategories.filter(
          (catalogOrganizationCategory) => {
            return (
              catalogOrganizationCategory.item.uuid === catalogCategory.rootUuid
            );
          }
        );

      catalogCategoryGridElements.push(
        <ComponentCatalogCard
          key={catalogCategory.rootUuid}
          history={history}
          item={item}
          isAdmin={isAdmin}
          catalogOrganizationCategory={catalogOrganizationCategory}
          catalogComponents={catalogComponents}
          catalogMaterials={catalogMaterials}
          catalogDimensions={catalogDimensions}
          setActiveComponentCatalogUuid={setActiveComponentCatalogUuid}
          onEditCategoryClicked={onEditCategoryClicked}
          onDeleteCategoryClicked={onDeleteCategoryClicked}
          onCopyCategoryClicked={onCopyCategoryClicked}
        />
      );
    });

    return catalogCategoryGridElements;
  };

  /**
   * Render all default ComponentCatalog Items a ComponentCatalogCard.
   *
   * @returns
   */
  const renderDefaultComponentCategories = () => {
    const filteredCatalogCategories = data.catalogCategories.filter(
      (item) =>
        defaultCatalogCategories.includes(item.rootUuid) &&
        filteredBySearchTerm.includes(item.item.uuid)
    );

    return (
      <div style={{ marginBottom: "2.5rem" }}>
        <Typography
          variant="h1"
          sx={{ marginTop: "4rem", marginBottom: "2.5rem" }}
        >
          {t('ComponentCatalogs.txtDefaultComponentCatalogs', {ns: 'screens_private'})}
        </Typography>
        <Grid container columns={{ xs: 2, sm: 4, md: 12 }} spacing={4}>
          {renderComponentCategoryCards(filteredCatalogCategories)}
        </Grid>
      </div>
    );
  };

  /**
   * Render all Organization owned ComponentCatalog Items a ComponentCatalogCard.
   *
   * @returns
   */
  const renderOrganizationComponentCategories = () => {
    const filteredCatalogCategories = data.catalogCategories.filter(
      (item) =>
        organizationCatalogCategories.includes(item.rootUuid) &&
        filteredBySearchTerm.includes(item.item.uuid)
    );

    return (
      <div>
        <Typography
          variant="h1"
          sx={{ marginTop: "5rem", marginBottom: "2.5rem" }}
        >
          {t('ComponentCatalogs.txtOwnComponentCatalogs', {ns: 'screens_private'})}
        </Typography>
        <Grid container columns={{ xs: 2, sm: 4, md: 12 }} spacing={4}>
          {renderComponentCategoryCards(filteredCatalogCategories)}
        </Grid>
      </div>
    );
  };

  return (
    <div className="project-bottom-container">
      {stateShowModal.mode !== "" &&
        renderModalContent(
          t,
          stateShowModal,
          closeModal,
          onModalCreateCategoryOkClicked,
          onModalUpdateCategoryOkClicked,
          onModalDeleteCategoryOkClicked,
          onModalCopyCategoryOkClicked
        )}
      <ComponentCatalogsHeader
        searchTerm={searchTerm}
        setComponentCatalogCategorySearchTerm={
          setComponentCatalogCategorySearchTerm
        }
        onCreateCategoryClicked={onCreateCategoryClicked}
        isAdmin={isAdmin}
      />
      <div className="component-category-cards-container">
        {syncStatus != "success" ? (
          <div className="spinner-container">
            <Spinner />
          </div>
        ) : (
          <div>
            {renderOrganizationComponentCategories()}
            {renderDefaultComponentCategories()}
          </div>
        )}
      </div>
    </div>
  );
};

function renderModalContent(
  t,
  payload,
  closeModal,
  onModalCreateCategoryOkClicked,
  onModalUpdateCategoryOkClicked,
  onModalDeleteCategoryOkClicked,
  onModalCopyCategoryOkClicked
) {
  switch (payload.mode) {
    case CREATE_KEY:
      return (
        <ComponentCatalogCategoryDialog
          modalOpen={payload.modalOpen}
          onClose={() => closeModal()}
          onClick={onModalCreateCategoryOkClicked}
        />
      );
    case EDIT_KEY:
      return (
        <ComponentCatalogCategoryDialog
          edit
          data={payload.data.catalogCategory}
          modalOpen={payload.modalOpen}
          onClose={() => closeModal()}
          onClick={onModalUpdateCategoryOkClicked}
        />
      );
    case DELETE_KEY:
      return (
        <Confirmation
          highlightedText={payload.data.catalogCategory.name}
          headline={t('Confirmation.deleteComponentCatalog.txtHeadline', {ns: 'common'})}
          leftSideText={t('Confirmation.deleteComponentCatalog.txtLeftSideText', {ns: 'common'})}
          modalOpen={payload.modalOpen}
          onClose={() => closeModal()}
          onClick={() => onModalDeleteCategoryOkClicked(payload)}
          rightSideText={t('Confirmation.deleteComponentCatalog.txtRightSideText', {ns: 'common'})}
          textButton={t('Confirmation.deleteComponentCatalog.txtTextButton', {ns: 'common'})}
        />
      );
    case COPY_KEY:
      return(
        <Confirmation
          highlightedText={payload.data.catalogCategory.name}
          headline={t('Confirmation.copyComponentCatalog.txtHeadline', {ns: 'common'})}
          leftSideText={t('Confirmation.copyComponentCatalog.txtLeftSideText', {ns: 'common'})}
          modalOpen={payload.modalOpen}
          onClose={() => closeModal()}
          onClick={() => onModalCopyCategoryOkClicked(payload)}
          rightSideText={t('Confirmation.copyComponentCatalog.txtRightSideText', {ns: 'common'})}
          textButton={t('Confirmation.copyComponentCatalog.txtTextButton', {ns: 'common'})}
        />
      )
  }
}

function dispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setComponentCatalogCategorySearchTerm,
      setActiveComponentCatalogUuid,
      syncComponentCatalogs,
      deleteComponentCatalog,
      createComponentCatalog,
      updateComponentCatalog,
      copyComponentCatalog
    },
    dispatch
  );
}

function stateToProps(state) {
  const syncStatus = state.getIn([
    "componentCatalogs",
    "componentCatalogSyncStatus",
  ]);

  const searchTerm = state.getIn(["componentCatalogs", "searchTerm"]);
  const filteredBySearchTerm = state
    .getIn(["componentCatalogs", "items", "catalogCategories"])
    .filter((item) => {
      const cond1 = String(item.item.name)
        .toLowerCase()
        .includes(String(searchTerm).toLowerCase());
      const cond2 = String(item.item.description)
        .toLowerCase()
        .includes(String(searchTerm).toLowerCase());
      return cond1 || cond2;
    })
    .map((item) => item.item.uuid);

  const defaultCatalogCategories = state
    .getIn(["componentCatalogs", "items", "catalogOrganizationCategories"])
    .filter((item) => !item.item.organization_id)
    .map((item) => item.item.uuid);
  const organizationCatalogCategories = state
    .getIn(["componentCatalogs", "items", "catalogOrganizationCategories"])
    .filter((item) => item.item.organization_id)
    .map((item) => item.item.uuid);

  const data = state.getIn(["componentCatalogs", "items"]);

  return {
    searchTerm: searchTerm,
    defaultCatalogCategories: defaultCatalogCategories,
    organizationCatalogCategories: organizationCatalogCategories,
    data: data.toJS(),
    filteredBySearchTerm:
      filteredBySearchTerm.length === undefined ||
      filteredBySearchTerm.length === 0
        ? []
        : filteredBySearchTerm,
    syncStatus: syncStatus,
    isAdmin: isAdmin(state.getIn(["user", "organization_role_groups"]), true),
  };
}

ComponentCatalogs.propTypes = propTypes;
export default connect(stateToProps, dispatchToProps)(ComponentCatalogs);
