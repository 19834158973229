/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";

import ColoredCircle from "../../../../../components/ColoredCircle";
import Confirmation from "../../../../../components/dialogs/Confirmation";
import MeasureLayerConfigDialog from "../../../../../containers/dialogs/MeasureLayerConfigDialog";
import {
  COLOR_MAP,
  COLOR_MAP_NAMES,
} from "../../../../../constants/colormapping";
import { LAYER_TYPE_NAME_MAP } from "../../../../../constants/measureLayerConfig";
import {
  deleteMeasureLayerConfig,
  updateMeasureLayerConfig,
} from "../../../../../actions/measure_layer_configs";

import history from "../../../../../lib/history";

import * as styles from "../../../../../styles/styles";

const propTypes = {
  catalogCategories: PropTypes.any,
  measureLayerConfigMap: PropTypes.object,
  deleteMeasureLayerConfig: PropTypes.func,
  updateMeasureLayerConfig: PropTypes.func,
};

const MeasureLayerTable = ({
  catalogCategories,
  measureLayerConfigMap,
  deleteMeasureLayerConfig,
  updateMeasureLayerConfig,
}) => {
  const { t } = useTranslation();

  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const [toModifyItemKey, setToModifyItemKey] = React.useState("");

  const closeModal = () => {
    setEditModalOpen(false);
    setDeleteModalOpen(false);
    setToModifyItemKey("");
  };

  const onClickMeasureLayerConfigDelete = (measureLayerConfig) => {
    deleteMeasureLayerConfig(measureLayerConfig);
    closeModal();
  };

  const onEditClicked = (key) => {
    setEditModalOpen(true);
    setToModifyItemKey(key);
  };

  const onDeleteClicked = (key) => {
    setDeleteModalOpen(true);
    setToModifyItemKey(key);
  };

  const onUpdateMeasureLayerConfig = (payload) => {
    updateMeasureLayerConfig(payload);
  };

  const onGoToComponentCatalogClicked = (payload) => {
    history.push(`/configurator/component-catalogs/${payload}`);
  };

  return (
    <>
      {deleteModalOpen &&
        showDeleteModal(
          t,
          measureLayerConfigMap.get(toModifyItemKey),
          closeModal,
          deleteModalOpen,
          onClickMeasureLayerConfigDelete
        )}
      {editModalOpen &&
        showEditModal(
          measureLayerConfigMap.get(toModifyItemKey),
          editModalOpen,
          closeModal,
          onUpdateMeasureLayerConfig
        )}
      <div>
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{t('Project.MeasureLayer.MeasureLayerTable.txtTableCellId', {ns: 'screens_private'})}</TableCell>
                <TableCell>{t('Project.MeasureLayer.MeasureLayerTable.txtTableCellType', {ns: 'screens_private'})}</TableCell>
                <TableCell>{t('Project.MeasureLayer.MeasureLayerTable.txtTableCellName', {ns: 'screens_private'})}</TableCell>
                <TableCell>{t('Project.MeasureLayer.MeasureLayerTable.txtTableCellColor', {ns: 'screens_private'})}</TableCell>
                <TableCell align="right">{t('Project.MeasureLayer.MeasureLayerTable.txtTableCellComponentCatalog', {ns: 'screens_private'})}</TableCell>
                <TableCell align="right">{t('Project.MeasureLayer.MeasureLayerTable.txtTableCellActions', {ns: 'screens_private'})}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {measureLayerConfigMap &&
                createRows(
                  t,
                  catalogCategories,
                  measureLayerConfigMap,
                  onEditClicked,
                  onDeleteClicked,
                  onGoToComponentCatalogClicked
                )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

/**
 * This function takes all CatalogCategories as an Immutable Object and tries
 * to find the CatalogCategory Name from a given CatalogCategory UUID.
 *
 * @param {object} catalogCategories This is an Immutable Object holding all available CatalogCategories.
 * @param {string} catalogCategoryUuid The CatalogCategory UUID.
 * @returns The CatalogCategory Name as a String or an empty String if nothing was found.
 */
function extractComponentCategoryName(catalogCategories, catalogCategoryUuid) {
  const filteredData = catalogCategories.filter((item) => {
    return item.item.uuid === catalogCategoryUuid;
  });

  if (filteredData && filteredData.length === 1) {
    return filteredData[0].item.name;
  }

  return "";
}

function createRows(
  t,
  catalogCategories,
  measureLayerConfigMap,
  onEditClicked,
  onDeleteClicked,
  onGoToComponentCatalogClicked
) {
  const rowUiElements = [];
  let index = 1;

  for (const [key, value] of measureLayerConfigMap.entries()) {
    rowUiElements.push(
      <TableRow key={index}>
        <TableCell>
          <Typography variant="body2">{index}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">
            {LAYER_TYPE_NAME_MAP[value.layer_type]}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">{value.layer_name}</Typography>
        </TableCell>
        <TableCell>
          {COLOR_MAP[value.color] ? (
            <ColoredCircle
              color={COLOR_MAP[value.color]}
              name={COLOR_MAP_NAMES[value.color]}
              size={"xm"}
            />
          ) : (
            <ColoredCircle size={"xm"} />
          )}
        </TableCell>
        <TableCell align="right">
          {value.component_catalogs && value.component_catalogs.length > 0 && (
            <Tooltip
              title={extractComponentCategoryName(
                catalogCategories,
                value.component_catalogs[0]
              )}
              arrow
            >
              <IconButton
                aria-label="delete"
                onClick={() =>
                  onGoToComponentCatalogClicked(value.component_catalogs)
                }
                style={{
                  backgroundColor: styles.darkBlueSuperLight,
                  marginRight: styles.spacing16,
                }}
                size="large"
              >
                <CheckIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
        <TableCell align="right">
          <Tooltip title={t('Project.MeasureLayer.MeasureLayerTable.txtTooltipEdit', {ns: 'screens_private'})} arrow>
            <IconButton
              aria-label="edit"
              onClick={() => onEditClicked(key)}
              style={{
                backgroundColor: styles.darkBlueSuperLight,
                marginRight: styles.spacing16,
              }}
              size="large"
            >
              <EditIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          {!value.required && (
            <Tooltip title={t('Project.MeasureLayer.MeasureLayerTable.txtTooltipDelete', {ns: 'screens_private'})} arrow>
              <IconButton
                aria-label="delete"
                onClick={() => onDeleteClicked(key)}
                style={{
                  backgroundColor: styles.darkBlueSuperLight,
                  marginRight: styles.spacing16,
                }}
                size="large"
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
      </TableRow>
    );
    index++;
  }

  return rowUiElements;
}

function showEditModal(
  measureLayerConfig,
  editModalOpen,
  closeModal,
  onUpdateMeasureLayerConfig
) {
  return (
    <MeasureLayerConfigDialog
      measureLayerConfig={measureLayerConfig}
      measureLayerConfigs={{}} // this is not correct. Correct implementation needed!
      edit={true}
      modalOpen={editModalOpen}
      onClose={() => closeModal()}
      onClick={() => closeModal()}
      onUpdateMeasureLayerConfig={onUpdateMeasureLayerConfig}
    />
  );
}

function showDeleteModal(
  t,
  measureLayerConfig,
  closeModal,
  deleteModalOpen,
  onClickMeasureLayerConfigDelete
) {
  return (
    <Confirmation
      highlightedText={measureLayerConfig.layer_name}
      headline={t('Confirmation.deleteMeasureLayerConfig.txtHeadline', {ns: 'common'})}
      leftSideText={t('Confirmation.deleteMeasureLayerConfig.txtLeftSideText', {ns: 'common'})}
      modalOpen={deleteModalOpen}
      onClose={() => closeModal()}
      onClick={() => onClickMeasureLayerConfigDelete(measureLayerConfig)}
      rightSideText={t('Confirmation.deleteMeasureLayerConfig.txtRightSideText', {insertedAt: moment(measureLayerConfig.inserted_at).format("DD.MM.YYYY"), ns: 'common'})}
      textButton={t('Confirmation.deleteMeasureLayerConfig.txtTextButton', {ns: 'common'})}
    />
  );
}

function dispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteMeasureLayerConfig,
      updateMeasureLayerConfig,
    },
    dispatch
  );
}

function stateToProps(state, ownProps) {
  const catalogCategories = state.getIn([
    "componentCatalogs",
    "items",
    "catalogCategories",
  ]);

  return { catalogCategories };
}

MeasureLayerTable.propTypes = propTypes;
export default connect(stateToProps, dispatchToProps)(MeasureLayerTable);
