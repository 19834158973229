/* eslint-disable no-unused-vars */
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { CookieConsent } from "react-cookie-consent";
import moment from "moment";
import "moment/locale/de";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { Link, Router } from "react-router-dom";

import ShowSnackbar from "./containers/ShowSnackbar";

import "./index.css";
import history from "./lib/history";
import { initStore } from "./lib/store";
import AppRoutes from "./routes";
import theme from "./styles/theme";
import i18next from './i18n';
import LanguageStorage from './lib/languageStorage';

const coockieConsentText = {
  "txtBody": "",
  "txtLink": "",
  "txtBtn": ""  
}

const t = (textCode, options) => {
  return i18next.t(textCode, options);
}

const store = initStore();

const rootElement = document.getElementById("root");
moment.locale("de");

const render = (Component) => {
  const root = createRoot(rootElement);
  root.render(
    <Provider store={store}>
      <Router history={history}>
        <StyledEngineProvider injectFirst>
          <ShowSnackbar />
          <CookieConsent
            location="none"
            buttonText={coockieConsentText.txtBtn}
            cookieName="netpipe-cookie"
            style={{
              background: "white",
              color: "#222",
              justifyConent: "center",
              alignItems: "center",
              left: "50%",
              right: "auto",
              bottom: "24px",
              transform: "translateX(-50%)",
              width: "auto",
              boxShadow: "0 3px 10px rgba(0,0,0,0.1)",
            }}
            buttonStyle={{
              background: "white",
              color: "#853BD1",
              fontSize: "1.3rem",
            }}
            contentStyle={{
              background: "white",
              color: "#222",
              fontSize: "1.4rem",
            }}
            expires={365}
          >
            <div>
              {coockieConsentText.txtBody}
              <Link target="_blank" to="/privacy">
                {coockieConsentText.txtLink}
              </Link>
            </div>
          </CookieConsent>
          <ThemeProvider theme={theme}>
            <Component />
          </ThemeProvider>
        </StyledEngineProvider>
      </Router>
    </Provider>
  );
};

i18next.on('languageChanged init', () => {
  coockieConsentText.txtBody = t('CookieConsent.txtBody', {ns: 'common'});
  coockieConsentText.txtLink = t('CookieConsent.txtLink', {ns: 'common'});
  coockieConsentText.txtBtn = t('CookieConsent.txtBtn', {ns: 'common'});

  const language = LanguageStorage.get();

  if (!language) {
    LanguageStorage.setLanguage(i18next.language);
  }
  
  render(AppRoutes);
})


if (module.hot) {
  module.hot.accept("./routes", () => {
    const NextApp = require("./routes").default;
    render(NextApp);
  });
}
