/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import React from "react"
import { useTranslation } from 'react-i18next';

import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Typography } from "@mui/material";

import ButtonGroup from "../../../components/ButtonGroup";
import CustomDialog from "../../../components/CustomDialog";

import TitleUiElement from "./Elements/TitleUiElement";
import DescriptionUiElement from "./Elements/DescriptionUiElement";
import BooleanUiElement from "./Elements/BooleanUiElement";
import NumberUiElement from "./Elements/NumberUiElement";
import StringUiElement from "./Elements/StringUiElement";
import StringSetUiElement from "./Elements/StringSetUiElement";
import DropdownUiElement from "./Elements/DropdownUiElement";
import GroupTitleUiElement from "./Elements/GroupTitleUiElement";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  TYPE_BOOLEAN,
  TYPE_INTEGER,
  TYPE_DOUBLE,
  TYPE_STRING,
  TYPE_STRING_SET,
  ORDERING_TYPE_INTRO_TOUR,
  ORDERING_TYPE_HINT_DIALOGS,
  ORDERING_TYPE_EXPORT,
  ORDERING_TYPE_ADVANCED_SETTINGS,
  ORDERING_TYPE_GNSS,
  ORDERING_TYPE_TRANSFORMATION,
  appSettingsOrdering,
  editAppSettingsDialogTitle,
  editAppSettingsDialogDescription,
  dropdownSettings,
  INTRO_TOUR_PROJECT_LIST,
  INTRO_TOUR_PROJECT,
  INTRO_TOUR_CONFLICT,
  INTRO_TOUR_PROJECT_DETAILS,
  INTRO_TOUR_CAMERA_AR_MEASUREMENT,
  INTRO_TOUR_VOLUME,
  INTRO_TOUR_GNSS,
  INTRO_TOUR_DRAWING,
  INTRO_TOUR_MAP,
  HINT_DIALOGS_MAP_AND_DRAWING,
  HINT_DIALOGS_CAMERA_AR_MEASUREMENT,
  HINT_DIALOGS_PROJECT_LIST,
  HINT_DIALOGS_PROJECT_DETAILS,
  ADVANCED_SETTINGS_CONFLICT,
  ADVANCED_SETTINGS_CAMERA_AR_MEASUREMENT_LOGGING,
  ADVANCED_SETTINGS_CAMERA_AR_MEASUREMENT,
  appSettingsGrouping
} from "../../../constants/userSettings";

import { hashCode } from "../../../sharedFunctions/hashCodeUtils";

import * as styles from "../../../styles/styles";

import "./styles.css";

const propTypes = {
  settings: PropTypes.any,
  user: PropTypes.object,
  modalOpen: PropTypes.bool,
  onPositiveButtonClicked: PropTypes.func,
  onNegativeButtonClicked: PropTypes.func,
};

// ToDo: Only the logged in user can change its own information. There is no
// endpoint for the admin to update from a different user the information.
const EditUserSettingsDialog = ({
  settings,
  modalOpen,
  onPositiveButtonClicked,
  onNegativeButtonClicked,
}) => {
  const { t } = useTranslation();

  const [state, setState] = React.useState(settings);
  const [stateChanged, setStateChanged] = React.useState(false);
  const [positiveButtonDisabled, setPositiveButtonDisabled] =
    React.useState(true);
  const [expanded, setExpanded] = React.useState('');

  const settingsHashCode = React.useMemo(() => {
    return hashCode(JSON.stringify(settings));
  }, [settings]);

  const handleAccordionChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleChange = (event) => {
    for (const id in state) {
      if (state[id].key === event.key) {
        state[id].value = event.value;
      }
    }

    const newSettingsHashCode = hashCode(JSON.stringify(state));
    const disablePositiveButton = settingsHashCode == newSettingsHashCode;

    setStateChanged(true);
    setPositiveButtonDisabled(disablePositiveButton);
    setState([...state]);
  };

  const handleChangeStringSet = (event) => {
    for (const id in state) {
      if (state[id].key === event.key) {
        const value = event.value; // indicates if the property should be part of the stringSet
        const property = event.property; // the index from prefExportFiles or prefExportPdfPages
        if (value) {
          !state[id].value.includes(property) && state[id].value.push(property);
        } else {
          state[id].value = state[id].value.filter((item) => item != property);
        }
      }
    }

    const newSettingsHashCode = hashCode(JSON.stringify(state));
    const disablePositiveButton = settingsHashCode == newSettingsHashCode;

    setStateChanged(true);
    setPositiveButtonDisabled(disablePositiveButton);
    setState([...state]);
  };

  const handleOnPositiveButtonClicked = () => {
    onPositiveButtonClicked(stateChanged, [...state]);
  };

  const uiElementList = generateUiElements(
    state,
    handleChange,
    handleChangeStringSet,
    handleAccordionChange,
    expanded
  );

  return (
    <>
      <CustomDialog
        open={modalOpen}
        onClick={handleOnPositiveButtonClicked}
        onClose={onNegativeButtonClicked}
        showHint={false}
        renderTitle={() => {
          return (
            <DialogTitle id="alert-dialog-title">
              {editAppSettingsDialogTitle}
            </DialogTitle>
          );
        }}
        renderContent={() => {
          return <DialogContent key={"content"}>
            <Typography
              key={`descriptions`}
              variant="body1"
              style={{ margin: `0 0 ${styles.spacing12} 0` }}
            >
              {editAppSettingsDialogDescription}
            </Typography> 
            {uiElementList}
          </DialogContent>;
        }}
        renderButtonGroup={(
          handleOnPositiveButtonClicked,
          onNegativeButtonClicked
        ) => {
          return (
            <DialogActions style={{ padding: "2.4rem 2.4rem 2rem" }}>
              <ButtonGroup
                align="right"
                leftButtonText={t('button.btnCancel', {ns: 'common'})}
                leftButtonClick={onNegativeButtonClicked}
                rightButtonText={t('button.btnSave', {ns: 'common'})}
                rightButtonClick={handleOnPositiveButtonClicked}
                rightButtonDisabled={positiveButtonDisabled}
                spacingTop={false}
              />
            </DialogActions>
          );
        }}
      />
      ;
    </>
  );
};

const generateUiElements = (
  androidSettings,
  handleChange,
  handleChangeStringSet,
  handleAccordionChange,
  expanded
) => {
  const uiElementListOrdered = {
    [ORDERING_TYPE_INTRO_TOUR]: 
      {
        [INTRO_TOUR_PROJECT_LIST]: [
          (<GroupTitleUiElement key={`group_title_${INTRO_TOUR_PROJECT_LIST}`} property={INTRO_TOUR_PROJECT_LIST}/>)
        ],
        [INTRO_TOUR_PROJECT]: [
          (<GroupTitleUiElement key={`group_title_${INTRO_TOUR_PROJECT}`} property={INTRO_TOUR_PROJECT}/>)
        ],
        [INTRO_TOUR_CONFLICT]: [
          (<GroupTitleUiElement key={`group_title_${INTRO_TOUR_CONFLICT}`} property={INTRO_TOUR_CONFLICT}/>)
        ],
        [INTRO_TOUR_PROJECT_DETAILS]: [
          (<GroupTitleUiElement key={`group_title_${INTRO_TOUR_PROJECT_DETAILS}`} property={INTRO_TOUR_PROJECT_DETAILS}/>)
        ],
        [INTRO_TOUR_CAMERA_AR_MEASUREMENT]: [
          (<GroupTitleUiElement key={`group_title_${INTRO_TOUR_CAMERA_AR_MEASUREMENT}`} property={INTRO_TOUR_CAMERA_AR_MEASUREMENT}/>)
        ],
        [INTRO_TOUR_VOLUME]: [
          (<GroupTitleUiElement key={`group_title_${INTRO_TOUR_VOLUME}`} property={INTRO_TOUR_VOLUME}/>)
        ],
        [INTRO_TOUR_GNSS]: [
          (<GroupTitleUiElement key={`group_title_${INTRO_TOUR_GNSS}`} property={INTRO_TOUR_GNSS}/>)
        ],
        [INTRO_TOUR_DRAWING]: [
          (<GroupTitleUiElement key={`group_title_${INTRO_TOUR_DRAWING}`} property={INTRO_TOUR_DRAWING}/>)
        ],
        [INTRO_TOUR_MAP]: [
          (<GroupTitleUiElement key={`group_title_${INTRO_TOUR_MAP}`} property={INTRO_TOUR_MAP}/>)
        ]
      }
    ,
    [ORDERING_TYPE_HINT_DIALOGS]: {
      [HINT_DIALOGS_MAP_AND_DRAWING]: [
        (<GroupTitleUiElement key={`group_title_${HINT_DIALOGS_MAP_AND_DRAWING}`} property={HINT_DIALOGS_MAP_AND_DRAWING}/>)
      ],
      [HINT_DIALOGS_CAMERA_AR_MEASUREMENT]: [
        (<GroupTitleUiElement key={`group_title_${HINT_DIALOGS_CAMERA_AR_MEASUREMENT}`} property={HINT_DIALOGS_CAMERA_AR_MEASUREMENT}/>)
      ],
      [HINT_DIALOGS_PROJECT_LIST]: [
        (<GroupTitleUiElement key={`group_title_${HINT_DIALOGS_PROJECT_LIST}`} property={HINT_DIALOGS_PROJECT_LIST}/>)
      ],
      [HINT_DIALOGS_PROJECT_DETAILS]: [
        (<GroupTitleUiElement key={`group_title_${HINT_DIALOGS_PROJECT_DETAILS}`} property={HINT_DIALOGS_PROJECT_DETAILS}/>)
      ]
    },
    [ORDERING_TYPE_EXPORT]: [],
    [ORDERING_TYPE_ADVANCED_SETTINGS]: {
      [ADVANCED_SETTINGS_CONFLICT]: [
        (<GroupTitleUiElement key={`group_title_${ADVANCED_SETTINGS_CONFLICT}`} property={ADVANCED_SETTINGS_CONFLICT}/>)
      ],
      [ADVANCED_SETTINGS_CAMERA_AR_MEASUREMENT_LOGGING]: [
        (<GroupTitleUiElement key={`group_title_${ADVANCED_SETTINGS_CAMERA_AR_MEASUREMENT_LOGGING}`} property={ADVANCED_SETTINGS_CAMERA_AR_MEASUREMENT_LOGGING}/>)
      ],
      [ADVANCED_SETTINGS_CAMERA_AR_MEASUREMENT]: [
        (<GroupTitleUiElement key={`group_title_${ADVANCED_SETTINGS_CAMERA_AR_MEASUREMENT}`} property={ADVANCED_SETTINGS_CAMERA_AR_MEASUREMENT}/>)
      ]
    },
    [ORDERING_TYPE_GNSS]: [],
    [ORDERING_TYPE_TRANSFORMATION]: [],
  };
  const uiElementList = [];

  for (const id in androidSettings) {
    const key = androidSettings[id].key;
    const orderingKey = appSettingsOrdering[key];
    if (!orderingKey) {
      continue;
    }

    let orderingList = uiElementListOrdered[orderingKey];
    if (typeof orderingList === 'object' && orderingList !== null && !Array.isArray(orderingList)) {
      orderingList = orderingList[appSettingsGrouping[orderingKey][key]]
    }

    if (androidSettings[id].type === TYPE_BOOLEAN) {
      orderingList.push(
        <BooleanUiElement
          key={key}
          androidSettings={androidSettings}
          id={id}
          keyIdx={key}
          handleChange={handleChange}
        />
      );
    } else if (androidSettings[id].type === TYPE_INTEGER) {
      orderingList.push(
        <NumberUiElement
          key={key}
          androidSettings={androidSettings}
          id={id}
          keyIdx={key}
          handleChange={handleChange}
        />
      );
    } else if (androidSettings[id].type === TYPE_DOUBLE) {
      orderingList.push(
        <NumberUiElement
          key={key}
          androidSettings={androidSettings}
          id={id}
          keyIdx={key}
          handleChange={handleChange}
        />
      );
    } else if (androidSettings[id].type === TYPE_STRING) {
      if (dropdownSettings[androidSettings[id].key]) {
        orderingList.push(
          <DropdownUiElement
            key={key}
            androidSettings={androidSettings}
            id={id}
            keyIdx={key}
            wordList={dropdownSettings[androidSettings[id].key]}
            handleChange={handleChange}
          />
        );
      } else {
        orderingList.push(
          <StringUiElement
            key={key}
            androidSettings={androidSettings}
            id={id}
            keyIdx={key}
            handleChange={handleChange}
          />
        );
      }
    } else if (androidSettings[id].type === TYPE_STRING_SET) {
      orderingList.push(
        <StringSetUiElement
          key={key}
          androidSettings={androidSettings}
          id={id}
          keyIdx={key}
          handleChangeStringSet={handleChangeStringSet}
        />
      );
    }
  }

  for (const property in uiElementListOrdered) {
    if (uiElementListOrdered[property].length === 0) {
      continue;
    }
    
    const tempUiElementList = []
    for (const idx in uiElementListOrdered[property]) {
      tempUiElementList.push(uiElementListOrdered[property][idx]);
    }

    uiElementList.push(
      <Accordion 
        key={`container-${property}`}
        expanded={expanded === `panel-${property}`} 
        onChange={handleAccordionChange(`panel-${property}`)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-content-${property}`}
          key={`panel1-header-${property}`}
          id={`panel1-header-${property}`}
        >
          <TitleUiElement key={`${property}_title`} property={property} />
        </AccordionSummary>
        <AccordionDetails
          key={`panel1-details-${property}`}
          id={`panel1-details-${property}`}
        >
          <DescriptionUiElement
            key={`${property}_description`}
            property={property}
          />
          {tempUiElementList}
        </AccordionDetails>
      </Accordion>
    );
  }

  return uiElementList;
};

EditUserSettingsDialog.propTypes = propTypes;
export default EditUserSettingsDialog;
/*
{
	"android": [{
		"key": "pref_gnss_use_height_component",
		"type": "Boolean",
		"value": true
	}, {
		"key": "measure_position_monitoring_and_data_logging_frequency",
		"type": "Integer",
		"value": 2000
	},{
		"key": "measure_position_monitoring_and_data_logging_segment_distance_threshold",
		"type": "Double",
		"value": 0.2
	}, {
		"key": "ar_camera_update_rate",
		"type": "String",
		"value": "60_fps"
	}, {
		"key": "pref_export_files",
		"type": "StringSet",
		"value": ["11", "1", "2", "4", "8", "9", "10"]
	}]
}
*/
