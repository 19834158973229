import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from 'react-i18next';

import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import Typography from "@mui/material/Typography";

import ButtonGroup from "../../../components/ButtonGroup";
import CustomDialog from "../../../components/CustomDialog";

import {
  requestAccountDeletionDialogTitle,
  requestAccountDeletionDialogBody,
} from "../../../constants/accountDeletionConstants";

const propTypes = {
  modalOpen: PropTypes.bool,
  onPositiveButtonClicked: PropTypes.func,
  onNegativeButtonClicked: PropTypes.func,
};

const RequestAccountDeletionDialog = ({
  modalOpen,
  onPositiveButtonClicked,
  onNegativeButtonClicked,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <CustomDialog
        open={modalOpen}
        onClick={onPositiveButtonClicked}
        onClose={onNegativeButtonClicked}
        showHint={false}
        renderTitle={() => {
          return (
            <DialogTitle id="alert-dialog-title">
              {requestAccountDeletionDialogTitle}
            </DialogTitle>
          );
        }}
        renderContent={() => {
          return (
            <DialogContent key={"content"}>
              <Typography variant="body2" component="div">
                {requestAccountDeletionDialogBody}
              </Typography>
            </DialogContent>
          );
        }}
        renderButtonGroup={(
          onPositiveButtonClicked,
          onNegativeButtonClicked
        ) => {
          return (
            <DialogActions style={{ padding: "2.4rem 2.4rem 2rem" }}>
              <ButtonGroup
                align="right"
                leftButtonText={t('button.btnCancel', {ns: 'common'})}
                leftButtonClick={onNegativeButtonClicked}
                rightButtonText={t('button.btnConfirm', {ns: 'common'})}
                rightButtonClick={onPositiveButtonClicked}
                spacingTop={false}
              />
            </DialogActions>
          );
        }}
      />
      ;
    </>
  );
};

RequestAccountDeletionDialog.propTypes = propTypes;
export default RequestAccountDeletionDialog;
