import i18next from '../i18n';

i18next.on('languageChanged init', () => {
    init()
})

const t = (textCode, options) => {
    return i18next.t(textCode, options);
}

export var requestAccountDeletionDialogTitle = ""
export var requestAccountDeletionDialogBody = ""

const init = () => {
    requestAccountDeletionDialogTitle = t('accountDeletionConstants.requestAccountDeletionDialogTitle', {ns: 'constants'});
    requestAccountDeletionDialogBody = t('accountDeletionConstants.requestAccountDeletionDialogBody', {ns: 'constants'});
}
