import i18next from '../i18n';

// used as used in the app
// needed as kes to access media files
export const WKT = "wkt";
export const DXF = "dxf";
export const DXF_LOCAL = "dxf_local";
export const DXF_8_DIGIT = "dxf_8_digit";
export const SHAPE = "shape";
export const LOCAL = "local";
export const PDF = "pdf";
export const IMAGES = "images";
export const TRANSFORMATION_RESULT = "transformationResult";
export const TXT = "txt";
export const IMAGE = "image";
export const DATA_MONITOR_RESULT = "dataMonitoringResult";

export const nameMap = {}

i18next.on('languageChanged init', () => {
  init()
})

const t = (textCode, options) => {
  return i18next.t(textCode, options);
}

const init = () => {
  nameMap[WKT] = t('download.txtWkt', {ns: 'constants'})
  nameMap[DXF] = t('download.txtDxf', {ns: 'constants'})
  nameMap[DXF_LOCAL] = t('download.txtDxfLocal', {ns: 'constants'})
  nameMap[DXF_8_DIGIT] = t('download.txtDxf8Digit', {ns: 'constants'})
  nameMap[SHAPE] = t('download.txtShape', {ns: 'constants'})
  nameMap[LOCAL] = t('download.txtLocal', {ns: 'constants'})
  nameMap[PDF] = t('download.txtPdf', {ns: 'constants'})
  nameMap[IMAGES] = t('download.txtImages', {ns: 'constants'})
  nameMap[TRANSFORMATION_RESULT] = t('download.txtTransformationResult', {ns: 'constants'})
  nameMap[TXT] = t('download.txtTxt', {ns: 'constants'})
  nameMap[DATA_MONITOR_RESULT]= t('download.txtDataMonitorResult', {ns: 'constants'})
}

