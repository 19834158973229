import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PropTypes from "prop-types";
import React from "react";

import * as styles from "../../styles/styles";

const propTypes = {
  color: PropTypes.string,
  style: PropTypes.object,
  onMenuEntryClicked: PropTypes.func,
  contextMenuEntries: PropTypes.array,
};

const ContextMenu = ({
  color = styles.darkBlueBase,
  contextMenuEntries = [],
  onMenuEntryClicked,
  style = {},
}) => {
  const [state, setState] = React.useState({
    anchorEl: null,
  });

  const handleClose = (key) => {
    // If dropdown is closed, key is still returned.
    // It is a class though. Check if key is string for
    // proper reaction.
    const bool = typeof key === "string";
    setState({
      anchorEl: null,
      modalOpen: bool,
      key: bool ? key : false,
    });
    onMenuEntryClicked(key);
  };

  return (
    <>
      <IconButton
        style={style}
        className="project-list-item-dropdown-icon"
        aria-owns={state.anchorEl ? "simple-menu" : undefined}
        aria-haspopup="true"
        onClick={(event) =>
          setState({ ...state, anchorEl: event.currentTarget })
        }
        size="large"
      >
        <MoreVertIcon style={{ color }} />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={state.anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(state.anchorEl)}
        onClose={handleClose}
      >
        {contextMenuEntries.map((element, index) => (
          <MenuItem
            divider={index !== contextMenuEntries.length - 1}
            key={element.key}
            onClick={() => handleClose(element.key)}
          >
            <Typography variant="body2" color="textPrimary">
              {element.text}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

ContextMenu.propTypes = propTypes;
export default ContextMenu;
