import React from "react";
import PropTypes from "prop-types";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

import * as styles from "../../styles/styles";
import "./styles.css";

const propTypes = {
  disableEscapeKeyDown: PropTypes.bool,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
  render: PropTypes.func,
  showCloseButton: PropTypes.bool,
};

const CustomModal = ({
  open = false,
  disableEscapeKeyDown = false,
  onClose,
  onClick,
  render,
  showCloseButton = false,
}) => {
  return (
    <Modal
      disableEscapeKeyDown={disableEscapeKeyDown}
      open={open}
      onClose={onClose}
    >
      <div className="custom-modal-paper">
        {showCloseButton && (
          <IconButton
            style={{
              position: "absolute",
              backgroundColor: styles.primary,
              color: styles.white,
              top: "1.5rem",
              right: "1.5rem",
              cursor: "pointer",
              zIndex: "1",
            }}
            onClick={onClose}
            size="large">
            <CloseIcon />
          </IconButton>
        )}
        {render(onClick, onClose)}
      </div>
    </Modal>
  );
};

CustomModal.propTypes = propTypes;
export default CustomModal;
