import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';

import { 
  pointNameLookupTable,
  MEASURE_POINT,
  REFERENCE_POINT,
  TOPOGRAPHIC_POINT,
  GNSS_POINT,
  GNSS_IMAGE,
  GNSS_REF_POINT
} from "../../../../../constants/pointLookupTable";

import "./styles.css";

const propTypes = {
  onClick: PropTypes.func,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  feature: PropTypes.object,
};

const MapPopUp = ({ id, feature, onClick }) => {
  const { t } = useTranslation();

  var popUpView;

  if (feature.layer.id.includes(MEASURE_POINT) || 
    feature.layer.id.includes(REFERENCE_POINT) || 
    feature.layer.id.includes(TOPOGRAPHIC_POINT)
  ) {
    popUpView = renderMeasurementPointPopUp(
      t, id, feature, onClick
    )
  } else if (feature.layer.id.includes(GNSS_POINT) || 
    feature.layer.id.includes(GNSS_IMAGE) || 
    feature.layer.id.includes(GNSS_REF_POINT)
  ) {
    popUpView = renderGnssPointPopUp(
      t, id, feature, onClick
    )
  }

  return popUpView
};

const renderMeasurementPointPopUp = (
  t,
  id,
  feature,
  onClick
) => {

  const image = JSON.parse(feature.properties.image).original;
  return (
    <div className="map-pop-up-custom-mapgoxgl-pop-up">
      <div className="map-pop-up-image-container">
        <img
          onClick={() => onClick(image)}
          className="map-pop-up-image"
          src={image}
          alt={t('Project.Map.MapPopUp.txtImageAlternative', {id: id, ns: 'screens_private'})}
        />
      </div>

      <div className="map-pop-up-title">{t('Project.Map.MapPopUp.txtPointId', {ns: 'screens_private'})}</div>
      <div className="map-pop-up-text">
        {feature.properties.id_user}
      </div>
      <div className="map-pop-up-title">{t('Project.Map.MapPopUp.txtPointType', {ns: 'screens_private'})}</div>
      <div className="map-pop-up-text">
        {`${
          feature.properties.used_for_transformation
            ? t('Project.Map.MapPopUp.txtPointTypeTransformationPoint', {ns: 'screens_private'})
            : pointNameLookupTable[feature.properties.type]
        }`}
      </div>
      <div className="map-pop-up-title">{t('Project.Map.MapPopUp.txtComponent', {ns: 'screens_private'})}</div>
      <div className="map-pop-up-text">
        {`${
          feature.properties.component !== "null"
            ? feature.properties.component
            : t('Project.Map.MapPopUp.txtNoComponentSet', {ns: 'screens_private'})
        }`}
      </div>
      
    </div>
  );
}

const renderGnssPointPopUp = (
  t,
  id,
  feature,
  onClick
) => {
  const image = JSON.parse(feature.properties.image).original;

  return (
    <div className="map-pop-up-custom-mapgoxgl-pop-up">
      <div className="map-pop-up-image-container">
        {image ? 
        <img
          onClick={() => onClick(image)}
          className="map-pop-up-image"
          src={image}
          alt={t('Project.Map.MapPopUp.txtImageAlternative', {id: id, ns: 'screens_private'})}
        />
      : <div className="map-pop-up-empty-image"/>}
      </div>

      <div className="map-pop-up-title">{t('Project.Map.MapPopUp.txtPointId', {ns: 'screens_private'})}</div>
      <div className="map-pop-up-text">
        {feature.properties.user_id}
      </div>
      <div className="map-pop-up-title">{t('Project.Map.MapPopUp.txtComponent', {ns: 'screens_private'})}</div>
      <div className="map-pop-up-text">
        {`${
          feature.properties.component !== "null"
            ? feature.properties.component_name
            : t('Project.Map.MapPopUp.txtNoComponentSet', {ns: 'screens_private'})
        }`}
      </div>
      
    </div>
  );
}

MapPopUp.propTypes = propTypes;
export default MapPopUp;
