/* eslint-disable no-unused-vars */
import Checkbox from "@mui/material/Checkbox";
import ListItem from "@mui/material/ListItem";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid2";
import classNames from "classnames";
import moment from "moment";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import React, { memo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  dropdownFinishedProjectElements,
  dropdownOpenProjectElements,
  dropdownArchivedProjectElements,
  DOWNLOAD_KEY,
  EDIT_KEY,
  ASSIGN_KEY,
  DELETE_KEY,
  RESET_KEY,
} from "../../../../../constants/contextMenuEntries";

import { STANDARD_PROJECT_LIST_FLAG } from "../../../../../constants/projectList";
import {
  STATE_OPEN,
  STATE_DONE,
  STATE_ARCHIVED,
} from "../../../../../constants/project";

import { deleteProject, resetProject } from "../../../../../actions/projects";

import Confirmation from "../../../../../components/dialogs/Confirmation";
import StateIndicator from "../../../../../components/StateIndicator";
import AssignUser from "../../../../../containers/dialogs/AssignUser";
import DownloadData from "../../../../../containers/dialogs/DownloadData";
import ProjectForm from "../../../../../containers/dialogs/ProjectForm";
import ContextMenu from "../../../../../containers/ContextMenu";

import * as styles from "../../../../../styles/styles";
import "./styles.css";

const propTypes = {
  project: PropTypes.object,
  projectInSelection: PropTypes.bool,
  handleClose: PropTypes.func,
  users: PropTypes.array,
  projectListTypeFlag: PropTypes.any,
  deleteProject: PropTypes.func,
  resetProject: PropTypes.func,
  classes: PropTypes.object,
  checkboxVisible: PropTypes.bool,
  onChange: PropTypes.func,
  history: PropTypes.object,
};

const overrideStyles = (theme) => ({
  root: {
    borderRadius: "10px",
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
    paddingTop: "1.5rem",
    paddingBottom: "1.5rem",
    backgroundColor: styles.white,
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: styles.darkBlueMedium,
    //boxShadow: styles.boxShadowListElement,
    "&:hover": {
      boxShadow: styles.boxShadowListElementHover,
    },
  },
  listItem: {
    cursor: "pointer",
    "&:hover $checkboxContainer": {
      display: "flex",
      opacity: 1,
    },
  },
  checkboxContainer: {
    opacity: 0,
    display: "none",
    justifyContent: "center",
    alignItems: "center",
  },
  checkboxVisible: {
    display: "flex",
    opacity: 1,
  },
  listItemTextmultiline: {
    marginBottom: "2rem",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
});

const divider = <div className="project-list-item-vertical-divider" />;

const ProjectListItem = ({
  projectListTypeFlag,
  project,
  projectInSelection,
  deleteProject,
  resetProject,
  classes,
  checkboxVisible,
  onChange,
  history,
  users,
}) => {
  const { t } = useTranslation();

  // Prepare State-Handling.
  const [modalOpen, setModalOpen] = React.useState(false);
  const [menuEntryKey, setMenuEntryKey] = React.useState(null);

  const closeModal = () => {
    setModalOpen(false);
    setMenuEntryKey(null);
  };

  const openModal = (menuEntryKey) => {
    const bool = typeof menuEntryKey === "string";
    setModalOpen(bool);
    setMenuEntryKey(bool ? menuEntryKey : false);
  };

  // Prepare Actions
  const deleteSelectedProject = () => {
    deleteProject(project.id);
    closeModal();
  };

  const resetSelectedProject = () => {
    resetProject(project.id);
    closeModal();
  };

  // Prepare Constants
  const unknownUser = { 
    first_name: t('Projects.ProjectList.ProjectListItem.txtFirstNameUnknown', {ns: 'screens_private'}), 
    last_name: t('Projects.ProjectList.ProjectListItem.txtLastNameUnknown', {ns: 'screens_private'}) };
  const inserted_at = moment(project.inserted_at);
  const updated_at = moment(project.updated_at);
  const due_date = moment(project.due_date);
  const user = users.find((u) => u.id === project.user_id) || unknownUser;
  const assignedUser =
    users.find((u) => u.id === project.assigned_user_id) || unknownUser;

  var contextMenuEntries;
  switch (project.state) {
    case STATE_DONE:
      contextMenuEntries = dropdownFinishedProjectElements;
      break;
    case STATE_ARCHIVED:
      contextMenuEntries = dropdownArchivedProjectElements;
      break;
    default:
    case STATE_OPEN:
      contextMenuEntries = dropdownOpenProjectElements;
      break;
  }

  // Rendering
  return (
    <>
      {modalOpen &&
        renderModalContent(
          t,
          project,
          modalOpen,
          menuEntryKey,
          closeModal,
          deleteSelectedProject,
          resetSelectedProject
        )}

      <ListItem
        classes={{ root: classes.root, divider: classes.divider }}
        onClick={() => {
          if (projectListTypeFlag === STANDARD_PROJECT_LIST_FLAG) {
            history.push(`/projects/${project.id}`);
          } else {
            history.push(`/projects/archived/${project.id}`);
          }
        }}
        className={classNames(classes.listItem)}
      >
        <Grid container spacing={1} alignItems="center" size={12}>
          <Grid size={2}>
            <div className="project-list-item-jobnumber-state-wrapper">
              <div
                className={classNames(classes.checkboxContainer, {
                  [classes.checkboxVisible]: checkboxVisible,
                })}
              >
                <Checkbox
                  className={classes.checkbox}
                  checked={projectInSelection}
                  onChange={() => onChange(project.id.toString())}
                  onClick={(e) => e.stopPropagation()}
                />
                {divider}
              </div>
              <ListItemText
                className="project-list-item-truncate-text"
                primaryTypographyProps={{
                  variant: "body1",
                  color: "textPrimary",
                }}
                primary={project.job_order}
                secondary={<StateIndicator state={project.state} />}
              />
            </div>
          </Grid>

          <Grid size={10} container>
            <Grid container size={12} direction="column">
              <Grid container spacing={1}>
                <Grid size={12}>
                  <ListItemText
                    classes={{ multiline: classes.listItemTextmultiline }}
                    primaryTypographyProps={{
                      variant: "subtitle1",
                      color: "textPrimary",
                      noWrap: true,
                    }}
                    primary={project.title}
                    secondaryTypographyProps={{
                      variant: "body1",
                      color: "textSecondary",
                      noWrap: true,
                    }}
                    secondary={project.description}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid size={{md:2, xs:4}}>
                  <ListItemText
                    primaryTypographyProps={{
                      variant: "caption",
                      color: "textSecondary",
                    }}
                    primary={t('Projects.ProjectList.ProjectListItem.listItemTextCreatedBy', {ns: 'screens_private'})}
                    secondaryTypographyProps={{
                      variant: "body2",
                      color: "textPrimary",
                    }}
                    secondary={user.first_name + " " + user.last_name}
                  />
                </Grid>
                <Grid size={{md:2, xs:4}}>
                  <ListItemText
                    primaryTypographyProps={{
                      variant: "caption",
                      color: "textSecondary",
                    }}
                    primary={t('Projects.ProjectList.ProjectListItem.listItemTextAssignedTo', {ns: 'screens_private'})}
                    secondaryTypographyProps={{
                      variant: "body2",
                      color: "textPrimary",
                    }}
                    secondary={
                      assignedUser.first_name + " " + assignedUser.last_name
                    }
                  />
                </Grid>
                <Grid size={{md:2, xs:4}}>
                  <ListItemText
                    secondaryTypographyProps={{
                      variant: "body2",
                      color: "textPrimary",
                    }}
                    primaryTypographyProps={{
                      variant: "caption",
                      color: "textSecondary",
                    }}
                    primary={t('Projects.ProjectList.ProjectListItem.listItemTextCreatedAt', {ns: 'screens_private'})}
                    secondary={
                      <>
                        <span>{inserted_at.format("dd. DD.MM.YYYY")}</span>
                        <br />
                        <span>{inserted_at.format("HH:mm")} Uhr</span>
                      </>
                    }
                  />
                </Grid>
                <Grid size={{md:2, xs:4}}>
                  <ListItemText
                    primaryTypographyProps={{
                      variant: "caption",
                      color: "textSecondary",
                    }}
                    primary={t('Projects.ProjectList.ProjectListItem.listItemTextUpdatedAt', {ns: 'screens_private'})}
                    secondaryTypographyProps={{
                      variant: "body2",
                      color: "textPrimary",
                    }}
                    secondary={
                      <>
                        <span>{updated_at.format("dd. DD.MM.YYYY")}</span>
                        <br />
                        <span>{updated_at.format("HH:mm")} {t('Projects.ProjectList.ProjectListItem.txtClock', {ns: 'screens_private'})}</span>
                      </>
                    }
                  />
                </Grid>
                <Grid size={{md:2, xs:4}}>
                  {project.due_date != null && (
                    <ListItemText
                      primaryTypographyProps={{
                        variant: "caption",
                        color: "textSecondary",
                      }}
                      primary={t('Projects.ProjectList.ProjectListItem.listItemTextDueDate', {ns: 'screens_private'})}
                      secondaryTypographyProps={{
                        variant: "body2",
                        color: "textPrimary",
                      }}
                      secondary={
                        <>
                          <span>{due_date.format("dd. DD.MM.YYYY")}</span>
                        </>
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {contextMenuEntries && (
          <ListItemSecondaryAction>
            <ContextMenu
              color={styles.darkBlueBase}
              contextMenuEntries={contextMenuEntries}
              onMenuEntryClicked={(menuEntryKey) =>
                handleOnMenuEntryClicked(menuEntryKey, openModal)
              }
            />
          </ListItemSecondaryAction>
        )}
      </ListItem>
    </>
  );
};

function handleOnMenuEntryClicked(menuEntryKey, openModal) {
  openModal(menuEntryKey);
}

function renderModalContent(
  t,
  project,
  modalOpen,
  menuEntryKey,
  closeModal,
  deleteSelectedProject,
  resetSelectedProject
) {
  const { title, inserted_at } = project;

  switch (menuEntryKey) {
    case EDIT_KEY:
      return (
        <ProjectForm
          edit={true}
          modalOpen={modalOpen}
          onClick={() => closeModal()}
          onClose={() => closeModal()}
          project={project}
        />
      );
    case ASSIGN_KEY:
      return (
        <AssignUser
          project={project}
          modalOpen={modalOpen}
          onClick={closeModal}
          onClose={closeModal}
        />
      );
    case DELETE_KEY:
      return (
        <Confirmation
          highlightedText={title}
          headline={t('Confirmation.deleteProject.txtHeadline', {ns: 'common'})}
          leftSideText={t('Confirmation.deleteProject.txtLeftSideText', {ns: 'common'})}
          modalOpen={modalOpen}
          onClose={() => closeModal()}
          onClick={deleteSelectedProject}
          rightSideText={t('Confirmation.deleteProject.txtRightSideText', {insertedAt: moment(inserted_at).format("DD.MM.YYYY"), ns: 'common'})}
          textButton={t('Confirmation.deleteProject.txtTextButton', {ns: 'common'})}
        />
      );
    case DOWNLOAD_KEY:
      return (
        <DownloadData
          bulkAction={false}
          modalOpen={modalOpen}
          projects={[project.id]}
          project={project}
          onClose={() => closeModal()}
          onClick={() => closeModal()}
        />
      );
    case RESET_KEY:
      return (
        <Confirmation
          highlightedText={title}
          headline={t('Confirmation.resetProject.txtHeadline', {ns: 'common'})}
          leftSideText={t('Confirmation.resetProject.txtLeftSideText', {ns: 'common'})}
          modalOpen={modalOpen}
          onClose={() => closeModal()}
          onClick={resetSelectedProject}
          rightSideText={t('Confirmation.resetProject.txtRightSideText', {ns: 'common'})}
          textButton={t('Confirmation.resetProject.txtTextButton', {ns: 'common'})}
        />
      );
    default:
      break;
  }
}

function dispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteProject,
      resetProject,
    },
    dispatch
  );
}

ProjectListItem.propTypes = propTypes;
export default memo(
  connect(
    null,
    dispatchToProps
  )(withStyles(overrideStyles, { withTheme: true })(ProjectListItem))
);
