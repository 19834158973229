// APP
export const RENDER_SNACKBAR = "RENDER_SNACKBAR";
export const RESET_SNACKBAR = "RESET_SNACKBAR";
export const SET_STANDARD_PROJECT_SYNC_STATUS =
  "SET_STANDARD_PROJECT_SYNC_STATUS";
export const SET_ARCHIVED_PROJECT_SYNC_STATUS =
  "SET_ARCHIVED_PROJECT_SYNC_STATUS";

//DASHBOARD
export const SORT_PROJECTS = "SORT_PROJECTS";
export const SORT_PROJECTS_REVERSE = "SORT_PROJECTS_REVERSE";
export const SHOW_DUMMY_PROJECT = "SHOW_DUMMY_PROJECT";
export const HIDE_DUMMY_PROJECT = "HIDE_DUMMY_PROJECT";

// DATA
export const SELECT_PROJECTS = "SELECT_PROJECTS";
export const SEARCH_PROJECTS = "SEARCH_PROJECTS";
export const UNSELECT_PROJECTS = "UNSELECT_PROJECTS";
export const SET_PAGINATION_PAGE_COUNT = "SET_PAGINATION_PAGE_COUNT";
export const SET_ACTIVE_PAGINATION_PAGE = "SET_ACTIVE_PAGINATION_PAGE";
export const ACTIVE_PAGINATION_PAGE_UPDATED = "ACTIVE_PAGINATION_PAGE_UPDATED";
export const TOTAL_SORTED_AND_FILTERED_PROJECT_COUNT =
  "TOTAL_SORTED_AND_FILTERED_PROJECT_COUNT";

// USER
export const SET_USER = "SET_USER";
export const LOGOUT = "LOGOUT";
export const EDIT_USER = "EDIT_USER";
export const FORGOT_PASSWORD_STATUS = "FORGOT_PASSWORD_STATUS";
export const SET_PASSWORD_STATUS = "SET_PASSWORD_STATUS";
export const RESET_USER_FLAGS = "RESET_USER_FLAGS";

// SYNC
export const SET_INIT_SYNCING = "SET_INIT_SYNCING";
export const SET_LAST_SYNC = "SET_LAST_SYNC";

// PROJECTS
export const SET_PROJECTS = "SET_PROJECTS";
export const SET_PROJECT = "SET_PROJECT";
export const CREATE_PROJECT = "CREATE_PROJECT";
export const EDIT_PROJECT = "EDIT_PROJECT";
export const REMOVE_PROJECT = "REMOVE_PROJECT";
export const SET_PROJECT_STATUS = "SET_PROJECT_STATUS";
export const RESET_PROJECT = "RESET_PROJECT";
export const GEOCODE_ADDRESS = "GEOCODE_ADDRESS";
export const SET_PROJECT_JOB_ORDER_VALID = "SET_PROJECT_JOB_ORDER_VALID";

// ORGANIZATIONS
export const SET_ORGANIZATIONS = "SET_ORGANIZATIONS";
export const SET_ORGANIZATION = "SET_ORGANIZATION";
export const EDIT_ORGANIZATION = "EDIT_ORGANIZATION";
export const EDIT_ORGANIZATION_USER = "EDIT_ORGANIZATION_USER";
export const REMOVE_ORGANIZATION = "REMOVE_ORGANIZATION";
export const CHECK_ACTIVE_SUBSCRIPTION = "CHECK_ACTIVE_SUBSCRIPTION";
export const SET_EXPIRED_SUBSCRIPTION = "SET_EXPIRED_SUBSCRIPTION";
export const SET_REMOVED_FROM_ORGANIZATION = "SET_REMOVED_FROM_ORGANIZATION";

// MEASURE LAYER CONFIG
export const SET_MEASURE_LAYER_CONFIG = "SET_MEASURE_LAYER_CONFIG";
export const SET_MEASURE_LAYER_CONFIGS = "SET_MEASURE_LAYER_CONFIGS";
export const EDIT_MEASURE_LAYER_CONFIG = "EDIT_MEASURE_LAYER_CONFIG";
export const REMOVE_MEASURE_LAYER_CONFIG = "REMOVE_MEASURE_LAYER_CONFIG";
export const CREATE_MEASURE_LAYER_CONFIG = "CREATE_MEASURE_LAYER_CONFIG";

// MEASUREMENTS
export const SET_MEASUREMENT = "SET_MEASUREMENT";
export const SET_MEASUREMENTS = "SET_MEASUREMENTS";
export const EDIT_MEASUREMENT = "EDIT_MEASUREMENT";
export const REMOVE_MEASUREMENT = "REMOVE_MEASUREMENT";
export const REMOVE_MEASUREMENTS = "REMOVE_MEASUREMENTS";

// MEASUREMENT LINES
export const SET_MEASUREMENT_LINE = "SET_MEASUREMENT_LINE";
export const SET_MEASUREMENT_LINES = "SET_MEASUREMENT_LINES";
export const EDIT_MEASUREMENT_LINE = "EDIT_MEASUREMENT_LINE";
export const REMOVE_MEASUREMENT_LINE = "REMOVE_MEASUREMENT_LINE";
export const REMOVE_MEASUREMENT_LINES = "REMOVE_MEASUREMENT_LINES";

// MEASUREMENT SEGMENTS
export const SET_MEASUREMENT_SEGMENT = "SET_MEASUREMENT_SEGMENT";
export const SET_MEASUREMENT_SEGMENTS = "SET_MEASUREMENT_SEGMENTS";
export const EDIT_MEASUREMENT_SEGMENT = "EDIT_MEASUREMENT_SEGMENT";
export const REMOVE_MEASUREMENT_SEGMENT = "REMOVE_MEASUREMENT_SEGMENT";
export const REMOVE_MEASUREMENT_SEGMENTS = "REMOVE_MEASUREMENT_SEGMENTS";

// MEDIA FILES
export const SET_MEDIA_FILE = "SET_MEDIA_FILE";
export const SET_MEDIA_FILES = "SET_MEDIA_FILES";
export const EDIT_MEDIA_FILE = "EDIT_MEDIA_FILE";
export const REMOVE_MEDIA_FILE = "REMOVE_MEDIA_FILE";
export const REMOVE_MEDIA_FILES = "REMOVE_MEDIA_FILES";
export const ARCHIVED_REMOVE_MEDIA_FILES = "ARCHIVED_REMOVE_MEDIA_FILES";

// PROJECT FILES
export const SET_PROJECT_FILE = "SET_PROJECT_FILE";
export const SET_PROJECT_FILES = "SET_PROJECT_FILES";
export const REMOVE_PROJECT_FILE = "REMOVE_PROJECT_FILE";
export const REMOVE_PROJECT_FILES = "REMOVE_PROJECT_FILES";
export const SHOW_PROJECT_FILE_PREVIEW = "SHOW_PROJECT_FILE_PREVIEW";
export const HIDE_PROJECT_FILE_PREVIEW = "HIDE_PROJECT_FILE_PREVIEW";

// VOLUMES
export const SET_VOLUMES = "SET_VOLUMES";
export const SET_VOLUME = "SET_VOLUME";
export const EDIT_VOLUME = "EDIT_VOLUME";
export const REMOVE_VOLUME = "REMOVE_VOLUME";
export const REMOVE_VOLUMES = "REMOVE_VOLUMES";

// TOPOGRAPHIC POINTS
export const SET_TOPOGRAPHIC_POINT = "SET_TOPOGRAPHIC_POINT";
export const SET_TOPOGRAPHIC_POINTS = "SET_TOPOGRAPHIC_POINTS";
export const EDIT_TOPOGRAPHIC_POINT = "EDIT_TOPOGRAPHIC_POINT";
export const REMOVE_TOPOGRAPHIC_POINT = "REMOVE_TOPOGRAPHIC_POINT";
export const REMOVE_TOPOGRAPHIC_POINTS = "REMOVE_TOPOGRAPHIC_POINTS";

// USER ADMINISTRATION
export const SEARCH_USER = "SEARCH_USER";

// REGISTER
export const CONFIRM_USER_STATUS = "CONFIRM_USER_STATUS";
export const REGISTER_USER_STATUS = "REGISTER_USER_STATUS";
export const RESET_REGISTER_FLAGS = "RESET_REGISTER_FLAGS";

export const BLOCK_PHOTO = "BLOCK_PHOTO";
export const UNBLOCK_PHOTO = "UNBLOCK_PHOTO";

// CATALOG COMPONENTS
export const SET_COMPONENT_CATALOG_CATEGORY_SEARCH_TERM =
  "SET_COMPONENT_CATALOG_CATEGORY_SEARCH_TERM";
export const SET_ACTIVE_COMPONENT_CATALOG_UUID =
  "SET_ACTIVE_COMPONENT_CATALOG_UUID";
export const SET_CATALOG_COMPONENTS = "SET_CATALOG_COMPONENTS";
export const SET_SYNC_CATALOG_COMPONENTS_STATUS =
  "SET_SYNC_CATALOG_COMPONENTS_STATUS";
export const CREATE_CATALOG_CATEGORY = "CREATE_CATALOG_CATEGORY";
export const UPDATE_CATALOG_CATEGORY = "UPDATE_CATALOG_CATEGORY";
export const DELETE_CATALOG_CATEGORY = "DELETE_CATALOG_CATEGORY";
export const CREATE_CATALOG_COMPONENT = "CREATE_CATALOG_COMPONENT";
export const UPDATE_CATALOG_COMPONENT = "UPDATE_CATALOG_COMPONENT";
export const DELETE_CATALOG_COMPONENT = "DELETE_CATALOG_COMPONENT";
export const CREATE_CATALOG_MATERIAL = "CREATE_CATALOG_MATERIAL";
export const UPDATE_CATALOG_MATERIAL = "UPDATE_CATALOG_MATERIAL";
export const DELETE_CATALOG_MATERIAL = "DELETE_CATALOG_MATERIAL";
export const CREATE_CATALOG_DIMENSION = "CREATE_CATALOG_DIMENSION";
export const UPDATE_CATALOG_DIMENSION = "UPDATE_CATALOG_DIMENSION";
export const DELETE_CATALOG_DIMENSION = "DELETE_CATALOG_DIMENSION";
export const CREATE_CATALOG_ORGANIZATION_CATEGORY =
  "CREATE_CATALOG_ORGANIZATION_CATEGORY";
export const CREATE_CATALOG_PIPE_DEFINITION = "CREATE_CATALOG_PIPE_DEFINITION";
export const DELETE_CATALOG_PIPE_DEFINITION = "DELETE_CATALOG_PIPE_DEFINITION";

// MEASURE LAYER CONFIG TEMPLATES
export const SET_MEASURE_LAYER_CONFIG_TEMPLATE_SEARCH_TERM =
  "SET_MEASURE_LAYER_CONFIG_TEMPLATE_SEARCH_TERM";
export const SET_ACTIVE_MEASURE_LAYER_CONFIG_TEMPLATE_UUID =
  "SET_ACTIVE_MEASURE_LAYER_CONFIG_TEMPLATE_UUID";
export const SET_MEASURE_LAYER_CONFIG_TEMPLATES =
  "SET_MEASURE_LAYER_CONFIG_TEMPLATES";
export const SET_SYNC_MEASURE_LAYER_CONFIG_TEMPLATES_STATUS =
  "SET_SYNC_MEASURE_LAYER_CONFIG_TEMPLATES_STATUS";
export const CREATE_MEASURE_LAYER_CONFIG_TEMPLATE =
  "CREATE_MEASURE_LAYER_CONFIG_TEMPLATE";
export const UPDATE_MEASURE_LAYER_CONFIG_TEMPLATE =
  "UPDATE_MEASURE_LAYER_CONFIG_TEMPLATE";
export const DELETE_MEASURE_LAYER_CONFIG_TEMPLATE =
  "DELETE_MEASURE_LAYER_CONFIG_TEMPLATE";
export const CREATE_MEASURE_LAYER_CONFIG_ITEM =
  "CREATE_MEASURE_LAYER_CONFIG_ITEM";
export const UPDATE_MEASURE_LAYER_CONFIG_ITEM =
  "UPDATE_MEASURE_LAYER_CONFIG_ITEM";
export const DELETE_MEASURE_LAYER_CONFIG_ITEM =
  "DELETE_MEASURE_LAYER_CONFIG_ITEM";


// MAP LAYER
export const SET_MAP_LAYERS = "SET_MAP_LAYERS"
export const SET_SYNC_STATUS_MAP_LAYER = "SET_SYNC_STATUS_MAP_LAYER"
export const CREATE_MAP_LAYER = "CREATE_MAP_LAYER"
export const UPDATE_MAP_LAYER = "UPDATE_MAP_LAYER"
export const DELETE_MAP_LAYER = "DELETE_MAP_LAYER"
export const SET_MAP_LAYER_TO_PROJECT_ASSIGNMENTS = "SET_MAP_LAYER_TO_PROJECT_ASSIGNMENTS"
export const SET_SYNC_STATUS_MAP_LAYER_TO_PROJECT_ASSIGNMENTS = "SET_SYNC_STATUS_MAP_LAYER_TO_PROJECT_ASSIGNMENTS"
export const CREATE_MAP_LAYER_TO_PROJECT_ASSIGNMENT = "CREATE_MAP_LAYER_TO_PROJECT_ASSIGNMENT"
export const DELETE_MAP_LAYER_TO_PROJECT_ASSIGNMENT = "DELETE_MAP_LAYER_TO_PROJECT_ASSIGNMENT"
export const UPLOADING_MAP_LAYER = "UPLOADING_MAP_LAYER"
export const UPLOADING_MAP_LAYER_DONE = "UPLOADING_MAP_LAYER_DONE"

// GNSS DATA
export const SET_GNSS_DATA = "SET_GNSS_DATA"
export const GNSS_DATA_CREATED = "GNSS_DATA_CREATED"
export const GNSS_DATA_UPDATED = "GNSS_DATA_UPDATED"
export const GNSS_DATA_DELETED = "GNSS_DATA_DELETED"
export const GNSS_POINT_UPDATED = "GNSS_POINT_UPDATED"
export const GNSS_POINT_DELETED = "GNSS_POINT_DELETED"
export const GNSS_IMAGE_UPDATED = "GNSS_IMAGE_UPDATED"
export const GNSS_IMAGE_DELETED = "GNSS_IMAGE_DELETED"
export const GNSS_SEGMENT_DELETED = "GNSS_SEGMENT_DELETED"
export const GNSS_LINE_DELETED = "GNSS_LINE_DELETED"
export const GNSS_POLYGON_DELETED = "GNSS_POLYGON_DELETED"

// LANGUAGE
export const LANGUAGE_CHANGED = "LANGUAGE_CHANGED"