/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import mapboxgl from "mapbox-gl";

const propTypes = {
  project: PropTypes.object,
};


const EmptyProjectMap = ({
  project
}) => {
  
  const mapContainer = React.useRef(null);
  const [map, setMap] = React.useState(null);

  //////////////////////////////////////////
  // componentDidMount, componentWillUnmount
  React.useEffect(() => {
    const newMap = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v9",
      accessToken:
        "pk.eyJ1IjoiZ3JvYmVydCIsImEiOiJja3d2ejAwN2MweGdxMnFtaThpbGRxbzNmIn0.p83TeA_nVV8WiMEpNFYnoQ",
    });

    newMap.on("load", async () => {
      newMap.addControl(
        new mapboxgl.NavigationControl({ visualizePitch: true }),
        "top-right"
      );
      newMap.addControl(new mapboxgl.ScaleControl());
      newMap.addControl(
        new mapboxgl.FullscreenControl({
          container: document.getElementById("project-map-container"),
        })
      );
      newMap.getCanvas().style.cursor = "auto";

      if (project.place.latLng) {
        newMap.jumpTo({
          center: project.place.latLng,
          zoom: 19,
        });
      }
    });

    setMap(newMap);

    return () => {
      newMap.remove();
    }
  }, []);
  // componentDidMount, componentWillUnmount
  //////////////////////////////////////////
  
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        width: "100%",
      }}
      ref={mapContainer}
    />
  );
}

EmptyProjectMap.propTypes = propTypes;
export default EmptyProjectMap;
