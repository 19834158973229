import React from 'react';
import PropTypes from 'prop-types';
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

import * as styles from '../../styles/styles';

const propTypes = {
  avatarStyles: PropTypes.object,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  margin: PropTypes.string,
  variant: PropTypes.oneOf(['small', 'large'])
};

const NoAvatar = ({
  avatarStyles,
  firstName, 
  lastName, 
  margin = '',
  variant
}) => {
  return <Avatar
    alt='avatar'
    style={{
      backgroundColor: styles.secondary,
      width: variant === 'large' ? 200 : styles.avatarSize,
      height: variant === 'large' ? 200 : styles.avatarSize,
      margin: margin,
      ...avatarStyles,
    }}>
    <Typography
      style={{ color: styles.white }}
      variant={variant === 'large' ? 'h1' : 'h6'}>
      {`${firstName[0]}${lastName[0]}`}
    </Typography>
  </Avatar >
}

NoAvatar.propTypes = propTypes;
export default NoAvatar;