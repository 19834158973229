import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Typography from "@mui/material/Typography";
import { Link } from 'react-router-dom';

import { success, fail, pending } from '../../../../../constants/status';
import Spinner from '../../../../../atoms/Spinner';

import * as styles from '../../../../../styles/styles';

const propTypes = {
  user: PropTypes.object,
  resetUserFlags: PropTypes.func
};

const InformationForgotPassword = ({
  user,
  resetUserFlags
}) => {
  const { t } = useTranslation();

  useEffect(() => { resetUserFlags() }, [resetUserFlags]);

  const renderMessage = () => {
    if (user.forgotPasswordStatus === success) {
      return t('ResetPassword.ForgotPasswordDialog.InformationForgotPassword.txtForgotPasswordStatusSuccess', {ns: 'screens_public'});
    } else if (user.forgotPasswordStatus === fail) {
      return t('ResetPassword.ForgotPasswordDialog.InformationForgotPassword.txtForgotPasswordStatusFail', {ns: 'screens_public'});
    } else if (user.forgotPasswordStatus === pending) {
      return <Spinner
        height={2.9}
        width={2.9} />
    } else {
      return null;
    }
  }

  return <>
    <Typography
      variant='h6'
      align='center'>
      {renderMessage()}
    </Typography>
    <Link
      style={{ textDecoration: 'none', color: 'inherit' }}
      to='/login'>
      <Typography
        color='secondary'
        variant='body1'
        style={{ cursor: 'pointer', margin: `${styles.spacing24} 0 0 0` }}>
          {t('ResetPassword.ForgotPasswordDialog.InformationForgotPassword.txtBackToWebsite', {ns: 'screens_public'})}
      </Typography>
    </Link>
  </>
}

InformationForgotPassword.propTypes = propTypes;
export default InformationForgotPassword;