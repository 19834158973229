/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";

import Typography from "@mui/material/Typography";
import MenuItem from '@mui/material/MenuItem';
import { ListItemText, ListItemIcon } from "@mui/material";

import { GB, DE } from 'country-flag-icons/react/3x2';


import Column from "../../layout/Column";


const propTypes = {
    language: PropTypes.string,
    languageChanged: PropTypes.func,
};


const UserProfileLanguageSelection = ({
    language,
    languageChanged
}) => {
    const [selectedLanguage, setSelectedLanguage] = React.useState(language);
    const { t } = useTranslation();

    const languages = [
        { 
            value: "de", 
            flag: <DE title={t('userProfile.UserProfileLanguageSelection.txtDE', {ns: 'components'})}/>, 
            text: t('userProfile.UserProfileLanguageSelection.txtDE', {ns: 'components'})
        },
        { 
            value: "en", 
            flag: <GB title={t('userProfile.UserProfileLanguageSelection.txtGB', {ns: 'components'})}/>, 
            text: t('userProfile.UserProfileLanguageSelection.txtGB', {ns: 'components'}) 
        },
    ]

    const handleChange = (newLanguage) => {
        setSelectedLanguage(newLanguage);
        languageChanged(newLanguage)
    }

    return (
        <>
            <Column side="left">
                <Typography color="primary" variant="h2">
                    {t('userProfile.UserProfileLanguageSelection.title', {ns: 'components'})}
                </Typography>
            </Column>

            <Column side="right">
                <div style={{ margin: `6rem 0 0 0` }}>
                    <FormControl
                        sx={{ m: 1, minWidth: 100 }}
                        variant="outlined"
                        style={{
                        margin: `0 0 0 0`,
                        paddingRight: `0`,
                        }}
                    >
                        <InputLabel htmlFor="outlined-native-simple">
                        {t('userProfile.UserProfileLanguageSelection.txtSelectLanguage', {ns: 'components'})}
                        </InputLabel>
                        <Select
                            SelectDisplayProps={{
                                style: {display: 'flex', alignItems: 'center'},
                            }}
                            autoWidth
                            value={selectedLanguage}
                            onChange={(e) => handleChange(e.target.value)}
                            label={t('userProfile.UserProfileLanguageSelection.txtSelectLanguage', {ns: 'components'})}
                            inputProps={{
                                name: "dropdown",
                                id: "outlined-native-simple",
                            }}
                        >
                            {languages.map(renderMenuItem)}
                        </Select>
                    </FormControl>
                </div>
            </Column>
        </>
    );
};

const renderMenuItem = (language) => {
    return (
        <MenuItem key={language.value} value={language.value}>
            <ListItemIcon style={{marginRight: '1rem'}}>
                {language.flag}
            </ListItemIcon>
            <ListItemText sx={{my: 0}}>
                {language.text}
            </ListItemText>
        </MenuItem>
    )
}

UserProfileLanguageSelection.propTypes = propTypes;
export default UserProfileLanguageSelection;
