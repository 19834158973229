/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';

import ButtonGroup from "../../../components/ButtonGroup";
import CustomDialog from "../../../components/CustomDialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import { validateTextFields } from "../../../sharedFunctions/validation";

import {
  CATALOG_CATEGORY_MAPPING,
  COMPONENTS,
  MATERIALS,
  DIMENSIONS,
} from "../../../constants/catalogCategoryMapping";

import * as styles from "../../../styles/styles";

const propTypes = {
  edit: PropTypes.bool,
  data: PropTypes.object,
  componentDataKey: PropTypes.string,
  modalOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
};

const GenericComponentCatalogDataDialog = ({
  edit = false,
  componentDataKey = "component",
  data = {
    catalogCategory: [{ rootUuid: "", item: { uuid: "" } }],
    data: {
      uuid: "",
      name: "",
      description: "",
    },
  },
  modalOpen,
  onClose,
  onClick,
}) => {
  const { t } = useTranslation();

  const name = data.data.name ? data.data.name : "";
  const description = data.data.description ? data.data.description : "";

  let dataType = "";

  if (componentDataKey === COMPONENTS) {
    dataType = CATALOG_CATEGORY_MAPPING["component"];
  } else if (componentDataKey === MATERIALS) {
    dataType = CATALOG_CATEGORY_MAPPING["material"];
  } else if (componentDataKey === DIMENSIONS) {
    dataType = CATALOG_CATEGORY_MAPPING["dimension"];
  }

  const [stateName, setStateName] = useState(name);
  const [stateDescription, setStateDescription] = useState(description);
  const [stateDisablePositiveButton, setDisablePositiveButton] = useState(true);

  const validationValues = React.useMemo(() => {
    return {
      name: {
        value: name ? name : "",
        required: true,
      },
      descriptions: {
        value: description ? description : "",
        required: true,
      },
    };
  }, [name, description]);

  const getOldValues = () => {
    return {
      name: stateName,
      description: stateDescription,
    };
  };

  const onChangeNameTextField = (event) => {
    const name = event.target.value;
    const newValues = { ...getOldValues(), name: name };
    setStateName(name);
    setDisablePositiveButton(validateTextFields(validationValues, newValues));
  };

  const onChangeDescriptionTextField = (event) => {
    const description = event.target.value;
    const newValues = { ...getOldValues(), description: description };
    setStateDescription(description);
    setDisablePositiveButton(validateTextFields(validationValues, newValues));
  };

  const executeOnClick = () => {
    if (edit) {
      onClick({
        uuid: data.data.uuid,
        data: {
          params: {
            name: stateName,
            description: stateDescription,
          },
        },
      });
    } else {
      onClick({
        catalogCategoryUuid: data.catalogCategory[0].item.uuid,
        data: {
          params: {
            name: stateName,
            description: stateDescription,
          },
        },
      });
    }
  };

  return (<>
    <CustomDialog
      maxWidth={"sm"}
      open={modalOpen}
      onClick={executeOnClick}
      onClose={onClose}
      showHint={false}
      renderTitle={() => {
        return (
          <DialogTitle
            id="alert-dialog-title"
            style={{ margin: `0 0 ${styles.spacing08} 0` }}
          >
            {edit ? t('dialogs.GenericComponentCatalogDataDialog.txtDialogTitleEdit', {dataType: dataType, ns: 'containers'}) : 
              t('dialogs.GenericComponentCatalogDataDialog.txtDialogTitleCreate', {dataType: dataType, ns: 'containers'}) }
          </DialogTitle>
        );
      }}
      renderContent={() => {
        return (
          (<DialogContent style={{ paddingTop: "20px" }}>
            <div style={{ margin: `0 0 ${styles.spacing16} 0` }}>
              <TextField
                fullWidth={true}
                id="outlined-full-width"
                label={t('dialogs.GenericComponentCatalogDataDialog.textFieldNameLabel', {ns: 'containers'})}
                margin="none"
                multiline={false}
                helperText={`${stateName.length}/255`}
                onChange={onChangeNameTextField}
                placeholder={t('dialogs.GenericComponentCatalogDataDialog.textFieldNamePlaceholder', {ns: 'containers'})}
                required={true}
                variant="outlined"
                value={stateName}
                slotProps={{
                  htmlInput: {
                    maxLength: 255,
                  }
                }}
              />
            </div>
            <div style={{ margin: `0 0 ${styles.spacing32} 0` }}>
              <TextField
                fullWidth={true}
                id="outlined-full-width"
                label={t('dialogs.GenericComponentCatalogDataDialog.textFieldDescriptionLabel', {ns: 'containers'})}
                margin="none"
                multiline={false}
                onChange={onChangeDescriptionTextField}
                placeholder={t('dialogs.GenericComponentCatalogDataDialog.textFieldDescriptionPlaceholder', {ns: 'containers'})}
                required={false}
                variant="outlined"
                value={stateDescription}
              />
            </div>
          </DialogContent>)
        );
      }}
      renderButtonGroup={(onClick, onClose) => {
        return (
          <DialogActions style={{ padding: "2.4rem 2.4rem 2rem" }}>
            <ButtonGroup
              align="right"
              leftButtonText={t('button.btnCancel', {ns: 'common'})}
              leftButtonClick={onClose}
              rightButtonText={edit ? t('button.btnEdit', {ns: 'common'}) : t('button.btnCreate', {ns: 'common'})}
              rightButtonClick={onClick}
              rightButtonDisabled={stateDisablePositiveButton}
              spacingTop={false}
            />
          </DialogActions>
        );
      }}
    />
  </>);
};

GenericComponentCatalogDataDialog.propTypes = propTypes;
export default GenericComponentCatalogDataDialog;
