/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';

import withStyles from "@mui/styles/withStyles";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const propTypes = {
  classes: PropTypes.object,
  dropdownSelection: PropTypes.object.isRequired,
  sortProjectsReverse: PropTypes.func.isRequired,
  descendingSorted: PropTypes.bool.isRequired,
  sortProjects: PropTypes.func.isRequired,
};

const overrideStyles = (theme) => ({
  iconSize: {
    fontSize: "1.5rem",
  },
  height: {
    padding: "1rem 4rem 1rem 1.5rem",
  },
  reverseButton: {
    margin: "0 0 0 0.4rem",
    padding: "1.15rem",
    minWidth: "1rem",
  },
});

const Dropdowns = ({
  dropdownSelection,
  sortProjects,
  descendingSorted, 
  sortProjectsReverse, 
  classes
}) => {
  const { t } = useTranslation();

  const [state, setState] = React.useState({
    values: {
      order: dropdownSelection.value,
    },
  })

  const handleChange = (event) => {
    const value = event.target.value;
    const name = "order";
    sortProjects({ text: name, value: value });
    setState({
      values: { ...state.values, [name]: value },
    });
  }

  return (
    <>
      <FormControl variant="outlined" size="small">
        <Select
          value={state.values.order}
          onChange={handleChange}
          autoWidth={true}
          displayEmpty
        >
          <MenuItem value={"inserted_at"}>{t('singleActionsMenu.Dropdowns.menuItemInsertedAt', {ns: 'components'})}</MenuItem>
          <MenuItem value={"updated_at"}>{t('singleActionsMenu.Dropdowns.menuItemUpdatedAt', {ns: 'components'})}</MenuItem>
          <MenuItem value={"due_date"}>{t('singleActionsMenu.Dropdowns.menuItemDueDate', {ns: 'components'})}</MenuItem>
          <MenuItem value={"state"}>{t('singleActionsMenu.Dropdowns.menuItemState', {ns: 'components'})}</MenuItem>
          <MenuItem value={"user_id"}>{t('singleActionsMenu.Dropdowns.menuItemUserId', {ns: 'components'})}</MenuItem>
          <MenuItem value={"assigned_user_id"}>
            {t('singleActionsMenu.Dropdowns.menuItemAssignedUserId', {ns: 'components'})}
          </MenuItem>
        </Select>
      </FormControl>
      <Button
        classes={{ outlined: classes.reverseButton }}
        size="small"
        variant="outlined"
        onClick={sortProjectsReverse}
      >
        {descendingSorted ? (
          <ArrowUpwardIcon
            classes={{ fontSizeSmall: classes.iconSize }}
            fontSize="small"
          />
        ) : (
          <ArrowDownwardIcon
            classes={{ fontSizeSmall: classes.iconSize }}
            fontSize="small"
          />
        )}
      </Button>
    </>
  );
}

Dropdowns.propTypes = propTypes;
export default withStyles(overrideStyles, { withTheme: true })(Dropdowns);
