/* eslint-disable no-case-declarations */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { MaterialReactTable } from "material-react-table";
import { MRT_Localization_DE } from "material-react-table/locales/de";

import Spinner from "../../../atoms/Spinner";
import ComponentCatalogHeader from "./ComponentCatalogHeader";
import ComponentCatalogActionMenuItems from "./ComponentCatalogActionMenuItems";
import ComponentCatalogTopToolbar from "./ComponentCatalogTopToolbar";
import GenericComponentCatalogDataDialog from "../../../containers/dialogs/GenericComponentCatalogDataDialog";
import ComponentCatalogPipeDefinitionDialog from "../../../containers/dialogs/ComponentCatalogPipeDefinitionDialog";
import Confirmation from "../../../components/dialogs/Confirmation";

import {
  createCatalogComponent,
  updateCatalogComponent,
  deleteCatalogComponent,
  createCatalogMaterial,
  deleteCatalogMaterial,
  updateCatalogMaterial,
  createCatalogDimension,
  deleteCatalogDimension,
  updateCatalogDimension,
  createCatalogPipeDefinition,
  deleteCatalogPipeDefinition,
  syncComponentCatalogs,
} from "../../../actions/componentCatalogs";

import {
  COMPONENTS,
  COMPONENT,
  MATERIALS,
  MATERIAL,
  DIMENSIONS,
  DIMENSION,
  PIPE_DEFINITION,
  CATALOG_CATEGORY_MAPPING,
  PIPE_DEFINITIONS,
} from "../../../constants/catalogCategoryMapping";

import { idle, success } from "../../../constants/status";
import {
  EDIT_KEY,
  DELETE_KEY,
  CREATE_KEY,
} from "../../../constants/contextMenuEntries";

import { isAdmin } from "../../../sharedFunctions/user";

import "./styles.css";

const propTypes = {
  isAdmin: PropTypes.bool,
  organizationCategory: PropTypes.bool,
  catalogCategory: PropTypes.array,
  data: PropTypes.object,
  syncStatus: PropTypes.string,
  createCatalogComponent: PropTypes.func,
  updateCatalogComponent: PropTypes.func,
  deleteCatalogComponent: PropTypes.func,
  createCatalogMaterial: PropTypes.func,
  deleteCatalogMaterial: PropTypes.func,
  updateCatalogMaterial: PropTypes.func,
  createCatalogDimension: PropTypes.func,
  deleteCatalogDimension: PropTypes.func,
  updateCatalogDimension: PropTypes.func,
  createCatalogPipeDefinition: PropTypes.func,
  deleteCatalogPipeDefinition: PropTypes.func,
  syncComponentCatalogs: PropTypes.func,
};

const ComponentCatalog = ({
  isAdmin,
  organizationCategory,
  catalogCategory,
  data,
  syncStatus,
  createCatalogComponent,
  updateCatalogComponent,
  deleteCatalogComponent,
  createCatalogMaterial,
  deleteCatalogMaterial,
  updateCatalogMaterial,
  createCatalogDimension,
  deleteCatalogDimension,
  updateCatalogDimension,
  createCatalogPipeDefinition,
  deleteCatalogPipeDefinition,
  syncComponentCatalogs,
}) => {
  const { t } = useTranslation();

  const defaultStateShowModal = React.useMemo(() => {
    return {
      mode: "",
      componentDataKey: "",
      modalOpen: false,
      data: {},
    };
  });

  const columns = React.useMemo(() => {
    return {
      [COMPONENTS]: [
        {
          accessorKey: "name",
          header: t('ComponentCatalog.txtColumnHeaderName', {ns: 'screens_private'}),
        },
        {
          accessorKey: "description",
          header: t('ComponentCatalog.txtColumnHeaderDescription', {ns: 'screens_private'}),
        },
        {
          accessorKey: "updated_at",
          header: t('ComponentCatalog.txtColumnHeaderUpdatedAt', {ns: 'screens_private'}),
        },
      ],
      [MATERIALS]: [
        {
          accessorKey: "name",
          header: t('ComponentCatalog.txtColumnHeaderName', {ns: 'screens_private'}),
        },
        {
          accessorKey: "description",
          header: t('ComponentCatalog.txtColumnHeaderDescription', {ns: 'screens_private'}),
        },
        {
          accessorKey: "updated_at",
          header: t('ComponentCatalog.txtColumnHeaderUpdatedAt', {ns: 'screens_private'}),
        },
      ],
      [DIMENSIONS]: [
        {
          accessorKey: "name",
          header: t('ComponentCatalog.txtColumnHeaderName', {ns: 'screens_private'}),
        },
        {
          accessorKey: "description",
          header: t('ComponentCatalog.txtColumnHeaderDescription', {ns: 'screens_private'}),
        },
        {
          accessorKey: "updated_at",
          header: t('ComponentCatalog.txtColumnHeaderUpdatedAt', {ns: 'screens_private'}),
        },
      ],
      [PIPE_DEFINITION]: [
        {
          accessorKey: "name",
          accessorFn: (row) => `${row.material.name} - ${row.dimension.name}`,
          header: t('ComponentCatalog.txtColumnHeaderName', {ns: 'screens_private'}),
        },
      ],
    };
  }, []);

  const executeFunctions = React.useMemo(() => {
    return {
      [COMPONENTS]: {
        [CREATE_KEY]: createCatalogComponent,
        [EDIT_KEY]: updateCatalogComponent,
        [DELETE_KEY]: deleteCatalogComponent,
      },
      [MATERIALS]: {
        [CREATE_KEY]: createCatalogMaterial,
        [EDIT_KEY]: updateCatalogMaterial,
        [DELETE_KEY]: deleteCatalogMaterial,
      },
      [DIMENSIONS]: {
        [CREATE_KEY]: createCatalogDimension,
        [EDIT_KEY]: updateCatalogDimension,
        [DELETE_KEY]: deleteCatalogDimension,
      },
      [PIPE_DEFINITION]: {
        [CREATE_KEY]: createCatalogPipeDefinition,
        [DELETE_KEY]: deleteCatalogPipeDefinition,
      },
    };
  });

  const [stateShowDataKey, setStateShowDataKey] = React.useState(COMPONENTS);
  const [stateShowModal, setStateShowModal] = React.useState(
    defaultStateShowModal
  );

  React.useEffect(() => {
    if (syncStatus === idle) {
      syncComponentCatalogs();
    }
  }, [syncStatus]);

  const stateRef = React.useRef();
  stateRef.dataComponent = stateShowDataKey;

  const tableData = data[stateShowDataKey];

  const openModal = (payload) => {
    setStateShowModal(payload);
  };

  const onCreateClicked = (componentDataKey) => {
    if (
      componentDataKey === COMPONENTS ||
      componentDataKey === MATERIALS ||
      componentDataKey === DIMENSIONS
    ) {
      openModal({
        mode: CREATE_KEY,
        componentDataKey: componentDataKey,
        modalOpen: true,
        data: {
          catalogCategory: catalogCategory,
          data: {
            name: "",
            description: "",
          },
        },
      });
    } else if (componentDataKey === PIPE_DEFINITION) {
      openModal({
        mode: CREATE_KEY,
        componentDataKey: componentDataKey,
        modalOpen: true,
        data: {
          catalogCategory: catalogCategory,
          data: {
            materials: data.materials,
            dimensions: data.dimensions,
          },
        },
      });
    }
  };

  const onEditClicked = (rowData, componentDataKey) => {
    openModal({
      mode: EDIT_KEY,
      componentDataKey: componentDataKey,
      modalOpen: true,
      data: {
        catalogCategory: catalogCategory,
        data: rowData.original,
      },
    });
  };

  const onDeleteClicked = (rowData, componentDataKey) => {
    openModal({
      mode: DELETE_KEY,
      componentDataKey: componentDataKey,
      modalOpen: true,
      data: {
        catalogCategory: catalogCategory,
        data: rowData.original,
      },
    });
  };

  const closeModal = () => {
    setStateShowModal(defaultStateShowModal);
  };

  // For information about the payload see the
  // GenericComponentCatalogDataDialog or
  // ComponentCatalogPipeDefinitionDialog Component.
  const onModalCreateOkClicked = (payload) => {
    executeFunctions[stateShowDataKey][CREATE_KEY](payload);
    closeModal();
  };

  // For information about the payload see the
  // GenericComponentCatalogDataDialog or
  // ComponentCatalogPipeDefinitionDialog Component.
  const onModalUpdateOkClicked = (payload) => {
    executeFunctions[stateShowDataKey][EDIT_KEY](payload);
    closeModal();
  };

  // For information about the payload see the
  // GenericComponentCatalogDataDialog or
  // ComponentCatalogPipeDefinitionDialog Component.
  const onModalDeleteOkClicked = (payload) => {
    executeFunctions[stateShowDataKey][DELETE_KEY](payload);
    closeModal();
  };

  const onHandleChange = (event) => {
    setStateShowDataKey(event.target.value);
  };

  const editableTable = () => {
    return (
      <MaterialReactTable
        initialState={{
          density: "spacious",
          pagination: { pageSize: 15, pageIndex: 0 },
        }}
        localization={MRT_Localization_DE}
        muiTableContainerProps={{
          sx: {
            height: "calc(100vh - 25rem)",
            maxHeight: "calc(100vh - 25rem)",
          },
        }}
        muiTableHeadProps={{
          sx: {
            height: "52px",
          },
        }}
        muiBottomToolbarProps={{
          sx: {
            height: "52px",
          },
        }}
        muiTablePaginationProps={{
          rowsPerPageOptions: [5, 10, 15, 20],
        }}
        muiTableBodyRowProps={{
          hover: false,
        }}
        renderTopToolbarCustomActions={() => {
          return (
            <ComponentCatalogTopToolbar
              catalogCategory={catalogCategory}
              stateShowDataKey={stateShowDataKey}
            />
          );
        }}
        enableStickyHeader
        enableDensityToggle={false}
        enableFullScreenToggle={false}
        enableHiding={false}
        positionActionsColumn="last"
        enableRowActions={organizationCategory}
        renderRowActionMenuItems={({ row, closeMenu }) => {
          return (
            <ComponentCatalogActionMenuItems
              row={row}
              closeMenu={closeMenu}
              dataComponent={stateRef.dataComponent}
              onEditClicked={onEditClicked}
              onDeleteClicked={onDeleteClicked}
            />
          );
        }}
        columns={columns[stateShowDataKey]}
        data={tableData[0].items}
      />
    );
  };

  const nonEditableTable = () => {
    return (
      <MaterialReactTable
        initialState={{
          density: "spacious",
          pagination: { pageSize: 15, pageIndex: 0 },
        }}
        localization={MRT_Localization_DE}
        muiTableContainerProps={{
          sx: {
            height: "calc(100vh - 25rem)",
            maxHeight: "calc(100vh - 25rem)",
          },
        }}
        muiTableHeadProps={{
          sx: {
            height: "52px",
          },
        }}
        muiBottomToolbarProps={{
          sx: {
            height: "52px",
          },
        }}
        muiTablePaginationProps={{
          rowsPerPageOptions: [5, 10, 15, 20],
        }}
        muiTableBodyRowProps={{
          hover: false,
        }}
        renderTopToolbarCustomActions={() => {
          return (
            <ComponentCatalogTopToolbar
              catalogCategory={catalogCategory}
              stateShowDataKey={stateShowDataKey}
            />
          );
        }}
        enableStickyHeader
        enableDensityToggle={false}
        enableFullScreenToggle={false}
        enableHiding={false}
        columns={columns[stateShowDataKey]}
        data={tableData[0].items}
      />
    );
  };

  return (
    <div>
      {stateShowModal.mode !== "" &&
        renderModalContent(
          t,
          stateShowModal,
          closeModal,
          onModalCreateOkClicked,
          onModalUpdateOkClicked,
          onModalDeleteOkClicked
        )}
      {syncStatus != success ? (
        <div className="spinner-container">
          <Spinner />
        </div>
      ) : (
        <div>
          <ComponentCatalogHeader
            organizationCategory={organizationCategory}
            setCreateModalOpen={() => onCreateClicked(stateRef.dataComponent)}
            onHandleChange={onHandleChange}
            isAdmin={isAdmin}
          />

          <div style={{ margin: "2rem 2rem 2rem 2rem" }}>
            {organizationCategory && isAdmin
              ? editableTable()
              : nonEditableTable()}
          </div>
        </div>
      )}
    </div>
  );
};

function renderModalContent(
  t, 
  payload,
  closeModal,
  onModalCreateOkClicked,
  onModalUpdateOkClicked,
  onModalDeleteOkClicked
) {
  switch (payload.mode) {
    case CREATE_KEY:
      switch (payload.componentDataKey) {
        case COMPONENTS:
        case MATERIALS:
        case DIMENSIONS:
          return (
            <GenericComponentCatalogDataDialog
              componentDataKey={payload.componentDataKey}
              data={payload.data}
              modalOpen={payload.modalOpen}
              onClose={() => closeModal()}
              onClick={onModalCreateOkClicked}
            />
          );
        case PIPE_DEFINITION:
          return (
            <ComponentCatalogPipeDefinitionDialog
              data={payload.data}
              modalOpen={payload.modalOpen}
              onClose={() => closeModal()}
              onClick={onModalCreateOkClicked}
            />
          );
      }
      break;
    case EDIT_KEY:
      return (
        <GenericComponentCatalogDataDialog
          edit
          componentDataKey={payload.componentDataKey}
          data={payload.data}
          modalOpen={payload.modalOpen}
          onClose={() => closeModal()}
          onClick={onModalUpdateOkClicked}
        />
      );
    case DELETE_KEY:
      let dataType = "";

      if (payload.componentDataKey === COMPONENTS) {
        dataType = CATALOG_CATEGORY_MAPPING[COMPONENT];
      } else if (payload.componentDataKey === MATERIALS) {
        dataType = CATALOG_CATEGORY_MAPPING[MATERIAL];
      } else if (payload.componentDataKey === DIMENSIONS) {
        dataType = CATALOG_CATEGORY_MAPPING[DIMENSION];
      } else if (payload.componentDataKey === PIPE_DEFINITION) {
        dataType = CATALOG_CATEGORY_MAPPING[PIPE_DEFINITION];
      }

      return (
        <Confirmation
          highlightedText={payload.data.catalogCategory.name}
          headline={t('Confirmation.deleteComponentCatalogType.txtHeadline', {dataType: dataType, ns: 'common'})}
          leftSideText={t('Confirmation.deleteComponentCatalogType.txtLeftSideText', {dataType: dataType, ns: 'common'})}
          modalOpen={payload.modalOpen}
          onClose={() => closeModal()}
          onClick={() => onModalDeleteOkClicked(payload.data)}
          rightSideText={t('Confirmation.deleteComponentCatalogType.txtRightSideText', {ns: 'common'})}
          textButton={t('Confirmation.deleteComponentCatalogType.txtTextButton', {ns: 'common'})}
        />
      );
  }
}

function dispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createCatalogComponent,
      updateCatalogComponent,
      deleteCatalogComponent,
      createCatalogMaterial,
      deleteCatalogMaterial,
      updateCatalogMaterial,
      createCatalogDimension,
      deleteCatalogDimension,
      updateCatalogDimension,
      createCatalogPipeDefinition,
      deleteCatalogPipeDefinition,
      syncComponentCatalogs,
    },
    dispatch
  );
}

function stateToProps(state, ownProps) {
  const componentCatalogUuid = ownProps.match.params.id;

  // ToDo: Get the Orga UUID as well. This might be handy later on if anything is added or updated.

  const syncStatus = state.getIn([
    "componentCatalogs",
    "componentCatalogSyncStatus",
  ]);

  const catalogCategory = state
    .getIn(["componentCatalogs", "items", "catalogCategories"])
    .filter((item) => item.item.uuid === componentCatalogUuid);

  const catalogOrganizationCategory = state
    .getIn(["componentCatalogs", "items", "catalogOrganizationCategories"])
    .filter((item) => item.item.uuid === catalogCategory[0].rootUuid);

  const organizationCategory =
    catalogOrganizationCategory.length &&
    catalogOrganizationCategory[0].item.organization_id
      ? true
      : false;

  const catalogComponents = state
    .getIn(["componentCatalogs", "items", "catalogComponentsCategories"])
    .filter((item) => item.rootUuid === componentCatalogUuid);

  const catalogMaterials = state
    .getIn(["componentCatalogs", "items", "catalogMaterialsCategories"])
    .filter((item) => item.rootUuid === componentCatalogUuid);

  const catalogDimensions = state
    .getIn(["componentCatalogs", "items", "catalogDimensionsCategories"])
    .filter((item) => item.rootUuid === componentCatalogUuid);

  const pipeDefinitions = state
    .getIn(["componentCatalogs", "items", "catalogPipeDefinitionsCategories"])
    .filter((item) => item.rootUuid === componentCatalogUuid);

  return {
    isAdmin: isAdmin(state.getIn(["user", "organization_role_groups"]), true),
    catalogCategory,
    organizationCategory,
    syncStatus,
    data: {
      components: catalogComponents,
      materials: catalogMaterials,
      dimensions: catalogDimensions,
      pipe_definition: pipeDefinitions,
    },
  };
}

ComponentCatalog.prototype = propTypes;
export default connect(stateToProps, dispatchToProps)(ComponentCatalog);
