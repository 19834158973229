/* eslint-disable no-unused-vars */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import moment from "moment";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import Divider from "@mui/material/Divider";
import withStyles from "@mui/styles/withStyles";

import {
  dropdownMeasurementListItemElements,
  EDIT_KEY,
  IMAGE_KEY,
} from "../../../../../../constants/contextMenuEntries";

import { updateTopographicPoint } from "../../../../../../actions/topographic_points";

import CustomModal from "../../../../../../components/CustomModal";
import CustomImageView from "../../../../../../components/CustomImageView";
import ContextMenu from "../../../../../../containers/ContextMenu";
import EditTopographicPointDialog from "../../../../../../containers/dialogs/EditTopographicPointDialog";
import { pointNameLookupTable } from "../../../../../../constants/pointLookupTable";

import * as styles from "../../../../../../styles/styles";
import "./styles.css";

const propTypes = {
  classes: PropTypes.object,
  topographicPoint: PropTypes.object,
  componentList: PropTypes.array,
  onListClick: PropTypes.func,
  onHoverListEnter: PropTypes.func,
  onHoverListLeave: PropTypes.func,
  measurmentListClick: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  updateTopographicPoint: PropTypes.func,
};

const overrideStyles = (theme) => ({
  root: {
    paddingTop: "0",
    paddingBottom: "0",
    paddingLeft: "0",
    margin: "0.5rem 1rem 0.5rem 1rem",
    width: "auto",
    backgroundColor: styles.white,
    boxShadow: styles.boxShadowListElement,
    "&:hover": {
      boxShadow: styles.boxShadowListElementHover,
    },
  },
  root_select: {
    paddingTop: "0",
    paddingBottom: "0",
    paddingLeft: "0",
    margin: "0.5rem 1rem 0.5rem 1rem",
    width: "auto",
    backgroundColor: "rgba(89, 83, 210, 0.08)",
    boxShadow: styles.boxShadowListElement,
    "&:hover": {
      boxShadow: styles.boxShadowListElementHover,
    },
  },
  vertical: {
    marginRight: "2rem",
  },
});

const TopographicPointListItem = ({
  topographicPoint,
  componentList,
  onListClick,
  onHoverListEnter,
  onHoverListLeave,
  measurmentListClick,
  updateTopographicPoint,
  classes,
}) => {
  const { t } = useTranslation();
  
  const [modalOpen, setModalOpen] = React.useState(false);
  const [menuEntryKey, setMenuEntryKey] = React.useState(null);
  const [selected, setSelected] = React.useState(false);

  const closeModal = () => {
    setModalOpen(false);
    setMenuEntryKey(null);
  };

  const openModal = (menuEntryKey) => {
    const bool = typeof menuEntryKey === "string";
    setModalOpen(bool);
    setMenuEntryKey(bool ? menuEntryKey : false);
  };

  const onClick = () => {
    setSelected(true);
    onListClick(topographicPoint);
  };

  const onClickImage = (event) => {
    event.stopPropagation();
    openModal(IMAGE_KEY);
  };

  const onClickTopographicPointEdit = (newTopographicPoint) => {
    updateTopographicPoint({
      id: newTopographicPoint.id,
      id_user: newTopographicPoint.id_user,
      component: newTopographicPoint.component,
      depth: newTopographicPoint.depth,
      project_id: newTopographicPoint.project_id,
    });
    closeModal();
  };

  const created_at = moment.unix(topographicPoint.created_at);
  const image = topographicPoint.image.original;
  const id = topographicPoint.id;

  const baseTitle = pointNameLookupTable[topographicPoint.type];
  const title =
    baseTitle + " - " + topographicPoint.id_user || topographicPoint.id;

  const isSelected = id === measurmentListClick

  return (
    <>
      {modalOpen &&
        renderModalContent(
          modalOpen,
          menuEntryKey,
          closeModal,
          onClickTopographicPointEdit,
          topographicPoint,
          componentList
        )}
      <ListItem
        onMouseLeave={() => onHoverListLeave(id, topographicPoint.type)}
        onMouseEnter={() => onHoverListEnter(id, topographicPoint.type)}
        onMouseOver={() => onHoverListEnter(id, topographicPoint.type)}
        classes={{ root: isSelected ? classes.root_select : classes.root }}
        className="measurment-list-item-list-item"
        onClick={onClick}
      >
        <img
          onClick={onClickImage}
          src={image}
          className="measurement-list-item-image-thumbnail"
          alt={`Bild`}
        />
        <Divider
          classes={{ vertical: classes.vertical }}
          orientation={"vertical"}
        />
        <ListItemText
          style={{ flex: 8 }}
          primary={title}
          secondary={created_at.format("dd. DD.MM.YYYY HH:mm:ss")}
        />
        <ListItemText
          primary={t('Project.Map.MeasurementList.TopographicPointListItem.txtComponent', {ns: 'screens_private'})}
          secondary={
            topographicPoint.component !== null
              ? topographicPoint.component
              : t('Project.Map.MeasurementList.TopographicPointListItem.txtNoComponentSet', {ns: 'screens_private'})
          }
        />
        <ListItemText
          primary={t('Project.Map.MeasurementList.TopographicPointListItem.txtHeightUnderGround', {ns: 'screens_private'})}
          secondary={topographicPoint.depth}
        />
        <ListItemSecondaryAction className="measurement-list-item-secondary-action">
          <ContextMenu
            color={styles.darkBlueBase}
            contextMenuEntries={dropdownMeasurementListItemElements}
            onMenuEntryClicked={(menuEntryKey) =>
              handleOnMenuEntryClicked(menuEntryKey, openModal)
            }
          />
        </ListItemSecondaryAction>
      </ListItem>
    </>
  );
};

function handleOnMenuEntryClicked(menuEntryKey, openModal) {
  openModal(menuEntryKey);
}

function renderModalContent(
  modalOpen,
  menuEntryKey,
  closeModal,
  onClickTopographicPointEdit,
  topographicPoint,
  componentList
) {
  switch (menuEntryKey) {
    case EDIT_KEY:
      return (
        <EditTopographicPointDialog
          topographicPoint={topographicPoint}
          componentList={componentList}
          modalOpen={modalOpen}
          onNegativeButtonClicked={() => closeModal()}
          onPositiveButtonClicked={onClickTopographicPointEdit}
        />
      );
    case IMAGE_KEY:
      return (
        <CustomModal
          showCloseButton={true}
          open={modalOpen}
          image={topographicPoint.image.original}
          onClose={() => closeModal()}
          render={() => (
            <CustomImageView>
              <img
                src={topographicPoint.image.original}
                className="image-modal-image"
                alt={`Bild`}
              />
            </CustomImageView>
          )}
        />
      );
    default:
      return <div></div>;
  }
}

function dispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateTopographicPoint,
    },
    dispatch
  );
}

TopographicPointListItem.propTypes = propTypes;
export default connect(
  null,
  dispatchToProps
)(withStyles(overrideStyles, { withTheme: true })(TopographicPointListItem));
