/* eslint-disable import/namespace */
/* eslint-disable no-unused-vars */
import { fromJS } from "immutable";
import {
  SET_GNSS_DATA,
  GNSS_DATA_CREATED,
  GNSS_DATA_UPDATED,
  GNSS_DATA_DELETED,
  GNSS_POINT_UPDATED,
  GNSS_POINT_DELETED,
  GNSS_IMAGE_UPDATED,
  GNSS_IMAGE_DELETED,
  GNSS_SEGMENT_DELETED,
  GNSS_LINE_DELETED,
  GNSS_POLYGON_DELETED
} from "../constants/actions/actions";

import { 
    setGnssData,
    createGnssData,
    updateGnssData,
    deleteGnssData,
    updateGnssPoint,
    deleteGnssPoint,
    updateGnssImage,
    deleteGnssImage,
    deleteGnssSegment,
    deleteGnssLine,
    deleteGnssPolygon
} from "../sharedFunctions/gnssData/stateUtils";

const initialState = fromJS({
    items: fromJS({
        images: [],
        lines: [],
        points: [],
        polygons: [],
        polygon_points: [],
        segments: []
    }),
});

export function gnssData(state = initialState, action) {
    switch (action.type) {
        case SET_GNSS_DATA:
            return setGnssData(state, action);

        case GNSS_DATA_CREATED:
            return createGnssData(state, action);

        case GNSS_DATA_UPDATED:
            return updateGnssData(state, action);

        case GNSS_DATA_DELETED:
            return deleteGnssData(state, action);

        case GNSS_POINT_UPDATED:
            return updateGnssPoint(state, action);

        case GNSS_POINT_DELETED:
            return deleteGnssPoint(state, action);

        case GNSS_IMAGE_UPDATED:
            return updateGnssImage(state, action);

        case GNSS_IMAGE_DELETED:
            return deleteGnssImage(state, action);

        case GNSS_SEGMENT_DELETED:
            return deleteGnssSegment(state, action);

        case GNSS_LINE_DELETED:
            return deleteGnssLine(state, action);

        case GNSS_POLYGON_DELETED:
            return deleteGnssPolygon(state, action);

        default:
            return state;
    }
}
