/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';

import withStyles from '@mui/styles/withStyles';
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";

import ButtonGroup from "../../../components/ButtonGroup";
import CustomDialog from "../../../components/CustomDialog";
import HintView from "../../../components/HintView";
import { HINT_UPDATE_BEHAVIOUR } from "../../../constants/hinttypesmapping";
import { validateTextFields } from "../../../sharedFunctions/validation";

import * as styles from "../../../styles/styles";

const propTypes = {
  classes: PropTypes.object,
  volume: PropTypes.object,
  modalOpen: PropTypes.bool,
  onPositiveButtonClicked: PropTypes.func,
  onNegativeButtonClicked: PropTypes.func,
};

const overrideStyles = (theme) => ({
  alignText: {
    margin: "auto 0 0 0",
  },
});

const EditVolumeDialog = ({
  classes,
  volume,
  modalOpen,
  onPositiveButtonClicked,
  onNegativeButtonClicked,
}) => {
  const { t } = useTranslation();

  const { description } = volume;

  const [stateDescription, setDescription] = useState(
    description ? description : ""
  );
  const [stateDisablePositiveButton, setDisablePositiveButton] = useState(true);

  const validationValues = React.useMemo(() => {
    return {
      description: {
        value: description ? description : "",
        required: true,
      },
    };
  }, [description]);

  const onChangeDescription = (event) => {
    const description = event.target.value;
    const newValues = { description: description };
    setDescription(description);
    setDisablePositiveButton(validateTextFields(validationValues, newValues));
  };

  const handleOnPositiveButtonClicked = () => {
    const updatedVolume = { ...volume, description: stateDescription };
    onPositiveButtonClicked(updatedVolume);
  };

  return (<>
    <CustomDialog
      open={modalOpen}
      onClick={handleOnPositiveButtonClicked}
      onClose={onNegativeButtonClicked}
      showHint={true}
      renderTitle={() => {
        return (
          <DialogTitle id="alert-dialog-title">
            {t('dialogs.EditVolumeDialog.txtDialogTitle', {ns: 'containers'})}
          </DialogTitle>
        );
      }}
      renderHint={() => {
        return <HintView hintMessageType={HINT_UPDATE_BEHAVIOUR} />;
      }}
      renderContent={() => {
        return (
          (<DialogContent>
            <TextField
              fullWidth={true}
              margin="none"
              label={t('dialogs.EditVolumeDialog.txtAutoCompleteDescriptionLabel', {ns: 'containers'})}
              multiline={true}
              onChange={onChangeDescription}
              placeholder={t('dialogs.EditVolumeDialog.txtAutoCompleteDescriptionPlaceholder', {ns: 'containers'})}
              required={true}
              style={{
                margin: `${styles.spacing24} 0 ${styles.spacing24} 0`,
              }}
              rows="5"
              variant="outlined"
              value={stateDescription}
              slotProps={{
                input: { classes: { input: classes.alignText } }
              }}
            />
          </DialogContent>)
        );
      }}
      renderButtonGroup={(
        handleOnPositiveButtonClicked,
        onNegativeButtonClicked
      ) => {
        return (
          <DialogActions style={{ padding: "2.4rem 2.4rem 2rem" }}>
            <ButtonGroup
              align="right"
              leftButtonText={t('button.btnCancel', {ns: 'common'})}
              leftButtonClick={onNegativeButtonClicked}
              rightButtonText={t('button.btnEdit', {ns: 'common'})}
              rightButtonClick={handleOnPositiveButtonClicked}
              rightButtonDisabled={stateDisablePositiveButton}
              spacingTop={false}
            />
          </DialogActions>
        );
      }}
    />
  </>);
};

EditVolumeDialog.propTypes = propTypes;
export default withStyles(overrideStyles, { withTheme: true })(
  EditVolumeDialog
);
