/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import List from "@mui/material/List";
import withStyles from "@mui/styles/withStyles";

import MeasurementListItem from "./MeasurementListItem";
import ReferenceListItem from "./ReferenceListItem";
import CustomizedListSubHeader from "./CustomizedListSubHeader";
import LineListSubHeader from "./LineListSubHeader";
import TopographicPointListSubHeader from "./TopographicPointListSubHeader";
import TopographicPointListItem from "./TopographicPointListItem";
import GnssPointListItem from "./GnssPointListItem";
import GnssRefPointListItem from "./GnssRefPointListItem";
import GnssLineListItem from "./GnssLineListItem";
import GnssPolygonListItem from "./GnssPolygonListItem";
import GnssImageListItem from "./GnssImageListItem";

import "./styles.css";
import ScreenshotListItem from "./ScreenshotListItem";
import VolumeListItem from "./VolumeListItem";

const overrideStyles = (theme) => ({
  root: {
    paddingTop: "0",
    paddingBottom: "0",
  },
});

const propTypes = {
  referencePoints: PropTypes.object,
  referencePointsPerLayer: PropTypes.array,
  measurementsPerLayer: PropTypes.array,
  topographicPointsPerLayer: PropTypes.array,
  volumes: PropTypes.object,
  mediaFiles: PropTypes.object,
  onListClick: PropTypes.func,
  onHoverListEnter: PropTypes.func,
  onHoverListLeave: PropTypes.func,
  measurmentListClick: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  componentCatalogItems: PropTypes.object,
  gnssPointsPerLayer: PropTypes.array,
  gnssRefPointsPerLayer: PropTypes.array,
  gnssLinesPerLayer: PropTypes.array,
  gnssPolygonPerLayer: PropTypes.array,
  gnssImages: PropTypes.array,
  classes: PropTypes.object,
};

const MeasurementList = ({
  referencePoints,
  referencePointsPerLayer,
  measurementsPerLayer,
  topographicPointsPerLayer,
  volumes,
  mediaFiles,
  onListClick,
  onHoverListEnter,
  onHoverListLeave,
  measurmentListClick,
  componentCatalogItems,
  gnssPointsPerLayer,
  gnssRefPointsPerLayer,
  gnssLinesPerLayer,
  gnssPolygonPerLayer,
  gnssImages,
  classes,
}) => {
  const { t } = useTranslation();

  var elements = prepareListItems(
    t,
    referencePointsPerLayer,
    referencePoints,
    measurementsPerLayer,
    topographicPointsPerLayer,
    volumes,
    mediaFiles,
    onListClick,
    onHoverListEnter,
    onHoverListLeave,
    measurmentListClick,
    componentCatalogItems,
    gnssPointsPerLayer,
    gnssRefPointsPerLayer,
    gnssLinesPerLayer,
    gnssPolygonPerLayer,
    gnssImages
  );
  return (
    <List classes={{ root: classes.root }} className="list-container">
      {elements}
    </List>
  );
};

function prepareListItems(
  t,
  referencePointsPerLayer,
  referencePoints,
  measurementsPerLayer,
  topographicPointsPerLayer,
  volumes,
  mediaFiles,
  onListClick,
  onHoverListEnter,
  onHoverListLeave,
  measurmentListClick,
  componentCatalogItems,
  gnssPointsPerLayer,
  gnssRefPointsPerLayer,
  gnssLinesPerLayer,
  gnssPolygonPerLayer,
  gnssImages
) {
  const volumeListItemKeys = Object.keys(volumes);
  const mediaFilesItemKeys = Object.keys(mediaFiles);

  var elementList = [];

  measurementsPerLayer.forEach((layerElement) => {
    prepareMeasurementLayerData(
      elementList,
      layerElement,
      measurmentListClick,
      onListClick,
      onHoverListEnter,
      onHoverListLeave,
      componentCatalogItems
    );
  });

  referencePointsPerLayer.forEach((layerElement) => {
    prepareReferenceData(
      t,
      elementList,
      layerElement,
      measurmentListClick,
      onListClick,
      onHoverListEnter,
      onHoverListLeave,
      componentCatalogItems
    );
  });

  topographicPointsPerLayer.forEach((layerElement) => {
    prepareTopographicLayerData(
      elementList,
      layerElement,
      measurmentListClick,
      onListClick,
      onHoverListEnter,
      onHoverListLeave,
      componentCatalogItems
    );
  });

  gnssPointsPerLayer.forEach((layerElement) => {
    prepareGnssPointData(
      elementList,
      layerElement,
      onHoverListEnter,
      onHoverListLeave,
      onListClick,
      measurmentListClick
    )
  })

  gnssRefPointsPerLayer.forEach((layerElement) => {
    prepareGnssRefPointData(
      elementList,
      layerElement,
      onHoverListEnter,
      onHoverListLeave,
      onListClick,
      measurmentListClick
    )
  })

  gnssLinesPerLayer.forEach((layerElement) => {
    prepareGnssLineData(
      elementList,
      layerElement,
      onHoverListEnter,
      onHoverListLeave,
      onListClick,
      measurmentListClick
    )
  })

  gnssPolygonPerLayer.forEach((layerElement) => {
    prepareGnssPolygonData(
      elementList,
      layerElement,
      onHoverListEnter,
      onHoverListLeave,
      onListClick,
      measurmentListClick
    )
  })

  if (gnssImages.length > 0) {
    prepareGnssImageData(
      t,
      elementList, 
      gnssImages, 
      onHoverListEnter,
      onHoverListLeave,
      onListClick,
      measurmentListClick);
  }

  if (volumeListItemKeys.length > 0) {
    prepareVolumeData(t, elementList, volumes, volumeListItemKeys);
  }

  if (mediaFilesItemKeys.length > 0) {
    prepareScreenshotData(t, elementList, mediaFiles, mediaFilesItemKeys);
  }

  return elementList;
}

function prepareMeasurementLayerData(
  elementList,
  layerElement,
  measurmentListClick,
  onListClick,
  onHoverListEnter,
  onHoverListLeave,
  componentCatalogItems
) {
  let componentList = [];
  let pipeDefinitionList = [];

  if (layerElement.layerConfig.component_catalogs) {
    // ToDo: Technical multiple ComponentCatalogs can be assigned to one MeasureLayerConfig.
    // For now we assume that only one ComponentCatalog is assigned.
    const componentCatalog = layerElement.layerConfig.component_catalogs[0];

    const catalogComponents = componentCatalogItems
      .get("catalogComponentsCategories")
      .filter((item) => item["rootUuid"] === componentCatalog);
    catalogComponents.forEach((component) => {
      component.items.forEach((componentItem) => {
        componentList.push({
          label: componentItem.name,
        });
      });
    });

    const pipeDefinitions = componentCatalogItems
      .get("catalogPipeDefinitionsCategories")
      .filter((element) => {
        return element["rootUuid"] === componentCatalog;
      });
    if (pipeDefinitions.length > 0) {
      const pipeDefinition = pipeDefinitions[0];

      pipeDefinition.items.forEach((item) => {
        pipeDefinitionList.push({
          label: `${item.material.name} ${item.dimension.name}`,
        });
      });
    }
  }

  elementList.push(
    <LineListSubHeader
      layerConfig={layerElement.layerConfig}
      pipeDefinitionList={pipeDefinitionList}
      line={layerElement.line}
      key={`measurement_line_header_${layerElement.line.id}`}
    />
  );

  const listItemKeys = Object.keys(layerElement.measurements);
  for (let index = 0; index < listItemKeys.length; index++) {
    elementList.push(
      <MeasurementListItem
        measurmentListClick={measurmentListClick}
        onListClick={onListClick}
        key={`measurement-${index}_${layerElement.line.id}`}
        layerConfig={layerElement.layerConfig}
        componentList={componentList}
        measurement={layerElement.measurements[listItemKeys[index]]}
        onHoverListEnter={onHoverListEnter}
        onHoverListLeave={onHoverListLeave}
      />
    );
  }
}

function prepareReferenceData(
  t,
  elementList,
  layerElement,
  measurmentListClick,
  onListClick,
  onHoverListEnter,
  onHoverListLeave,
  componentCatalogItems
) {
  let componentList = [];
  let pipeDefinitionList = [];

  if (layerElement.layerConfig.component_catalogs) {
    // ToDo: Technical multiple ComponentCatalogs can be assigned to one MeasureLayerConfig.
    // For now we assume that only one ComponentCatalog is assigned.
    const componentCatalog = layerElement.layerConfig.component_catalogs[0];

    const catalogComponents = componentCatalogItems
      .get("catalogComponentsCategories")
      .filter((item) => item["rootUuid"] === componentCatalog);
    catalogComponents.forEach((component) => {
      component.items.forEach((componentItem) => {
        componentList.push({
          label: componentItem.name,
        });
      });
    });

    const pipeDefinitions = componentCatalogItems
      .get("catalogPipeDefinitionsCategories")
      .filter((element) => {
        return element["rootUuid"] === componentCatalog;
      });
    if (pipeDefinitions.length > 0) {
      const pipeDefinition = pipeDefinitions[0];

      pipeDefinition.items.forEach((item) => {
        pipeDefinitionList.push({
          label: `${item.material.name} ${item.dimension.name}`,
        });
      });
    }
  }

  const listItemKeys = Object.keys(layerElement.referencePoints);

  if (listItemKeys.length > 0) {
    elementList.push(
      <CustomizedListSubHeader
        key={`reference_point_header`}
        subHeaderName={t('Project.Map.MeasurementList.txtReferenceListItemSubHeader', {ns: 'screens_private'})}
        color={layerElement.layerConfig.color}
      />
    );
  }

  for (let index = 0; index < listItemKeys.length; index++) {
    elementList.push(
      <ReferenceListItem
        measurmentListClick={measurmentListClick}
        onListClick={onListClick}
        key={`reference-${index}_${layerElement.layerConfig.id}`}
        measurement={layerElement.referencePoints[listItemKeys[index]]}
        componentList={componentList}
        onHoverListEnter={onHoverListEnter}
        onHoverListLeave={onHoverListLeave}
      />
    );
  }
}

function prepareTopographicLayerData(
  elementList,
  layerElement,
  measurmentListClick,
  onListClick,
  onHoverListEnter,
  onHoverListLeave,
  componentCatalogItems
) {
  let componentList = [];

  if (layerElement.layerConfig.component_catalogs) {
    // ToDo: Technical multiple ComponentCatalogs can be assigned to one MeasureLayerConfig.
    // For now we assume that only one ComponentCatalog is assigned.
    const componentCatalog = layerElement.layerConfig.component_catalogs[0];

    const catalogComponents = componentCatalogItems
      .get("catalogComponentsCategories")
      .filter((item) => item["rootUuid"] === componentCatalog);
    catalogComponents.forEach((component) => {
      component.items.forEach((componentItem) => {
        componentList.push({
          label: componentItem.name,
        });
      });
    });
  }

  elementList.push(
    <TopographicPointListSubHeader
      topographicPointLayer={layerElement.layerConfig}
      key={`topographic_point_layer_header_${layerElement.layerConfig.id}`}
    />
  );

  const listItemKeys = Object.keys(layerElement.topographicPoints);
  for (let index = 0; index < listItemKeys.length; index++) {
    elementList.push(
      <TopographicPointListItem
        measurmentListClick={measurmentListClick}
        onListClick={onListClick}
        key={`point-${index}_${layerElement.layerConfig.id}`}
        topographicPoint={layerElement.topographicPoints[listItemKeys[index]]}
        componentList={componentList}
        onHoverListEnter={onHoverListEnter}
        onHoverListLeave={onHoverListLeave}
      />
    );
  }
}

function prepareGnssPointData(
  elementList, 
  layerElement,
  onHoverListEnter,
  onHoverListLeave,
  onListClick,
  measurmentListClick
) {
  elementList.push(
    <CustomizedListSubHeader
      key={`gnss_point_header_${layerElement.layerConfig.id}`}
      subHeaderName={layerElement.layerConfig.layer_name}
      color={layerElement.layerConfig.color}
    />
  );

  const listItemKeys = Object.keys(layerElement.gnssPoints);
  for (let index = 0; index < listItemKeys.length; index++) {
    elementList.push(
      <GnssPointListItem
        key={`gnss-point-${index}_${layerElement.layerConfig.id}`}
        gnssPoint={layerElement.gnssPoints[index]}
        layerConfig={layerElement.layerConfig}
        onHoverListEnter={onHoverListEnter}
        onHoverListLeave={onHoverListLeave}
        onListClick={onListClick}
        measurmentListClick={measurmentListClick}
      />
    )
  }
}

function prepareGnssRefPointData(
  elementList, 
  layerElement,
  onHoverListEnter,
  onHoverListLeave,
  onListClick,
  measurmentListClick
) {
  elementList.push(
    <CustomizedListSubHeader
      key={`gnss_ref_point_header_${layerElement.layerConfig.id}`}
      subHeaderName={layerElement.layerConfig.layer_name}
      color={layerElement.layerConfig.color}
    />
  );

  const listItemKeys = Object.keys(layerElement.gnssRefPoints);
  for (let index = 0; index < listItemKeys.length; index++) {
    elementList.push(
      <GnssRefPointListItem
        key={`gnss-ref-point-${index}_${layerElement.layerConfig.id}`}
        gnssRefPoint={layerElement.gnssRefPoints[index]}
        layerConfig={layerElement.layerConfig}
        onHoverListEnter={onHoverListEnter}
        onHoverListLeave={onHoverListLeave}
        onListClick={onListClick}
        measurmentListClick={measurmentListClick}
      />
    )
  }
}

function prepareGnssLineData(
  elementList, 
  layerElement,
  onHoverListEnter,
  onHoverListLeave,
  onListClick,
  measurmentListClick
) {
  const headerTitle = `${layerElement.layerConfig.layer_name}, ${layerElement.gnssLine.component_name}, ${layerElement.gnssLine.distance}${layerElement.gnssLine.unit}`

  elementList.push(
    <CustomizedListSubHeader
      key={`gnss_line_header_${layerElement.layerConfig.id}`}
      subHeaderName={headerTitle}
      color={layerElement.layerConfig.color}
    />
  );

  const listItemKeys = Object.keys(layerElement.gnssPoints);
  for (let index = 0; index < listItemKeys.length; index++) {
    elementList.push(
      <GnssLineListItem
        key={`gnss-line-${index}_${layerElement.layerConfig.id}`}
        gnssPoint={layerElement.gnssPoints[index]}
        gnssLine={layerElement.gnssLine}
        layerConfig={layerElement.layerConfig}
        onHoverListEnter={onHoverListEnter}
        onHoverListLeave={onHoverListLeave}
        onListClick={onListClick}
        measurmentListClick={measurmentListClick}
      />
    )
  }
}

function prepareGnssPolygonData(
  elementList, 
  layerElement,
  onHoverListEnter,
  onHoverListLeave,
  onListClick,
  measurmentListClick
) {
  const headerTitle = `${layerElement.layerConfig.layer_name}, ${layerElement.gnssPolygon.component_name}, ${layerElement.gnssPolygon.area}${layerElement.gnssPolygon.unit}`

  elementList.push(
    <CustomizedListSubHeader
      key={`gnss_polygon_header_${layerElement.layerConfig.id}`}
      subHeaderName={headerTitle}
      color={layerElement.layerConfig.color}
    />
  );

  const listItemKeys = Object.keys(layerElement.gnssPoints);
  for (let index = 0; index < listItemKeys.length; index++) {
    elementList.push(
      <GnssPolygonListItem
        key={`gnss-polygon-${index}_${layerElement.layerConfig.id}`}
        gnssPoint={layerElement.gnssPoints[index]}
        gnssPolygon={layerElement.gnssPolygon}
        layerConfig={layerElement.layerConfig}
        onHoverListEnter={onHoverListEnter}
        onHoverListLeave={onHoverListLeave}
        onListClick={onListClick}
        measurmentListClick={measurmentListClick}
      />
    )
  }
}

function prepareGnssImageData(
  t,
  elementList, 
  gnssImages, 
  onHoverListEnter,
  onHoverListLeave,
  onListClick,
  measurmentListClick
) {
  elementList.push(
    <CustomizedListSubHeader
      key={`gnss_image_header`}
      subHeaderName={t('Project.Map.MeasurementList.txtGnssImageListItemSubHeader', {ns: 'screens_private'})}
      color={"unknown"}
    />
  );
  for (let index = 0; index < gnssImages.length; index++) {
    elementList.push(
      <GnssImageListItem
        key={`gnss-image-${index}`}
        gnssImage={gnssImages[index]}
        onHoverListEnter={onHoverListEnter}
        onHoverListLeave={onHoverListLeave}
        onListClick={onListClick}
        measurmentListClick={measurmentListClick}
      />
    )
  }
}

function prepareVolumeData(
  t,
  elementList, 
  volumes, 
  volumeListItemKeys
) {
  elementList.push(
    <CustomizedListSubHeader
      key={`volume_header`}
      subHeaderName={t('Project.Map.MeasurementList.txtVolumeListItemSubHeader', {ns: 'screens_private'})}
      color={"unknown"}
    />
  );
  for (let index = 0; index < volumeListItemKeys.length; index++) {
    elementList.push(
      <VolumeListItem
        key={index + "_volume"}
        volumeItem={volumes[volumeListItemKeys[index]]}
      />
    );
  }
}

function prepareScreenshotData(
  t,
  elementList, 
  mediaFiles, 
  mediaFilesItemKeys
) {
  elementList.push(
    <CustomizedListSubHeader
      key={`screenshot_header`}
      subHeaderName={t('Project.Map.MeasurementList.txtScreenshotListItemSubHeader', {ns: 'screens_private'})}
    />
  );
  for (let index = 0; index < mediaFilesItemKeys.length; index++) {
    elementList.push(
      <ScreenshotListItem
        key={index + "_screenshot"}
        screenshotItem={mediaFiles[mediaFilesItemKeys[index]]}
      />
    );
  }
}

MeasurementList.propTypes = propTypes;

export default withStyles(overrideStyles, { withTheme: true })(MeasurementList);
