import React from "react";
import PropTypes from "prop-types";

import "./styles.css";

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  side: PropTypes.oneOf(["right", "left"]).isRequired,
  flexDirection: PropTypes.string,
  flexWrap: PropTypes.string,
};

const Column = ({ 
  children, 
  flexDirection = "row", 
  flexWrap = "nowrap", 
  side 
}) => {
  return (
    <div
      className={`columns-container columns-${side}-side`}
      style={{ flexDirection, flexWrap }}
    >
      {children}
    </div>
  );
};

Column.propTypes = propTypes;
export default Column;
