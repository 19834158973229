/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';

import { useLocation } from "react-router-dom";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import Header from "../../../../components/Header";

import {
  COMPONENTS,
  MATERIALS,
  DIMENSIONS,
  PIPE_DEFINITION,
  CATALOG_CATEGORY_MAPPING,
} from "../../../../constants/catalogCategoryMapping";

import history from "../../../../lib/history";

import useExitDetector from "../../../../util/useExitDetector";
import localStorageHandler from "../../../../util/localStorageHandler";

import * as styles from "../../../../styles/styles";

const propTypes = {
  setCreateModalOpen: PropTypes.func,
  onHandleChange: PropTypes.func,
  organizationCategory: PropTypes.bool,
  isAdmin: PropTypes.bool,
};

const ComponentCatalogHeader = ({
  setCreateModalOpen,
  organizationCategory,
  onHandleChange,
  isAdmin,
}) => {
  const { t } = useTranslation();

  const [stateSelected, setStateSelected] = React.useState(COMPONENTS);
  const [getLastPath, clearLastPath] = localStorageHandler();
  const pathName = useLocation().pathname;
  useExitDetector(pathName);

  return (
    <Header>
      <IconButton
        style={{
          textDecoration: "none",
        }}
        onClick={() => {
          if (getLastPath() === pathName) {
            clearLastPath();
            history.go(-2);
          } else {
            history.goBack();
          }
        }}
        size="large"
      >
        <ArrowBackIcon />
      </IconButton>

      <div className="header-right-side">
        <div className="single-actions-menu-dropdown-wrapper">
          <FormControl variant="outlined" size="small">
            <Select
              style={{ marginRight: "1rem" }}
              value={stateSelected}
              onChange={(event) => {
                setStateSelected(event.target.value);
                onHandleChange(event);
              }}
              autoWidth={true}
            >
              <MenuItem value={COMPONENTS}>
                {CATALOG_CATEGORY_MAPPING[COMPONENTS]}
              </MenuItem>
              <MenuItem value={MATERIALS}>
                {CATALOG_CATEGORY_MAPPING[MATERIALS]}
              </MenuItem>
              <MenuItem value={DIMENSIONS}>
                {CATALOG_CATEGORY_MAPPING[DIMENSIONS]}
              </MenuItem>
              <MenuItem value={PIPE_DEFINITION}>
                {CATALOG_CATEGORY_MAPPING[PIPE_DEFINITION]}
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        {isAdmin && organizationCategory && (
          <Button
            style={{ margin: `0 ${styles.spacing16} 0 0` }}
            color="primary"
            variant="contained"
            size="medium"
            onClick={() => setCreateModalOpen(true)}
          >
            <AddCircleIcon style={{ margin: "0 1.2rem 0 0" }} />
            {t('button.btnCreate', {ns: 'common'})}
          </Button>
        )}
      </div>
    </Header>
  );
};

ComponentCatalogHeader.propTypes = propTypes;
export default ComponentCatalogHeader;
