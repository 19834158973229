/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';

import moment from "moment";

import withStyles from "@mui/styles/withStyles";

import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import Divider from "@mui/material/Divider";


import CustomModal from "../../../../../../components/CustomModal";
import CustomImageView from "../../../../../../components/CustomImageView";
import {
    GNSS_IMAGE, 
    pointNameLookupTable 
} from "../../../../../../constants/pointLookupTable";

import { IMAGE_KEY } from "../../../../../../constants/contextMenuEntries";

import * as styles from "../../../../../../styles/styles";
import "./styles.css";

const propTypes = {
    classes: PropTypes.object,
    gnssImage: PropTypes.object,
    layerConfig: PropTypes.object,
    onHoverListEnter: PropTypes.func,
    onHoverListLeave: PropTypes.func,
    onListClick: PropTypes.func,
    measurmentListClick: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

const overrideStyles = (theme) => ({
    root: {
      paddingTop: "0",
      paddingBottom: "0",
      paddingLeft: "0",
      margin: "0.5rem 1rem 0.5rem 1rem",
      width: "auto",
      backgroundColor: styles.white,
      boxShadow: styles.boxShadowListElement,
      "&:hover": {
        boxShadow: styles.boxShadowListElementHover,
      },
    },
    root_select: {
        paddingTop: "0",
        paddingBottom: "0",
        paddingLeft: "0",
        margin: "0.5rem 1rem 0.5rem 1rem",
        width: "auto",
        backgroundColor: "rgba(89, 83, 210, 0.08)",
        boxShadow: styles.boxShadowListElement,
        "&:hover": {
          boxShadow: styles.boxShadowListElementHover,
        },
      },
    vertical: {
      marginRight: "2rem",
    },
});

const GnssImageListItem = ({
    gnssImage,
    layerConfig,
    classes,
    onHoverListEnter,
    onHoverListLeave,
    onListClick,
    measurmentListClick
}) => {
    const { t } = useTranslation();

    const [selected, setSelected] = React.useState(false);
    const [modalOpen, setModalOpen] = React.useState(false);
    const [menuEntryKey, setMenuEntryKey] = React.useState(null);

    const created_at = moment.unix(gnssImage.created_at);
    const image = gnssImage.image.original;
    const id = gnssImage.uuid;

    const baseTitle = pointNameLookupTable[GNSS_IMAGE];
    const title = baseTitle + " - " + gnssImage.user_id || "";

    const onClick = () => {
        setSelected(true);
        onListClick(gnssImage);
    };

    const closeModal = () => {
        setModalOpen(false);
        setMenuEntryKey(null);
      };

    const onClickImage = (event) => {
        event.stopPropagation();
        openModal(IMAGE_KEY);
    };

      const openModal = (menuEntryKey) => {
        const bool = typeof menuEntryKey === "string";
        setModalOpen(bool);
        setMenuEntryKey(bool ? menuEntryKey : false);
    };

    const isSelected = id === measurmentListClick

    return (
        <>
            {modalOpen &&
                renderModalContent(
                  t,
                  modalOpen,
                  menuEntryKey,
                  closeModal,
                  gnssImage,
            )}
            
            <ListItem
                classes={{ root: isSelected ? classes.root_select : classes.root }}
                onMouseLeave={() => onHoverListLeave(id, GNSS_IMAGE)}
                onMouseEnter={() => onHoverListEnter(id, GNSS_IMAGE)}
                onMouseOver={() => onHoverListEnter(id, GNSS_IMAGE)}
                onClick={onClick}
                className="gnss-image-list-item-list-item"
            >
                {image ? 
                    <div className="gnss-image-list-item-image-container">
                        <img
                            onClick={onClickImage}
                            src={image}
                            className="gnss-image-list-item-image-thumbnail"
                            alt={t('Project.Map.MeasurementList.GnssImageListItem.txtImageAlternative', {GNSS_IMAGE: GNSS_IMAGE, ns: 'screens_private'})}
                        />
                        <Divider
                            classes={{ vertical: classes.vertical }}
                            orientation={"vertical"}
                        />
                    </div>
                    : <div style={{marginRight: "2rem"}}></div>}
                
                <ListItemText
                    style={{ flex: 8 }}
                    primary={title}
                    secondary={created_at.format("dd. DD.MM.YYYY HH:mm:ss")}
                />
                <ListItemText
                    primary={t('Project.Map.MeasurementList.GnssImageListItem.txtComponent', {ns: 'screens_private'})}
                    secondary={
                        gnssImage.component_name !== null
                        ? gnssImage.component_name
                        : t('Project.Map.MeasurementList.GnssImageListItem.txtNoComponentSet', {ns: 'screens_private'})
                    }
                />
            </ListItem>
        </>
    )
}

function renderModalContent(
  t,
  modalOpen,
  menuEntryKey,
  closeModal,
  gnssImage,
) {
  switch (menuEntryKey) {
    case IMAGE_KEY:
      return (
        <CustomModal
          showCloseButton={true}
          open={modalOpen}
          image={gnssImage.image.original}
          onClose={() => closeModal()}
          render={() => (
            <CustomImageView>
              <img
                src={gnssImage.image.original}
                className="image-modal-image"
                alt={t('Project.Map.MeasurementList.GnssImageListItem.txtCustomModalImageAlternative', {ns: 'screens_private'})}
              />
            </CustomImageView>
          )}
        />
      );
    default:
      return <div></div>;
  }
}

GnssImageListItem.propTypes = propTypes;
export default withStyles(overrideStyles, { withTheme: true}) (
    GnssImageListItem
)