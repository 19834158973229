export default {
    setLanguage(language) {
        localStorage.setItem('language', language);
    },

    get() {
        return localStorage.getItem('language');
    },

    clear() {
        localStorage.clear();
    }
}