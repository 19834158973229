/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import withStyles from "@mui/styles/withStyles";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";

import ButtonGroup from "../../../components/ButtonGroup";
import CustomDialog from "../../../components/CustomDialog";
import HintView from "../../../components/HintView";
import { HINT_UPDATE_BEHAVIOUR } from "../../../constants/hinttypesmapping";
import { validateTextFields } from "../../../sharedFunctions/validation";

import * as styles from "../../../styles/styles";

const propTypes = {
  classes: PropTypes.object,
  measurementLine: PropTypes.object,
  layerConfig: PropTypes.object,
  pipeDefinitionList: PropTypes.array,
  modalOpen: PropTypes.bool,
  onPositiveButtonClicked: PropTypes.func,
  onNegativeButtonClicked: PropTypes.func,
};

const overrideStyles = (theme) => ({
  alignText: {
    margin: "auto 0 0 0",
  },
});

const EditMeasurementLineDialog = ({
  classes,
  measurementLine,
  layerConfig,
  pipeDefinitionList,
  modalOpen,
  onPositiveButtonClicked,
  onNegativeButtonClicked,
}) => {
  const { t } = useTranslation();

  const activePipeDefinitionIndex = pipeDefinitionList.findIndex(
    (item) => item.label === measurementLine.component
  );

  const componentValue =
    activePipeDefinitionIndex > 0
      ? pipeDefinitionList[activePipeDefinitionIndex]
      : null;

  const [stateComponentInputValue, setComponentInputValue] = useState(
    measurementLine.component
  );
  const [stateComponentValue, setStateComponentValue] =
    useState(componentValue);
  const [stateDisablePositiveButton, setDisablePositiveButton] = useState(true);

  const validationValues = React.useMemo(() => {
    return {
      component: {
        value: measurementLine.component,
        required: true,
      },
    };
  }, [measurementLine.component]);

  const onChangeComponent = (event) => {
    const component = event.target.value;
    const newValues = { component: component };
    setComponentInputValue(component);
    setDisablePositiveButton(validateTextFields(validationValues, newValues));
  };

  const onChangeComponentAutocomplete = (event, newValue) => {
    let component = "";
    if (newValue) {
      component = newValue;
    }
    const newValues = { component: component };
    setComponentInputValue(newValue);
    setDisablePositiveButton(validateTextFields(validationValues, newValues));
  };

  const handleOnPositiveButtonClicked = () => {
    const updateMeasurementLine = {
      ...measurementLine,
      component: stateComponentInputValue,
    };
    onPositiveButtonClicked(updateMeasurementLine);
  };

  const renderLayerNameAutocompleteInput = () => {
    return (
      <div style={{ margin: `0 0 ${styles.spacing32} 0` }}>
        <Autocomplete
          fullWidth={true}
          freeSolo
          id="combo-box-component"
          value={stateComponentValue}
          onChange={(event, newValue) => {
            setStateComponentValue(newValue);
          }}
          inputValue={stateComponentInputValue}
          onInputChange={onChangeComponentAutocomplete}
          options={pipeDefinitionList}
          renderInput={(params) => (
            <TextField {...params} label={t('dialogs.EditMeasurementLineDialog.txtAutoCompleteDescriptionLabel', {ns: 'containers'})} />
          )}
          renderOption={(props, option, { inputValue }) => {
            const matches = match(option.label, inputValue, {
              insideWords: true,
            });
            const parts = parse(option.label, matches);

            return (
              <li {...props}>
                <div>
                  {parts.map((part, index) => (
                    <span
                      key={index}
                      style={{
                        fontWeight: part.highlight ? 700 : 400,
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                </div>
              </li>
            );
          }}
        />
      </div>
    );
  };

  const renderLayerNameInput = () => {
    return (
      (<TextField
        fullWidth={true}
        margin="none"
        label={t('dialogs.EditMeasurementLineDialog.textFieldComponentLabel', {ns: 'containers'})}
        multiline={false}
        onChange={onChangeComponent}
        placeholder={t('dialogs.EditMeasurementLineDialog.textFieldComponentPlaceholder', {ns: 'containers'})}
        required={true}
        style={{
          margin: `${styles.spacing24} 0 ${styles.spacing24} 0`,
        }}
        variant="outlined"
        value={stateComponentInputValue}
        slotProps={{
          input: { classes: { input: classes.alignText } }
        }}
      />)
    );
  };

  return (
    <>
      <CustomDialog
        open={modalOpen}
        onClick={handleOnPositiveButtonClicked}
        onClose={onNegativeButtonClicked}
        showHint={true}
        renderTitle={() => {
          return (
            <DialogTitle id="alert-dialog-title">
              {t('dialogs.EditMeasurementLineDialog.txtDialogTitle', {ns: 'containers'})}
            </DialogTitle>
          );
        }}
        renderHint={() => {
          return <HintView hintMessageType={HINT_UPDATE_BEHAVIOUR} />;
        }}
        renderContent={() => {
          return (
            <DialogContent>
              {pipeDefinitionList.length > 0
                ? renderLayerNameAutocompleteInput()
                : renderLayerNameInput()}
            </DialogContent>
          );
        }}
        renderButtonGroup={(
          handleOnPositiveButtonClicked,
          onNegativeButtonClicked
        ) => {
          return (
            <DialogActions style={{ padding: "2.4rem 2.4rem 2rem" }}>
              <ButtonGroup
                align="right"
                leftButtonText={t('button.btnCancel', {ns: 'common'})}
                leftButtonClick={onNegativeButtonClicked}
                rightButtonText={t('button.btnEdit', {ns: 'common'})}
                rightButtonClick={handleOnPositiveButtonClicked}
                rightButtonDisabled={stateDisablePositiveButton}
                spacingTop={false}
              />
            </DialogActions>
          );
        }}
      />
    </>
  );
};

EditMeasurementLineDialog.propTypes = propTypes;
export default withStyles(overrideStyles, { withTheme: true })(
  EditMeasurementLineDialog
);
