// ORGANIZATION
export const ORGANIZATION_UPDATE_EVENT = "organization_update";
export const ORGANIZATION_USER_UPDATE_EVENT = "organization_user_update";

// PROJECT
export const PROJECT_CREATE_EVENT = "project_create";
export const PROJECT_ASSIGNED_EVENT = "project_assigned";
export const PROJECT_DEASSIGNED_EVENT = "project_deassigned";
export const PROJECT_UPDATE_EVENT = "project_update";
export const PROJECT_DELETE_EVENT = "project_delete";
export const PROJECT_RESET_EVENT = "project_reset";

// MEASURE_LAYER_CONFIG
export const MEASURE_LAYER_CONFIGS_CREATE_EVENT =
  "measure_layer_configs_create";
export const MEASURE_LAYER_CONFIG_UPDATE_EVENT = "measure_layer_config_update";
export const MEASURE_LAYER_CONFIG_DELETE_EVENT = "measure_layer_config_delete";

// MEASUREMENT
export const MEASUREMENTS_CREATE_EVENT = "measurements_create";
export const MEASUREMENT_UPDATE_EVENT = "measurement_update";
export const MEASURMENET_DELETE_EVENT = "measurement_delete";
export const MEASUREMENTS_DELETE_EVENT = "measurements_delete";

// MEASUREMENT_LINE
export const MEASUREMENT_LINES_CREATE_EVENT = "measurement_lines_create";
export const MEASUREMENT_LINE_UPDATE_EVENT = "measurement_line_update";

// MEASUREMENT_SEGMENT
export const MEASUREMENT_SEGMENTS_CREATE_EVENT = "measurement_segments_create";
export const MEASUREMENT_SEGMENT_UPDATE_EVENT = "measurement_segment_update";

// MEDIA_FILE
export const MEDIA_FILE_CREATE_EVENT = "media_file_create";
export const MEDIA_FILE_UPDATE_EVENT = "media_file_update";
export const MEDIA_FILE_DELETED_EVENT = "media_file_deleted";

// PROJECT_FILE
export const PROJECT_FILE_CREATE_EVENT = "project_file_create";
export const PROJECT_FILE_DELETED_EVENT = "project_file_deleted";

// VOLUME
export const VOLUMES_CREATE_EVENT = "volumes_create";
export const VOLUME_UPDATE_EVENT = "volume_update";
export const VOLUME_DELETED_EVENT = "volume_deleted";

// TOPOGRAPHIC POINT
export const TOPOGRAPHIC_POINTS_CREATE_EVENT = "topographic_points_create";
export const TOPOGRAPHIC_POINT_UPDATE_EVENT = "topographic_point_update";

// USER
export const USER_UPDATE_EVENT = "user_update";
export const USER_STATUS_EVENT = "user_status_event";

// COMPONENT CATALOG
export const CATALOG_CATEGORY_UPDATED_EVENT = "catalog_category_updated";
export const CATALOG_CATEGORY_DELETED_EVENT = "catalog_category_deleted";
export const CATALOG_COMPONENT_UPDATED_EVENT = "catalog_component_updated";
export const CATALOG_COMPONENT_DELETED_EVENT = "catalog_component_deleted";
export const CATALOG_MATERIAL_UPDATED_EVENT = "catalog_material_updated";
export const CATALOG_MATERIAL_DELETED_EVENT = "catalog_material_deleted";
export const CATALOG_DIMENSION_UPDATED_EVENT = "catalog_dimension_updated";
export const CATALOG_DIMENSION_DELETED_EVENT = "catalog_dimension_deleted";
export const CATALOG_COMPONENT_CATEGORY_CREATED_EVENT =
  "catalog_component_category_created";
export const CATALOG_DIMENSION_CATEGORY_CREATED_EVENT =
  "catalog_dimension_category_created";
export const CATALOG_MATERIAL_CATEGORY_CREATED_EVENT =
  "catalog_material_category_created";
export const CATALOG_PIPE_DEFINITION_CATEGORY_CREATED_EVENT =
  "catalog_pipe_definition_category_created";
export const CATALOG_PIPE_DEFINITION_CATEGORY_DELETED_EVENT =
  "catalog_pipe_definition_category_deleted";
export const CATALOG_ORGANIZATION_CATEGORY_CREATED_EVENT =
  "catalog_organization_category_created";

// MEASURE LAYER CONFIG TEMPLATES
export const MEASURE_LAYER_CONFIG_TEMPLATE_CREATED_EVENT =
  "measure_layer_config_template_created";
export const MEASURE_LAYER_CONFIG_TEMPLATE_UPDATED_EVENT =
  "measure_layer_config_template_updated";
export const MEASURE_LAYER_CONFIG_TEMPLATE_DELETED_EVENT =
  "measure_layer_config_template_deleted";
export const MEASURE_LAYER_CONFIG_ITEM_CREATED_EVENT =
  "measure_layer_config_item_created";
export const MEASURE_LAYER_CONFIG_ITEM_UPDATED_EVENT =
  "measure_layer_config_item_updated";
export const MEASURE_LAYER_CONFIG_ITEM_DELETED_EVENT =
  "measure_layer_config_item_deleted";

// MAP LAYER
export const MAP_LAYER_CREATED_EVENT =
  "map_layer_created"
export const MAP_LAYER_UPDATED_EVENT = 
  "map_layer_updated"
export const MAP_LAYER_DELETED_EVENT = 
  "map_layer_deleted"
export const MAP_LAYER_TO_PROJECT_ASSIGNMENT_CREATED_EVENT = 
  "map_layer_to_project_assignment_created"
export const MAP_LAYER_TO_PROJECT_ASSIGNMENT_DELETED_EVENT = 
  "map_layer_to_project_assignment_deleted"

  // GNSS DATA
  export const GNSS_DATA_CREATED_EVENT = "gnss_data_created"
  export const GNSS_DATA_UPDATED_EVENT = "gnss_data_updated"
  export const GNSS_DATA_DELETED_EVENT = "gnss_data_deleted"
  export const GNSS_POINT_UPDATED_EVENT = "gnss_point_updated"
  export const GNSS_POINT_DELETED_EVENT = "gnss_point_deleted"
  export const GNSS_IMAGE_UPDATED_EVENT = "gnss_image_updated"
  export const GNSS_IMAGE_DELETED_EVENT = "gnss_image_deleted"
  export const GNSS_SEGMENT_DELETED_EVENT = "gnss_segment_deleted"
  export const GNSS_LINE_DELETED_EVENT = "gnss_line_deleted"
  export const GNSS_POLYGON_DELETED_EVENT = "gnss_polygon_deleted"  