/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import Button from "@mui/material/Button";
import MapIcon from "@mui/icons-material/Map";
import QueueIcon from "@mui/icons-material/Queue";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import {
  MAP_VIEW_TYPE,
  AR_LAYER_VIEW_TYPE,
  PROJECT_FILES_VIEW_TYPE,
} from "../../../../../../constants/project";

import * as styles from "../../../../../../styles/styles";

const propTypes = {
  project: PropTypes.object,
  history: PropTypes.object,
  changeProjectView: PropTypes.func,
  showProjectSidePanelActions: PropTypes.object,
};
// Show the Aufnahmelayer Icon only if the project is open or has only a volume measurement.
const ProjectModules = ({
  project,
  history,
  changeProjectView,
  showProjectSidePanelActions,
}) => {
  const { t } = useTranslation();

  const [selected, setSelected] = useState({
    MAP_VIEW_TYPE: true,
    AR_LAYER_VIEW_TYPE: false,
    PROJECT_FILES_VIEW_TYPE: false,
  });
  const containerStyle = { marginTop: "1rem" };

  const updateSelectedState = (newSelected) => {
    if (newSelected == MAP_VIEW_TYPE) {
      setSelected({
        MAP_VIEW_TYPE: true,
        AR_LAYER_VIEW_TYPE: false,
        PROJECT_FILES_VIEW_TYPE: false,
      });
    } else if (newSelected == AR_LAYER_VIEW_TYPE) {
      setSelected({
        MAP_VIEW_TYPE: false,
        AR_LAYER_VIEW_TYPE: true,
        PROJECT_FILES_VIEW_TYPE: false,
      });
    } else if (newSelected == PROJECT_FILES_VIEW_TYPE) {
      setSelected({
        MAP_VIEW_TYPE: false,
        AR_LAYER_VIEW_TYPE: false,
        PROJECT_FILES_VIEW_TYPE: true,
      });
    }
  };

  return (
    <>
      <div style={{ ...containerStyle }}>
        {showProjectSidePanelActions["map_view_button"] && (
          <Button
            style={{
              color: selected[MAP_VIEW_TYPE]
                ? styles.white
                : styles.darkBlueBase,
            }}
            startIcon={
              <MapIcon
                style={{
                  color: selected[MAP_VIEW_TYPE]
                    ? styles.white
                    : styles.darkBlueBase,
                }}
              />
            }
            onClick={() => {
              updateSelectedState(MAP_VIEW_TYPE);
              changeProjectView(MAP_VIEW_TYPE);
            }}
          >
            {t('Project.Map.ProjectSideBar.ProjectModules.txtMapView', {ns: 'screens_private'})}
          </Button>
        )}

        {showProjectSidePanelActions["measure_layer_view_button"] && (
          <Button
            style={{
              color: selected[AR_LAYER_VIEW_TYPE]
                ? styles.white
                : styles.darkBlueBase,
            }}
            startIcon={
              <QueueIcon
                style={{
                  color: selected[AR_LAYER_VIEW_TYPE]
                    ? styles.white
                    : styles.darkBlueBase,
                }}
              />
            }
            onClick={() => {
              updateSelectedState(AR_LAYER_VIEW_TYPE);
              changeProjectView(AR_LAYER_VIEW_TYPE);
            }}
          >
            {t('Project.Map.ProjectSideBar.ProjectModules.txtLayerView', {ns: 'screens_private'})}
          </Button>
        )}

        {showProjectSidePanelActions["project_files_view_button"] && (
          <Button
            style={{
              color: selected[PROJECT_FILES_VIEW_TYPE]
                ? styles.white
                : styles.darkBlueBase,
            }}
            startIcon={
              <AttachFileIcon
                style={{
                  color: selected[PROJECT_FILES_VIEW_TYPE]
                    ? styles.white
                    : styles.darkBlueBase,
                }}
              />
            }
            onClick={() => {
              updateSelectedState(PROJECT_FILES_VIEW_TYPE);
              changeProjectView(PROJECT_FILES_VIEW_TYPE);
            }}
          >
            {t('Project.Map.ProjectSideBar.ProjectModules.txtProjectFilesView', {ns: 'screens_private'})}
          </Button>
        )}
      </div>
    </>
  );
};

ProjectModules.propTypes = propTypes;
export default ProjectModules;
