import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import queryString from "qs";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  checkForExpiredSubscription,
  setExpiredSubscription,
} from "../../../actions/organizations";

import { setUser, validate } from "../../../actions/user";
import LandingPage from "../../../components/LandingPage";
import LogIn from "../../../containers/LogIn";
import API from "../../../lib/api";
import HTTP from "../../../lib/http";
import { initApp, initSockets } from "../../../lib/init";
import Storage from "../../../lib/storage";
import Validate from "../../../screens/public/Validate";
import { isAdmin } from "../../../sharedFunctions/user";

import "./styles.css";

const propTypes = {
  checkForExpiredSubscription: PropTypes.func,
  history: PropTypes.object,
  setExpiredSubscription: PropTypes.func,
  validate: PropTypes.func,
  removedFromOrganization: PropTypes.any,
  setUser: PropTypes.func,
  initApp: PropTypes.func,
  location: PropTypes.object,
};

const Login = ({
  checkForExpiredSubscription,
  history,
  setExpiredSubscription,
  validate,
  removedFromOrganization,
  location,
  setUser,
  initApp,
}) => {
  const { t } = useTranslation();

  const [validating, setValidating] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [passwordVisible, setPasswordVisible] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [rememberLogin, setRememberLogin] = React.useState(false);
  const [errorText, setErrorText] = React.useState("");
  const [shouldSignIn, setShouldSignIn] = React.useState(false);

  ////////////////////
  // componentDidMount
  React.useEffect(() => {
    if (removedFromOrganization) {
      setValidating(false);
      setLoading(false);
      setErrorText(t('Login.txtErrorMsgFromOrgaRemoved', {ns: 'screens_public'}));
      return;
    }

    const controller = new AbortController();

    if (Storage.get("jwt")) {
      validate()
        .then((validateResponse) => {
          if (validateResponse.status === 200) {
            if (
              validateResponse.data.user.organization_role_groups.length === 0
            ) {
              setValidating(false);
              setLoading(false);
              setErrorText(t('Login.txtErrorMsgNoOrga', {ns: 'screens_public'}));
              return;
            }
            initSockets();
            checkForExpiredSubscription(controller)
              .then((subscriptionResponse) => {
                if (subscriptionResponse.status === 423) {
                  setExpiredSubscription(true);
                  history.push("/subscription-expired");
                } else {
                  history.push(getRedirectRoute());
                }
              });
          } else {
            if (
              validateResponse.response &&
              validateResponse.response.status === 401
            ) {
              Storage.clearStorage();
            }

            setValidating(false);
          }
        })
        .catch((error) => {
          console.log(error)
          setValidating(false);
        });
    } else {
      setValidating(false);
    }

    return () => {
      controller.abort();
    };
  }, []);
  // componentDidMount
  ////////////////////
  

  /////////////////////
  // componentDidUpdate
  React.useEffect(() => {
    if (!shouldSignIn) {
      return
    }

    const controller = new AbortController();
    const signal = controller.signal;

    async function signIn() {
      try {
        setLoading(true)
        Storage.setRememberLogin(rememberLogin);
        const userResponse = await HTTP.post(
          API.user.signIn,
          {
            email: email,
            password: password,
            client: "web",
          },
          { signal }
        );
  
        if (userResponse.data.user.organization_role_groups.length === 0) {
          setLoading(false)
          setErrorText(t('Login.txtErrorMsgNoOrga', {ns: 'screens_public'}));
          return;
        }
  
        let user = {
          ...userResponse.data.user,
          login: true,
          loading: false,
        };
  
        setUser(user);
        initSockets();
        await checkForExpiredSubscription(controller)
          .then((response) => {
            if (response.status === 423) {
              setExpiredSubscription(true);
              if (isAdmin(user.organization_role_groups, false)) {
                history.push("/billing");
              } else {
                history.push("/subscription-expired");
              }
              setLoading(false);
            } else {
              initApp().then(() => {
                setLoading(false);
                history.push(getRedirectRoute());
              });
            }
          });
      } catch (error) {
        if (error.name === "AbortError") {
          console.log("Request cancelled", error);
        } else {
          setLoading(false);
          setErrorText((error.response && error.response.data.error) || t('Login.txtErrorMsgLogin', {ns: 'screens_public'}))
        }
      }

      setShouldSignIn(false);
    }

    signIn();

    return () => {
      controller.abort();
    }
  }, [shouldSignIn]);
  // componentDidUpdate
  /////////////////////
  

  const getRedirectRoute = () => {
    let urlParams = queryString.parse(
      location && location.search
    );
    return urlParams["?redirect"] || "/projects";
  }

  const onKeyDown = (event) => {
    if (event.key === "Enter") signIn();
  }

  const signIn = () => {
    setShouldSignIn(true) 
  }

  return (
    <>
    {validating ? <Validate /> : <LandingPage headline={t('Login.txtTitle', {ns: 'screens_public'})}>
      <LogIn
        email={email}
        password={password}
        passwordVisible={passwordVisible}
        rememberLogin={rememberLogin}
        signIn={signIn}
        errorText={errorText}
        loading={loading}
        setEmail={(em) => setEmail(em)}
        setPassword={(pw) => setPassword(pw)}
        setPasswordVisible={(pwV) => setPasswordVisible(pwV)}
        setRememberLogin={(rL) => setRememberLogin(rL)}
        onKeyDown={onKeyDown}
        history={history}
      />
    </LandingPage>}
    </>
  )
}

Login.propTypes = propTypes;

function dispatchToProps(dispatch) {
  return bindActionCreators(
    {
      checkForExpiredSubscription,
      setExpiredSubscription,
      initApp,
      setUser,
      validate,
    },
    dispatch
  );
}

function stateToProps(state) {
  return {
    removedFromOrganization: state.getIn([
      "organizations",
      "removedFromOrganization",
    ]),
  };
}

export default connect(stateToProps, dispatchToProps)(Login);
