import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';

import { MenuItem } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  COMPONENTS,
  MATERIALS,
  DIMENSIONS,
  PIPE_DEFINITION,
} from "../../../../constants/catalogCategoryMapping";

import { EDIT_KEY, DELETE_KEY } from "../../../../constants/contextMenuEntries";

const propTypes = {
  row: PropTypes.object,
  closeMenu: PropTypes.func,
  dataComponent: PropTypes.string,
  onEditClicked: PropTypes.func,
  onDeleteClicked: PropTypes.func,
};

const ComponentCatalogActionMenuItems = ({
  row,
  closeMenu,
  dataComponent,
  onEditClicked,
  onDeleteClicked,
}) => {
  const { t } = useTranslation();

  const rowActions = React.useMemo(() => {
    return {
      [COMPONENTS]: [EDIT_KEY, DELETE_KEY],
      [MATERIALS]: [EDIT_KEY, DELETE_KEY],
      [DIMENSIONS]: [EDIT_KEY, DELETE_KEY],
      [PIPE_DEFINITION]: [DELETE_KEY],
    };
  });

  const renderRowActionMenuItems = (row, closeMenu) => {
    const menuItems = [];

    if (rowActions[dataComponent].includes(EDIT_KEY)) {
      menuItems.push(renderRowActionEditMenuItem(row, closeMenu));
    }
    if (rowActions[dataComponent].includes(DELETE_KEY)) {
      menuItems.push(renderRowActionDeleteMenuItem(row, closeMenu));
    }

    return menuItems;
  };

  const renderRowActionEditMenuItem = (row, closeMenu) => {
    return (
      <MenuItem
        key={1}
        onClick={() => {
          onEditClicked(row, dataComponent);
          closeMenu();
        }}
      >
        <EditIcon /> {t('ComponentCatalog.ComponentCatalogActionMenuItems.txtMenuItemEdit', {ns: 'screens_private'})}
      </MenuItem>
    );
  };

  const renderRowActionDeleteMenuItem = (row, closeMenu) => {
    return (
      <MenuItem
        key={2}
        onClick={() => {
          onDeleteClicked(row, dataComponent);
          closeMenu();
        }}
      >
        <DeleteIcon /> {t('ComponentCatalog.ComponentCatalogActionMenuItems.txtMenuItemDelete', {ns: 'screens_private'})}
      </MenuItem>
    );
  };

  return renderRowActionMenuItems(row, closeMenu);
};

ComponentCatalogActionMenuItems.propTypes = propTypes;
export default ComponentCatalogActionMenuItems;
