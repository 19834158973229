/* eslint-disable react/no-is-mounted */
import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from 'react-i18next';

import {
  deleteAvatar,
  uploadAvatar,
  updateUserDetails,
  requestAccountDeletion,
} from "../../../actions/user";
import {
  updateLanguage
} from "../../../actions/language";
import EditUserSettingsDialog from "../../../containers/dialogs/EditUserSettingsDialog";
import RequestAccountDeletionDialog from "../../../containers/dialogs/RequestAccountDeletionDialog";
import Container from "../../../components/layout/Container";
import Header from "../../../components/Header";
import DisplayUserProfile from "../../../components/userProfile/DisplayUserProfile";
import UserProfileSupport from "../../../components/userProfile/UserProfileSupport";
import UserProfileDocuments from "../../../components/userProfile/UserProfileDocuments";
import UserProfileAppSettings from "../../../components/userProfile/UserProfileAppSettings";
import UserProfileAccountDeletion from "../../../components/userProfile/UserProfileAccountDeletion";
import UserProfileLanguageSelection from "../../../components/userProfile/UserProfileLanguageSelection";
import Divider from "../../../atoms/Divider";

import * as styles from "../../../styles/styles";
import "./styles.css";

const propTypes = {
  user: PropTypes.object,
  organization: PropTypes.object,
  deleteAvatar: PropTypes.func,
  uploadAvatar: PropTypes.func,
  updateUserDetails: PropTypes.func,
  requestAccountDeletion: PropTypes.func,
  updateLanguage: PropTypes.func,
  language: PropTypes.string
};


const UserProfile = ({
  user,
  organization,
  deleteAvatar,
  uploadAvatar,
  updateUserDetails,
  requestAccountDeletion,
  updateLanguage,
  language
}) => {
  const { t, i18n } = useTranslation();
  const settings = user.toJS().settings.android;

  const [state, setState] = React.useState({
    avatar: user.getIn(["avatar", "original"]),
    firstName: user.get("first_name"),
    lastName: user.get("last_name"),
    settingsAndroid: settings,
    settingsChanged: false,
    editUser: false,
    firstNameValid: true,
    lastNameValid: true,
    avatarTooLarge: false,
    appSettingsModalOpened: false,
    requestAccountDeletionModalOpened: false,
  })

  const cancel = () => {
    const androidSettings = user.toJS().settings.android;

    setState({
      ...state,
      editUser: false,
      avatarTooLarge: false,
      firstName: user.get("first_name"),
      lastName: user.get("last_name"),
      avatar: user.getIn(["avatar", "original"]),
      settingsAndroid: androidSettings,
      settingsChanged: false,
    });
  }

  const updateEditUserFlag = (flag) => {
    setState({ ...state, editUser: flag });
  }

  const editUserDetails = () => {
    // Update firstName, lastName and Android-Settings here
    if (state.firstNameValid && state.lastNameValid) {
      const userDetails = {
        first_name: state.firstName.trim(),
        last_name: state.lastName.trim(),
        settings: {
          android: state.settingsAndroid,
        },
      };
      if (
        userDetails.first_name !== user.get("first_name") ||
        userDetails.last_name !== user.get("last_name") ||
        state.settingsChanged
      )
        updateUserDetails(userDetails);
    }

    // Update the Avatar here
    if (state.avatar !== null && typeof state.avatar === "object") {
      const newAvatar = new FormData();
      newAvatar.append(
        "avatar",
        state.avatar,
        state.avatar ? state.avatar.name : { user: { avatar: "" } }
      );
      uploadAvatar(newAvatar);

    } else if (state.avatar === null && user.getIn(["avatar", "original"])) {
      deleteAvatar();
    }

    // Reset the component state
    setState({
      ...state, 
      editUser: false,
      avatarTooLarge: false,
      settingsChanged: false,
    });
  }

  const createAvatarUrl = (avatar) => {
    if (avatar)
      return typeof avatar === "string" ? avatar : URL.createObjectURL(avatar);
    return avatar;
  }

  const onChangeAvatar = (avatar) => {
    setState({ ...state, avatar });
  }

  const onAvatarSizeToLarge = (avatarTooLarge) => {
    setState({ ...state, avatarTooLarge });
  }

  const onChangeName = (field, event) => {
    const name = event.target.value;
    const validKey = `${field}Valid`;
    setState({
      ...state, 
      [field]: name,
      [validKey]: name.length > 0,
    });
  }

  const onAppSettingsModelOpen = () => {
    setState({ ...state, appSettingsModalOpened: true });
  }
  const appSettingsModalClose = () => {
    setState({ ...state, appSettingsModalOpened: false });
  }

  const onRequestAccountDeletionModalOpen = () => {
    setState({ ...state, requestAccountDeletionModalOpened: true });
  }
  const onRequestAccountDeletionModalClose = () => {
    setState({ ...state, requestAccountDeletionModalOpened: false });
  }

  const editUserAppSettings = (settingsAndroid) => {
    if (state.firstNameValid && state.lastNameValid) {
      const userDetails = {
        first_name: state.firstName.trim(),
        last_name: state.lastName.trim(),
        settings: {
          android: settingsAndroid,
        },
      };
      updateUserDetails(userDetails);
    }

    setState({
      ...state, 
      editUser: false,
      avatarTooLarge: false,
      settingsChanged: false,
    });
  }

  const executeRequestAccountDeletion = () => {
    requestAccountDeletion(organization.get("id"));
  }

  const renderEditUserSettingDialog = () => {
    const androidSettings = user.toJS().settings.android;

    return (
      <EditUserSettingsDialog
        key={"EditUserSettingsDialog"}
        settings={androidSettings}
        modalOpen={state.appSettingsModalOpened}
        onNegativeButtonClicked={() => appSettingsModalClose()}
        onPositiveButtonClicked={(stateChanged, updatedSettings) => {
          setState({
            ...state, 
            settingsAndroid: updatedSettings,
            settingsChanged: stateChanged,
          });
          appSettingsModalClose();
          editUserAppSettings(updatedSettings);
        }}
      />
    );
  }

  const renderRequestAccountDeletionDialog = () => {
    return (
      <RequestAccountDeletionDialog
        key={"RequestAccountDeletionDialog"}
        modalOpen={state.requestAccountDeletionModalOpened}
        onNegativeButtonClicked={() =>
          onRequestAccountDeletionModalClose()
        }
        onPositiveButtonClicked={() => {
          onRequestAccountDeletionModalClose();
          executeRequestAccountDeletion();
        }}
      />
    );
  }

  const languageChanged = (language) => {
    i18n.changeLanguage(language);
    updateLanguage(language);
  }

  return (
    <>
      {state.appSettingsModalOpened && renderEditUserSettingDialog()}
      {state.requestAccountDeletionModalOpened &&
        renderRequestAccountDeletionDialog()}

      <Header showUserSettings={false}>
        <div></div>
      </Header>
      <Container fullAppHeight={true} divider={true}>
        <Typography
          color="primary"
          variant="h2"
          style={{ width: "100%", margin: `0 0 ${styles.spacing24} 0` }}
        >
          {t('userProfile.title', {ns: 'components'})}
        </Typography>

        <DisplayUserProfile
          avatar={state.avatar}
          avatarTooLarge={state.avatarTooLarge}
          firstName={state.firstName}
          firstNameValid={state.firstNameValid}
          lastName={state.lastName}
          lastNameValid={state.lastNameValid}
          editUser={state.editUser}
          email={user.get("email")}
          cancel={cancel}
          editUserDetails={editUserDetails}
          updateEditUserFlag={updateEditUserFlag}
          onChangeName={onChangeName}
          createAvatarUrl={createAvatarUrl}
          onAvatarSizeToLarge={onAvatarSizeToLarge}
          onChangeAvatar={onChangeAvatar}
        />

        <Divider />

        <UserProfileAppSettings modalOpen={onAppSettingsModelOpen} />

        <Divider />

        <UserProfileLanguageSelection 
          language={language}
          languageChanged={languageChanged}/>

        <Divider />

        <UserProfileDocuments />

        <Divider />

        <UserProfileSupport />

        <Divider />

        <UserProfileAccountDeletion
          user={user}
          modalOpen={onRequestAccountDeletionModalOpen}
        />
      </Container>
    </>
  );
}

function dispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteAvatar,
      uploadAvatar,
      updateUserDetails,
      requestAccountDeletion,
      updateLanguage
    },
    dispatch
  );
}

function stateToProps(state) {
  const organization = state.getIn(["organizations", "items"]).first();
  const user = state.get("user");
  const language = state.getIn(["language", "language"]);

  return {
    user,
    organization,
    language
  };
}

UserProfile.propTypes = propTypes;
export default connect(stateToProps, dispatchToProps)(UserProfile);
