/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';

import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";

import CustomDialog from "../../../components/CustomDialog";
import { validateTextFields } from "../../../sharedFunctions/validation";

import ButtonGroup from "../../../components/ButtonGroup";
import FileUploadComponent from "../../../components/FileUploadComponent";

const propTypes = {
    modalOpen: PropTypes.bool,
    onCloseModal: PropTypes.func,
    uploadProjectFile: PropTypes.func,
    maxSize: PropTypes.number,
    acceptFileTypes: PropTypes.string,
    acceptedFileExtensions: PropTypes.array
};

const FileUploadDialog = ({
    modalOpen,
    onCloseModal,
    uploadProjectFile,
    maxSize,
    acceptFileTypes,
    acceptedFileExtensions
}) => {
    const { t } = useTranslation();

    const [stateFiles, setFiles] = useState([])
    const [stateDisablePositiveButton, setDisablePositiveButton] = useState(true);

    const fileList = ""

    const validationValues = React.useMemo(() => {
        return {
          fileList: {
            value: "",
            required: true
          }
        };
      }, [
        fileList]);
    
    const getOldValues = () => {
        return {
            fileList: stateFiles.length === 0 ? "" : `${stateFiles.length}`
        };
    };

    const fileSelected = (files) => {
        const name = files.length > 0 ? `${stateFiles.length + 1}` : "";
        const newValues = { ...getOldValues(), fileList: name };
        setFiles(files);
        setDisablePositiveButton(validateTextFields(validationValues, newValues));
    }

    const handleOnPositiveButtonClicked = () => {
        if (stateFiles.length > 0) {
            const newProjectFileFormData = new FormData();
            newProjectFileFormData.append(
                "project_file[file]",
                stateFiles[0],
                stateFiles[0].name
            );
            uploadProjectFile(newProjectFileFormData)
        }
    }

    return (
    <>
        <CustomDialog
            open={modalOpen}
            onClick={handleOnPositiveButtonClicked}
            onClose={onCloseModal}
            renderTitle={() => {
                return (
                    <DialogTitle id="alert-dialog-title">
                        {t('dialogs.FileUploadDialog.txtDialogTitle', {ns: 'containers'})}
                    </DialogTitle>
                );
            }}
            renderContent={() => {
                return (
                    <DialogContent>
                        <FileUploadComponent 
                            onFilesSelected={(files) => {
                                fileSelected(files)
                            }}
                            acceptFileTypes={acceptFileTypes}
                            acceptedFileExtensions={acceptedFileExtensions}
                            maxSize={maxSize}
                            titleText={t('dialogs.FileUploadDialog.txtFileUploadComponentTitle', {ns: 'containers'})}
                            bodyText={t('dialogs.FileUploadDialog.txtFileUploadComponentBody', {maxSize: maxSize, ns: 'containers'})}/>
                    </DialogContent>
                );
            }}
            renderButtonGroup={(
                handleOnPositiveButtonClicked,
                onCloseModal
              ) => {
                return (
                  <DialogActions style={{ padding: "2.4rem 2.4rem 2rem" }}>
                    <ButtonGroup
                      align="right"
                      leftButtonText={t('button.btnCancel', {ns: 'common'})}
                      leftButtonClick={onCloseModal}
                      rightButtonText={t('button.btnCreate', {ns: 'common'})}
                      rightButtonClick={handleOnPositiveButtonClicked}
                      rightButtonDisabled={stateDisablePositiveButton}
                      spacingTop={false}
                    />
                  </DialogActions>
                );
              }}
        />
    </>
)}

FileUploadDialog.propTypes = propTypes;
export default FileUploadDialog