/* eslint-disable import/namespace */
/* eslint-disable no-unused-vars */
import { createAction } from "redux-actions";
import {
  EDIT_PROJECT,
  REMOVE_PROJECT,
  SET_PROJECT,
  SET_PROJECT_STATUS,
  SET_PROJECTS,
  RESET_PROJECT,
  GEOCODE_ADDRESS,
  SET_PROJECT_JOB_ORDER_VALID,
} from "../constants/actions/actions";
import { STATE_ARCHIVED } from "../constants/project";
import {
  PROJECT_CREATE_EVENT,
  PROJECT_ASSIGNED_EVENT,
  PROJECT_DEASSIGNED_EVENT,
  PROJECT_UPDATE_EVENT,
  PROJECT_DELETE_EVENT,
  PROJECT_RESET_EVENT,
} from "../constants/channelevents";
import {
  assignProjectSuccess,
  assignProjectFail,
  createProjectFail,
  createProjectSuccess,
  createProjectFailOnlyOneProjectAllowed,
  createProjectFailJobOrderAlreadyUsed,
  deleteProjectSuccess,
  deleteProjectFail,
  deleteProjectsFail,
  updateProjectFail,
  updateProjectSuccess,
  resetProjectFail,
  resetProjectSuccess,
} from "../constants/snackbars";
import { LAYER_TYPE_NAME_MAP } from "../constants/measureLayerConfig";

import { setMeasurements, removeMeasurements } from "../actions/measurements";
import {
  setMeasurementLines,
  removeMeasurementLines,
} from "../actions/measurementlines";
import {
  setMeasurementSegments,
  removeMeasurementSegments,
} from "../actions/measurementsegments";
import {
  setMediaFiles,
  removeMediaFiles,
  archivedRemoveMediaFiles,
} from "../actions/mediaFiles";
import { setVolumes, removeVolumes } from "../actions/volumes";
import {
  setTopographicPoints,
  removeTopographicPoints,
} from "../actions/topographic_points";
import {
  setMeasureLayerConfigs,
  createMeasureLayerConfigs,
} from "../actions/measure_layer_configs";

import { copyMeasureLayerConfigTemplateToProject } from "../actions/measureLayerConfigTemplates";

import API from "../lib/api";
import HTTP from "../lib/http";
import MAPBOX_HTTP from "../lib/mapboxHttp";
import { getOrgaId } from "../sharedFunctions/organization";
import { isAdmin } from "../sharedFunctions/user";
import { hideDummyProject, showDummyProject } from "./dashboard";
import { unselectProjects } from "./data";
import { renderSnackbar } from "./snackbars";

export const setProjects = createAction(SET_PROJECTS);
export const setProject = createAction(SET_PROJECT);
export const editProject = createAction(EDIT_PROJECT);
export const removeProject = createAction(REMOVE_PROJECT);
export const setProjectStatus = createAction(SET_PROJECT_STATUS);
export const handleResetProjectEvent = createAction(RESET_PROJECT);
export const handleGeocodeAddress = createAction(GEOCODE_ADDRESS);
export const setProjectJobOrderValid = createAction(
  SET_PROJECT_JOB_ORDER_VALID
);

export const CableActions = (message, connection, store) => {
  switch (message.event) {
    case PROJECT_CREATE_EVENT:
      store.dispatch(
        setProject({
          id: message.payload.project.id,
          item: message.payload.project,
        })
      );
      break;

    case PROJECT_ASSIGNED_EVENT: {
      // Split the payload into it's different parts
      // and do multiple dispatches to the store.
      const project = message.payload.project;
      const measurements = project.measurements;
      const measurementLines = project.measurement_lines;
      const measurementSegments = project.measurement_segments;
      const mediaFiles = project.media_files;
      const volumes = project.volumes;
      const measureLayerConfigs = project.measure_layer_configs;
      const topographic_points = project.topographic_points;

      store.dispatch(
        setProject({
          id: project.id,
          item: project,
        })
      );
      store.dispatch(setMeasurements(measurements));
      store.dispatch(setMeasurementLines(measurementLines));
      store.dispatch(setMeasurementSegments(measurementSegments));
      store.dispatch(setMediaFiles(mediaFiles));
      store.dispatch(setVolumes(volumes));
      store.dispatch(setMeasureLayerConfigs(measureLayerConfigs));
      store.dispatch(setTopographicPoints(topographic_points));
      break;
    }

    case PROJECT_DEASSIGNED_EVENT:
      if (
        !isAdmin(
          store.getState().getIn(["user", "organization_role_groups"]),
          true
        ) &&
        store.getState().getIn(["user", "id"]) !==
          message.payload.project.user_id
      ) {
        store.dispatch(
          unselectProjects([message.payload.project.id.toString()])
        );
        store.dispatch(
          removeProject({
            id: message.payload.project.id,
            item: message.payload.project,
          })
        );
      }
      break;

    case PROJECT_UPDATE_EVENT:
      store.dispatch(
        editProject({
          id: message.payload.project.id,
          item: message.payload.project,
        })
      );
      if (message.payload.project.state === STATE_ARCHIVED) {
        store.dispatch(archivedRemoveMediaFiles(message.payload.project));
      }
      break;

    case PROJECT_DELETE_EVENT:
      store.dispatch(unselectProjects([message.payload.project.id.toString()]));
      store.dispatch(
        removeProject({
          id: message.payload.project.id,
          item: message.payload.project,
        })
      );

      store.dispatch(handleResetProjectEvent(message.payload.project));
      store.dispatch(removeMeasurements(message.payload.project));
      store.dispatch(removeMeasurementLines(message.payload.project));
      store.dispatch(
        removeMeasurementSegments({
          project: message.payload.project,
          measurementLines: getFilteredMeasuementLines(
            store,
            getProjectIdInt(message)
          ),
        })
      );
      store.dispatch(removeMediaFiles(message.payload.project));
      store.dispatch(removeVolumes(message.payload.project));
      store.dispatch(removeTopographicPoints(message.payload.project));

      break;

    case PROJECT_RESET_EVENT:
      store.dispatch(handleResetProjectEvent(message.payload.project));
      store.dispatch(removeMeasurements(message.payload.project));
      store.dispatch(removeMeasurementLines(message.payload.project));
      store.dispatch(
        removeMeasurementSegments({
          project: message.payload.project,
          measurementLines: getFilteredMeasuementLines(
            store,
            getProjectIdInt(message)
          ),
        })
      );
      store.dispatch(removeMediaFiles(message.payload.project));
      store.dispatch(removeVolumes(message.payload.project));
      store.dispatch(removeTopographicPoints(message.payload.project));
      break;

    default:
      break;
  }
};

function getProjectIdInt(message) {
  return parseInt(message.payload.project.id);
}

function getFilteredMeasuementLines(store, projectIdInt) {
  return store
    .getState()
    .getIn(["measurementLines", "items"])
    .filter((item) => item.get("project_id") === projectIdInt);
}

export function validateProjectJobOrder(jobOrder) {
  return async (dispatch, getState) => {
    const orgaId = getOrgaId(getState());
    await HTTP.post(API.organizations.validateProjectJobOrder(orgaId), {
      params: {
        job_order: jobOrder,
      },
    })
      .then(function (response) {
        dispatch(setProjectJobOrderValid(true));
      })
      .catch(function (error) {
        dispatch(setProjectJobOrderValid(false));
      });
  };
}

export function deleteProject(projectId) {
  return async (dispatch, getState) => {
    await HTTP.delete(API.projects.member(projectId))
      .then(function (response) {
        dispatch(renderSnackbar(deleteProjectSuccess));
      })
      .catch(function (error) {
        dispatch(renderSnackbar(deleteProjectFail));
        console.log("UPDATE PROJECT ERROR", error);
      });
  };
}

export function deleteProjects(projects) {
  return async (dispatch, getState) => {
    const projectArr = [].concat(projects);
    await Promise.all(
      projectArr.map(async (projectId) => {
        HTTP.delete(API.projects.member(projectId)).catch(function (error) {
          console.log("DELETE PROJECT ERROR", error);
          dispatch(renderSnackbar(deleteProjectsFail));
        });
      })
    );
  };
}

export function createNewProject(project, measureLayerConfigTemplateUuid) {
  return async (dispatch, getState) => {
    const orgaId = getOrgaId(getState());
    dispatch(showDummyProject());

    HTTP.post(API.organizations.createProject(orgaId), { project })
      .then(function (response) {
        if (measureLayerConfigTemplateUuid.length > 0) {
          // Copy Layer from Template.
          const data = {
            params: {
              uuid: measureLayerConfigTemplateUuid,
            },
          };
          dispatch(
            copyMeasureLayerConfigTemplateToProject(response.data.project.id, data)
          );
        } else {
          // Create default Layer UseCase.
          const refLayer = {
            color: "indigo",
            layer_name: LAYER_TYPE_NAME_MAP[2],
            layer_type: 2,
            required: true,
          };
          const measureLineLayer = {
            color: "purple",
            layer_name: LAYER_TYPE_NAME_MAP[1],
            layer_type: 1,
            required: true,
          };
          dispatch(
            createMeasureLayerConfigs(response.data.project, [
              refLayer,
              measureLineLayer,
            ])
          );
        }
      })
      .then(function (response) {
        dispatch(renderSnackbar(createProjectSuccess));
      })
      .catch(function (error) {
        console.log(error.response.data.error);
        switch (error.response.data.error) {
          case "only one project allowed":
            dispatch(renderSnackbar(createProjectFailOnlyOneProjectAllowed));
            break;
          case "job order already used":
            dispatch(renderSnackbar(createProjectFailJobOrderAlreadyUsed));
            break;
          default:
            dispatch(renderSnackbar(createProjectFail));
            break;
        }
      })
      .finally(function () {
        dispatch(hideDummyProject());
      });
  };
}

export function updateProject(project) {
  return async (dispatch, getState) => {
    await HTTP.put(API.projects.member(project.id), { project })
      .then(function (response) {
        dispatch(renderSnackbar(updateProjectSuccess));
      })
      .catch(function (error) {
        dispatch(renderSnackbar(updateProjectFail));
        console.log("UPDATE PROJECT ERROR", error);
      });
  };
}

export function updateProjects(projects) {
  return async (dispatch, getState) => {
    const projectArr = [].concat(projects);
    await Promise.all(
      projectArr.map(async (project) => {
        HTTP.put(API.projects.member(project.id), { project }).catch(function (
          error
        ) {
          console.log("UPDATE PROJECT ERROR", error);
          dispatch(renderSnackbar(deleteProjectsFail));
        });
      })
    );
  };
}

export function assignUser(project, user) {
  return async (dispatch, getState) => {
    await HTTP.post(API.projects.assignUser(project.id), {
      project: { assigned_user_id: user.id },
    })
      .then(function (response) {
        dispatch(renderSnackbar(assignProjectSuccess));
      })
      .catch(function (error) {
        dispatch(renderSnackbar(assignProjectFail));
        console.log("ASSIGN PROJECT ERROR", error);
      });
  };
}

export function resetProject(projectId) {
  return async (dispatch, getState) => {
    await HTTP.post(API.projects.resetProject(projectId))
      .then(function (response) {
        dispatch(renderSnackbar(resetProjectSuccess));
      })
      .catch(function (error) {
        dispatch(renderSnackbar(resetProjectFail));
        console.log("ASSIGN PROJECT ERROR", error);
      });
  };
}

export function geocodeAddress(projectId, address, token) {
  return async (dispatch, getState) => {
    await MAPBOX_HTTP.get(API.projects.geocodeAddress(address, token))
      .then(function (response) {
        dispatch(
          handleGeocodeAddress({
            projectId: projectId,
            response: response,
          })
        );
      })
      .catch(function (error) {});
  };
}
