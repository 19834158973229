import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";

import SearchButton from "../";
import SearchResultsSmall from "./SearchResultsSmall";

import "./styles.css";

const propTypes = {
  getResults: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onClickOutside: PropTypes.func.isRequired,
  projects: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  results: PropTypes.object.isRequired,
  searchTerm: PropTypes.string.isRequired,
  search: PropTypes.func.isRequired,
  searchKeyList: PropTypes.object,
  placeholderText: PropTypes.string,
};

const SearchButtonResults = ({
  getResults,
  onClick,
  onClickOutside,
  projects,
  users,
  results,
  searchTerm,
  search,
  searchKeyList,
  placeholderText = "",
}) => {
  const clickOutside = useRef(null);

  const handleClickOutside = () => {
    onClickOutside();
  };

  const handleClick = (event) => {
    if (clickOutside.current && !clickOutside.current.contains(event.target)) {
      handleClickOutside();
    }
  };

  useEffect(() => {
    // The first call acts like componentDidMount
    document.addEventListener("click", handleClick, false);

    // If th ecomponent will be unmounted useEffect returns this
    return () => {
      document.removeEventListener("click", handleClick, false);
    };
  }, []); // If the Dependency-Array is empty, the useEffect acts like componentDidMount/componentWillUnmount

  return (
    <div ref={clickOutside}>
      <SearchButton
        showResults={true}
        projects={projects}
        users={users}
        results={getResults}
        searchTerm={searchTerm}
        searchKeyList={searchKeyList}
        search={search}
        placeholderText={placeholderText}
      >
        <SearchResultsSmall
          classNameContainer="project-search-position"
          onClick={onClick}
          projects={results}
        />
      </SearchButton>
    </div>
  );
};

SearchButtonResults.propTypes = propTypes;
export default SearchButtonResults;
