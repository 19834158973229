/* eslint-disable import/namespace */
/* eslint-disable no-case-declarations */
import { createAction } from "redux-actions";
import {
  CHECK_ACTIVE_SUBSCRIPTION,
  EDIT_ORGANIZATION,
  EDIT_ORGANIZATION_USER,
  REMOVE_ORGANIZATION,
  SET_EXPIRED_SUBSCRIPTION,
  SET_ORGANIZATION,
  SET_ORGANIZATIONS,
  SET_REMOVED_FROM_ORGANIZATION,
} from "../constants/actions/actions";
import {
  ORGANIZATION_UPDATE_EVENT,
  ORGANIZATION_USER_UPDATE_EVENT,
} from "../constants/channelevents";
import API from "../lib/api";

import HTTP from "../lib/http";
import { initApp } from "../lib/init";
import { getOrgaId } from "../sharedFunctions/organization";

export const setOrganizations = createAction(SET_ORGANIZATIONS);
export const setOrganization = createAction(SET_ORGANIZATION);
export const editOrganization = createAction(EDIT_ORGANIZATION);
export const editOrganizationUser = createAction(EDIT_ORGANIZATION_USER);
export const removeOrganization = createAction(REMOVE_ORGANIZATION);
export const setExpiredSubscription = createAction(SET_EXPIRED_SUBSCRIPTION);
export const setRemovedFromOrganization = createAction(
  SET_REMOVED_FROM_ORGANIZATION
);
const checkActiveSubsription = createAction(CHECK_ACTIVE_SUBSCRIPTION);

export const CableActions = (response, ac, store) => {
  switch (response.event) {
    case "organization_create":
      store.dispatch(
        setOrganization({
          id: response.payload.organization.id,
          item: response.payload.organization,
        })
      );
      break;

    case ORGANIZATION_UPDATE_EVENT:
      store.dispatch(
        editOrganization({
          id: response.payload.organization.id,
          item: response.payload.organization,
        })
      );
      if (!response.payload.organization.active) {
        store.dispatch(setExpiredSubscription(true));
      } else {
        if (store.getState().getIn(["organizations", "subscriptionExpired"]))
          store.dispatch(initApp()).then(() => {
            store.dispatch(setExpiredSubscription(false));
          });
      }
      break;

    case ORGANIZATION_USER_UPDATE_EVENT:
      const organizationId = response.topic.split(":")[1];
      store.dispatch(
        editOrganizationUser({
          organizationId,
          user: response.payload,
        })
      );
      break;

    case "organization_delete":
      store.dispatch(
        removeOrganization({
          id: response.payload.organization.id,
          item: response.payload.organization,
        })
      );
      break;
    default:
      break;
  }
};

export function checkForExpiredSubscription(controller) {
  return async (dispatch, getState) => {
    const orgaId = getOrgaId(getState());
    dispatch(checkActiveSubsription());
    if (orgaId) {
      try {
        const signal = controller.signal;
        const response = await HTTP.get(
          API.organizations.getActiveSubscription(orgaId),
          { signal }
        );
        return response;
      } catch (error) {
        console.log("ERROR GET ACTIVE SUBSCRIPTIONS", error);
        return error.response;
      }
    } else {
      // fail
    }
  };
}
