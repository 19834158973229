import i18next from '../i18n';

i18next.on('languageChanged init', () => {
  init()
})

const t = (textCode, options) => {
  return i18next.t(textCode, options);
}

export const LAYER_TYPE_LINE_IDX = 1;
export const LAYER_TYPE_REFERENCE_POINT_IDX = 2;
export const LAYER_TYPE_POINT_IDX = 3;
export const LAYER_TYPE_GNSS_POINT_IDX = 7;
export const LAYER_TYPE_GNSS_LINE_IDX = 8;
export const LAYER_TYPE_GNSS_POLYGON_IDX = 9;
export const LAYER_TYPE_GNSS_CONTINUOUS_LINE_IDX = 10;
export const LAYER_TYPE_GNSS_REF_POINT_IDX = 11;

export const LAYER_TYPE_NAME_MAP = {};

const init = () => {
  LAYER_TYPE_NAME_MAP[1] = t('measureLayerConfig.txtLayerType1', {ns: 'constants'})
  LAYER_TYPE_NAME_MAP[2] = t('measureLayerConfig.txtLayerType2', {ns: 'constants'})
  LAYER_TYPE_NAME_MAP[3] = t('measureLayerConfig.txtLayerType3', {ns: 'constants'})
  LAYER_TYPE_NAME_MAP[7] = t('measureLayerConfig.txtLayerType7', {ns: 'constants'})
  LAYER_TYPE_NAME_MAP[8] = t('measureLayerConfig.txtLayerType8', {ns: 'constants'})
  LAYER_TYPE_NAME_MAP[9] = t('measureLayerConfig.txtLayerType9', {ns: 'constants'})
  LAYER_TYPE_NAME_MAP[10] = t('measureLayerConfig.txtLayerType10', {ns: 'constants'})
  LAYER_TYPE_NAME_MAP[11] = t('measureLayerConfig.txtLayerType11', {ns: 'constants'})
}