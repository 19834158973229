import i18next from '../i18n';

export const COMPONENTS = "components";
export const COMPONENT = "component";
export const MATERIALS = "materials";
export const MATERIAL = "material";
export const DIMENSIONS = "dimensions";
export const DIMENSION = "dimension";
export const PIPE_DEFINITION = "pipe_definition";
export const PIPE_DEFINITIONS = "pipe_definitions";

export const CATALOG_CATEGORY_MAPPING = {}

i18next.on('languageChanged init', () => {
  init()
})

const t = (textCode, options) => {
  return i18next.t(textCode, options);
}

const init = () => {
  CATALOG_CATEGORY_MAPPING.components = t('catalogCategoryMapping.txtComponents', {ns: 'constants'})
  CATALOG_CATEGORY_MAPPING.component = t('catalogCategoryMapping.txtComponent', {ns: 'constants'})
  CATALOG_CATEGORY_MAPPING.materials = t('catalogCategoryMapping.txtMaterials', {ns: 'constants'})
  CATALOG_CATEGORY_MAPPING.material = t('catalogCategoryMapping.txtMaterial', {ns: 'constants'})
  CATALOG_CATEGORY_MAPPING.dimensions = t('catalogCategoryMapping.txtDimensions', {ns: 'constants'})
  CATALOG_CATEGORY_MAPPING.dimension = t('catalogCategoryMapping.txtDimension', {ns: 'constants'})
  CATALOG_CATEGORY_MAPPING.pipe_definition = t('catalogCategoryMapping.txtPipeDefinition', {ns: 'constants'})
  CATALOG_CATEGORY_MAPPING.pipe_definitions = ""
}

export const PAYLOAD_CATALOG_COMPONENT = "catalog_component";
export const PAYLOAD_CATALOG_MATERIAL = "catalog_material";
export const PAYLOAD_CATALOG_DIMENSION = "catalog_dimension";
