/* eslint-disable no-unused-vars */
import withStyles from "@mui/styles/withStyles";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

import Switch from "@mui/material/Switch";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import BuildIcon from "@mui/icons-material/Build";
import PlaceIcon from "@mui/icons-material/Place";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import moment from "moment";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid2";
import Tooltip from "@mui/material/Tooltip";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { hideDummyProject, showDummyProject } from "../../../actions/dashboard";
import {
  parseStreetNameFromAddress,
  parseZipCodeFromAddress,
  parseCityFromAddress,
} from "../../../sharedFunctions/project/parser";
import { serializeAddress } from "../../../sharedFunctions/project/serializer";

import {
  createNewProject,
  updateProject,
  validateProjectJobOrder,
} from "../../../actions/projects";
import ButtonGroup from "../../../components/ButtonGroup";
import CustomDialog from "../../../components/CustomDialog";
import HintView from "../../../components/HintView";
import { HINT_UPDATE_BEHAVIOUR } from "../../../constants/hinttypesmapping";
import { validateTextFields } from "../../../sharedFunctions/validation";
import { hasOrganizationFeatureFlag } from "../../../sharedFunctions/organization";
import { PROJECT_RESTRICTED_SINGLE } from "../../../constants/featureFlags";

import * as projectConst from "../../../constants/project";
import * as styles from "../../../styles/styles";

const propTypes = {
  classes: PropTypes.object,
  createNewProject: PropTypes.func,
  edit: PropTypes.bool,
  modalOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
  project: PropTypes.object,
  rightButton: PropTypes.string,
  updateProject: PropTypes.func,
  validateProjectJobOrder: PropTypes.func,
  isProjectRestrictedSingle: PropTypes.bool,
  isProjectJobOrderValid: PropTypes.bool,
  measureLayerConfigTemplates: PropTypes.array,
  measureLayerConfigItems: PropTypes.array,
};

const overrideStyles = (theme) => ({
  alignText: {
    margin: "auto 0 0 0",
  },
});

const ProjectForm = ({
  classes,
  createNewProject,
  edit = false,
  modalOpen,
  onClick,
  onClose,
  project = {
    title: "",
    description: "",
    jobNumber: "",
    place: {},
  },
  updateProject,
  validateProjectJobOrder,
  isProjectRestrictedSingle,
  isProjectJobOrderValid = false,
  measureLayerConfigTemplates,
  measureLayerConfigItems,
}) => {
  const { t } = useTranslation();
  
  isProjectJobOrderValid = edit ? true : isProjectJobOrderValid;

  const { title, description, job_order, due_date, state } = project;
  const street_name = parseStreetNameFromAddress(project.place);
  const zip_code = parseZipCodeFromAddress(project.place);
  const city = parseCityFromAddress(project.place);

  const [stateStreetName, setStreetName] = useState(street_name);
  const [stateZipCode, setZipCode] = useState(zip_code);
  const [stateCity, setCity] = useState(city);
  const [selectedDate, setDate] = useState(
    project.due_date != null ? moment(project.due_date) : null
  );
  const [stateTitle, setTitle] = useState(title ? title : "");
  const [stateDescription, setDescription] = useState(
    description ? description : ""
  );
  const [stateJobOrder, setJobOrder] = useState(job_order ? job_order : "");
  const [stateDisablePositiveButton, setDisablePositiveButton] = useState(true);
  const [stateState, setState] = useState(state);
  const [toggleArchiveInfo, setToggleArchiveInfo] = useState(false);
  const [timeoutState, setTimeoutState] = React.useState({});

  const [
    stateMeasureLayerConfigTemplateUuid,
    setMeasureLayerConfigTemplateUuid,
  ] = useState("");

  const dateFormatter = (str) => {
    return str;
  };

  const validationValues = React.useMemo(() => {
    return {
      title: {
        value: title ? title : "",
        required: true,
      },
      job_order: {
        value: job_order ? job_order : "",
        required: true,
      },
      description: {
        value: description ? description : "",
        required: true,
      },
      due_date: {
        value:
          due_date != null ? moment(due_date).format("dd. DD.MM.YYYY") : null,
        required: false,
      },
      street_name: {
        value: street_name,
        required: false,
      },
      zip_code: {
        value: zip_code,
        required: false,
      },
      city: {
        value: city,
        required: false,
      },
      state: {
        value: state,
        required: false,
      },
    };
  }, [
    title,
    job_order,
    description,
    due_date,
    street_name,
    zip_code,
    city,
    state,
  ]);

  const getOldValues = () => {
    return {
      title: stateTitle,
      job_order: stateJobOrder,
      description: stateDescription,
      due_date: selectedDate,
      street_name: stateStreetName,
      zip_code: stateZipCode,
      city: stateCity,
      state: stateState,
    };
  };

  const onChangeTemplateUuid = (event) => {
    const newTemplateUuid = event.target.value;
    setMeasureLayerConfigTemplateUuid(newTemplateUuid);
  };

  const onDateChange = (date, value) => {
    const due_date = date != null ? date.format("dd. DD.MM.YYYY") : null;
    const newValues = { ...getOldValues(), due_date: due_date };
    setDate(date);
    setDisablePositiveButton(validateTextFields(validationValues, newValues));
  };

  const changeStreetName = (event) => {
    const streetName = event.target.value;
    const newValues = { ...getOldValues(), street_name: streetName };
    setStreetName(streetName);
    setDisablePositiveButton(validateTextFields(validationValues, newValues));
  };

  const changeZipCode = (event) => {
    const zipCode = event.target.value;
    const newValues = { ...getOldValues(), zip_code: zipCode };
    setZipCode(zipCode);
    setDisablePositiveButton(validateTextFields(validationValues, newValues));
  };

  const changeCity = (event) => {
    const city = event.target.value;
    const newValues = { ...getOldValues(), city: city };
    setCity(city);
    setDisablePositiveButton(validateTextFields(validationValues, newValues));
  };

  const changeTitle = (event) => {
    const title = event.target.value;
    const newValues = { ...getOldValues(), title: title };
    setTitle(title);
    setDisablePositiveButton(validateTextFields(validationValues, newValues));
  };

  const changeDescription = (event) => {
    const description = event.target.value;
    const newValues = { ...getOldValues(), description: description };
    setDescription(description);
    setDisablePositiveButton(validateTextFields(validationValues, newValues));
  };

  const changeJobNumber = (event) => {
    const jobNumber = event.target.value;
    const newValues = { ...getOldValues(), job_order: jobNumber };
    setJobOrder(jobNumber);
    setDisablePositiveButton(validateTextFields(validationValues, newValues));

    clearTimeout(timeoutState);
    setTimeoutState(
      setTimeout(() => {
        validateProjectJobOrder(jobNumber);
      }, 500)
    );
  };

  const changeState = (event) => {
    let state = projectConst.STATE_DONE;
    if (event.target.checked) {
      state = projectConst.STATE_ARCHIVED;
    }
    const newValues = { ...getOldValues(), state: state };
    setState(state);
    setToggleArchiveInfo(!toggleArchiveInfo);
    setDisablePositiveButton(validateTextFields(validationValues, newValues));
  };

  const executeOnClick = () => {
    if (edit) {
      const updatedProject = {
        title: stateTitle.trim(),
        description: stateDescription.trim(),
        job_order: stateJobOrder.trim(),
        due_date:
          selectedDate != null
            ? selectedDate.format("yyyy-MM-DD HH:mm:ss")
            : "",
        id: project.id,
        place: serializeAddress(stateStreetName, stateZipCode, stateCity),
        state: stateState,
      };
      updateProject(updatedProject);
      onClick();
    } else {
      const newProject = {
        title: stateTitle.trim(),
        description: stateDescription.trim(),
        job_order: stateJobOrder.trim(),
        due_date:
          selectedDate != null
            ? selectedDate.format("yyyy-MM-DD HH:mm:ss")
            : "",
        place: serializeAddress(stateStreetName, stateZipCode, stateCity),
      };
      createNewProject(newProject, stateMeasureLayerConfigTemplateUuid);
      onClick();
    }
  };

  const renderTitleElement = () => {
    return (
      (<TextField
        fullWidth={true}
        id="outlined-full-width"
        label={t('dialogs.ProjectForm.textFieldTitleLabel', {ns: 'containers'})}
        margin="none"
        multiline={false}
        helperText={`${stateTitle.length}/255`}
        onChange={changeTitle}
        placeholder={t('dialogs.ProjectForm.textFieldTitlePlaceholder', {ns: 'containers'})}
        required={true}
        style={{ margin: `0 0 ${styles.spacing24} 0` }}
        variant="outlined"
        value={stateTitle}
        slotProps={{
          htmlInput: {
            maxLength: 255,
          }
        }}
      />)
    );
  };

  const renderJobOrderElement = () => {
    return (
      (<TextField
        error={!isProjectJobOrderValid}
        id="outlined-full-width"
        fullWidth={true}
        label={t('dialogs.ProjectForm.textFieldJobOrderLabel', {ns: 'containers'})}
        margin="none"
        multiline={false}
        helperText={
          !isProjectJobOrderValid && stateJobOrder.length > 0
            ? t('dialogs.ProjectForm.textFieldJobOrderHelperText', {ns: 'containers'})
            : `${stateJobOrder.length}/255`
        }
        onChange={changeJobNumber}
        placeholder={t('dialogs.ProjectForm.textFieldJobOrderPlaceholder', {ns: 'containers'})}
        required={true}
        style={{ margin: `0 0 ${styles.spacing24} 0` }}
        value={stateJobOrder}
        variant="outlined"
        slotProps={{
          htmlInput: {
            maxLength: 255,
          }
        }}
      />)
    );
  };

  const renderDescriptionElement = () => {
    return (
      (<TextField
        fullWidth={true}
        margin="none"
        label={t('dialogs.ProjectForm.textFieldDescriptionLabel', {ns: 'containers'})}
        multiline={true}
        onChange={changeDescription}
        placeholder={t('dialogs.ProjectForm.textFieldDescriptionPlaceholder', {ns: 'containers'})}
        required={true}
        style={{ margin: `0 0 ${styles.spacing24} 0` }}
        minRows="5"
        variant="outlined"
        value={stateDescription}
        slotProps={{
          input: { classes: { input: classes.alignText } }
        }}
      />)
    );
  };

  const renderDueDateElement = () => {
    return (
      <>
        <Grid size={11}>
          <div style={{ margin: `0 0 ${styles.spacing24} 0` }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                style={{ width: "100%" }}
                autoOk={true}
                showTodayButton={true}
                value={selectedDate}
                format="dd. DD.MM.YYYY"
                onChange={onDateChange}
                rifmFormatter={dateFormatter}
                slotProps= {{
                  textField: {
                    variant: "outlined"
                  }
                }}
              />
            </LocalizationProvider>
          </div>
        </Grid>
      </>
    );
  };

  const generateTooltipText = (element) => {
    const filteredData = measureLayerConfigItems.filter((item) => {
      return element.item.uuid === item.rootUuid;
    });

    return t('dialogs.ProjectForm.textFieldDescriptionLabel', {layerCount: filteredData[0].items.length, ns: 'containers'});
  };

  const renderTemplateElement = () => {
    const menuItems = [];

    measureLayerConfigTemplates.forEach((element) => {
      menuItems.push(
        <MenuItem
          key={`${element.rootUuid}_${element.item.uuid}`}
          value={element.item.uuid}
        >
          <Tooltip enterDelay={1000} title={generateTooltipText(element)}>
            <div style={{ marginLeft: "1.5rem" }}>{element.item.name}</div>
          </Tooltip>
        </MenuItem>
      );
    });

    return (
      <FormControl
        style={{ margin: `0 0 ${styles.spacing24} 0` }}
        fullWidth={true}
        size="medium"
      >
        <InputLabel id="select-template-label">
          {t('dialogs.ProjectForm.txtInputLabelProjectTemplate', {ns: 'containers'})}
        </InputLabel>
        <Select
          label={t('dialogs.ProjectForm.txtSelectProjectTemplate', {ns: 'containers'})}
          labelId="select-template-label"
          id="select-template"
          value={stateMeasureLayerConfigTemplateUuid}
          onChange={onChangeTemplateUuid}
          defaultValue={""}
        >
          {menuItems}
        </Select>
      </FormControl>
    );
  };

  const renderAddressElement = () => {
    return (<>
      <Grid size={11}>
        <TextField
          fullWidth={true}
          margin="none"
          label={t('dialogs.ProjectForm.textFieldStreetNameLabel', {ns: 'containers'})}
          multiline={false}
          onChange={changeStreetName}
          placeholder={t('dialogs.ProjectForm.textFieldStreetNamePlaceholder', {ns: 'containers'})}
          required={false}
          style={{ margin: `0 0 ${styles.spacing16} 0` }}
          variant="outlined"
          value={stateStreetName}
          slotProps={{
            input: { classes: { input: classes.alignText } }
          }}
        />
      </Grid>
      <Grid container spacing={1}>
        <Grid size={3}>
          <TextField
            fullWidth={true}
            margin="none"
            label={t('dialogs.ProjectForm.textFieldZipCodeLabel', {ns: 'containers'})}
            multiline={false}
            onChange={changeZipCode}
            placeholder={t('dialogs.ProjectForm.textFieldZipCodePlaceholder', {ns: 'containers'})}
            required={false}
            style={{ margin: `0 0 ${styles.spacing24} 0` }}
            variant="outlined"
            value={stateZipCode}
            slotProps={{
              input: { classes: { input: classes.alignText } }
            }}
          />
        </Grid>
        <Grid size={8}>
          <TextField
            fullWidth={true}
            margin="none"
            label={t('dialogs.ProjectForm.textFieldCityLabel', {ns: 'containers'})}
            multiline={false}
            onChange={changeCity}
            placeholder={t('dialogs.ProjectForm.textFieldCityPlaceholder', {ns: 'containers'})}
            required={false}
            style={{ margin: `0 0 ${styles.spacing24} 0` }}
            variant="outlined"
            value={stateCity}
            slotProps={{
              input: { classes: { input: classes.alignText } }
            }}
          />
        </Grid>
      </Grid>
    </>);
  };

  const renderArchiveProjectControl = () => {
    return (
      <>
        {toggleArchiveInfo && (
          <Grid>
            <Tooltip
              title={
                stateState === projectConst.STATE_ARCHIVED
                  ? t('dialogs.ProjectForm.txtTooltipArchivedOne', {ns: 'containers'})
                  : t('dialogs.ProjectForm.txtTooltipArchivedTwo', {ns: 'containers'})
              }
              arrow
            >
              <ErrorOutlineIcon
                style={{
                  height: "38px",
                  margin: `${styles.spacing24} ${styles.spacing12} 0 0`,
                }}
              />
            </Tooltip>
          </Grid>
        )}
        <Grid>
          <FormControlLabel
            control={
              <Switch
                checked={stateState === projectConst.STATE_ARCHIVED}
                onChange={changeState}
              />
            }
            label={t('dialogs.ProjectForm.chkArchive', {ns: 'containers'})}
            style={{ margin: `${styles.spacing24} 0 0 0` }}
          />
        </Grid>
        <Grid xs={1} />
      </>
    );
  };

  return (
    <>
      <CustomDialog
        maxWidth={"sm"}
        open={modalOpen}
        onClick={executeOnClick}
        onClose={onClose}
        showHint={edit}
        renderTitle={() => {
          return (
            <DialogTitle id="alert-dialog-title">
              {edit ? t('dialogs.ProjectForm.txtDialogTitleEdit', {ns: 'containers'}) : 
                t('dialogs.ProjectForm.txtDialogTitleCreate', {ns: 'containers'})}
            </DialogTitle>
          );
        }}
        renderHint={() => {
          return <HintView hintMessageType={HINT_UPDATE_BEHAVIOUR} />;
        }}
        renderContent={() => {
          return (
            <DialogContent>
              <Grid
                container
                direction="column"
                spacing={0.5}
                style={{ marginTop: `${styles.spacing24}` }}
              >
                <Grid container direction="row" size={12}>
                  <Grid direction="column" size={1}>
                    <Tooltip title={t('dialogs.ProjectForm.txtTooltipBaseInformation', {ns: 'containers'})} arrow>
                      <BusinessCenterIcon />
                    </Tooltip>
                  </Grid>
                  <Grid container direction="column" size={11}>
                    <Grid size={11}>
                      {renderTitleElement()}
                    </Grid>
                    <Grid size={11}>
                      {!edit && renderJobOrderElement()}
                    </Grid>
                    <Grid size={11}>
                      {renderDescriptionElement()}
                    </Grid>
                  </Grid>
                </Grid>
                {!edit && (
                  <Grid container direction="row" size={12}>
                    <Grid direction="column" size={1}>
                      <Tooltip title={t('dialogs.ProjectForm.txtTooltipProjectTemplate', {ns: 'containers'})} arrow>
                        <BuildIcon />
                      </Tooltip>
                    </Grid>
                    <Grid container direction="column" size={11}>
                      <Grid size={11}>
                        {renderTemplateElement()}
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                <Grid container direction="row" size={12}>
                  <Grid direction="column" size={1}>
                    <Tooltip title={t('dialogs.ProjectForm.txtTooltipPlace', {ns: 'containers'})} arrow>
                      <PlaceIcon />
                    </Tooltip>
                  </Grid>
                  <Grid container direction="column" size={11}>
                    {renderAddressElement()}
                  </Grid>
                </Grid>
                <Grid container direction="row" size={12}>
                  <Grid direction="column" size={1}>
                    <Tooltip title={t('dialogs.ProjectForm.txtTooltipDueDate', {ns: 'containers'})} arrow>
                      <NotificationsActiveIcon />
                    </Tooltip>
                  </Grid>
                  <Grid container direction="column" size={11}>
                    {renderDueDateElement()}
                  </Grid>
                </Grid>
                {(stateState === projectConst.STATE_DONE ||
                  stateState === projectConst.STATE_ARCHIVED) &&
                  !isProjectRestrictedSingle && (
                    <Grid
                      container
                      direction="row"
                      size={12}
                      style={{ justifyContent: "flex-end" }}
                    >
                      {renderArchiveProjectControl()}
                    </Grid>
                  )}
              </Grid>
            </DialogContent>
          );
        }}
        renderButtonGroup={(onClick, onClose) => {
          return (
            <DialogActions style={{ padding: "2.4rem 2.4rem 2rem" }}>
              <ButtonGroup
                align="right"
                leftButtonText={t('button.btnCancel', {ns: 'common'})}
                leftButtonClick={onClose}
                rightButtonText={edit ? t('button.btnEdit', {ns: 'common'}) : t('button.btnCreate', {ns: 'common'})}
                rightButtonClick={onClick}
                rightButtonDisabled={
                  stateDisablePositiveButton || !isProjectJobOrderValid
                }
                spacingTop={false}
              />
            </DialogActions>
          );
        }}
      />
    </>
  );
};

function dispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createNewProject,
      hideDummyProject,
      showDummyProject,
      updateProject,
      validateProjectJobOrder,
    },
    dispatch
  );
}

function stateToProps(state) {
  const isProjectRestrictedSingle = hasOrganizationFeatureFlag(
    state,
    PROJECT_RESTRICTED_SINGLE
  );

  const isProjectJobOrderValid = state.getIn([
    "projects",
    "isProjectJobOrderValid",
  ]);

  const measureLayerConfigTemplates = state.getIn([
    "measureLayerConfigTemplates",
    "items",
    "measureLayerConfigTemplates",
  ]);

  const measureLayerConfigItems = state.getIn([
    "measureLayerConfigTemplates",
    "items",
    "measureLayerConfigItems",
  ]);

  return {
    isProjectRestrictedSingle,
    measureLayerConfigTemplates:
      (measureLayerConfigTemplates.size &&
        measureLayerConfigTemplates.size === 0) === 0
        ? []
        : measureLayerConfigTemplates,
    measureLayerConfigItems:
      (measureLayerConfigItems.size && measureLayerConfigItems.size === 0) === 0
        ? []
        : measureLayerConfigItems,
    isProjectJobOrderValid,
  };
}

ProjectForm.propTypes = propTypes;
const styledProjectForm = withStyles(overrideStyles, { withTheme: true })(
  ProjectForm
);
export default connect(stateToProps, dispatchToProps)(styledProjectForm);
