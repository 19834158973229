import i18next from '../i18n';

i18next.on('languageChanged init', () => {
    init()
})
  
const t = (textCode, options) => {
    return i18next.t(textCode, options);
}

export const SECTORS = []

const init = () => {
    SECTORS.length = 0
    
    SECTORS.push(
        t('organizationSectorConstants.txtPrivate', {ns: 'constants'})
    )
    SECTORS.push(
        t('organizationSectorConstants.txtServiceProvider', {ns: 'constants'})
    )
    SECTORS.push(
        t('organizationSectorConstants.txtNetworkOperator', {ns: 'constants'})
    )
    SECTORS.push(
        t('organizationSectorConstants.txtOther', {ns: 'constants'})
    )
}