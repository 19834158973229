import i18next from '../i18n';


export const HINT_UPDATE_BEHAVIOUR = "hint_update_behaviour";

export const HINT_TYPE_MAPPING = {}

i18next.on('languageChanged init', () => {
    init()
})

const t = (textCode, options) => {
    return i18next.t(textCode, options);
}

const init = () => {
    HINT_TYPE_MAPPING.hint_update_behaviour = t('hinttypesmapping.txtHintUpdateBehaviour', {ns: 'constants'})
}