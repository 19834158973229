/* eslint-disable no-unused-vars */
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { removeUser } from "../../../actions/userAdministration";
import { updateUserDetails } from "../../../actions/user";
import AvatarName from "../../../components/AvatarName";
import Confirmation from "../../../components/dialogs/Confirmation";
import * as styles from "../../../styles/styles";
import { styling } from "../styles";

import "./styles.css";

const propTypes = {
  classes: PropTypes.object,
  loggedInUser: PropTypes.object.isRequired,
  removeUser: PropTypes.func,
  users: PropTypes.object,
  history: PropTypes.object,
};

const overrideStyles = (theme) => ({
  root: {
    ...styling.root,
  },
  listItem: {
    ...styling.listItem,
  },
});

const RegistredUsers = ({
  classes,
  loggedInUser,
  removeUser,
  users,
  history,
}) => {
  const { t } = useTranslation();

  const [removeUserModalOpen, setRemoveUserModalOpen] = React.useState(false);
  const [modalForUserId, setModalForUserId] = React.useState(0);

  const onRemoveUserClicked = (user) => {
    setModalForUserId(user.id);
    setRemoveUserModalOpen(true);
  };

  const removeSelectedUser = (user) => {
    setRemoveUserModalOpen(false);
    removeUser(user.id);
  };

  const dropdownMenu = [
    {
      text: "Entfernen",
      key: "remove",
      func: onRemoveUserClicked,
    },
    // ToDo: Only the logged in user can change its own information. There is no
    // endpoint for the admin to update from a different user the information.
    //{
    //  text: "App-Einstellungen",
    //  key: "appSettings",
    //  func: handleAppSettingsMenuItemClick,
    //},
  ];

  const compare = (a, b) => {
    if (a.last_name < b.last_name) return -1;
    if (a.last_name > b.last_name) return 1;
    return 0;
  };

  const closeModal = () => {
    setRemoveUserModalOpen(false);
  };

  return (
    <>
      {users.toJS &&
        users
          .toJS()
          .sort(compare)
          .map((user, index) => {
            const currentUser = loggedInUser.toJS();
            const isAdmin =
              currentUser.email === user.email &&
              currentUser.organization_role_groups[0].name === "Admin";
            return (
              <div key={index}>
                {removeUserModalOpen && modalForUserId === user.id && (
                  <Confirmation
                    highlightedText={`${user.first_name} ${user.last_name}`}
                    headline={t('Confirmation.removeUserAsUser.txtHeadline', {ns: 'common'})}
                    leftSideText={t('Confirmation.removeUserAsUser.txtLeftSideText', {ns: 'common'})}
                    modalOpen={removeUserModalOpen}
                    onClose={() => closeModal()}
                    onClick={() => removeSelectedUser(user)}
                    rightSideText={t('Confirmation.removeUserAsUser.txtRightSideText', {ns: 'common'})}
                    textButton={t('Confirmation.removeUserAsUser.txtTextButton', {ns: 'common'})}
                  />
                )}

                <ListItem disablePadding key={user.id}>
                  <ListItemButton
                    classes={{ root: classes.root }}
                    className={classNames(classes.listItem)}
                    onClick={() => {
                      if (isAdmin) {
                        history.push("/settings");
                      } else {
                        history.push(`/organization/user_settings/${user.id}`);
                      }
                    }}
                  >
                    <AvatarName
                      registredUser={true}
                      firstName={user.first_name}
                      lastName={user.last_name}
                      avatar={user.avatar.original}
                    />
                    <div className="registred-users-role">
                      <Typography
                        style={{ color: styles.darkBlueDark }}
                        variant="body1"
                      >
                        {isAdmin ? t('users.RegistredUsers.txtAdminRole', {ns: 'containers'}) : 
                          t('users.RegistredUsers.txtUserRole', {ns: 'containers'})}
                      </Typography>
                    </div>
                  </ListItemButton>
                </ListItem>
              </div>
            );
          })}
    </>
  );
};

function dispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeUser,
      updateUserDetails,
    },
    dispatch
  );
}

function stateToProps(state) {
  if (!state.getIn(["organizations", "items"]).first()) {
    return { users: [], loggedInUser: {} };
  }
  const searchTerm = state.getIn(["userAdministration", "searchTerm"]);
  const registredUsers = state
    .getIn(["organizations", "items"])
    .first()
    .get("users");

  return {
    users:
      searchTerm.length > 0
        ? registredUsers.filter((registredUser) => {
            return searchTerm.split(" ").some((word) => {
              return (
                registredUser
                  .get("email")
                  .toLowerCase()
                  .indexOf(word.toLowerCase()) > -1 ||
                registredUser
                  .get("first_name")
                  .toLowerCase()
                  .indexOf(word.toLowerCase()) > -1 ||
                registredUser
                  .get("last_name")
                  .toLowerCase()
                  .indexOf(word.toLowerCase()) > -1
              );
            });
          })
        : registredUsers,
    loggedInUser: state.get("user"),
  };
}

RegistredUsers.propTypes = propTypes;
const styledRegistredUsers = withStyles(overrideStyles, { withTheme: true })(
  RegistredUsers
);
export default connect(stateToProps, dispatchToProps)(styledRegistredUsers);
