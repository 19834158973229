import React from "react";
import PropTypes from "prop-types";
import { Puff } from "react-loader-spinner";

import * as styles from "../../styles/styles";

const propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

const Spinner = ({ 
  height = 4,
  width = 4 
}) => {
  return (
    <Puff
      color={styles.primary}
      height={`${height}rem`}
      width={`${width}rem`}
    />
  );
};

Spinner.propTypes = propTypes;
export default Spinner;
