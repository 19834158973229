import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';

import { Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";

import { CATALOG_CATEGORY_MAPPING } from "../../../../constants/catalogCategoryMapping";

const propTypes = {
  catalogCategory: PropTypes.array,
  stateShowDataKey: PropTypes.string,
};

const ComponentCatalogTopToolbar = ({ catalogCategory, stateShowDataKey }) => {
  const { t } = useTranslation();

  const renderInfo = () => {
    if (stateShowDataKey === "materials") {
      return (
        <Tooltip
          title={t('ComponentCatalog.ComponentCatalogTopToolbar.txtTooltipMaterials', {ns: 'screens_private'})}
          arrow
        >
          <InfoIcon />
        </Tooltip>
      );
    } else if (stateShowDataKey === "dimensions") {
      return (
        <Tooltip
          title={t('ComponentCatalog.ComponentCatalogTopToolbar.txtTooltipDimensions', {ns: 'screens_private'})}
          arrow
        >
          <InfoIcon />
        </Tooltip>
      );
    } else if (stateShowDataKey === "pipe_definition") {
      return (
        <Tooltip
          title={t('ComponentCatalog.ComponentCatalogTopToolbar.txtTooltipPipeDefinition', {ns: 'screens_private'})}
          arrow
        >
          <InfoIcon />
        </Tooltip>
      );
    } else {
      return <div></div>;
    }
  };
  return (
    <div style={{ padding: "1rem" }}>
      <div style={{ display: "flex" }}>
        <Typography
          variant="h4"
          component="div"
          sx={{ margin: "0 0 1rem 0", fontSize: "2.5rem" }}
        >
          {`${catalogCategory[0].item.name} - ${CATALOG_CATEGORY_MAPPING[stateShowDataKey]}`}
        </Typography>
        <div style={{ marginLeft: "1.5rem" }}>{renderInfo()}</div>
      </div>
      <Typography variant="body2" component="div" sx={{ fontSize: "1.5rem" }}>
        {catalogCategory[0].item.description
          ? catalogCategory[0].item.description
          : t('ComponentCatalog.ComponentCatalogTopToolbar.txtNoCatalogDescription', {ns: 'screens_private'})}
      </Typography>
    </div>
  );
};

ComponentCatalogTopToolbar.propTypes = propTypes;
export default ComponentCatalogTopToolbar;
