/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import moment from "moment";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import Divider from "@mui/material/Divider";
import withStyles from "@mui/styles/withStyles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  dropdownVolumeListItemElements,
  DELETE_KEY,
  EDIT_KEY,
  IMAGE_KEY,
} from "../../../../../../constants/contextMenuEntries";

import { deleteVolume, updateVolume } from "../../../../../../actions/volumes";

import CustomModal from "../../../../../../components/CustomModal";
import CustomImageView from "../../../../../../components/CustomImageView";
import Confirmation from "../../../../../../components/dialogs/Confirmation";
import EditVolumeDialog from "../../../../../../containers/dialogs/EditVolumeDialog";
import ContextMenu from "../../../../../../containers/ContextMenu";

import { DecimalPrecision2 } from "../../../../../../sharedFunctions/roundNumberUtils";

import * as styles from "../../../../../../styles/styles";
import "./styles.css";

const propTypes = {
  classes: PropTypes.object,
  volumeItem: PropTypes.object,
  deleteVolume: PropTypes.func,
  updateVolume: PropTypes.func,
};

const overrideStyles = (theme) => ({
  root: {
    paddingTop: "0",
    paddingBottom: "0",
    paddingLeft: "0",
    margin: "0.5rem 1rem 0.5rem 1rem",
    width: "auto",
    backgroundColor: styles.white,
    boxShadow: styles.boxShadowListElement,
    "&:hover": {
      boxShadow: styles.boxShadowListElementHover,
    },
  },
  root_select: {
    paddingTop: "0",
    paddingBottom: "0",
    paddingLeft: "0",
    margin: "0.5rem 1rem 0.5rem 1rem",
    width: "auto",
    backgroundColor: "rgba(89, 83, 210, 0.08)",
    boxShadow: styles.boxShadowListElement,
    "&:hover": {
      boxShadow: styles.boxShadowListElementHover,
    },
  },
  vertical: {
    marginRight: "2rem",
  },
});

const VolumeListItem = ({
  volumeItem,
  deleteVolume,
  updateVolume,
  classes,
}) => {
  const { t } = useTranslation();
  
  const [modalOpen, setModalOpen] = React.useState(false);
  const [menuEntryKey, setMenuEntryKey] = React.useState(null);

  const closeModal = () => {
    setModalOpen(false);
    setMenuEntryKey(null);
  };

  const openModal = (menuEntryKey) => {
    const bool = typeof menuEntryKey === "string";
    setModalOpen(bool);
    setMenuEntryKey(bool ? menuEntryKey : false);
  };

  const onClickImage = (event) => {
    event.stopPropagation();
    openModal(IMAGE_KEY);
  };

  const onClickVolumeDelete = () => {
    deleteVolume(volumeItem.id);
    closeModal();
  };

  const onClickVolumeEdit = (newVolume) => {
    updateVolume({
      id: newVolume.id,
      description: newVolume.description,
      project_id: newVolume.project_id,
      volume: newVolume.volume,
    });
    closeModal();
  };

  const created_at = moment.unix(volumeItem.created_at);
  const image = volumeItem.image_path.original;
  const title = t('Project.Map.MeasurementList.VolumeListItem.txtTitle', {ns: 'screens_private'});
  
  return (
    <>
      {modalOpen &&
        renderModalContent(
          t,
          modalOpen,
          menuEntryKey,
          closeModal,
          onClickVolumeDelete,
          onClickVolumeEdit,
          volumeItem
        )}
      <ListItem
        classes={{ root: classes.root }}
        className="screenshot-list-item-list-item"
      >
        <img
          onClick={onClickImage}
          src={image}
          className="screenshot-list-item-image-thumbnail"
          alt={`Bild`}
        />
        <Divider
          classes={{ vertical: classes.vertical }}
          orientation={"vertical"}
        />
        <ListItemText
          style={{ flex: 8 }}
          primary={title}
          secondary={created_at.format("dd. DD.MM.YYYY HH:mm:ss")}
        />
        <ListItemText
          primary={t('Project.Map.MeasurementList.VolumeListItem.txtDescription', {ns: 'screens_private'})}
          secondary={
            volumeItem.description !== null
              ? volumeItem.description
              : t('Project.Map.MeasurementList.VolumeListItem.txtNoDescriptionSet', {ns: 'screens_private'})
          }
        />
        <ListItemText
          primary={t('Project.Map.MeasurementList.VolumeListItem.txtVolumeValue', {ns: 'screens_private'})}
          secondary={DecimalPrecision2.round(volumeItem.volume, 2)}
        />

        <ListItemSecondaryAction className="volume-list-item-secondary-action">
          <ContextMenu
            color={styles.darkBlueBase}
            contextMenuEntries={dropdownVolumeListItemElements}
            onMenuEntryClicked={(menuEntryKey) =>
              handleOnMenuEntryClicked(menuEntryKey, openModal)
            }
          />
        </ListItemSecondaryAction>
      </ListItem>
    </>
  );
};

function handleOnMenuEntryClicked(menuEntryKey, openModal) {
  openModal(menuEntryKey);
}

function renderModalContent(
  t,
  modalOpen,
  menuEntryKey,
  closeModal,
  onClickVolumeDelete,
  onClickVolumeEdit,
  volumeItem
) {
  switch (menuEntryKey) {
    case DELETE_KEY:
      return (
        <Confirmation
          highlightedText={t('Confirmation.deleteVolumeListItem.txtHighlightedText', {ns: 'common'})}
          headline={t('Confirmation.deleteVolumeListItem.txtHeadline', {ns: 'common'})}
          leftSideText={t('Confirmation.deleteVolumeListItem.txtLeftSideText', {ns: 'common'})}
          modalOpen={modalOpen}
          onClose={() => closeModal()}
          onClick={onClickVolumeDelete}
          rightSideText={t('Confirmation.deleteVolumeListItem.txtRightSideText', {insertedAt: moment(volumeItem.inserted_at).format("DD.MM.YYYY"), ns: 'common'})}
          textButton={t('Confirmation.deleteVolumeListItem.txtTextButton', {ns: 'common'})}
        />
      );
    case EDIT_KEY:
      return (
        <EditVolumeDialog
          volume={volumeItem}
          modalOpen={modalOpen}
          onNegativeButtonClicked={() => closeModal()}
          onPositiveButtonClicked={onClickVolumeEdit}
        />
      );
    case IMAGE_KEY:
      return (
        <CustomModal
          showCloseButton={true}
          open={modalOpen}
          image={volumeItem.image_path.original}
          onClose={() => closeModal()}
          render={() => (
            <CustomImageView>
              <img
                src={volumeItem.image_path.original}
                className="image-modal-image"
                alt={`Bild`}
              />
            </CustomImageView>
          )}
        />
      );
    default:
      return <div></div>;
  }
}

function dispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteVolume,
      updateVolume,
    },
    dispatch
  );
}

VolumeListItem.propTypes = propTypes;
export default connect(
  null,
  dispatchToProps
)(withStyles(overrideStyles, { withTheme: true })(VolumeListItem));
