import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import PropTypes from "prop-types";
import React, { memo } from "react";


const propTypes = {
    primaryText: PropTypes.string,
    listItemIndex: PropTypes.any,
    listItemDownloadFile: PropTypes.any,
    checkboxChecked: PropTypes.bool,
    onCheckBoxChanged: PropTypes.func
}


const BulkActionDownloadItem = ({
    primaryText,
    listItemIndex,
    listItemDownloadFile,
    checkboxChecked,
    onCheckBoxChanged
}) => {
    return <ListItem
        key={listItemIndex}
        button={"false"}>
        <ListItemIcon>
            <Checkbox
                checked={checkboxChecked}
                onChange={(event) => onCheckBoxChanged(listItemDownloadFile, event.target.checked)} />
        </ListItemIcon>
        <ListItemText
            primary={primaryText} />
    </ListItem>;
}

BulkActionDownloadItem.propTypes = propTypes;
export default memo(BulkActionDownloadItem);