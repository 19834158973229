import i18next from '../i18n';
import { fromJS } from "immutable";
import {
    LANGUAGE_CHANGED
} from "../constants/actions/actions";
import LanguageStorage from "../lib/languageStorage";

const initLanguage = () => {
    const language = LanguageStorage.get();

    if (!language) {
        return i18next.language
    } else {
        i18next.changeLanguage(language);
        return language
    }
}

export const initialState = fromJS({
  language: initLanguage()
});

export function language (state = initialState, action) {
    switch(action.type) {
        case LANGUAGE_CHANGED:
            LanguageStorage.setLanguage(action.payload);
            return state.set('language', action.payload);
        default:
            return state
    }
}