import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';

import * as projectConst from "../../constants/project";
import * as styles from "../../styles/styles";
import "./styles.css";

const propTypes = {
  state: PropTypes.string.isRequired,
  style: PropTypes.object,
};

const StateIndicator = ({ 
  state, 
  style = {}
}) => {
  const { t } = useTranslation();
  let color = null;
  let text = null;
  switch (state) {
    case projectConst.STATE_OPEN:
      text = t('StateIndicator.txtStateOpen', {ns: 'components'});
      color = styles.statusOpen;
      break;
    case projectConst.STATE_IN_PROGRESS:
      text = t('StateIndicator.txtStateInProgress', {ns: 'components'});
      color = styles.statusInProgress;
      break;
    case projectConst.STATE_DONE:
      text = t('StateIndicator.txtStateDone', {ns: 'components'});
      color = styles.statusDone;
      break;
    case projectConst.STATE_SYNCED:
      text = t('StateIndicator.txtStateSynced', {ns: 'components'});
      color = styles.statusInSync;
      break;
    case projectConst.STATE_UPLOAD:
      text = t('StateIndicator.txtStateUpload', {ns: 'components'});
      color = styles.statusInUpload;
      break;
    case projectConst.STATE_ARCHIVED:
      text = t('StateIndicator.txtStateArchived', {ns: 'components'});
      color = styles.statusArchived;
      break;
    default:
      break;
  }
  return (
    <span
      className="state-indicator-container"
      style={{ backgroundColor: color, ...style }}
    >
      <span style={{ color: styles.white }} className="state-indicator-text">
        {text}
      </span>
    </span>
  );
};

StateIndicator.propTypes = propTypes;
export default StateIndicator;
