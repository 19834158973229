import i18next from '../i18n';

i18next.on('languageChanged init', () => {
    init()
})

const t = (textCode, options) => {
    return i18next.t(textCode, options);
}

export const DESCRIPTION = "description"
export const ORIGINAL_FILENAME = "original_filename"
export const MAP_LAYER_REASON_TYPE = "map_layer_reason_type"
export const MAP_LAYER_TYPE = "map_layer_type"
export const MAP_LAYER_ASSIGNMENT_TYPE = "map_layer_assignment_type"


////////////////////
// Overlay / Basemap
export const MAP_LAYER_TYPE_SELECTION_LIST = []
export const MAP_LAYER_TYPE_MAPPING = {}

export const OVERLAY_KEY = "overlay"
export const BASEMAP_KEY = "basemap"

export var OVERLAY_DE = ""
export var BASEMAP_DE = ""
// Overlay / Basemap
////////////////////


///////////////////
// Project / Global
export const MAP_LAYER_ASSIGNMENT_TYPE_SELECTION_LIST = []
export const MAP_LAYER_ASSIGNMENT_TYPE_MAPPING = {}

export const PROJECT_KEY = "project"
export const GLOBAL_KEY = "global"

export var PROJECT_DE = ""
export var GLOBAL_DE = ""
// Project / Global
///////////////////


/////////////
// Layer-Type
export const MAP_LAYER_REASON_TYPE_SELECTION_LIST = []
export const MAP_LAYER_REASON_TYPE_MAPPING = {}

export const MAP_LAYER_KEY = "map_layer"
export const DEFAULT_MAP_LAYER_KEY = "default_map_layer"
export const FIND_POINT_LAYER_KEY = "find_point_layer"

export var MAP_LAYER_DE = ""
export var DEFAULT_MAP_LAYER_DE = ""
export var FIND_POINT_LAYER_DE = ""
// Layer-Type
/////////////


const init = () => {
    ////////////////////
    // Overlay / Basemap
    OVERLAY_DE = t('mapLayerMapping.txtOverlay', {ns: 'constants'})
    BASEMAP_DE = t('mapLayerMapping.txtBasemap', {ns: 'constants'})

    MAP_LAYER_TYPE_SELECTION_LIST.length = 0

    MAP_LAYER_TYPE_SELECTION_LIST.push(
        {
            label: OVERLAY_DE,
            value: OVERLAY_KEY
        }
    )
    MAP_LAYER_TYPE_SELECTION_LIST.push(
        {
            label: BASEMAP_DE,
            value: BASEMAP_KEY
        }
    )

    MAP_LAYER_TYPE_MAPPING.overlay = OVERLAY_DE
    MAP_LAYER_TYPE_MAPPING.basemap = BASEMAP_DE
    // Overlay / Basemap
    ////////////////////


    ///////////////////
    // Project / Global
    PROJECT_DE = t('mapLayerMapping.txtProject', {ns: 'constants'})
    GLOBAL_DE = t('mapLayerMapping.txtGlobal', {ns: 'constants'})

    MAP_LAYER_ASSIGNMENT_TYPE_SELECTION_LIST.length = 0

    MAP_LAYER_ASSIGNMENT_TYPE_SELECTION_LIST.push(
        {
            label: PROJECT_DE,
            value: PROJECT_KEY
        }
    )
    MAP_LAYER_ASSIGNMENT_TYPE_SELECTION_LIST.push(
        {
            label: GLOBAL_DE,
            value: GLOBAL_KEY
        }
    )

    MAP_LAYER_ASSIGNMENT_TYPE_MAPPING.project = PROJECT_DE
    MAP_LAYER_ASSIGNMENT_TYPE_MAPPING.global = GLOBAL_DE
    // Project / Global
    ///////////////////
    

    /////////////
    // Layer-Type
    MAP_LAYER_DE = t('mapLayerMapping.txtMapLayer', {ns: 'constants'})
    DEFAULT_MAP_LAYER_DE = t('mapLayerMapping.txtDefaultMapLayer', {ns: 'constants'})
    FIND_POINT_LAYER_DE = t('mapLayerMapping.txtFindPointLayer', {ns: 'constants'})

    MAP_LAYER_REASON_TYPE_SELECTION_LIST.length = 0

    MAP_LAYER_REASON_TYPE_SELECTION_LIST.push(
        {
            label: MAP_LAYER_DE,
            value: MAP_LAYER_KEY
        }
    )
    MAP_LAYER_REASON_TYPE_SELECTION_LIST.push(
        {
            label: DEFAULT_MAP_LAYER_DE,
            value: DEFAULT_MAP_LAYER_KEY
        }
    )
    MAP_LAYER_REASON_TYPE_SELECTION_LIST.push(
        {
            label: FIND_POINT_LAYER_DE,
            value: FIND_POINT_LAYER_KEY
        }
    )

    MAP_LAYER_REASON_TYPE_MAPPING.map_layer = MAP_LAYER_DE
    MAP_LAYER_REASON_TYPE_MAPPING.default_map_layer = DEFAULT_MAP_LAYER_DE
    MAP_LAYER_REASON_TYPE_MAPPING.find_point_layer = FIND_POINT_LAYER_DE
    // Layer-Type
    /////////////
}