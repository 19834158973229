/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import withStyles from "@mui/styles/withStyles";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";

import ButtonGroup from "../../../components/ButtonGroup";
import CustomDialog from "../../../components/CustomDialog";
import HintView from "../../../components/HintView";
import { HINT_UPDATE_BEHAVIOUR } from "../../../constants/hinttypesmapping";
import { validateTextFields } from "../../../sharedFunctions/validation";

import * as styles from "../../../styles/styles";

const propTypes = {
  classes: PropTypes.object,
  measurement: PropTypes.object,
  componentList: PropTypes.array,
  modalOpen: PropTypes.bool,
  onPositiveButtonClicked: PropTypes.func,
  onNegativeButtonClicked: PropTypes.func,
};

const overrideStyles = (theme) => ({
  alignText: {
    margin: "auto 0 0 0",
  },
});

const EditReferencePointDialog = ({
  classes,
  measurement,
  componentList,
  modalOpen,
  onPositiveButtonClicked,
  onNegativeButtonClicked,
}) => {
  const { t } = useTranslation();

  const { id_user, component } = measurement;

  const activeComponentIndex = componentList.findIndex(
    (item) => item.label === component
  );

  const componentValue =
    activeComponentIndex > 0 ? componentList[activeComponentIndex] : null;

  const [stateIdUser, setIdUser] = useState(id_user ? id_user : measurement.id);
  const [stateComponentInputValue, setStateComponentInputValue] = useState(
    component ? component : ""
  );
  const [stateComponentValue, setStateCompontValue] = useState(componentValue);
  const [stateDisablePositiveButton, setDisablePositiveButton] = useState(true);

  const validationValues = React.useMemo(() => {
    return {
      id_user: {
        value: id_user ? id_user : measurement.id,
        required: true,
      },
      component: {
        value: component ? component : "",
        required: false,
      },
    };
  }, [id_user, component, measurement.id]);

  const onChangeID = (event) => {
    const id_user = event.target.value;
    const newValues = { id_user: id_user };
    setIdUser(id_user);
    setDisablePositiveButton(validateTextFields(validationValues, newValues));
  };

  const onChangeComponent = (event) => {
    const component = event.target.value;
    const newValues = {
      id_user: stateIdUser,
      component: component,
    };
    setStateComponentInputValue(component);
    setDisablePositiveButton(validateTextFields(validationValues, newValues));
  };

  const onChangeComponentAutocomplete = (event, newValue) => {
    let component = "";
    if (newValue) {
      component = newValue;
    }
    const newValues = {
      id_user: stateIdUser,
      component: component,
    };
    setStateComponentInputValue(newValue);
    setDisablePositiveButton(validateTextFields(validationValues, newValues));
  };

  const handleOnPositiveButtonClicked = () => {
    const updateMeasurement = {
      ...measurement,
      id_user: stateIdUser,
      component: stateComponentInputValue,
    };
    onPositiveButtonClicked(updateMeasurement);
  };

  const renderCustomIdTextField = () => {
    return (
      (<TextField
        fullWidth={true}
        margin="none"
        label={t('dialogs.EditReferencePointDialog.textFieldIdLabel', {ns: 'containers'})}
        multiline={false}
        helperText={`${stateIdUser.length}/255`}
        onChange={onChangeID}
        placeholder={t('dialogs.EditReferencePointDialog.textFieldIdPlaceholder', {ns: 'containers'})}
        required={true}
        style={{
          margin: `${styles.spacing24} 0 ${styles.spacing24} 0`,
        }}
        variant="outlined"
        value={stateIdUser}
        slotProps={{
          input: { classes: { input: classes.alignText } },

          htmlInput: {
            maxLength: 255,
          }
        }} />)
    );
  };

  const renderComponentTextField = () => {
    return (
      (<TextField
        fullWidth={true}
        margin="none"
        label={t('dialogs.EditReferencePointDialog.textFieldComponentLabel', {ns: 'containers'})}
        multiline={false}
        helperText={`${stateComponentInputValue}/255`}
        onChange={onChangeComponent}
        placeholder={t('dialogs.EditReferencePointDialog.textFieldComponentPlaceholder', {ns: 'containers'})}
        required={false}
        style={{ margin: `0 0 ${styles.spacing24} 0` }}
        variant="outlined"
        value={stateComponentInputValue}
        slotProps={{
          input: { classes: { input: classes.alignText } },

          htmlInput: {
            maxLength: 255,
          }
        }} />)
    );
  };

  const renderComponentAutocompleteInput = () => {
    return (
      <div style={{ margin: `0 0 ${styles.spacing32} 0` }}>
        <Autocomplete
          fullWidth={true}
          freeSolo
          id="combo-box-component"
          value={stateComponentValue}
          onChange={(event, newValue) => {
            setStateCompontValue(newValue);
          }}
          inputValue={stateComponentInputValue}
          onInputChange={onChangeComponentAutocomplete}
          options={componentList}
          renderInput={(params) => (
            <TextField {...params} label={t('dialogs.EditReferencePointDialog.txtAutoCompleteDescriptionLabel', {ns: 'containers'})} />
          )}
          renderOption={(props, option, { inputValue }) => {
            const matches = match(option.label, inputValue, {
              insideWords: true,
            });
            const parts = parse(option.label, matches);

            return (
              <li {...props}>
                <div>
                  {parts.map((part, index) => (
                    <span
                      key={index}
                      style={{
                        fontWeight: part.highlight ? 700 : 400,
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                </div>
              </li>
            );
          }}
        />
      </div>
    );
  };

  return (
    <>
      <CustomDialog
        open={modalOpen}
        onClick={handleOnPositiveButtonClicked}
        onClose={onNegativeButtonClicked}
        showHint={true}
        renderTitle={() => {
          return (
            <DialogTitle id="alert-dialog-title">
              {t('dialogs.EditReferencePointDialog.txtDialogTitle', {ns: 'containers'})}
            </DialogTitle>
          );
        }}
        renderHint={() => {
          return <HintView hintMessageType={HINT_UPDATE_BEHAVIOUR} />;
        }}
        renderContent={() => {
          return (
            <DialogContent>
              {renderCustomIdTextField()}
              {componentList.length > 0
                ? renderComponentAutocompleteInput()
                : renderComponentTextField()}
            </DialogContent>
          );
        }}
        renderButtonGroup={(
          handleOnPositiveButtonClicked,
          onNegativeButtonClicked
        ) => {
          return (
            <DialogActions style={{ padding: "2.4rem 2.4rem 2rem" }}>
              <ButtonGroup
                align="right"
                leftButtonText={t('button.btnCancel', {ns: 'common'})}
                leftButtonClick={onNegativeButtonClicked}
                rightButtonText={t('button.btnEdit', {ns: 'common'})}
                rightButtonClick={handleOnPositiveButtonClicked}
                rightButtonDisabled={stateDisablePositiveButton}
                spacingTop={false}
              />
            </DialogActions>
          );
        }}
      />
    </>
  );
};

EditReferencePointDialog.propTypes = propTypes;
export default withStyles(overrideStyles, { withTheme: true })(
  EditReferencePointDialog
);
