/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';

import { Link, useLocation } from "react-router-dom";
import { IconButton, Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Button from "@mui/material/Button";

import Header from "../../../../components/Header";

import history from "../../../../lib/history";

import useExitDetector from "../../../../util/useExitDetector";
import localStorageHandler from "../../../../util/localStorageHandler";

import * as styles from "../../../../styles/styles";

const propTypes = {
  measureLayerConfigTemplate: PropTypes.array,
  setCreateModalOpen: PropTypes.func,
  isAdmin: PropTypes.bool,
};

const MeasureLayerConfigTemplateHeader = ({
  measureLayerConfigTemplate,
  setCreateModalOpen,
  isAdmin,
}) => {
  const { t } = useTranslation();

  const [getLastPath, clearLastPath] = localStorageHandler();
  const pathName = useLocation().pathname;
  useExitDetector(pathName);

  const renderGoBack = () => {
    return (
      <IconButton
        style={{
          textDecoration: "none",
        }}
        onClick={() => {
          if (getLastPath() === pathName) {
            clearLastPath();
            history.go(-2);
          } else {
            history.goBack();
          }
        }}
        size="large"
      >
        <ArrowBackIcon />
      </IconButton>
    );
  };

  const renderTitle = () => {
    return (
      <Typography variant="h5" component="div" noWrap={true}>
        {measureLayerConfigTemplate.length > 0
          ? `${measureLayerConfigTemplate[0].item.name}`
          : ""}
      </Typography>
    );
  };

  return (
    <Header>
      <div className="header-left-side" style={{ marginLeft: "0" }}>
        {renderGoBack()}
        {renderTitle()}
      </div>

      <div className="header-right-side">
        {isAdmin && (
          <Button
            style={{ margin: `0 ${styles.spacing16} 0 0` }}
            color="primary"
            variant="contained"
            size="medium"
            onClick={() => setCreateModalOpen(true)}
          >
            <AddCircleIcon style={{ margin: "0 1.2rem 0 0" }} />
            {t('button.btnCreate', {ns: 'common'})}
          </Button>
        )}
      </div>
    </Header>
  );
};

MeasureLayerConfigTemplateHeader.propTypes = propTypes;
export default MeasureLayerConfigTemplateHeader;
