import i18next from '../i18n';

export const DOWNLOAD_KEY = "download";
export const EDIT_KEY = "edit";
export const ASSIGN_KEY = "assign";
export const DELETE_KEY = "delete";
export const IMAGE_KEY = "image";
export const RESET_KEY = "reset";
export const CREATE_KEY = "create";
export const COPY_KEY = "copy";

export const dropdownFinishedProjectElements = []
export const dropdownOpenProjectElements = []
export const dropdownArchivedProjectElements = []
export const dropdownScreenshotListItemElements = []
export const dropdownVolumeListItemElements = []
export const dropdownMeasurementListItemElements = []

i18next.on('languageChanged init', () => {
  init()
})

const t = (textCode, options) => {
  return i18next.t(textCode, options);
}

const init = () => {
  dropdownFinishedProjectElements.length = 0
  dropdownOpenProjectElements.length = 0
  dropdownArchivedProjectElements.length = 0
  dropdownScreenshotListItemElements.length = 0
  dropdownVolumeListItemElements.length = 0
  dropdownMeasurementListItemElements.length = 0

  //////////////////////////////////
  // dropdownFinishedProjectElements
  dropdownFinishedProjectElements.push(
    {
      text: t('contextMenuEntries.txtDownload', {ns: 'constants'}),
      key: DOWNLOAD_KEY,
    }
  )
  dropdownFinishedProjectElements.push(
    {
      text: t('contextMenuEntries.txtEdit', {ns: 'constants'}),
      key: EDIT_KEY,
    }
  )
  dropdownFinishedProjectElements.push(
    {
      text: t('contextMenuEntries.txtAssign', {ns: 'constants'}),
      key: ASSIGN_KEY,
    }
  )
  dropdownFinishedProjectElements.push(
    {
      text: t('contextMenuEntries.txtReset', {ns: 'constants'}),
      key: RESET_KEY,
    }
  )
  dropdownFinishedProjectElements.push(
    {
      text: t('contextMenuEntries.txtDelete', {ns: 'constants'}),
      key: DELETE_KEY,
    }
  )
  // dropdownFinishedProjectElements
  //////////////////////////////////
  

  //////////////////////////////
  // dropdownOpenProjectElements
  dropdownOpenProjectElements.push(
    {
      text: t('contextMenuEntries.txtEdit', {ns: 'constants'}),
      key: EDIT_KEY,
    }
  )
  dropdownOpenProjectElements.push(
    {
      text: t('contextMenuEntries.txtAssign', {ns: 'constants'}),
      key: ASSIGN_KEY,
    }
  )
  dropdownOpenProjectElements.push(
    {
      text: t('contextMenuEntries.txtDelete', {ns: 'constants'}),
      key: DELETE_KEY,
    }
  )
  // dropdownOpenProjectElements
  //////////////////////////////
  

  //////////////////////////////////
  // dropdownArchivedProjectElements
  dropdownArchivedProjectElements.push(
    {
      text: t('contextMenuEntries.txtEdit', {ns: 'constants'}),
      key: EDIT_KEY,
    }
  )
  dropdownArchivedProjectElements.push(
    {
      text: t('contextMenuEntries.txtAssign', {ns: 'constants'}),
      key: ASSIGN_KEY,
    }
  )
  dropdownArchivedProjectElements.push(
    {
      text: t('contextMenuEntries.txtDelete', {ns: 'constants'}),
      key: DELETE_KEY,
    }
  )
  // dropdownArchivedProjectElements
  //////////////////////////////////
  

  /////////////////////////////////////
  // dropdownScreenshotListItemElements
  dropdownScreenshotListItemElements.push(
    {
      text: t('contextMenuEntries.txtDelete', {ns: 'constants'}),
      key: DELETE_KEY,
    }
  )
  // dropdownScreenshotListItemElements
  /////////////////////////////////////
  
  
  /////////////////////////////////
  // dropdownVolumeListItemElements
  dropdownVolumeListItemElements.push(
    {
      text: t('contextMenuEntries.txtEdit', {ns: 'constants'}),
      key: EDIT_KEY,
    }
  )
  dropdownVolumeListItemElements.push(
    {
      text: t('contextMenuEntries.txtDelete', {ns: 'constants'}),
      key: DELETE_KEY,
    }
  )
  // dropdownVolumeListItemElements
  /////////////////////////////////
  

  //////////////////////////////////////
  // dropdownMeasurementListItemElements
  dropdownMeasurementListItemElements.push(
    {
      text: t('contextMenuEntries.txtEdit', {ns: 'constants'}),
      key: EDIT_KEY,
    }
  )
  // dropdownMeasurementListItemElements
  //////////////////////////////////////
}
