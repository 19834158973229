import i18next from '../i18n';

i18next.on('languageChanged init', () => {
  init()
})

const t = (textCode, options) => {
  return i18next.t(textCode, options);
}

export const MEASURE_POINT = "measure_point";
export const REFERENCE_POINT = "reference_point";
export const TOPOGRAPHIC_POINT = "topographic_point";
export const LABEL = "label";
export const GNSS_POINT = "gnss_point";
export const GNSS_LINE = "gnss_line";
export const GNSS_POLYGON = "gnss_polygon";
export const GNSS_IMAGE = "gnss_image";
export const GNSS_REF_POINT = "gnss_ref_point";

export const pointNameLookupTable = {};

export const pointGroupIdLookupTable = {
  measure_point: 1,
  reference_point: 2,
  topographic_point: 3,
  gnss_point: 4,
  gnss_ref_point: 5
};

const init = () => {
  pointNameLookupTable.measure_point = t('pointLookupTable.txtMeasurePoint', {ns: 'constants'})
  pointNameLookupTable.reference_point = t('pointLookupTable.txtReferencePoint', {ns: 'constants'})
  pointNameLookupTable.topographic_point = t('pointLookupTable.txtTopographicPoint', {ns: 'constants'})
  pointNameLookupTable.gnss_point = t('pointLookupTable.txtGnssPoint', {ns: 'constants'})
  pointNameLookupTable.gnss_image = t('pointLookupTable.txtGnssImage', {ns: 'constants'})
  pointNameLookupTable.gnss_ref_point = t('pointLookupTable.txtGnssRefPoint', {ns: 'constants'})
}