import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import VisibilityOnIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import React from "react";
import Spinner from "../../atoms/Spinner";

import * as styles from "../../styles/styles";
import "./styles.css";

const propTypes = {
  history: PropTypes.object,
  email: PropTypes.string,
  password: PropTypes.string,
  onKeyDown: PropTypes.any,
  setEmail: PropTypes.func,
  setPassword: PropTypes.func,
  passwordVisible: PropTypes.bool,
  setPasswordVisible: PropTypes.func,
  rememberLogin: PropTypes.bool,
  setRememberLogin: PropTypes.func,
  errorText: PropTypes.string,
  loading: PropTypes.bool,
  signIn: PropTypes.func,
};

const LogIn = ({
  history,
  email,
  password,
  onKeyDown,
  setEmail,
  setPassword,
  passwordVisible,
  setPasswordVisible,
  rememberLogin,
  setRememberLogin,
  errorText,
  loading,
  signIn,
}) => {
  const { t } = useTranslation();

  const logInButtonDisabled = () => {
    if (loading) return true;
    return email.length === 0 || password.length === 0;
  };

  return (
    <div className="log-in-text-input-wrapper">
      {errorText && (
        <Typography style={{ color: styles.red }}>{errorText}</Typography>
      )}
      <TextField
        variant="standard"
        className="log-in-text-input"
        label={t('Login.textFieldEmailLabel', {ns: 'containers'})}
        margin="normal"
        onChange={(event) => setEmail(event.target.value.trim())}
        onKeyDown={onKeyDown}
        type="email"
        value={email}
      />
      <div className="log-in-password-field-input-wrapper">
        <TextField
          variant="standard"
          className="log-in-text-input"
          label={t('Login.textFieldPasswordLabel', {ns: 'containers'})}
          margin="normal"
          onKeyDown={onKeyDown}
          onChange={(event) => setPassword(event.target.value.trim())}
          type={passwordVisible ? "text" : "password"}
          value={password}
        />
        <div
          className="log-in-password-field-icon-click-area"
          onClick={() => setPasswordVisible(!passwordVisible)}
        >
          {passwordVisible ? (
            <VisibilityOnIcon className="log-in-password-field-visbility-icon" />
          ) : (
            <VisibilityOffIcon className="log-in-password-field-visbility-icon" />
          )}
        </div>
      </div>
      <Typography
        className="log-in-text-input log-in-forgot-password"
        color="textSecondary"
        onClick={() => history.push("forgot-password")}
        variant="body2"
      >
        {t('Login.txtForgotPassword', {ns: 'containers'})}
      </Typography>
      <div className="log-in-remember-me-container">
        <Checkbox
          color="primary"
          checked={rememberLogin}
          onChange={() => setRememberLogin(!rememberLogin)}
        />
        <Typography variant="body2">
          {t('Login.txtStayLoggedIn', {ns: 'containers'})}
        </Typography>
      </div>
      <Button
        disabled={logInButtonDisabled()}
        style={{
          margin: `${styles.spacing24} 0 ${styles.spacing24} 0`,
          width: "25%",
          alignSelf: "center",
        }}
        color="primary"
        onClick={() => signIn()}
        size="large"
        variant="contained"
      >
        {loading ? <Spinner /> : "Log In"}
      </Button>
      <div className="log-in-already-registred">
        <Typography color="textPrimary" variant="body2">
        {t('Login.txtNotRegisteredYet', {ns: 'containers'})}
        </Typography>
        <Typography
          color="primary"
          className="log-in-register-link"
          onClick={() => history.push("/register")}
          variant="body2"
        >
          {t('Login.txtRegistration', {ns: 'containers'})}
        </Typography>
      </div>
    </div>
  );
};

LogIn.propTypes = propTypes;
export default LogIn;
