/* eslint-disable no-empty */
/* eslint-disable no-unused-vars */
/* eslint-disable import/namespace */
import { createAction } from "redux-actions";
import HTTP from "../lib/http";
import {
  SET_COMPONENT_CATALOG_CATEGORY_SEARCH_TERM,
  SET_ACTIVE_COMPONENT_CATALOG_UUID,
  SET_CATALOG_COMPONENTS,
  SET_SYNC_CATALOG_COMPONENTS_STATUS,
  // CATEGORY
  CREATE_CATALOG_CATEGORY,
  UPDATE_CATALOG_CATEGORY,
  DELETE_CATALOG_CATEGORY,
  // COMPONENT
  CREATE_CATALOG_COMPONENT,
  UPDATE_CATALOG_COMPONENT,
  DELETE_CATALOG_COMPONENT,
  // MATERIAL
  CREATE_CATALOG_MATERIAL,
  UPDATE_CATALOG_MATERIAL,
  DELETE_CATALOG_MATERIAL,
  // DIMENSION
  CREATE_CATALOG_DIMENSION,
  UPDATE_CATALOG_DIMENSION,
  DELETE_CATALOG_DIMENSION,
  // ORGANIZATION
  CREATE_CATALOG_ORGANIZATION_CATEGORY,
  // PIPE DEFINITION
  CREATE_CATALOG_PIPE_DEFINITION,
  DELETE_CATALOG_PIPE_DEFINITION,
} from "../constants/actions/actions";
import {
  // CATEGORY
  CATALOG_ORGANIZATION_CATEGORY_CREATED_EVENT,
  CATALOG_CATEGORY_UPDATED_EVENT,
  CATALOG_CATEGORY_DELETED_EVENT,
  // COMPONENT
  CATALOG_COMPONENT_CATEGORY_CREATED_EVENT,
  CATALOG_COMPONENT_UPDATED_EVENT,
  CATALOG_COMPONENT_DELETED_EVENT,
  // MATERIAL
  CATALOG_MATERIAL_CATEGORY_CREATED_EVENT,
  CATALOG_MATERIAL_UPDATED_EVENT,
  CATALOG_MATERIAL_DELETED_EVENT,
  // DIMENSION
  CATALOG_DIMENSION_CATEGORY_CREATED_EVENT,
  CATALOG_DIMENSION_UPDATED_EVENT,
  CATALOG_DIMENSION_DELETED_EVENT,
  // PIPE DEFINITION
  CATALOG_PIPE_DEFINITION_CATEGORY_CREATED_EVENT,
  CATALOG_PIPE_DEFINITION_CATEGORY_DELETED_EVENT,
} from "../constants/channelevents";
import {
  copyComponentCatalogSuccess,
  copyComponentCatalogFail
} from "../constants/snackbars";

import { fail, pending, success } from "../constants/status";
import API from "../lib/api";
import { renderSnackbar } from "./snackbars";
import { getOrgaId } from "../sharedFunctions/organization";

export const setComponentCatalogCategorySearchTerm = createAction(
  SET_COMPONENT_CATALOG_CATEGORY_SEARCH_TERM
);
export const setActiveComponentCatalogUuid = createAction(
  SET_ACTIVE_COMPONENT_CATALOG_UUID
);
export const setCatalogComponents = createAction(SET_CATALOG_COMPONENTS);
export const setSyncCatalogComponentsStatus = createAction(
  SET_SYNC_CATALOG_COMPONENTS_STATUS
);

// ORGANIZATION ACTIONS
export const createCatalogOrganizationCategory = createAction(
  CREATE_CATALOG_ORGANIZATION_CATEGORY
);

// CATEGORY ACTIONS
export const createCatalogCategoryAction = createAction(
  CREATE_CATALOG_CATEGORY
);
export const updateCatalogCategoryAction = createAction(
  UPDATE_CATALOG_CATEGORY
);
export const deleteCatalogCategoryAction = createAction(
  DELETE_CATALOG_CATEGORY
);

// COMPONENT ACTIONS
export const createCatalogComponentAction = createAction(
  CREATE_CATALOG_COMPONENT
);
export const updateCatalogComponentAction = createAction(
  UPDATE_CATALOG_COMPONENT
);
export const deleteCatalogComponentAction = createAction(
  DELETE_CATALOG_COMPONENT
);

// MATERIAL ACTIONS
export const createCatalogMaterialAction = createAction(
  CREATE_CATALOG_MATERIAL
);
export const updateCatalogMaterialAction = createAction(
  UPDATE_CATALOG_MATERIAL
);
export const deleteCatalogMaterialAction = createAction(
  DELETE_CATALOG_MATERIAL
);

// DIMENSION ACTIONS
export const createCatalogDimensionAction = createAction(
  CREATE_CATALOG_DIMENSION
);
export const updateCatalogDimensionAction = createAction(
  UPDATE_CATALOG_DIMENSION
);
export const deleteCatalogDimensionAction = createAction(
  DELETE_CATALOG_DIMENSION
);

// PIPE DEFINITION ACTIONS
export const createCatalogPipeDefinitionAction = createAction(
  CREATE_CATALOG_PIPE_DEFINITION
);
export const deleteCatalogPipeDefinitionAction = createAction(
  DELETE_CATALOG_PIPE_DEFINITION
);

// WEBSOCKET-EVENT HANDLING
export const CableActions = (message, connection, store) => {
  switch (message.event) {
    // CATEGORY
    case CATALOG_ORGANIZATION_CATEGORY_CREATED_EVENT:
      store.dispatch(
        createCatalogOrganizationCategory(
          message.payload.catalog_organization_category
        )
      );
      break;
    case CATALOG_CATEGORY_UPDATED_EVENT:
      store.dispatch(
        updateCatalogCategoryAction(message.payload.catalog_category)
      );
      break;
    case CATALOG_CATEGORY_DELETED_EVENT:
      store.dispatch(
        deleteCatalogCategoryAction(message.payload.catalog_category)
      );
      break;

    // COMPONENT
    case CATALOG_COMPONENT_CATEGORY_CREATED_EVENT:
      store.dispatch(
        createCatalogComponentAction(message.payload.catalog_component_category)
      );
      break;
    case CATALOG_COMPONENT_UPDATED_EVENT:
      store.dispatch(
        updateCatalogComponentAction(message.payload.catalog_component)
      );
      break;
    case CATALOG_COMPONENT_DELETED_EVENT:
      store.dispatch(
        deleteCatalogComponentAction(message.payload.catalog_component)
      );
      break;

    // MATERIAL
    case CATALOG_MATERIAL_CATEGORY_CREATED_EVENT:
      store.dispatch(
        createCatalogMaterialAction(message.payload.catalog_material_category)
      );
      break;
    case CATALOG_MATERIAL_UPDATED_EVENT:
      store.dispatch(
        updateCatalogMaterialAction(message.payload.catalog_material)
      );
      break;
    case CATALOG_MATERIAL_DELETED_EVENT:
      store.dispatch(
        deleteCatalogMaterialAction(message.payload.catalog_material)
      );
      break;

    // DIMENSION
    case CATALOG_DIMENSION_CATEGORY_CREATED_EVENT:
      store.dispatch(
        createCatalogDimensionAction(message.payload.catalog_dimension_category)
      );
      break;
    case CATALOG_DIMENSION_UPDATED_EVENT:
      store.dispatch(
        updateCatalogDimensionAction(message.payload.catalog_dimension)
      );
      break;
    case CATALOG_DIMENSION_DELETED_EVENT: {
      store.dispatch(
        deleteCatalogDimensionAction(message.payload.catalog_dimension)
      );
      break;
    }

    // PIPE DEFINITION
    case CATALOG_PIPE_DEFINITION_CATEGORY_CREATED_EVENT:
      store.dispatch(
        createCatalogPipeDefinitionAction(
          message.payload.catalog_pipe_definition_category
        )
      );
      break;
    case CATALOG_PIPE_DEFINITION_CATEGORY_DELETED_EVENT:
      store.dispatch(
        deleteCatalogPipeDefinitionAction(
          message.payload.catalog_pipe_definition_category
        )
      );
      break;
  }
};

// HTTP ENDPOINT HANDLING

export const syncComponentCatalogs = () => {
  return async (dispatch, getState) => {
    dispatch(setSyncCatalogComponentsStatus(pending));

    // Hint: the uuid and md5 hash are pseudo values.
    // They are needed to get proper data back from the backend.
    // The Backend splits the sync result into three parts, add, update and delete.
    const requestData = {
      params: [
        {
          catalog_category_uuid: "19aa62c2-2ca4-4af1-a659-ee4a60bc198b",
          catalog_category_md5_hash: "9E53820749C6C9D0BA8C6D0F3D8859D1",
        },
      ],
    };

    const orgaId = getOrgaId(getState());

    await HTTP.post(
      API.organizations.componentCatalogs.syncComponentCatalogs(orgaId),
      requestData
    )
      .then(function (response) {
        dispatch(setCatalogComponents(response.data.add));
        dispatch(setSyncCatalogComponentsStatus(success));
      })
      .catch(function (error) {
        dispatch(setSyncCatalogComponentsStatus(fail));
      });
  };
};

/**
 * Create a new Catalog Category with the following payload
 *
 * ```
 * {
 *   "params": {
 *     "name": "",
 *     "description": ""
 *   }
 * }
 * ```
 *
 * @param {object} payload
 * @returns
 */
export const createComponentCatalog = (payload) => {
  return async (dispatch, getState) => {
    const orgaId = getOrgaId(getState());

    await HTTP.post(
      API.organizations.componentCatalogs.createComponentCatalog(orgaId),
      payload
    ).catch(function (error) {});
  };
};

/**
 * Delete a Catalog Category. On the Backend Side this Endpoint is
 * only usable for a User with Admin rights.
 * If a Catalog Category is deleted all related Data is deleted as well.
 *
 * @param {string} catalogCategoryUuid
 * @returns
 */
export const deleteComponentCatalog = (catalogCategoryUuid) => {
  return async (dispatch, getState) => {
    const orgaId = getOrgaId(getState());

    await HTTP.delete(
      API.organizations.componentCatalogs.deleteComponentCatalog(
        orgaId,
        catalogCategoryUuid
      )
    ).catch(function (error) {});
  };
};

/**
 * Update a Component Catalog with the following payload
 *
 * ```
 * {
 *   "params": {
 *     "name": "",
 *     "description": ""
 *   }
 * }
 * ```
 *
 * @param {string} catalogCategoryUuid
 * @param {object} payload
 * @returns
 */
export const updateComponentCatalog = (catalogCategoryUuid, payload) => {
  return async (dispatch, getState) => {
    const orgaId = getOrgaId(getState());

    await HTTP.put(
      API.organizations.componentCatalogs.updateComponentCatalog(
        orgaId,
        catalogCategoryUuid
      ),
      payload
    ).catch(function (error) {});
  };
};

/**
 * Copy a Component Catalog with the given uuid.
 *
 * The copied catalog will have a new uuid. The name of the new catalog will
 * be the name of the source catalog with "(Copy)" appended to it.
 *
 * @param {string} catalogCategoryUuid - The uuid of the catalog to be copied.
 * @returns
 */
export const copyComponentCatalog = (catalogCategoryUuid) => {
  return async (dispatch, getState) => {
    const orgaId = getOrgaId(getState());
    await HTTP.post(
      API.organizations.componentCatalogs.copyComponentCatalog(
        orgaId
      ),
      {
        "params": {
          "uuid": catalogCategoryUuid
        }
      }
    ).then(function (response) {
      dispatch(renderSnackbar(copyComponentCatalogSuccess));
    }).catch(function (error) {
      dispatch(renderSnackbar(copyComponentCatalogFail));
    });
  };
}

/**
 * Create for a Component Catalog a new Component with the following payload
 *
 * ```
 * {
 *   "params": {
 *     "name": "",
 *     "description": ""
 *   }
 * }
 * ```
 *
 * @param {object} payload
 * @returns
 */
export const createCatalogComponent = (payload) => {
  const data = payload.data;
  const catalogCategoryUuid = payload.catalogCategoryUuid;

  return async (dispatch, getState) => {
    const orgaId = getOrgaId(getState());

    await HTTP.post(
      API.organizations.componentCatalogs.createCatalogComponent(
        orgaId,
        catalogCategoryUuid
      ),
      data
    ).catch(function (error) {});
  };
};

/**
 * Delete a certain Component from a Component Catalog.
 *
 * @param {object} payload
 * @returns
 */
export const deleteCatalogComponent = (payload) => {
  const uuid = payload.data.uuid;
  return async (dispatch, getState) => {
    const orgaId = getOrgaId(getState());

    await HTTP.delete(
      API.organizations.componentCatalogs.deleteCatalogComponent(orgaId, uuid)
    ).catch(function (error) {});
  };
};

/**
 * Update a specific component from a component list with the following payload
 *
 * ```
 * {
 *   "params": {
 *     "name": "",
 *     "description": ""
 *   }
 * }
 * ```
 *
 * @param {object} payload
 * @returns
 */
export const updateCatalogComponent = (payload) => {
  const uuid = payload.uuid;
  const data = payload.data;
  return async (dispatch, getState) => {
    const orgaId = getOrgaId(getState());

    await HTTP.put(
      API.organizations.componentCatalogs.updateCatalogComponent(orgaId, uuid),
      data
    ).catch(function (error) {});
  };
};

/**
 * Create for a Component Catalog a new Material with the following payload
 *
 * ```
 * {
 *   "params": {
 *     "name": "",
 *     "description": ""
 *   }
 * }
 * ```
 *
 * @param {object} payload
 * @returns
 */
export const createCatalogMaterial = (payload) => {
  const data = payload.data;
  const catalogCategoryUuid = payload.catalogCategoryUuid;

  return async (dispatch, getState) => {
    const orgaId = getOrgaId(getState());

    await HTTP.post(
      API.organizations.componentCatalogs.createCatalogMaterial(
        orgaId,
        catalogCategoryUuid
      ),
      data
    ).catch(function (error) {});
  };
};

/**
 * Delete a certain Material from a Component Catalog.
 *
 * @param {object} payload
 * @returns
 */
export const deleteCatalogMaterial = (payload) => {
  const uuid = payload.data.uuid;
  return async (dispatch, getState) => {
    const orgaId = getOrgaId(getState());

    await HTTP.delete(
      API.organizations.componentCatalogs.deleteCatalogMaterial(orgaId, uuid)
    ).catch(function (error) {});
  };
};

/**
 * Update a specific Material from a component list with the following payload
 *
 * ```
 * {
 *   "params": {
 *     "name": "",
 *     "description": ""
 *   }
 * }
 * ```
 *
 * @param {object} payload
 * @returns
 */
export const updateCatalogMaterial = (payload) => {
  const uuid = payload.uuid;
  const data = payload.data;
  return async (dispatch, getState) => {
    const orgaId = getOrgaId(getState());

    await HTTP.put(
      API.organizations.componentCatalogs.updateCatalogMaterial(orgaId, uuid),
      data
    ).catch(function (error) {});
  };
};

/**
 * Create for a Component Catalog a new Dimension with the following payload
 *
 * ```
 * {
 *   "params": {
 *     "name": "",
 *     "description": ""
 *   }
 * }
 * ```
 *
 * @param {object} payload
 * @returns
 */
export const createCatalogDimension = (payload) => {
  const data = payload.data;
  const catalogCategoryUuid = payload.catalogCategoryUuid;

  return async (dispatch, getState) => {
    const orgaId = getOrgaId(getState());

    await HTTP.post(
      API.organizations.componentCatalogs.createCatalogDimension(
        orgaId,
        catalogCategoryUuid
      ),
      data
    ).catch(function (error) {});
  };
};

/**
 * Delete a certain Dimension from a Component Catalog.
 *
 * @param {object} payload
 * @returns
 */
export const deleteCatalogDimension = (payload) => {
  const uuid = payload.data.uuid;
  return async (dispatch, getState) => {
    const orgaId = getOrgaId(getState());

    await HTTP.delete(
      API.organizations.componentCatalogs.deleteCatalogDimension(orgaId, uuid)
    ).catch(function (error) {});
  };
};

/**
 * Update a specific Dimension from a component list with the following payload
 *
 * ```
 * {
 *   "params": {
 *     "name": "",
 *     "description": ""
 *   }
 * }
 * ```
 *
 * @param {object} payload
 * @returns
 */
export const updateCatalogDimension = (payload) => {
  const uuid = payload.uuid;
  const data = payload.data;
  return async (dispatch, getState) => {
    const orgaId = getOrgaId(getState());

    await HTTP.put(
      API.organizations.componentCatalogs.updateCatalogDimension(orgaId, uuid),
      data
    ).catch(function (error) {});
  };
};

/**
 * Create for Component Catalog a new PipeDefinition. A PipeDefinition consist
 * of a Material and a Dimension.
 *
 * ```
 * {
 *   "params": {
 *     "material": {
 *       "name": "PA",
 *       "description": ""
 *     },
 *     "dimension": {
 *       "name": "d41",
 *       "description": ""
 *     }
 *   }
 * }
 * ```
 *
 * @param {object} payload
 * @returns
 */
export const createCatalogPipeDefinition = (payload) => {
  const data = payload.data;
  const catalogCategoryUuid = payload.catalogCategoryUuid;
  return async (dispatch, getState) => {
    const orgaId = getOrgaId(getState());

    await HTTP.post(
      API.organizations.componentCatalogs.createPipeDefinition(
        orgaId,
        catalogCategoryUuid
      ),
      data
    ).catch(function (error) {});
  };
};

/**
 * Delete a certain PipeDefinition from a Component Catalog.
 *
 * @param {object} payload
 * @returns
 */
export const deleteCatalogPipeDefinition = (payload) => {
  const catalogCategoryUuid = payload.catalogCategory[0].item.uuid;
  const catalogPipeDefinitionUuid = payload.data.pipeDefinition.uuid;

  return async (dispatch, getState) => {
    const orgaId = getOrgaId(getState());

    await HTTP.delete(
      API.organizations.componentCatalogs.deletePipeDefinition(
        orgaId,
        catalogCategoryUuid,
        catalogPipeDefinitionUuid
      )
    ).catch(function (error) {});
  };
};
