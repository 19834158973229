import React from "react";
import PropTypes from "prop-types";

import Tooltip from "@mui/material/Tooltip";

import "./styles.css";

const propTypes = {
  color: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.string,
};

const ColoredCircle = ({ 
  color = "#000000", 
  name = "", 
  size = "xs" 
}) => {
  return (
    <div>
      {name === "" ? (
        <div
          className={`circle circle-${size}`}
          style={{ backgroundColor: color }}
        ></div>
      ) : (
        <Tooltip title={name} arrow>
          <div
            className={`circle circle-${size}`}
            style={{ backgroundColor: color }}
          ></div>
        </Tooltip>
      )}
    </div>
  );
};

ColoredCircle.propTypes = propTypes;
export default ColoredCircle;
