import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import VisibilityOnIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import * as styles from "../../../styles/styles";
import "./styles.css";
import "../styles.css";

const propTypes = {
  className: PropTypes.string,
  enterHit: PropTypes.func,
  isValid: PropTypes.func.isRequired,
  margin: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};

const anyNumber = /[0-9]/;
const specialCharacters = /[\s!@#$%^&*()_+=`~:;',.[\]{}'\\|<>?/-]/;
const upperCases = /[A-Z]/;
const lowerCases = /[a-z]/;

const PasswordField = ({
  className = "",
  enterHit,
  isValid,
  margin = { margin: 0 },
  onChange,
  password,
  placeholder,
}) => {
  const { t } = useTranslation();

  const checkForNumber = (password) => anyNumber.test(password);

  const checkForSpecialCharacter = (password) =>
    specialCharacters.test(password);

  const checkForUpperCase = (password) => upperCases.test(password);

  const checkForLowerCase = (password) => lowerCases.test(password);

  const checkForMinLength = (password) => password.length >= 8;

  const [state, setState] = useState({
    controllPassword: "",
    controllPasswordVisible: false,
    passwordVisible: false,
    passwordStrength: {
      number: checkForNumber(password),
      specialCharacter: checkForSpecialCharacter(password),
      upperCase: checkForUpperCase(password),
      lowerCase: checkForLowerCase(password),
      minLength: checkForMinLength(password),
    },
    validPassword: false,
    controllPasswordValid: false,
  });

  const checkPasswordStrength = (password) => {
    const number = checkForNumber(password);
    const specialCharacter = checkForSpecialCharacter(password);
    const upperCase = checkForUpperCase(password);
    const lowerCase = checkForLowerCase(password);
    const minLength = checkForMinLength(password);
    const controllPasswordValid = password === state.controllPassword;

    const validPassword =
      number && specialCharacter && upperCase && lowerCase && minLength;

    setState({
      ...state,
      passwordStrength: {
        ...state.passwordStrength,
        number,
        specialCharacter,
        upperCase,
        lowerCase,
        minLength,
      },
      validPassword,
      controllPasswordValid,
    });
    return validPassword && controllPasswordValid;
  };

  const changePassword = (event) => {
    const password = event.target.value;
    const validPassword = checkPasswordStrength(password);
    isValid(validPassword);
    onChange(password);
  };

  const changeControllPassword = (event) => {
    const controllPassword = event.target.value;
    const validPassword = state.validPassword;
    const controllPasswordValid = controllPassword === password;

    setState({
      ...state,
      controllPassword: controllPassword,
      controllPasswordValid: controllPasswordValid,
    });

    isValid(controllPasswordValid && validPassword);
  };

  const onKeyDown = (event) => {
    event.key === "Enter" && enterHit();
  };

  return (
    (<div className={className}>
      <div className="password-field-input-wrapper">
        <TextField
          autoFocus={true}
          fullWidth={true}
          label={t('registration.PasswordField.textFieldPasswordLabel', {ns: 'components'})}
          name="password"
          onKeyDown={onKeyDown}
          onChange={changePassword}
          placeholder={placeholder || t('registration.PasswordField.textFieldPasswordPlaceholder', {ns: 'components'})}
          required={true}
          style={margin}
          type={state.passwordVisible ? "text" : "password"}
          value={password}
          variant="standard"
          slotProps={{
            inputLabel: {
              shrink: true,
            }
          }}
        />
        <div
          className="password-field-icon-click-area"
          onClick={() =>
            setState({ ...state, passwordVisible: !state.passwordVisible })
          }
        >
          {state.passwordVisible ? (
            <VisibilityOnIcon className="password-field-visbility-icon" />
          ) : (
            <VisibilityOffIcon className="password-field-visbility-icon" />
          )}
        </div>
      </div>
      <div className="password-field-strength-wrapper">
        <div className="password-field-strength-requirements-wrapper">
          <span
            className={`password-field-strength-circle ${
              state.passwordStrength.lowerCase
                ? "password-field-fullfilled"
                : "password-field-not-fullfilled"
            }`}
          />
          <Typography
            className="password-field-strength"
            color={`${
              state.passwordStrength.lowerCase ? "textPrimary" : "textSecondary"
            }`}
            variant="caption"
          >
            {t('registration.PasswordField.txtOneLowerCaseLetter', {ns: 'components'})}
          </Typography>
        </div>
        <div className="password-field-strength-requirements-wrapper">
          <span
            className={`password-field-strength-circle ${
              state.passwordStrength.number
                ? "password-field-fullfilled"
                : "password-field-not-fullfilled"
            }`}
          />
          <Typography
            className="password-field-strength"
            color={`${
              state.passwordStrength.number ? "textPrimary" : "textSecondary"
            }`}
            variant="caption"
          >
            {t('registration.PasswordField.txtOneNumber', {ns: 'components'})}
          </Typography>
        </div>
        <div className="password-field-strength-requirements-wrapper">
          <span
            className={`password-field-strength-circle ${
              state.passwordStrength.upperCase
                ? "password-field-fullfilled"
                : "password-field-not-fullfilled"
            }`}
          />
          <Typography
            className="password-field-strength"
            color={`${
              state.passwordStrength.upperCase ? "textPrimary" : "textSecondary"
            }`}
            variant="caption"
          >
            {t('registration.PasswordField.txtOneUpperCaseLetter', {ns: 'components'})}
          </Typography>
        </div>
        <div className="password-field-strength-requirements-wrapper">
          <span
            className={`password-field-strength-circle ${
              state.passwordStrength.specialCharacter
                ? "password-field-fullfilled"
                : "password-field-not-fullfilled"
            }`}
          />
          <Typography
            className="password-field-strength"
            color={`${
              state.passwordStrength.specialCharacter
                ? "textPrimary"
                : "textSecondary"
            }`}
            variant="caption"
          >
            {t('registration.PasswordField.txtOneSpecialCharacter', {ns: 'components'})}
          </Typography>
        </div>
        <div className="password-field-strength-requirements-wrapper">
          <span
            className={`password-field-strength-circle ${
              state.passwordStrength.minLength
                ? "password-field-fullfilled"
                : "password-field-not-fullfilled"
            }`}
          />
          <Typography
            className="password-field-strength"
            color={`${
              state.passwordStrength.minLength ? "textPrimary" : "textSecondary"
            }`}
            variant="caption"
          >
            {t('registration.PasswordField.txtMinEightCharacters', {ns: 'components'})}
          </Typography>
        </div>
      </div>
      <div className="password-field-input-wrapper">
        <TextField
          error={!state.controllPasswordValid}
          autoFocus={false}
          fullWidth={true}
          label={t('registration.PasswordField.textFieldRepeatPasswordLabel', {ns: 'components'})}
          name="password2"
          onKeyDown={onKeyDown}
          onChange={(event) => changeControllPassword(event)}
          placeholder={t('registration.PasswordField.textFieldRepeatPasswordPlaceholder', {ns: 'components'})}
          required={true}
          style={{ margin: `${styles.spacing24} 0 ${styles.spacing24} 0` }}
          type={state.controllPasswordVisible ? "text" : "password"}
          value={state.controllPassword}
          variant="standard"
          slotProps={{
            inputLabel: {
              shrink: true,
            }
          }}
        />
        <div
          className="password-field-icon-click-area"
          onClick={() =>
            setState({
              ...state,
              controllPasswordVisible: !state.controllPasswordVisible,
            })
          }
        >
          {state.controllPasswordVisible ? (
            <VisibilityOnIcon className="password-field-visbility-icon" />
          ) : (
            <VisibilityOffIcon className="password-field-visbility-icon" />
          )}
        </div>
      </div>
    </div>)
  );
};

PasswordField.propTypes = propTypes;
export default PasswordField;
