import React from "react";
import PropTypes from "prop-types";

import "./styles.css";

const propTypes = {
  children: PropTypes.array,
  fullAppHeight: PropTypes.bool,
  padding: PropTypes.bool,
};

const Container = ({ 
  children, 
  fullAppHeight = true, 
  padding = true 
}) => {
  return (
    <div
      className={`
    layout-conainer 
    ${fullAppHeight ? "layout-container-full-app-height" : ""}
    ${
      padding
        ? "layout-container-with-padding"
        : "layout-container-withoug-padding"
    }
    `}
    >
      {children}
    </div>
  );
};

Container.propTypes = propTypes;
export default Container;
