/* eslint-disable import/namespace */
/* eslint-disable no-unused-vars */
import { createAction } from "redux-actions";

import {
    SET_GNSS_DATA,
    GNSS_DATA_CREATED,
    GNSS_DATA_UPDATED,
    GNSS_DATA_DELETED,
    GNSS_POINT_UPDATED,
    GNSS_POINT_DELETED,
    GNSS_IMAGE_UPDATED,
    GNSS_IMAGE_DELETED,
    GNSS_SEGMENT_DELETED,
    GNSS_LINE_DELETED,
    GNSS_POLYGON_DELETED
} from "../constants/actions/actions";
import {
    GNSS_DATA_CREATED_EVENT,
    GNSS_DATA_UPDATED_EVENT,
    GNSS_DATA_DELETED_EVENT,
    GNSS_POINT_UPDATED_EVENT,
    GNSS_POINT_DELETED_EVENT,
    GNSS_IMAGE_UPDATED_EVENT,
    GNSS_IMAGE_DELETED_EVENT,
    GNSS_SEGMENT_DELETED_EVENT,
    GNSS_LINE_DELETED_EVENT,
    GNSS_POLYGON_DELETED_EVENT
} from "../constants/channelevents";

export const setGnssData = createAction(SET_GNSS_DATA);
export const createdGnssData = createAction(GNSS_DATA_CREATED);
export const updatedGnssData = createAction(GNSS_DATA_UPDATED);
export const deletedGnssData = createAction(GNSS_DATA_DELETED);
export const updatedGnssPoint = createAction(GNSS_POINT_UPDATED);
export const deletedGnssPoint = createAction(GNSS_POINT_DELETED);
export const updatedGnssImage = createAction(GNSS_IMAGE_UPDATED);
export const deletedGnssImage = createAction(GNSS_IMAGE_DELETED);
export const deletedGnssSegment = createAction(GNSS_SEGMENT_DELETED);
export const deletedGnssLine = createAction(GNSS_LINE_DELETED);
export const deletedGnssPolygon = createAction(GNSS_POLYGON_DELETED);

export const CableActions = (message, connection, store) => {
    switch (message.event) {
        case GNSS_DATA_CREATED_EVENT:
            store.dispatch(createdGnssData(message.payload.gnss_data));
            break;

        case GNSS_DATA_UPDATED_EVENT:
            store.dispatch(updatedGnssData(message.payload.gnss_data));
            break;

        case GNSS_DATA_DELETED_EVENT:
            store.dispatch(deletedGnssData(message.payload.gnss_data));
            break;

        case GNSS_POINT_UPDATED_EVENT:
            store.dispatch(updatedGnssPoint(message.payload));
            break;

        case GNSS_POINT_DELETED_EVENT:
            store.dispatch(deletedGnssPoint(message.payload));
            break;

        case GNSS_IMAGE_UPDATED_EVENT:
            store.dispatch(updatedGnssImage(message.payload));
            break;

        case GNSS_IMAGE_DELETED_EVENT:
            store.dispatch(deletedGnssImage(message.payload));
            break;

        case GNSS_SEGMENT_DELETED_EVENT:
            store.dispatch(deletedGnssSegment(message.payload));
            break;

        case GNSS_LINE_DELETED_EVENT:
            store.dispatch(deletedGnssLine(message.payload));
            break;

        case GNSS_POLYGON_DELETED_EVENT:
            store.dispatch(deletedGnssPolygon(message.payload));
            break;

        default:
            break;
    }
}