/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import queryString from "qs";

import Wordmark from "../../../atoms/Wordmark";
import { pending, success, fail } from "../../../constants/status";
import { confirmUser, resetRegisterFlags } from "../../../actions/register";
import Spinner from "../../../atoms/Spinner";

import qrCodeGooglePlayStore from "../../../assets/images/qr_code_google_play_store.png";

import "./styles.css";
import * as styles from "../../../styles/styles";

const propTypes = {
  confirmUser: PropTypes.func,
  history: PropTypes.object,
  location: PropTypes.object,
  userStatus: PropTypes.string,
  resetRegisterFlags: PropTypes.func,
};

const ConfirmUser = ({
  confirmUser,
  history,
  location,
  userStatus,
  resetRegisterFlags
}) => {
  const { t } = useTranslation();
  
  const netpipeWordmarkWidth = 22.7;
  const netpipeWordmarkHeight = 6.1;

  //////////////////////////////////////////
  // componentDidMount, componentWillUnmount
  React.useEffect(() => {
    let urlParams = queryString.parse(location && location.search);
    const token = urlParams["?confirmation_token"];
    confirmUser(token);
    
    return () => {
      resetRegisterFlags();
    }
  }, []);
  // componentDidMount, componentWillUnmount
  //////////////////////////////////////////
  

  const renderSuccessfullyConfirmed = () => {
    return (
      <>
        <Typography
          variant="h6"
          style={{
            marginBottom: styles.spacing24,
            marginTop: styles.spacing24,
          }}
        >
          {t('ConfirmUser.txtTitle', {ns: 'screens_public'})}
        </Typography>
        <Typography variant="body1" color="textPrimary">
          {t('ConfirmUser.txtBody', {ns: 'screens_public'})}
        </Typography>
        <div className="app-download-div">
          <a href="https://play.google.com/store/apps/details?id=com.netpipe.measure&gl=DE&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
            <img
              className="google-play-store-badge"
              alt="Jetzt bei Google Play"
              src="https://play.google.com/intl/en_us/badges/static/images/badges/de_badge_web_generic.png"
            />
          </a>
          <img className="qr-code" src={qrCodeGooglePlayStore} alt={`Bild`} />
        </div>
        <Typography
          styles={{ marginTop: styles.spacing24 }}
          className="confirm-user-link"
          color="primary"
          onClick={() => history.push("/login")}
          variant="body2"
        >
          {t('ConfirmUser.txtToLogin', {ns: 'screens_public'})}
        </Typography>
      </>
    );
  }

  const renderText = () => {
    if (userStatus === pending) {
      return (
        <Typography
          variant="h6"
          style={{
            color: styles.darkBlueMediumDark,
            marginBottom: styles.spacing24,
          }}
        >
          {t('ConfirmUser.txtInfoAccountValidation', {ns: 'screens_public'})}
        </Typography>
      );
    } else if (userStatus === success) {
      return renderSuccessfullyConfirmed();
    } else if (userStatus === fail) {
      return (
        <Typography variant="h6" style={{ color: styles.darkBlueMediumDark }}>
          {t('ConfirmUser.txtErrorMsg', {ns: 'screens_public'})}
        </Typography>
      );
      // Email neusenden?
    }
  }

  return (
    <div className="root">
      <div className="confirm-user-container">
        <a href="https://www.netpipe.io">
          <Wordmark
            width={netpipeWordmarkWidth}
            height={netpipeWordmarkHeight}
          />
        </a>
        {renderText()}
        {userStatus === pending && <Spinner height={2.9} width={2.9} />}
      </div>
    </div>
  );
}

ConfirmUser.propTypes = propTypes;

function dispatchToProps(dispatch) {
  return bindActionCreators(
    {
      confirmUser,
      resetRegisterFlags,
    },
    dispatch
  );
}

function stateToProps(state) {
  return {
    userStatus: state.getIn(["register", "confirmUserStatus"]),
  };
}

export default connect(stateToProps, dispatchToProps)(ConfirmUser);
