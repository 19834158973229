import React from "react";
import PropTypes from "prop-types";

import UserMenu from "../../containers/UserMenu";

import "./styles.css";

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  divider: PropTypes.bool,
  subHeader: PropTypes.object,
  showUserSettings: PropTypes.bool,
};

const Header = ({ 
  children, 
  divider, 
  subHeader, 
  showUserSettings = true, 
}) => {
  return (
    <div className="header-container">
      <div className="header-container-row">
        {children}
        <UserMenu showUserSettings={showUserSettings} />
      </div>
      {divider && <div className="header-container-divider" />}
      {subHeader}
    </div>
  );
};

Header.propTypes = propTypes;
export default Header;
