import React from 'react';
import 'particles.js';

import './styles.css';

const Particles = () => {

    React.useEffect(() => {
        window.particlesJS("particles-js", {
            "particles": {
                "number": {
                    "value": 100,
                    "density": {
                        "enable": true,
                        "value_area": 1000
                    }
                },
                "color": {
                    "value": "#ff44ee"
                },
                "shape": {
                    "type": "circle",
                    "stroke": {
                        "width": 0,
                    },
                    "polygon": {
                        "nb_sides": 5
                    }
                },
                "opacity": {
                    "value": 0.5,
                    "random": false,
                    "anim": {
                        "enable": false,
                        "speed": 1,
                        "opacity_min": 0.2,
                        "sync": false
                    }
                },
                "size": {
                    "value": 9,
                    "random": true,
                    "anim": {
                        "enable": false,
                        "speed": 25,
                        "size_min": 1,
                        "sync": false
                    }
                },
                "line_linked": {
                    "enable": true,
                    "distance": 200,
                    "color": "#ff88dd",
                    "opacity": 0.75,
                    "width": 1.5
                },
                "move": {
                    "enable": true,
                    "speed": 1.25,
                    "direction": "none",
                    "random": true,
                    "straight": false,
                    "out_mode": "out",
                    "bounce": false,
                    "attract": {
                        "enable": false,
                        "rotateX": 600,
                        "rotateY": 1200
                    }
                }
            },
            "interactivity": {
                "detect_on": "canvas",
                "events": {
                    "onhover": {
                        "enable": false,
                        "mode": "grab"
                    },
                    "onclick": {
                        "enable": false,
                        "mode": "push"
                    },
                    "resize": true
                },
                "modes": {
                    "grab": {
                        "distance": 100,
                        "line_linked": {
                            "opacity": 0.8
                        }
                    },
                    "bubble": {
                        "distance": 480,
                        "size": 80,
                        "duration": 2,
                        "opacity": 8,
                        "speed": 3
                    },
                    "repulse": {
                        "distance": 200,
                        "duration": 0.4
                    },
                    "push": {
                        "particles_nb": 8
                    },
                    "remove": {
                        "particles_nb": 1
                    }
                }
            },
            "retina_detect": true
        });
    }, []);

    return (
        <div
            id="particles-js"
            className='particles-js'></div>)
};


export default Particles;