/* eslint-disable react/no-is-mounted */
import {
  SYSTEM_CLICK,
  COMPONENT_TYPE_CLICK,
  COMPONENT_TYPE_POINT,
  COMPONENT_TYPE_GNSS_POINT,
  COMPONENT_TYPE_GNSS_IMAGE,
  COMPONENT_TYPE_GNSS_REF_POINT,
  MOUSE_EVENT_CLICK,
} from "../../../../../../../constants/ecs";

export default class SystemClick {
  constructor(params) {
    this.systemType = SYSTEM_CLICK;
    this.map = params.map;
    this.onShowPopUp = params.onShowPopUp;
    this.onHighlightUpdatedListener = params.onHighlightUpdatedListener;
  }

  run(entities) {
    for (var entity in entities) {
      this.runComponent(entities, entity, COMPONENT_TYPE_POINT)
      this.runComponent(entities, entity, COMPONENT_TYPE_GNSS_POINT)
      this.runComponent(entities, entity, COMPONENT_TYPE_GNSS_IMAGE)
      this.runComponent(entities, entity, COMPONENT_TYPE_GNSS_REF_POINT)
    }
  }

  runComponent(entities, entity, componentName) {
    if (entities[entity].getComponents()[COMPONENT_TYPE_CLICK]) {
      if (entities[entity].getComponents()[componentName]) {
        let componentPoint = entities[entity].getComponents()[componentName];
        this._setOnClickEvent(componentPoint);
        this._setOnDeSelectClickEvent(componentPoint);
      }
    }
  }

  onGlobalHighlightEvent(entities, elementId, componentType) {
    for (var entity in entities) {
      // Only consider entities with a click and point component
      if (entities[entity].getComponents()[COMPONENT_TYPE_CLICK]) {

        var component;

        if (entities[entity].getComponents()[COMPONENT_TYPE_POINT]) {
          component = entities[entity].getComponents()[COMPONENT_TYPE_POINT];

        } else if (entities[entity].getComponents()[COMPONENT_TYPE_GNSS_POINT]) {
          component = entities[entity].getComponents()[COMPONENT_TYPE_GNSS_POINT];

        } else if (entities[entity].getComponents()[COMPONENT_TYPE_GNSS_IMAGE]) {
          component = entities[entity].getComponents()[COMPONENT_TYPE_GNSS_IMAGE];

        } else if (entities[entity].getComponents()[COMPONENT_TYPE_GNSS_REF_POINT]) {
          component = entities[entity].getComponents()[COMPONENT_TYPE_GNSS_REF_POINT];
        }

        // check if any other point component is currently
        // highlighted on the map. If that is the case, remove the highlight.
        if (
          !component.idList.includes(elementId) ||
          (component.currentHighlight !== -1 &&
            component.currentHighlight === elementId)
        ) {
          component.currentHighlight !== -1 &&
            this._deactivateClicked(component.currentHighlight, component.name);
          this._updateHighlightedElement(-1, true, component);
          continue;
        }

        // If we got this far, highlight the point component
        // of the correct entity.
        if (component.getComponentName() === componentType) {
          component.currentHighlight !== -1 &&
            this._deactivateClicked(component.currentHighlight, component.name);
          this._activateClicked(elementId, component.name);
          this._updateHighlightedElement(elementId, false, component);
        }
      }
    }
  }

  _setOnClickEvent(component) {
    this.map.on(MOUSE_EVENT_CLICK, component.name, (event) => {
      const id = event.features[0].id;
      const coordinates = event.features[0].geometry.coordinates.slice();
      while (Math.abs(event.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += event.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      this.onShowPopUp(this.map, event, component, id, coordinates);

      component.currentHighlight !== -1 &&
        this._deactivateClicked(component.currentHighlight, component.name);
      this._activateClicked(id, component.name);

      this._updateHighlightedElement(id, true, component);
    });
  }

  _setOnDeSelectClickEvent(component) {
    this.map.on(MOUSE_EVENT_CLICK, (event) => {
      // stop propagation of event
      // mapbox draws on canvas, so event.stopPropagation() in
      // this.map.on('click', 'points', event => { ... does not
      // stop the bubblin, so check for if any feature was clicked
      if (
        this.map
          .queryRenderedFeatures(event.point)
          .filter((feature) => feature.source === component.name).length === 0
      ) {
        component.source.data.features.forEach((feature) => {
          if (component.currentHighlight !== -1) {
            if (component.currentHighlight === feature.id) {
              this._deactivateClicked(feature.id, component.name);
            }
          }
        });
      }

      if (this.map.queryRenderedFeatures(event.point).length === 0) {
        this._updateHighlightedElement(-1, true, component);
      }
    });
  }

  _activateClicked(id, componentName) {
    this.map.setFeatureState(
      {
        source: componentName,
        id: id,
      },
      { click: true }
    );
  }

  _deactivateClicked(id, componentName) {
    this.map.setFeatureState(
      {
        source: componentName,
        id: id,
      },
      { click: false }
    );
  }

  _updateHighlightedElement(id, eventFlag, component) {
    component.currentHighlight = id;
    if (this.onHighlightUpdatedListener !== -1 && eventFlag)
      this.onHighlightUpdatedListener(id, component);
  }
}
