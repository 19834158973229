import { fromJS } from "immutable";
import { t } from 'i18next';

import {
  HIDE_DUMMY_PROJECT,
  SHOW_DUMMY_PROJECT,
  SORT_PROJECTS,
  SORT_PROJECTS_REVERSE,
} from "../constants/actions/actions";

export const initialState = fromJS({
  header: {
    descendingSorted: true,
    currentDropdownSelection: {
      text: t('dashboard.txtDropdownInsertedAt', {ns: 'reducers'}),
      value: "inserted_at",
    },
  },
  ui: {
    creatingProject: false,
  },
  selection: {},
});

export function dashboard(state = initialState, action) {
  switch (action.type) {
    case SORT_PROJECTS:
      return state.setIn(
        ["header", "currentDropdownSelection"],
        fromJS(action.payload)
      );
    case SORT_PROJECTS_REVERSE:
      return state.setIn(
        ["header", "descendingSorted"],
        !state.getIn(["header", "descendingSorted"])
      );
    case SHOW_DUMMY_PROJECT:
      return state.setIn(["ui", "creatingProject"], true);
    case HIDE_DUMMY_PROJECT:
      return state.setIn(["ui", "creatingProject"], false);
    default:
      return state;
  }
}
