/* eslint-disable import/namespace */
/* eslint-disable no-unused-vars */
import Immutable, { fromJS, Map } from "immutable";

export const prepareItems = (array) => {
    return Map(array.map((item) => [item.uuid, fromJS(item)]));
  };

export function setGnssData(state, action) {
    const newItems = {
        images: fromJS(action.payload.images),
        lines: fromJS(action.payload.lines),
        points: fromJS(action.payload.points),
        polygons: fromJS(action.payload.polygons),
        polygon_points: fromJS(action.payload.polygons_points),
        segments: fromJS(action.payload.segments),
    };    
    return state.mergeIn(["items"], newItems);
}

/**
 * Create new GnssData. The payload has the following structure
 * 
 * {
 *   "gnss_data": {
 *     "points": [
 *       {
 *         "index": -1,
 *         "image": {
 *           "original": ""
 *         },
 *         "uuid": "",
 *         "project_id": -1,
 *         "project_uuid": "",
 *         "created_at": 0,
 *         "inserted_at": 0,
 *         "updated_at": 0,
 *         "layer_uuid": "",
 *         "user_id": "",
 *         "component_name:" "",
 *         "tof_value": 0,
 *         "projected_coordinate": [],
 *         "lat_lng_alt_coordinate": [],
 *         "original_filename": ""
 *       }
 *     ],
 *     "images": [
 *       {
 *         "image": {
 *           "original": ""
 *         },
 *         "uuid": "",
 *         "project_id": -1,
 *         "project_uuid": "",
 *         "created_at": 0,
 *         "inserted_at": "",
 *         "updated_at": "",
 *         "layer_uuid": "",
 *         "user_id": "",
 *         "component_name:" "",
 *         "projected_coordinate": [],
 *         "lat_lng_alt_coordinate": [],
 *         "original_filename": ""
 *       }
 *     ],
 *     "lines": [
 *       {
 *         "unit": "cm",
 *         "uuid": "",
 *         "project_id": 0,
 *         "project_uuid": "",
 *         "created_at": 0,
 *         "inserted_at": "",
 *         "updated_at": "",
 *         "layer_uuid": "",
 *         "user_id": "",
 *         "component_name": "",
 *         "distance": ""
 *       }
 *     ],
 *     "polygons": [
 *       {
 *         "unit": "h",
 *         "uuid": "",
 *         "project_id": 0,
 *         "project_uuid": "",
 *         "created_at": 0,
 *         "inserted_at": "",
 *         "updated_at": "",
 *         "layer_uuid": "",
 *         "user_id": "",
 *         "component_name": "",
 *         "area": ""
 *       }
 *     ],
 *     "polygons_points": [
 *       {
 *         "inserted_at": "",
 *         "updated_at": "",
 *         "gnss_polygon_uuid": "",
 *         "gnss_point_uuid": ""
 *       }
 *     ],
 *     "segments": [
 *       {
 *         "id": 0,
 *         "unit": "cm",
 *         "uuid": "",
 *         "project_id": 0,
 *         "project_uuid": "",
 *         "created_at": 0,
 *         "inserted_at": "",
 *         "updated_at": "",
 *         "layer_uuid": "",
 *         "point_a_uuid": "",
 *         "point_b_uuid": "",
 *         "line_uuid": "",
 *         "distance": ""
 *       }
 *     ]
 *   }
 * }
 * 
 * 
 * @param {Object} state That is the gnssData State.
 * @param {Object} action The action contains the new GnssData from the Backend.
 * @returns The updated Immutable State is returned.
 */
export function createGnssData(state, action) {
    // Check if the new data is already inserted in the store.
    // The issue is, that always two events are triggered which leads
    // to two call to this function
    const chainedFunction = pipe(
        gnssDataCreateFunction,
        gnssDataCreateFunction,
        gnssDataCreateFunction,
        gnssDataCreateFunction,
        gnssDataCreateFunction,
        gnssDataCreateFunction
    )

    return chainedFunction(
        state,
        [action.payload.points, "points"],
        [action.payload.images, "images"],
        [action.payload.lines, "lines"],
        [action.payload.polygons, "polygons"],
        [action.payload.polygons_points, "polygon_points"],
        [action.payload.segments, "segments"]
    )
}


/**
 * Update GnssData. The payload has the following structure
 * 
 * {
 *   "gnss_data": {
 *     "points": [
 *       {
 *         "index": -1,
 *         "image": {
 *           "original": ""
 *         },
 *         "uuid": "",
 *         "project_id": -1,
 *         "project_uuid": "",
 *         "created_at": 0,
 *         "inserted_at": 0,
 *         "updated_at": 0,
 *         "layer_uuid": "",
 *         "user_id": "",
 *         "component_name:" "",
 *         "tof_value": 0,
 *         "projected_coordinate": [],
 *         "lat_lng_alt_coordinate": [],
 *         "original_filename": ""
 *       }
 *     ],
 *     "images": [
 *       {
 *         "image": {
 *           "original": ""
 *         },
 *         "uuid": "",
 *         "project_id": -1,
 *         "project_uuid": "",
 *         "created_at": 0,
 *         "inserted_at": "",
 *         "updated_at": "",
 *         "layer_uuid": "",
 *         "user_id": "",
 *         "component_name:" "",
 *         "projected_coordinate": [],
 *         "lat_lng_alt_coordinate": [],
 *         "original_filename": ""
 *       }
 *     ],
 *     "lines": [
 *       {
 *         "unit": "cm",
 *         "uuid": "",
 *         "project_id": 0,
 *         "project_uuid": "",
 *         "created_at": 0,
 *         "inserted_at": "",
 *         "updated_at": "",
 *         "layer_uuid": "",
 *         "user_id": "",
 *         "component_name": "",
 *         "distance": ""
 *       }
 *     ],
 *     "polygons": [
 *       {
 *         "unit": "h",
 *         "uuid": "",
 *         "project_id": 0,
 *         "project_uuid": "",
 *         "created_at": 0,
 *         "inserted_at": "",
 *         "updated_at": "",
 *         "layer_uuid": "",
 *         "user_id": "",
 *         "component_name": "",
 *         "area": ""
 *       }
 *     ],
 *     "polygons_points": [
 *       {
 *         "inserted_at": "",
 *         "updated_at": "",
 *         "gnss_polygon_uuid": "",
 *         "gnss_point_uuid": ""
 *       }
 *     ],
 *     "segments": [
 *       {
 *         "id": 0,
 *         "unit": "cm",
 *         "uuid": "",
 *         "project_id": 0,
 *         "project_uuid": "",
 *         "created_at": 0,
 *         "inserted_at": "",
 *         "updated_at": "",
 *         "layer_uuid": "",
 *         "point_a_uuid": "",
 *         "point_b_uuid": "",
 *         "line_uuid": "",
 *         "distance": ""
 *       }
 *     ]
 *   }
 * }
 * 
 * 
 * @param {Object} state That is the gnssData State.
 * @param {Object} action The action contains the updated GnssData from the Backend.
 * @returns The updated Immutable State is returned.
 */
export function updateGnssData(state, action) {
    const chainedFunction = pipe(
        gnssDataUpdateFunction,
        gnssDataUpdateFunction,
        gnssDataUpdateFunction,
        gnssDataUpdateFunction,
        gnssDataUpdateFunction
    )

    return chainedFunction(
        state,
        [action.payload.points, "points"],
        [action.payload.images, "images"],
        [action.payload.lines, "lines"],
        [action.payload.polygons, "polygons"],
        [action.payload.segments, "segments"]
    )
}

/**
 * Delete GnssData. The payload has the following structure
 * 
 * {
 *   "gnss_data": {
 *     "gnss_point_uuids": [],
 *     "gnss_image_uuids": [],
 *     "gnss_line_uuids": [],
 *     "gnss_polygon_uuids": [],
 *     "gnss_segment_uuids": []
 *   }
 * }
 * 
 * @param {Object} state That is the gnssData State.
 * @param {Object} action 
 * @returns The updated Immutable State is returned.
 */
export function deleteGnssData(state, action) {
    const chainedFunction = pipe(
        gnssDataDeleteFunction,
        gnssDataDeleteFunction,
        gnssDataDeleteFunction,
        gnssDataDeleteFunction,
        gnssDataDeleteFunction
    )

    return chainedFunction(
        state,
        [action.payload.gnss_point_uuids, "points"],
        [action.payload.gnss_image_uuids, "images"],
        [action.payload.gnss_line_uuids, "lines"],
        [action.payload.gnss_polygon_uuids, "polygons"],
        [action.payload.gnss_segment_uuids, "segments"]
    )
}

/**
 * Use this function to update the image information of a 
 * GnssPoint. If anything else has changed checkout the 
 * function updateGnssData().
 * 
 * @param {Object} state That is the gnssData State.
 * @param {Object} action 
 * @returns The updated Immutable State is returned.
 */
export function updateGnssPoint(state, action) {
    return state.updateIn(["items", "points"], points => {
        const index = points.findIndex(point => point.get("uuid") === action.payload.uuid);

        if (index === -1) {
            return points;
        }

        return points.update(index, point => fromJS(action.payload));
    })
}

/**
 * Delete a single GnssPoint.
 * 
 * @param {Object} state That is the gnssData State.
 * @param {Object} action 
 * @returns The updated Immutable State is returned.
 */
export function deleteGnssPoint(state, action) {
    return state.updateIn(["items", "points"], points => 
        points.filter(point => action.payload.uuid !== point.get("uuid")))
}

/**
 * Use this function to update the image information of a 
 * GnssImage. If anything else has changed checkout th e
 * function updateGnssData().
 * 
 * @param {Object} state That is the gnssData State.
 * @param {Object} action 
 * @returns The updated Immutable State is returned.
 */
export function updateGnssImage(state, action) {
    return state.updateIn(["items", "images"], images => {
        const index = images.findIndex(image => image.get("uuid") === action.payload.uuid);

        if (index === -1) {
            return images;
        }

        return images.update(index, image => fromJS(action.payload));
    })
}

/**
 * Delete a single GnssImage.
 * 
 * @param {Object} state That is the gnssData State. 
 * @param {Object} action 
 * @returns The updated Immutable State is returned.
 */
export function deleteGnssImage(state, action) {
    return state.updateIn(["items", "images"], images => 
        images.filter(image => action.payload.uuid !== image.get("uuid")))
}

/**
 * Delete a single GnssSegment.
 * It is not very likely that this function is called. 
 * Normally the deletion if GnssData happens in the function 
 * deleteGnssData().
 * 
 * 
 * @param {Object} state That is the gnssData State.
 * @param {Object} action 
 * @returns The updated Immutable State is returned.
 */
export function deleteGnssSegment(state, action) {
    return state.updateIn(["items", "segments"], segments => 
        segments.filter(segment => action.payload.uuid !== segment.get("uuid")))
}

/**
 * Delete a single GnssLine.
 * It is not very likely that this function is called. 
 * Normally the deletion if GnssData happens in the function 
 * deleteGnssData().
 * 
 * 
 * @param {Object} state That is the gnssData State.
 * @param {Object} action 
 * @returns The updated Immutable State is returned.
 */
export function deleteGnssLine(state, action) {
    return state.updateIn(["items", "lines"], lines => 
        lines.filter(line => action.payload.uuid !== line.get("uuid")))
}

/**
 * Delete a single GnssPolygon.
 * It is not very likely that this function is called. 
 * Normally the deletion if GnssData happens in the function 
 * deleteGnssData().
 * 
 * 
 * @param {Object} state That is the gnssData State.
 * @param {Object} action 
 * @returns The updated Immutable State is returned.
 */
export function deleteGnssPolygon(state, action) {
    return state.updateIn(["items", "polygons"], polygons => 
        polygons.filter(polygon => action.payload.uuid !== polygon.get("uuid")))
}

/////////////////////////////
// Private Functions - Helper
/**
 * This code defines a function called pipe that takes a variable number of 
 * functions (fns) as arguments. It returns a new function that takes a single 
 * argument x. When called, this new function applies each of the original 
 * functions to x in sequence, passing the output of each function to the next one. 
 * This is a common pattern in functional programming known as function composition.
 * 
 * @returns 
 */
const pipe = (...fns) => (state, ...data) => fns.reduce((acc, fn, index) => fn(acc)(data[index]), state);
// Private Functions - Helper
/////////////////////////////


///////////////////////////////////////
// Private Functions - Create Gnss Data
/**
 * This is a function that creates new GNSS data items and adds them 
 * to an existing state.
 * dataList[0] => The new data list from the backend.
 * dataList[1] => The key from the local store to load the already existing data items.
 * 
 * @param {Object} state 
 * @returns The updated Immutable State is returned.
 */
const gnssDataCreateFunction = (state) => (dataList) => {
    if (dataList[0].length === 0) {
        return state
    }

    return state.updateIn(["items", dataList[1]], dataItems => {
        const newDataItems = dataList[0]

        const existingUUIDs = new Set(dataItems.map(item => item.get("uuid")))

        const uniqueNewDataItems = newDataItems.filter(newDataItem => {
            return !existingUUIDs.has(newDataItem.uuid)
        })

        return dataItems.concat(fromJS(uniqueNewDataItems))
    })
}
// Private Functions - Create Gnss Data
///////////////////////////////////////


///////////////////////////////////////
// Private Functions - Update Gnss Data
/**
 * The dataList has always two entries.
 * dataList[0] => The updated data list from the backend.
 * dataList[1] => The key from the local store to load the already existing data items.
 * 
 * @param {Object} state That is the gnssData State.
 * @returns The updated Immutable State is returned.
 */
const gnssDataUpdateFunction = (state) => (dataList) => {
    if (dataList[0].length === 0) {
        return state
    }

    const updatedDataMap = dataList[0].reduce((acc, dataItem) => {
        acc[dataItem.uuid] = dataItem
        return acc
    }, {})

    return state.updateIn(["items", dataList[1]], items => 
        items.map(item => {

            const uuid = item.get("uuid")

            if (updatedDataMap[uuid]) {
                return fromJS(updatedDataMap[uuid])
            }

            return item
        })
    )
}
// Private Functions - Update Gnss Data
///////////////////////////////////////


///////////////////////////////////////
// Private Functions - Delete Gnss Data
/**
 * The dataList has always two entries.
 * dataList[0] => A list of uuids to delete.
 * dataList[1] => The key from the local store to load the already existing data items.
 * 
 * @param {Object} state That is the gnssData State.
 * @returns The updated Immutable State is returned.
 */
const gnssDataDeleteFunction = (state) => (dataList) => {
    if (dataList[0].length === 0) {
        return state
    }

    const uuidsToDelete = new Set(dataList[0])

    return state.updateIn(["items", dataList[1]], dataItems => 
        dataItems.filter(dataItem => !uuidsToDelete.has(dataItem.get("uuid"))))
}
// Private Functions - Delete Gnss Data
///////////////////////////////////////
