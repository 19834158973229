/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';

import moment from "moment";

import withStyles from "@mui/styles/withStyles";

import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import Divider from "@mui/material/Divider";


import CustomModal from "../../../../../../components/CustomModal";
import CustomImageView from "../../../../../../components/CustomImageView";
import {
    GNSS_POINT, 
    pointNameLookupTable 
} from "../../../../../../constants/pointLookupTable";

import { IMAGE_KEY } from "../../../../../../constants/contextMenuEntries";

import * as styles from "../../../../../../styles/styles";
import "./styles.css";

const propTypes = {
    classes: PropTypes.object,
    gnssPoint: PropTypes.object,
    layerConfig: PropTypes.object,
    onHoverListEnter: PropTypes.func,
    onHoverListLeave: PropTypes.func,
    onListClick: PropTypes.func,
    measurmentListClick: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

const overrideStyles = (theme) => ({
    root: {
      paddingTop: "0",
      paddingBottom: "0",
      paddingLeft: "0",
      margin: "0.5rem 1rem 0.5rem 1rem",
      width: "auto",
      backgroundColor: styles.white,
      boxShadow: styles.boxShadowListElement,
      "&:hover": {
        boxShadow: styles.boxShadowListElementHover,
      },
    },
    root_select: {
        paddingTop: "0",
        paddingBottom: "0",
        paddingLeft: "0",
        margin: "0.5rem 1rem 0.5rem 1rem",
        width: "auto",
        backgroundColor: "rgba(89, 83, 210, 0.08)",
        boxShadow: styles.boxShadowListElement,
        "&:hover": {
          boxShadow: styles.boxShadowListElementHover,
        },
      },
    vertical: {
      marginRight: "2rem",
    },
});

const GnssPointListItem = ({
    gnssPoint,
    layerConfig,
    classes,
    onHoverListEnter,
    onHoverListLeave,
    onListClick,
    measurmentListClick
}) => {
    const { t } = useTranslation();

    const [selected, setSelected] = React.useState(false);
    const [modalOpen, setModalOpen] = React.useState(false);
    const [menuEntryKey, setMenuEntryKey] = React.useState(null);

    const created_at = moment.unix(gnssPoint.created_at);
    const image = gnssPoint.image.original;
    const id = gnssPoint.uuid;

    const baseTitle = pointNameLookupTable[GNSS_POINT];
    const title = baseTitle + " - " + gnssPoint.user_id || "";

    const onClick = () => {
        setSelected(true);
        onListClick(gnssPoint);
    };

    const closeModal = () => {
        setModalOpen(false);
        setMenuEntryKey(null);
      };

    const onClickImage = (event) => {
        event.stopPropagation();
        openModal(IMAGE_KEY);
    };

      const openModal = (menuEntryKey) => {
        const bool = typeof menuEntryKey === "string";
        setModalOpen(bool);
        setMenuEntryKey(bool ? menuEntryKey : false);
    };

    const isSelected = id === measurmentListClick

    return (
        <>
            {modalOpen &&
                renderModalContent(
                    modalOpen,
                    menuEntryKey,
                    closeModal,
                    gnssPoint,
            )}
            
            <ListItem
                classes={{ root: isSelected ? classes.root_select : classes.root }}
                onMouseLeave={() => onHoverListLeave(id, GNSS_POINT)}
                onMouseEnter={() => onHoverListEnter(id, GNSS_POINT)}
                onMouseOver={() => onHoverListEnter(id, GNSS_POINT)}
                onClick={onClick}
                className="gnss-point-list-item-list-item"
            >
                {image ? 
                    <div className="gnss-point-list-item-image-container">
                        <img
                            onClick={onClickImage}
                            src={image}
                            className="gnss-point-list-item-image-thumbnail"
                            alt={`${GNSS_POINT} Bild`}
                        />
                        <Divider
                            classes={{ vertical: classes.vertical }}
                            orientation={"vertical"}
                        />
                    </div>
                    : <div style={{marginRight: "2rem"}}></div>}
                
                <ListItemText
                    style={{ flex: 8 }}
                    primary={title}
                    secondary={created_at.format("dd. DD.MM.YYYY HH:mm:ss")}
                />
                <ListItemText
                    primary={t('Project.Map.MeasurementList.GnssPointListItem.txtComponent', {ns: 'screens_private'})}
                    secondary={
                        gnssPoint.component_name !== null
                        ? gnssPoint.component_name
                        : t('Project.Map.MeasurementList.GnssPointListItem.txtNoComponentSet', {ns: 'screens_private'})
                    }
                />
            </ListItem>
        </>
    )
}

function renderModalContent(
    modalOpen,
    menuEntryKey,
    closeModal,
    gnssPoint,
  ) {
    switch (menuEntryKey) {
      case IMAGE_KEY:
        return (
          <CustomModal
            showCloseButton={true}
            open={modalOpen}
            image={gnssPoint.image.original}
            onClose={() => closeModal()}
            render={() => (
              <CustomImageView>
                <img
                  src={gnssPoint.image.original}
                  className="image-modal-image"
                  alt={`Bild`}
                />
              </CustomImageView>
            )}
          />
        );
      default:
        return <div></div>;
    }
}

GnssPointListItem.propTypes = propTypes;
export default withStyles(overrideStyles, { withTheme: true}) (
    GnssPointListItem
)