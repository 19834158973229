import React from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import { useTranslation } from 'react-i18next';

import * as styles from "../../../styles/styles";
import "../styles.css";
const propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  onChangeFirstName: PropTypes.func,
  onChangeLastName: PropTypes.func,
};

const EditUserProfile = ({
  firstName,
  lastName,
  onChangeFirstName,
  onChangeLastName,
}) => {
  const { t } = useTranslation();

  return (
    <div className="user-profile-name-surname-container">
      <TextField
        variant="standard"
        error={firstName.length === 0}
        style={{ margin: `0 0 ${styles.spacing16} 0` }}
        fullWidth={true}
        value={firstName}
        placeholder={t("userProfile.EditUserProfile.firstNamePlaceholder", {ns: 'components'})}
        name="firstName"
        type="name"
        label={t("userProfile.EditUserProfile.firstNameTitle", {ns: 'components'})}
        onChange={onChangeFirstName}
      />
      <TextField
        variant="standard"
        error={lastName.length === 0}
        fullWidth={true}
        value={lastName}
        placeholder={t("userProfile.EditUserProfile.lastNamePlaceholder", {ns: 'components'})}
        name="lastName"
        type="name"
        label={t("userProfile.EditUserProfile.lastNameTitle", {ns: 'components'})}
        onChange={onChangeLastName}
      />
    </div>
  );
};

EditUserProfile.propTypes = propTypes;
export default EditUserProfile;
