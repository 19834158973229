/* eslint-disable no-unused-vars */
/* eslint-disable react/no-is-mounted */
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import withStyles from "@mui/styles/withStyles";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { assignUser } from "../../../actions/projects";
import ButtonGroup from "../../../components/ButtonGroup";
import CustomDialog from "../../../components/CustomDialog";
import "./styles.css";

const propTypes = {
  users: PropTypes.array,
  modalOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
  assignUser: PropTypes.func,
  project: PropTypes.object,
};

const overrideStyles = (theme) => ({
  alignText: {
    margin: "auto 0 0 0",
  },
});


const AssignUser = ({
  users,
  modalOpen, 
  onClose,
  onClick, 
  assignUser, 
  project
}) => {
  const { t } = useTranslation();

  const [state, setState] = React.useState({ selectedUser: null });

  const assignedUser = users.find((u) => u.id === project.assigned_user_id);

  const assignToUser = () => {
    assignUser(project, state.selectedUser);
    onClick();
  }

  const buildPattern = (str) => {
    const arr = [...str];
    arr.splice(0, 0, ".*");
    arr.splice(arr.length, 0, ".*");
    return new RegExp(arr.join(""), "i");
  }

  const fullName = (obj) => {
    return obj.first_name + " " + obj.last_name + " (" + obj.email + ")";
  }

  return (
    <CustomDialog
      open={modalOpen}
      onClick={() => {}}
      onClose={onClose}
      renderTitle={() => {
        return (
          <DialogTitle id="alert-dialog-title">
            {t('dialogs.AssignUser.txtDialogTitle', {ns: 'containers'})}
          </DialogTitle>
        );
      }}
      renderContent={() => {
        return (
          <DialogContent>
            <Autocomplete
              options={users}
              value={assignedUser}
              noOptionsText={t('dialogs.AssignUser.txtAutoCompleteAssignUserNoOptionsText', {ns: 'containers'})}
              filterSelectedOptions={true}
              filterOptions={(options, state) => {
                const pattern = buildPattern(state.inputValue);
                return options.filter((option) =>
                  pattern.test(fullName(option))
                );
              }}
              renderInput={(params) => <TextField {...params} />}
              getOptionLabel={fullName}
              onChange={(e, value) => setState({ selectedUser: value })}
            />
          </DialogContent>
        );
      }}
      renderButtonGroup={(onClick, onClose) => {
        return (
          <DialogActions style={{ padding: "2.4rem 2.4rem 2rem" }}>
            <ButtonGroup
              align="right"
              leftButtonText={t('button.btnCancel', {ns: 'common'})}
              leftButtonClick={onClose}
              rightButtonText={t('button.btnAssign', {ns: 'common'})}
              rightButtonDisabled={state.selectedUser === null}
              rightButtonClick={assignToUser}
              spacingTop={true}
            />
          </DialogActions>
        );
      }}
    />
  );
}

function dispatchToProps(dispatch) {
  return bindActionCreators(
    {
      assignUser,
    },
    dispatch
  );
}

function stateToProps(state) {
  const organization = state.getIn(["organizations", "items"]).first();
  const users = organization ? organization.get("users").toJS() : [];
  return {
    users,
  };
}

AssignUser.propTypes = propTypes;
const styledAssignUser = withStyles(overrideStyles, { withTheme: true })(
  AssignUser
);
export default connect(stateToProps, dispatchToProps)(styledAssignUser);
