import React from "react";
import { useTranslation } from 'react-i18next';

import Typography from "@mui/material/Typography";

import Column from "../../layout/Column";

import * as styles from "../../../styles/styles";

const UserProfileSupport = () => {
  const { t } = useTranslation();

  return (
    <>
      <Column side="left">
        <Typography
          color="primary"
          style={{ margin: `0 0 ${styles.spacing24} 0` }}
          variant="h2"
        >
          {t('userProfile.UserProfileSupport.title', {ns: 'components'})}
        </Typography>
      </Column>
      <Column side="right">
        <div style={{ margin: `6rem 0 0 0` }}>
          <a
            href="https://www.netpipe.io/kontakt-support"
            target="_BLANK"
            rel="noopener noreferrer"
          >
            <Typography color="textPrimary" variant="body1">
            {t('userProfile.UserProfileSupport.linkSupport', {ns: 'components'})}
            </Typography>
          </a>
        </div>
      </Column>
    </>
  );
};

export default UserProfileSupport;
