/* eslint-disable import/namespace */
/* eslint-disable no-unused-vars */
import { createAction } from "redux-actions";
import HTTP from "../lib/http";

import {
  SET_MEASURE_LAYER_CONFIG_TEMPLATE_SEARCH_TERM,
  SET_ACTIVE_MEASURE_LAYER_CONFIG_TEMPLATE_UUID,
  SET_MEASURE_LAYER_CONFIG_TEMPLATES,
  SET_SYNC_MEASURE_LAYER_CONFIG_TEMPLATES_STATUS,
  CREATE_MEASURE_LAYER_CONFIG_TEMPLATE,
  UPDATE_MEASURE_LAYER_CONFIG_TEMPLATE,
  DELETE_MEASURE_LAYER_CONFIG_TEMPLATE,
  CREATE_MEASURE_LAYER_CONFIG_ITEM,
  UPDATE_MEASURE_LAYER_CONFIG_ITEM,
  DELETE_MEASURE_LAYER_CONFIG_ITEM,
} from "../constants/actions/actions";

import {
  MEASURE_LAYER_CONFIG_TEMPLATE_CREATED_EVENT,
  MEASURE_LAYER_CONFIG_TEMPLATE_UPDATED_EVENT,
  MEASURE_LAYER_CONFIG_TEMPLATE_DELETED_EVENT,
  MEASURE_LAYER_CONFIG_ITEM_CREATED_EVENT,
  MEASURE_LAYER_CONFIG_ITEM_UPDATED_EVENT,
  MEASURE_LAYER_CONFIG_ITEM_DELETED_EVENT,
} from "../constants/channelevents";
import {
  copyMeasureLayerConfigTemplateSuccess,
  copyMeasureLayerConfigTemplateFail
} from "../constants/snackbars";

import { fail, pending, success } from "../constants/status";
import API from "../lib/api";
import { renderSnackbar } from "./snackbars";
import { getOrgaId } from "../sharedFunctions/organization";

export const setMeasureLayerConfigTemplateSearchTerm = createAction(
  SET_MEASURE_LAYER_CONFIG_TEMPLATE_SEARCH_TERM
);
export const setActiveMeasureLayerConfigTemplateUuid = createAction(
  SET_ACTIVE_MEASURE_LAYER_CONFIG_TEMPLATE_UUID
);
export const setMeasureLayerConfigTemplates = createAction(
  SET_MEASURE_LAYER_CONFIG_TEMPLATES
);
export const setSyncMeasureLayerConfigTemplatesStatus = createAction(
  SET_SYNC_MEASURE_LAYER_CONFIG_TEMPLATES_STATUS
);
export const createMeasureLayerConfigTemplateAction = createAction(
  CREATE_MEASURE_LAYER_CONFIG_TEMPLATE
);
export const updateMeasureLayerConfigTemplateAction = createAction(
  UPDATE_MEASURE_LAYER_CONFIG_TEMPLATE
);
export const deleteMeasureLayerConfigTemplateAction = createAction(
  DELETE_MEASURE_LAYER_CONFIG_TEMPLATE
);
export const createMeasureLayerConfigItemAction = createAction(
  CREATE_MEASURE_LAYER_CONFIG_ITEM
);
export const updateMeasureLayerConfigItemAction = createAction(
  UPDATE_MEASURE_LAYER_CONFIG_ITEM
);
export const deleteMeasureLayerConfigItemAction = createAction(
  DELETE_MEASURE_LAYER_CONFIG_ITEM
);

export const CableActions = (message, connection, store) => {
  switch (message.event) {
    case MEASURE_LAYER_CONFIG_TEMPLATE_CREATED_EVENT:
      store.dispatch(
        createMeasureLayerConfigTemplateAction(
          message.payload.measure_layer_config_organization_template
        )
      );
      break;
    case MEASURE_LAYER_CONFIG_TEMPLATE_UPDATED_EVENT:
      store.dispatch(
        updateMeasureLayerConfigTemplateAction(
          message.payload.measure_layer_config_template
        )
      );
      break;
    case MEASURE_LAYER_CONFIG_TEMPLATE_DELETED_EVENT:
      store.dispatch(
        deleteMeasureLayerConfigTemplateAction(
          message.payload.measure_layer_config_template
        )
      );
      break;
    case MEASURE_LAYER_CONFIG_ITEM_CREATED_EVENT:
      store.dispatch(
        createMeasureLayerConfigItemAction(
          message.payload.measure_layer_config_template_item
        )
      );
      break;
    case MEASURE_LAYER_CONFIG_ITEM_UPDATED_EVENT:
      store.dispatch(
        updateMeasureLayerConfigItemAction(
          message.payload.measure_layer_config_item
        )
      );
      break;
    case MEASURE_LAYER_CONFIG_ITEM_DELETED_EVENT:
      store.dispatch(
        deleteMeasureLayerConfigItemAction(
          message.payload.measure_layer_config_item
        )
      );
      break;
  }
};

/**
 * Get a list of all available templates for an organization.
 *
 * {
 *   "add": {},
 *   "update": {},
 *   "delete": {}
 * }
 */
export const syncMeasureLayerConfigTemplates = () => {
  return async (dispatch, getState) => {
    dispatch(setSyncMeasureLayerConfigTemplatesStatus(pending));

    // Hint: the uuid and md5 hash are pseudo values.
    // They are needed to get proper data back from the backend.
    // The Backend splits the sync result into three parts, add, update and delete.
    const requestData = {
      params: [
        {
          measure_layer_config_template_uuid:
            "19aa62c2-2ca4-4af1-a659-ee4a60bc198b",
          measure_layer_config_template_md5_hash:
            "9E53820749C6C9D0BA8C6D0F3D8859D1",
        },
      ],
    };

    const orgaId = getOrgaId(getState());

    await HTTP.post(
      API.organizations.measureLayerConfigTemplates.syncMeasureLayerConfigTemplates(
        orgaId
      ),
      requestData
    )
      .then(function (response) {
        dispatch(setMeasureLayerConfigTemplates(response.data.add));
        dispatch(setSyncMeasureLayerConfigTemplatesStatus(success));
      })
      .catch(function (error) {
        dispatch(setSyncMeasureLayerConfigTemplatesStatus(fail));
      });
  };
};

/**
 * Copy all Items from a Template to a Project.
 *
 * @param {int} projectId
 * @param {object} payload
 * @returns
 */
export const copyMeasureLayerConfigTemplateToProject = (projectId, payload) => {
  return async (dispatch, getState) => {
    const orgaId = getOrgaId(getState());

    await HTTP.post(
      API.organizations.measureLayerConfigTemplates.copyMeasureLayerConfigTemplateToProject(
        orgaId,
        projectId
      ),
      payload
    ).catch(function (error) {});
  };
};

export const copyMeasureLayerConfigTemplate = (uuid) => {
  return async (dispatch, getState) => {
    const orgaId = getOrgaId(getState()); 
    await HTTP.post(
      API.organizations.measureLayerConfigTemplates.copyMeasureLayerConfigTemplate(
        orgaId
      ),
      {
        "params": {
          "uuid": uuid
        }
      }
    ).then(function (response) {
      dispatch(renderSnackbar(copyMeasureLayerConfigTemplateSuccess));
    }).catch(function (error) {
      dispatch(renderSnackbar(copyMeasureLayerConfigTemplateFail));
    });
  };
}

/**
 * Create a new Template.
 *
 * @param {object} payload
 * @returns
 */
export const createMeasureLayerConfigTemplate = (payload) => {
  return async (dispatch, getState) => {
    const orgaId = getOrgaId(getState());

    await HTTP.post(
      API.organizations.measureLayerConfigTemplates.createMeaureLayerConfigTemplate(
        orgaId
      ),
      payload
    ).catch(function (error) {});
  };
};

/**
 * Update information from an existing Template.
 *
 * @param {string} uuid
 * @param {object} payload
 * @returns
 */
export const updateMeasureLayerConfigTemplate = (uuid, payload) => {
  return async (dispatch, getState) => {
    const orgaId = getOrgaId(getState());

    await HTTP.put(
      API.organizations.measureLayerConfigTemplates.updateMeasureLayerConfigTemplate(
        orgaId,
        uuid
      ),
      payload
    ).catch(function (error) {});
  };
};

/**
 * Delete a Template.
 *
 * @param {string} uuid
 * @returns
 */
export const deleteMeasureLayerConfigTemplate = (uuid) => {
  return async (dispatch, getState) => {
    const orgaId = getOrgaId(getState());

    await HTTP.delete(
      API.organizations.measureLayerConfigTemplates.deleteMeasureLayerConfigTemplate(
        orgaId,
        uuid
      )
    ).catch(function (error) {});
  };
};

/**
 * Add to an existing Template a new Item.
 *
 * @param {string} templateUuid
 * @param {object} payload
 * @returns
 */
export const createMeasureLayerConfigItem = (templateUuid, payload) => {
  return async (dispatch, getState) => {
    const orgaId = getOrgaId(getState());

    await HTTP.post(
      API.organizations.measureLayerConfigTemplates.createMeasureLayerConfigItem(
        orgaId,
        templateUuid
      ),
      payload
    ).catch(function (error) {});
  };
};

/**
 * Update an existing Item.
 *
 * @param {string} uuid
 * @param {object} payload
 * @returns
 */
export const updateMeasureLayerConfigItem = (uuid, payload) => {
  return async (dispatch, getState) => {
    const orgaId = getOrgaId(getState());

    await HTTP.put(
      API.organizations.measureLayerConfigTemplates.updateMeasureLayerConfigItem(
        orgaId,
        uuid
      ),
      payload
    ).catch(function (error) {});
  };
};

/**
 * Delete an Item.
 *
 * @param {string} uuid
 * @returns
 */
export const deleteMeasureLayerConfigItem = (uuid) => {
  return async (dispatch, getState) => {
    const orgaId = getOrgaId(getState());

    await HTTP.delete(
      API.organizations.measureLayerConfigTemplates.deleteMeasureLayerConfigItem(
        orgaId,
        uuid
      )
    ).catch(function (error) {});
  };
};
