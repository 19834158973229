/* eslint-disable no-unused-vars */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import withStyles from "@mui/styles/withStyles";
import Input from "@mui/material/Input";
import AddCircleButton from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { sendInvitation } from "../../../actions/userAdministration";
import CustomDialog from "../../../components/CustomDialog";
import ButtonGroup from "../../../components/ButtonGroup";

import * as styles from "../../../styles/styles";
import "./styles.css";

const propTypes = {
  classes: PropTypes.object,
  headline: PropTypes.string,
  modalOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
  organizations: PropTypes.object,
  sendInvitation: PropTypes.func,
};

const overrideStyles = (theme) => ({
  addButton: {
    margin: "0 0 0 -1.2rem",
    position: "absolute",
  },
  deleteButton: {
    color: styles.darkBlueMedium,
    cursor: "pointer",
    margin: `0 0 0 ${styles.spacing16}`,
    "&:hover": {
      color: styles.darkBlueDark,
    },
  },
  input: {
    fontSize: "1.5rem",
  },
});

const InviteNewUser = ({
  classes,
  headline,
  modalOpen,
  onClose,
  onClick,
  organizations,
  sendInvitation,
}) => {
  const { t } = useTranslation();

  const [state, setState] = React.useState({
    maxUsersReached: false,
    users: {
      0: {
        email: "",
        valid: false,
      },
    },
  });

  const inviteUser = () => {
    const { users } = state;
    const keys = Object.keys(users);
    if (keys.every((key) => users[key].valid)) {
      const newUsers = {
        organization_invitations: keys.reduce((acc, key) => {
          const email = users[key].email;
          if (email.length > 0) return acc.concat({ email });
          return acc;
        }, []),
      };
      sendInvitation(newUsers);
      onClick();
      setState({
        ...state,
        invitingUserSuccess: true,
      });
    }
  };

  const addNewUser = (key) => {
    setState({
      ...state,
      users: {
        ...state.users,
        [key + 1]: {
          email: "",
          valid: true,
        },
      },
    });
  };

  const onChange = (event, key) => {
    const value = event.target.value.trim();
    const pattern =
      /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    setState({
      ...state,
      users: {
        ...state.users,
        [key]: {
          email: value.trim(),
          valid: value.length === 0 ? true : new RegExp(pattern).test(value),
        },
      },
    });
  };

  const deleteUser = (key) => {
    const users = { ...state.users };
    if (Object.keys(users).length === 1) {
      users[key].email = "";
    } else {
      delete users[key];
    }
    setState({
      ...state,
      users,
    });
  };

  const onKeyPress = (event, newKey) => {
    if (event.key === "Enter" || event.key === "Tab") {
      if (
        organizations.user_count + Object.keys(state.users).length ===
        organizations.max_user_count
      ) {
        setState({
          ...state,
          maxUsersReached: true,
        });
      } else {
        setState({
          ...state,
          maxUsersReached: false,
        });
        addNewUser(newKey);
      }
    }
  };

  const showInvitedUsers = (newKey, userKeys) => {
    return Object.keys(state.users).map((key, index) => {
      const user = state.users[key];
      return (
        <div className="invite-new-user-user" key={key}>
          <Input
            autoFocus={index === userKeys.length - 1 ? true : false}
            classes={{ root: classes.input }}
            fullWidth={true}
            name="email"
            onChange={(event) => onChange(event, key)}
            onKeyPress={(event) => onKeyPress(event, newKey)}
            placeholder={t('dialogs.InviteNewUser.inputEmailPlaceholder', {ns: 'containers'})}
            required={true}
            type="email"
            value={user.email}
          />
          <IconButton
            classes={{ root: classes.deleteButton }}
            onClick={() => deleteUser(key)}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </div>
      );
    });
  };

  const emailsValid = () => {
    const users = state.users;
    const keys = Object.keys(users);
    return keys.every((key) => users[key].valid);
  };

  const render = () => {
    const userKeys = Object.keys(state.users);
    const newKey = parseInt(userKeys[userKeys.length - 1], 10);
    return (
      <CustomDialog
        open={modalOpen}
        onClick={inviteUser}
        onClose={onClose}
        renderTitle={() => {
          return (
            <DialogTitle id="alert-dialog-title">
              {t('dialogs.InviteNewUser.txtDialogTitle', {ns: 'containers'})}
            </DialogTitle>
          );
        }}
        renderContent={() => {
          return (
            <DialogContent>
              <div className="invite-new-user-input-wrapper">
                {showInvitedUsers(newKey, userKeys)}
              </div>
              <div className="invite-new-user-add-button-wrapper">
                {state.maxUsersReached ? (
                  <Typography variant="body2" color="error">
                    {t('dialogs.InviteNewUser.txtMaxUserReached', {ns: 'containers'})}
                  </Typography>
                ) : (
                  <>
                    <IconButton
                      onClick={() => addNewUser(newKey)}
                      classes={{ root: classes.addButton }}
                      color="primary"
                      size="large"
                    >
                      <AddCircleButton />
                    </IconButton>
                    <Typography
                      style={{ margin: "0 0 0 4rem" }}
                      variant="body2"
                      color="primary"
                    >
                      {t('button.btnAddUser', {ns: 'common'})}
                    </Typography>
                  </>
                )}
              </div>
            </DialogContent>
          );
        }}
        renderButtonGroup={(onClick, onClose) => {
          return (
            <DialogActions style={{ padding: "2.4rem 2.4rem 2rem" }}>
              <ButtonGroup
                align="right"
                leftButtonText={t('button.btnCancel', {ns: 'common'})}
                leftButtonClick={onClose}
                rightButtonText={t('button.btnAddUser', {ns: 'common'})}
                rightButtonDisabled={!emailsValid()}
                rightButtonClick={onClick}
                spacingTop={true}
              />
            </DialogActions>
          );
        }}
      />
    );
  };

  return render();
};

function dispatchToProps(dispatch) {
  return bindActionCreators(
    {
      sendInvitation,
    },
    dispatch
  );
}

function stateToProps(state) {
  const organizations = state.get("organizations");
  return {
    organizations,
  };
}

InviteNewUser.propTypes = propTypes;
const styledInviteNewUser = withStyles(overrideStyles, { withTheme: true })(
  InviteNewUser
);
export default connect(stateToProps, dispatchToProps)(styledInviteNewUser);
