/* eslint-disable react/no-is-mounted */
import React, { Component } from "react";
import PropTypes from "prop-types";

import EmptyProjectMap from "./EmptyProjectMap";
import ProjectMap from "./ProjectMap";

const propTypes = {
  project: PropTypes.object,
  measurements: PropTypes.object,
  referencePoints: PropTypes.object,
  referencePointsPerLayer: PropTypes.array,
  measurementsPerLayer: PropTypes.array,
  volumes: PropTypes.object,
  mediaFiles: PropTypes.object,
  topographicPoints: PropTypes.object,
  topographicPointsPerLayer: PropTypes.array,
  componentCatalogItems: PropTypes.object,
  gnssPointsPerLayer: PropTypes.array,
  gnssRefPointsPerLayer: PropTypes.array,
  gnssLinesPerLayer: PropTypes.array,
  gnssPolygonPerLayer: PropTypes.array,
  gnssPoints: PropTypes.array,
  gnssImages: PropTypes.array
};

class MapSelector extends Component {
  renderMap() {
    const {
      project,
      measurements,
      referencePoints,
      referencePointsPerLayer,
      measurementsPerLayer,
      volumes,
      mediaFiles,
      topographicPoints,
      topographicPointsPerLayer,
      componentCatalogItems,
      gnssPointsPerLayer,
      gnssRefPointsPerLayer,
      gnssLinesPerLayer,
      gnssPolygonPerLayer,
      gnssPoints,
      gnssImages
    } = this.props;

    if (Object.keys(measurements).length > 0 || Object.keys(volumes).length > 0 || gnssPoints.length > 0 || gnssImages.length > 0)
      return (
        <ProjectMap
          project={project}
          measurements={measurements}
          referencePoints={referencePoints}
          referencePointsPerLayer={referencePointsPerLayer}
          measurementsPerLayer={measurementsPerLayer}
          volumes={volumes}
          mediaFiles={mediaFiles}
          topographicPoints={topographicPoints}
          topographicPointsPerLayer={topographicPointsPerLayer}
          componentCatalogItems={componentCatalogItems}
          gnssPointsPerLayer={gnssPointsPerLayer}
          gnssRefPointsPerLayer={gnssRefPointsPerLayer}
          gnssLinesPerLayer={gnssLinesPerLayer}
          gnssPolygonPerLayer={gnssPolygonPerLayer}
          gnssPoints={gnssPoints}
          gnssImages={gnssImages}
        />
      );
    return <EmptyProjectMap project={project} />;
  }

  render() {
    return this.renderMap();
  }
}

MapSelector.propTypes = propTypes;
export default MapSelector;
